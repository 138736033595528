import styled from "@emotion/styled";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import ReportsModal from "components/navigation/expandableSection/ReportsModal";
import SpeedoGraph from "components/recharts/test/SpeedoGraph";
import useReport from "hooks/reports/useReport";
import { sumOfMonth } from "lib/apiDataHelpers/sumOfMonths";
import {
    Bar,
    BarChart,
    CartesianAxis,
    Cell,
    LabelList,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from "recharts";
import { theme } from "style/theme";

import RevenueDetailBudget from "./ModelBudget";

const RevenueSectionBudget = () => {
    const report = useReport();

    if (!report) {
        return null;
    }

    const {
        revenue: { actual, budget },
        reportDates: { selectedMonth },
    } = report;
    const actualForMonth = sumOfMonth(actual[selectedMonth]);
    const budgetForMonth = sumOfMonth(budget[selectedMonth]);
    const varianceForMonth = actualForMonth - budgetForMonth;

    const BudgetOverRev = Math.round((actualForMonth / budgetForMonth) * 100);

    const data = [
        {
            name: "Actual",
            color: theme.colors.Yellow,
            key: actualForMonth,
        },
        {
            name: "Budget",
            color: theme.colors.DarkPink,
            key: budgetForMonth,
        },
    ];
    return (
        <ItemSection id='month-budget' light minHeight={355}>
            <div className='col-md-8'>
                <h1>
                    Here's your current revenue compared to your budget in
                    {" " +
                        selectedMonth.selectByMonths(
                            selectedMonth.toTextMonth(),
                            selectedMonth.toTextMonth(),
                            selectedMonth.toMmmmYyyy(),
                        )}
                </h1>
                <figure>
                    <ResponsiveContainer width='100%' height={200}>
                        <BarChart
                            barCategoryGap={20}
                            data={data}
                            layout='vertical'
                            margin={{ top: 5, right: 100, left: 0, bottom: 5 }}>
                            <CartesianAxis axisLine={false} />
                            <XAxis
                                type='number'
                                style={{ fill: theme.colors.DarkBlue }}
                                stroke={"none"}
                                tickFormatter={(value: number) =>
                                    Writer.FormatCurrency(value)
                                }
                            />
                            <YAxis
                                type='category'
                                dataKey='name'
                                style={{ fill: theme.colors.DarkBlue }}
                                stroke={"none"}
                            />
                            <Bar
                                isAnimationActive={false}
                                dataKey='key'
                                fill={theme.colors.Yellow}>
                                <LabelList
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                    }}
                                    fill={theme.colors.DarkBlue}
                                    formatter={(value: number) =>
                                        Writer.FormatCurrency(value)
                                    }
                                    dataKey='key'
                                    position='right'
                                />
                                {data.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={entry.color}
                                    />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </figure>
            </div>
            <SpeedoContainer>
                <SpeedoGraph
                    chartValue={
                        BudgetOverRev === -Infinity ||
                        BudgetOverRev === Infinity
                            ? 0
                            : BudgetOverRev
                    }
                    chartMax={200}
                />
                <h3>
                    {budgetForMonth ? (
                        <span>
                            So far this month you generated{" "}
                            {Writer.FormatCurrency(Math.abs(varianceForMonth))}{" "}
                            {varianceForMonth > 0 ? "more" : "less"} than you
                            budgeted
                        </span>
                    ) : (
                        "You have no Budget please do your budget to use this graph"
                    )}
                </h3>
            </SpeedoContainer>

            <ReportsModal light path='budget-model'>
                <RevenueDetailBudget />
            </ReportsModal>
        </ItemSection>
    );
};

export default RevenueSectionBudget;

const SpeedoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    text-align: center;
    margin: 0 auto;
`;
