import styled from "@emotion/styled";
import LogoHorizontal from "components/brand/LogoHorizontal";
import { AnimatedSymbol } from "components/general/AnimatedSymbol";
import CoverPage from "components/general/CoverPage/CoverPage";
import FullScreenNav from "components/navigation/fullScreenNav";
import PhoneNav from "components/navigation/PhoneNav";
import useWindowDimensions from "hooks/useWindowDimensions";
import { ReactNode } from "react";
import { theme } from "style/theme";

interface ReportsCoverPageProps {
    name: string;
    children: ReactNode;
}

const ReportsCoverPage = ({ name, children }: ReportsCoverPageProps) => {
    const { width } = useWindowDimensions();
    return (
        <div>
            <CoverPage>
                <TopBar>
                    <LogoHorizontal
                        absolute={false}
                        showText={false}
                        asLink={true}
                    />
                    <FullScreenNav />
                    {width <= theme.sizes.contentWidthPhoneNumber && (
                        <PhoneNav />
                    )}
                </TopBar>

                <AnimatedSymbol />
                <span className='styalised-graph'></span>
                <Children>{children}</Children>
            </CoverPage>
        </div>
    );
};

export default ReportsCoverPage;

const TopBar = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 24px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.DarkBlue};
`;

const Children = styled.div`
    padding: 8px;
    margin-bottom: 64px;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;

    header,
    .feature {
        max-width: ${({ theme }) => theme.sizes.contentWidth} / 2;
        text-align: center;
        font-size: ${({ theme }) => theme.fonts.defaultFontSize} + 16px;
        font-weight: 500;

        h1 {
            text-transform: uppercase;
            font-weight: 700;
            font-size: $default-font-size + 23px;
            border-bottom: 1px solid ${({ theme }) => theme.colors.DarkBlue};
            padding: 5px 0;
            margin: 0 0 40px 0;
        }
    }
`;
