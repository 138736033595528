import { css } from "@emotion/react";
import styled from "@emotion/styled";

interface PropgressProps {
    progress: number;
}

enum ProgressStatus {
    Done = "Done",
    Current = "Current",
    Upcoming = "Upcoming",
}

const getStatus = (index: number, progress: number) => {
    if (index < progress) {
        return ProgressStatus.Done;
    }
    if (index === progress) {
        return ProgressStatus.Current;
    }
    return ProgressStatus.Upcoming;
};

const Progress = ({ progress }: PropgressProps) => {
    return (
        <Container>
            {steps.map((step, index) => {
                const status = getStatus(index, progress);
                return (
                    <Step first={index === 0} key={index} progress={status}>
                        {index > 0 && <StepLine progress={status} />}
                        <StepContent progress={status}>
                            <span>
                                {status === ProgressStatus.Done && (
                                    <svg
                                        width='10'
                                        height='7'
                                        viewBox='0 0 12 9'
                                        fill='currentColor'>
                                        <path d='M1.05025 3.70714C1.44077 3.31661 2.07394 3.31661 2.46446 3.70714L5.29289 6.53556C5.68341 6.92609 5.68341 7.55925 5.29289 7.94978C4.90236 8.3403 4.2692 8.3403 3.87867 7.94978L1.05025 5.12135C0.659724 4.73083 0.659724 4.09766 1.05025 3.70714Z' />
                                        <path d='M10.9498 0.878709C11.3403 1.26923 11.3403 1.9024 10.9498 2.29292L5.29289 7.94978C4.90236 8.3403 4.2692 8.3403 3.87867 7.94978C3.48815 7.55925 3.48816 6.92609 3.87869 6.53556L9.53554 0.878709C9.92606 0.488184 10.5592 0.488184 10.9498 0.878709Z' />
                                    </svg>
                                )}
                            </span>
                            <div>{step}</div>
                        </StepContent>
                    </Step>
                );
            })}
        </Container>
    );
};

export default Progress;

const Container = styled.div`
    display: flex;
    width: 900px;
`;

const Step = styled.div<{ first: boolean; progress: ProgressStatus }>`
    display: flex;
    width: ${({ first }) => (first ? "auto" : "100%")};
`;

const StepContent = styled.div<{ progress: ProgressStatus }>`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 80px;
    z-index: 1;

    > span {
        width: 16px;
        height: 16px;
        display: flex;
        align-items: center;
        justify-content: center;
        border-radius: 100%;

        background-color: ${({ theme, progress }) => {
            switch (progress) {
                case ProgressStatus.Done:
                    return theme.colors.DarkBlue;
                case ProgressStatus.Current:
                    return theme.colors.DarkBlue;
                case ProgressStatus.Upcoming:
                    return theme.colors.LightAqua;
            }
        }};
        border: 4px solid
            ${({ theme, progress }) => {
                switch (progress) {
                    case ProgressStatus.Done:
                        return theme.colors.DarkBlue;
                    case ProgressStatus.Current:
                        return theme.colors.DarkBlue;
                    case ProgressStatus.Upcoming:
                        return theme.colors.LightAqua;
                }
            }};

        ${({ theme, progress }) => {
            switch (progress) {
                case ProgressStatus.Done:
                    return css`
                        background-color: ${theme.colors.DarkPink};
                        border: 4px solid ${theme.colors.DarkPink};
                    `;
                case ProgressStatus.Current:
                    return css`
                        background-color: ${theme.colors.Pink};
                        border: 4px solid ${theme.colors.DarkPink};
                    `;
                case ProgressStatus.Upcoming:
                    return css`
                        background-color: ${theme.colors.LightAqua};
                        border: 4px solid ${theme.colors.Pink};
                    `;
            }
        }}
    }

    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        text-align: center;
    }
`;

const StepLine = styled.div<{ progress: ProgressStatus }>`
    height: 2px;
    width: 100%;
    background-color: ${({ theme, progress }) => {
        switch (progress) {
            case ProgressStatus.Done:
                return theme.colors.DarkBlue;
            case ProgressStatus.Current:
                return theme.colors.DarkBlue;
            case ProgressStatus.Upcoming:
                return theme.colors.LightAqua;
        }
    }};
    margin-top: 6px;
    margin-left: -40px;
    margin-right: -40px;
`;

const steps = ["Create Account", "Connect Xero", "Payment"];
