import styled from "@emotion/styled";
import { ReactComponent as ConnectXero } from "assets/img/xero/connect-blue.svg";
import initiateXeroConnect from "components/helper/initiateXeroConnect";
import {
    AccountingProvider,
    useProviderDisconnectMutation,
    useXeroConnectionsQuery,
    useXeroLoginUrlQuery,
} from "redux/rtkQuery/xero";
// import { ReactComponent as DisconnectBlue } from "assets/img/xero/disconnect-blue.svg";

const Integrations = () => {
    const { data: providerConnections, isLoading } = useXeroConnectionsQuery();
    const { data: loginUrl } = useXeroLoginUrlQuery();
    const [disconnectProvider] = useProviderDisconnectMutation();
    return providerConnections ? (
        <Container>
            <h1> Integrations</h1>

            {/* <label>Xero</label> */}
            {/* {
                        <ConnectXero>
                           {xeroConnections && xeroConnections.length > 0 ? (
                                <>
                                    <h4>Connected organisations:</h4>
                                    <ul>
                                        {xeroConnections.map((c, index) => (
                                            <li>{c.TennantName}</li>
                                        ))}
                                    </ul>
                                    Add more organisations:
                                    <ConnectXeroButton onClick={handleXero}>
                                        <ConnectBlue />
                                    </ConnectXeroButton> 
                                </>
                            ) : (
                                <ConnectXeroButton
                                    onClick={() => {
                                        if (loginUrl) {
                                            window.location.href = loginUrl;
                                        }
                                    }}>
                                    <ConnectBlue />
                                </ConnectXeroButton>
                            )} 
                        </ConnectXero>
                    } */}
            <IntegrationContainer>
                {isLoading ? (
                    <div>Loading...</div>
                ) : (
                    providerConnections &&
                    providerConnections.length > 0 &&
                    providerConnections.map((c, index) => {
                        return c.provider === AccountingProvider.xero ? (
                            <IntegrationButton>
                                <span>
                                    {c.organisationName} (
                                    {AccountingProvider[c.provider]}):{" "}
                                </span>
                                <DisconnectXero
                                    onClick={() =>
                                        confirm(
                                            "Are you sure you want to disconnect, this will remove all your data?",
                                        ) &&
                                        disconnectProvider([
                                            c.connectionId,
                                            c.providerOrginisationId,
                                        ])
                                    }>
                                    Disconnect
                                </DisconnectXero>
                            </IntegrationButton>
                        ) : (
                            <span>TODO: disconnect quickbooks</span>
                        );
                    })
                )}
            </IntegrationContainer>
            <h2>Connect another Xero organisation:</h2>
            <p>To connect another xero organisation, click the button below.</p>
            <p>
                When you get to the xero page, ensure the orginisation you wish
                to connect is selected in the dropdown at the top of the page.
            </p>
            <ConnectXero
                style={{ cursor: "pointer" }}
                onClick={initiateXeroConnect}
            />
        </Container>
    ) : null;
};

export default Integrations;

const IntegrationContainer = styled.div`
    display: flex;
    flex-direction: column;
    background-color: #f5f5f5;
    width: 350px;
    border-radius: 10px;
    overflow: hidden;
`;

const IntegrationHeader = styled.div`
    display: flex;

    svg {
        width: 50px;
        height: 50px;
    }
`;

const IntegrationBody = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
    text-align: center;
    font-size: 14px;
`;

const IntegrationButton = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #e2e2e2;
    padding: 10px;
`;

const DisconnectXero = styled.button`
    background-color: #ff5454;
    border: none;
    border-radius: 5px;
    color: white;

    &:hover {
        cursor: pointer;
        background-color: #ffa3a3;
    }
`;

const Container = styled.div``;
