import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
    BudgetSources,
    OrganisationIdentifier,
    OrgSubscriptionStatus,
} from "common/types/objects/Organisation";
import DropDownMenu from "components/general/DropMenu";
import EditBudget from "components/popups/EditBudget";
import useAuthState from "hooks/firebase/useAuthState";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { useEffect, useMemo } from "react";
import { NavLink } from "react-router-dom";
import organsationSlice, {
    useGetOrganisationsQuery,
    useSyncDataMutation,
} from "redux/rtkQuery/organisations";
import { useAppDispatch } from "redux/store";

import ChangeOrg from "./nav/changeOrg";
import User from "./User";
import { ReactComponent as UpdatingSVG } from "../../assets/general/updating.svg";

/**
 * FullScreenNav component renders a full screen navigation bar.
 * @returns {JSX.Element} The rendered FullScreenNav component.
 */
const FullScreenNav = () => {
    const dispatch = useAppDispatch();
    const [user] = useAuthState();
    const { data } = useGetOrganisationsQuery(undefined, { skip: !user });

    const organisations = useMemo(
        () =>
            data?.organisations.filter(
                org => org.subscriptionStatus !== OrgSubscriptionStatus.none,
            ),
        [data?.organisations],
    );
    const currentOrganisation = data?.currentOrganisation;

    /**
     * createOrgComponents creates a list of organization components.
     * @param {any[]} orgArray - An array of organizations.
     * @returns {JSX.Element[]} An array of organization components.
     */
    const createOrgComponents = (orgArray: any[]) => {
        return orgArray.map(org => {
            return (
                <div
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                    }}>
                    {ChangeOrg({
                        org,
                        updateSelectedOrganisation: org =>
                            dispatch(
                                organsationSlice.util.updateQueryData(
                                    "getOrganisations",
                                    undefined,
                                    old => {
                                        old.currentOrganisation = org;
                                    },
                                ),
                            ),
                    })}
                </div>
            );
        });
    };

    return (
        <>
            <Container>
                {/* Subscription NavLink */}
                <span>
                    <NavLink to='/subscribe'>Subscription</NavLink>
                </span>

                {/* Budget Editor NavLink */}
                <span>
                    {currentOrganisation?.budgetSource ===
                    BudgetSources.hellocashflow ? (
                        <NavLink to='/budget-editor/revenue-&-expenses'>
                            Budget Editor
                        </NavLink>
                    ) : currentOrganisation ? (
                        <EditBudget organisation={currentOrganisation} />
                    ) : (
                        ""
                    )}
                </span>

                {/* Reports NavLink */}
                <span>
                    <NavLink to='/reports/my-revenue'>Reports</NavLink>
                </span>
                {/* DropDownMenu with organizations */}
                <DropDownMenu
                    line
                    menu={{
                        title: currentOrganisation?.name,
                        items: createOrgComponents(organisations ?? []),
                    }}
                />
                {/* User component - renders the user name, icon, and can be clicked to show user information */}
                <User />
                {/* Update component for syning data */}
                <Update />
            </Container>
        </>
    );
};

export default FullScreenNav;

/**
 * Update component renders the update status and provides a click handler to sync data.
 * @returns {JSX.Element} The rendered Update component.
 */
const Update = () => {
    const [user, loading] = useAuthState();
    const currentOrganisation = useCurrentOrg();
    const lastSyncedKey = `lastSynced-${currentOrganisation?.organisationId}`;

    const getLastSynced = (): Date | undefined => {
        const storedValue = localStorage.getItem(lastSyncedKey);
        if (storedValue) {
            return new Date(storedValue);
        }
        return undefined;
    };

    const setLastSynced = (value: Date) => {
        localStorage.setItem(lastSyncedKey, value.toISOString());
    };

    const [syncData, { fulfilledTimeStamp, isError, error, isLoading }] =
        useSyncDataMutation({
            fixedCacheKey: "syncData",
        });

    const time = useMemo(() => {
        return fulfilledTimeStamp
            ? new Date(fulfilledTimeStamp).toLocaleString()
            : undefined;
    }, [fulfilledTimeStamp]);

    useEffect(() => {
        const lastSynced = getLastSynced();
        if (
            user &&
            currentOrganisation &&
            !/^\/onboarding/.test(window.location.pathname) &&
            !/^\/account\/connect/.test(window.location.pathname) &&
            //only auto sync if less than ten minutes since last sync
            (!lastSynced ||
                new Date().getTime() - lastSynced.getTime() > 600000)
        ) {
            setLastSynced(new Date());
            console.log("syncing data auto");
            //if currentOrg.dataSyncedFrom is less than 2 years ago, they haven't been properly onboarded and we should download 3 years, otherwise just sync 1 year
            doDataSync(currentOrganisation, syncData);
        }
        //including lastSynced in the dependency array will cause the data to sync on the schedule, this is probably desired
        //Ultimatly we would want to try and impliment a "has anyting actually changed" solution for all accounting providers
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [currentOrganisation, syncData, user]);

    return (
        <UpdateStyle loading={isLoading ? "true" : ""}>
            <UpdatingSVG
                onClick={() => doDataSync(currentOrganisation, syncData)}
                title={
                    time
                        ? `last updated at ${time}`
                        : isError
                        ? `Error: ${error}`
                        : "has not been updated this session"
                }
            />
        </UpdateStyle>
    );
};

const UpdateStyle = styled.div<{ loading: string }>`
    cursor: pointer;
    width: 35px;
    height: 35px;
    ${({ loading }) =>
        loading &&
        css`
            cursor: default;
            animation: 2.5s infinite linear slidein;
            @keyframes slidein {
                from {
                    rotate: 0deg;
                }

                to {
                    rotate: 360deg;
                }
            }
        `}
`;

const Container = styled.div`
    height: 100%;
    width: 80%;
    display: flex;
    align-items: center;
    justify-content: space-evenly;
    font-size: 16px;
    @media (max-width: ${({ theme }) =>
            theme.sizes.contentWidthPhoneNumber + 1}px) {
        display: none;
    }
    a {
        position: relative;
        text-decoration: none;
        color: black;
        &:hover {
            &::after {
                left: 16px;
                width: calc(100%);
                background: #000;
                top: 22px;
                left: 0;
                content: "";
                height: 3px;
                position: absolute;
                color: #000;
                font-family: Inter, X-LocaleSpecific, sans-serif;
                font-size: 16px;
                font-weight: 700;
                line-height: 1.5;
            }
        }
    }
`;
function doDataSync(
    currentOrganisation: OrganisationIdentifier | undefined,
    syncData: (data: {
        organisation: OrganisationIdentifier;
        years: number;
    }) => any,
) {
    if (!currentOrganisation) {
        return;
    }
    const ls = localStorage.getItem(
        `synced-years-${currentOrganisation.organisationId}`,
    );
    const dataSyncedFromDate = new Date(currentOrganisation.dataSyncedFrom);
    const yearsToSync = dataSyncedFromDate
        ? new Date().getFullYear() - dataSyncedFromDate.getFullYear() > 2 ||
          (ls && parseInt(ls) > 2)
            ? 1
            : 3
        : 1;
    syncData({
        organisation: currentOrganisation,
        years: yearsToSync,
    });
}
