import MonthKey from "common/types/monthKey";
import { format } from "date-fns";
import { addMonthsTo } from "lib/date/addMonthsTo";

//REgardless of which month is selected, we sometimes need to know what the last complete month is (ee, editing data)
const d_ = new Date();
export const CURRENT_MONTH = format(d_, "yyyy-MM") as MonthKey;

export const YEAR_ENDED_THIS_MONTH = Array.from(Array(12).keys()).map(i =>
    addMonthsTo(CURRENT_MONTH, -11 + i),
);

d_.setDate(0); //Previous month
export const LATEST_COMPLETE_MONTH = format(d_, "yyyy-MM") as MonthKey;

export const YEAR_ENDED_LAST_COMPLETE_MONTH = Array.from(Array(12).keys()).map(
    i => addMonthsTo(LATEST_COMPLETE_MONTH, -11 + i),
);

export const DATE_START_MONTH = addMonthsTo(LATEST_COMPLETE_MONTH, -36);
export const DATE_END_MONTH = addMonthsTo(LATEST_COMPLETE_MONTH, 1);
export const DATE_BUDGET_END_MONTH = addMonthsTo(LATEST_COMPLETE_MONTH, 13);
