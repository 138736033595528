import React, { memo } from "react";
type intrinsicAnchor = JSX.IntrinsicElements["a"];

interface SmoothScrollLinkProps extends intrinsicAnchor {
    to: string;
}

const smoothScrollTo = (anchorRef: string | null) => {
    if (anchorRef?.charAt(0) !== "#") return;
    const element = document.querySelector(anchorRef);
    // if (element?.scrollIntoView) {
    //   setTimeout(() => {
    //     element?.scrollIntoView({ behavior: "smooth", block: "end", inline: "nearest" })
    //   })
    //   return true
    // }
    if (element) {
        const elementY = element.getBoundingClientRect().top + window.scrollY;

        window.scrollTo({
            top: elementY,
            behavior: "smooth",
        });
    }
    return false;
};

const SmoothScrollLink = ({ children, to }: SmoothScrollLinkProps) => {
    const handleClick = (
        e: React.MouseEvent<HTMLAnchorElement, MouseEvent>,
    ) => {
        e.preventDefault();
        return smoothScrollTo(e.currentTarget.getAttribute("href"));
    };

    return (
        <a onClick={handleClick} href={to}>
            {children}
        </a>
    );
};

export default memo(SmoothScrollLink);
