import styled from "@emotion/styled";
import monthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import SelectRevStream from "components/inputs/SelectRevStream";
import Bubble from "components/reports/Bubble";
import useReport from "hooks/reports/useReport";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { toMmmYy } from "lib/date/reStringifyMonth";
import {
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { theme } from "style/theme";
import { ItemCustomClasification, OrgData } from "types/OrganisationType";

import { ChartHeader } from "./Styled";

interface AverageRevProps {
    customerName: string;
    customerInputs: OrgData;
}

const AverageRev = ({ customerInputs, customerName }: AverageRevProps) => {
    const report = useReport();

    if (!report) return null;

    const {
        revenue: { actual: revenueActual },
        items,
        reportDates: { lastTwelveMonths },
    } = report;

    const itemChecker = (month: monthKey) => {
        const object = revenueActual[month];
        if (object) {
            const total = Object.entries(object)
                .filter(
                    ([code, number]) =>
                        items[code].itemClasification ===
                        ItemCustomClasification.CustomerRevenue,
                )
                .reduce(
                    (a, [code, number]) => a + (isNaN(number) ? 0 : number),
                    0,
                );
            return total;
        }
        return 0;
    };

    const revenueCustomerData = lastTwelveMonths.map(m => {
        const number = (customerInputs[m] && customerInputs[m].actual) || 0;
        const output = itemChecker(m) / number;
        return {
            name: m,
            revenue: output === Infinity || !output ? 0 : output,
        };
    });
    return (
        <ItemSection padding='0' light column>
            <ChartHeader>
            <h1>
                Average revenue per{" "}
                {customerName.toLowerCase().slice(0, customerName.length - 1)}
            </h1>
            <h2>
                Total revenue divided by total {customerName.toLowerCase()}
            </h2>
            </ChartHeader>
            <SelectRevStream />
            <ResponsiveContainer height={300} width='100%'>
                <LineChart data={revenueCustomerData}>
                    <XAxis
                        interval={0}
                        type='category'
                        dataKey='name'
                        style={{ fill: theme.colors.DarkBlue }}
                        // stroke={"none"}
                        tick={tickMonthWrap}
                    />
                    <ReferenceLine
                        y={0}
                        label=''
                        stroke='red'
                        strokeDasharray='3 3'
                    />
                    <YAxis
                        type='number'
                        style={{ fill: theme.colors.DarkBlue }}
                        // stroke={"none"}
                        tickFormatter={(value: number) =>
                            Writer.FormatCurrency(value)
                        }
                    />
                    <Tooltip
                        labelStyle={{
                            color: theme.colors.DarkBlue,
                        }}
                        labelFormatter={props => toMmmYy(props)}
                        formatter={value =>
                            Writer.FormatCurrency(value as number)
                        }
                    />
                    <Line
                        name='Revenue'
                        strokeWidth={5}
                        type='monotone'
                        dataKey='revenue'
                        stroke={theme.colors.Yellow}
                    />
                </LineChart>
            </ResponsiveContainer>
            <Row>
                <Bubble>
                    <h3>Average Monthly Revenue Per Customer</h3>
                    <mark>
                        $
                        {(
                            revenueCustomerData.reduce((output, input) => {
                                return output + input.revenue || 0;
                            }, 0) / 12
                        ).toFixed(2)}
                    </mark>
                    <span></span>
                </Bubble>
            </Row>
        </ItemSection>
    );
};

export default AverageRev;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
`;
