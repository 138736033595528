import { USER_TAG } from "common/constants/TAGS";
import { initializeApp } from "firebase/app";
import {
    getAuth,
    onAuthStateChanged,
    signInWithCustomToken,
    User,
} from "firebase/auth";
import { getFirestore } from "firebase/firestore";
import { getFunctions } from "firebase/functions";
import { api } from "redux/rtkQuery/api";
import { stripeSlice } from "redux/rtkQuery/stripe";

// TODO: Replace the following with your app's Firebase project configuration
// See: https://firebase.google.com/docs/web/learn-more#config-object
const config = {
    apiKey: process.env.REACT_APP_FIREBASE_APIKEY,
    authDomain: process.env.REACT_APP_FIREBASE_AUTHDOMAIN,
    projectId: process.env.REACT_APP_FIREBASE_PROJECTID,
    storageBucket: process.env.REACT_APP_FIREBASE_STORAGEBUCKET,
    messagingSenderId: process.env.REACT_APP_FIREBASE_MESSAGINGSENDERID,
    appId: process.env.REACT_APP_FIREBASE_APPID,
};
const app = initializeApp(config);
export const firestore = getFirestore(app);
export const auth = getAuth(app);
export const functions = getFunctions(app, "australia-southeast1");
export const signInCustomToken = async (token: string) =>
    await signInWithCustomToken(auth, token);

export const subbed: Array<(user: User | null) => void> = [];

onAuthStateChanged(auth, (user: User | null) => {
    // console.log(`Auth changed: ${JSON.stringify(user, null, 2)}`);
    subbed.forEach(cb => cb(user ? { ...user } : null));
});

export const subScribeToAuthStateChange = (
    callback: (user: User | null) => void,
) => {
    subbed.push(callback);
    return () => {
        subbed.indexOf(callback) > -1 &&
            subbed.splice(subbed.indexOf(callback), 1);
    };
};

subScribeToAuthStateChange(_user => {
    api.util.invalidateTags([USER_TAG]);
    stripeSlice.util.invalidateTags([USER_TAG]);
});

export const updateUserState = () => {
    subbed.forEach(cb => cb(auth.currentUser ? auth.currentUser : null));
};

export const getCustomClaims: () => Promise<
    Record<string, any> | undefined
> = async () => {
    const token = await auth.currentUser?.getIdTokenResult();
    return token?.claims;
};

export const getUserType: () => Promise<{
    isAppstore: boolean;
    isXero: boolean;
    isQuickbooks: boolean;
}> = async () => {
    const claims = await getCustomClaims();
    return {
        isAppstore: !!claims?.appStore,
        isXero: !!claims?.xero,
        isQuickbooks: !!claims?.quickbooks,
    };
};
