import styled from "@emotion/styled";
import LogoHorizontal from "components/brand/LogoHorizontal";
import FullScreenNav from "components/navigation/fullScreenNav";
import PhoneNav from "components/navigation/PhoneNav";
import Footer from "components/reports/ReportsFooter/ReportsFooter";

const TermsAndConditions = () => {
    return (
        <Container>
            <TopBar>
                <LogoHorizontal
                    absolute={false}
                    showText={false}
                    asLink={true}
                />
                <FullScreenNav />
                <PhoneNav />
            </TopBar>
            <Page>
                <h1>
                    <span>Hello Cashflow Terms of Service</span>
                </h1>
                <p>
                    <span></span>
                </p>
                <p>
                    <span>
                        This terms and conditions notice was last updated on:
                        2nd September 2022.
                    </span>
                </p>
                <p>
                    <span></span>
                </p>
                <h2>
                    <span>
                        1. What this agreement <br />
                        is about
                    </span>
                </h2>
                <p>
                    <span>
                        1.1.If you are a user of Hello Cashflow products and
                        services (&ldquo;Hello Cashflow&rdquo;), this agreement
                        describes how you may use Hello Cashflow and is made up
                        of these terms and conditions and our Privacy Notice.
                    </span>
                </p>
                <p>
                    <span>
                        1.2. If you are a partner and have subscribed to the
                        Hello Cashflow Partner Program terms and conditions
                        (&ldquo;Hello Cashflow Partner&rdquo;), this agreement
                        describes how you and your own clients may use Hello
                        Cashflow and this agreement applies in addition to the
                        terms and conditions of the Hello Cashflow Partner
                        Program as applicable to and accepted by you. If there
                        is any difference between this agreement and the Hello
                        Cashflow Partner Program terms and conditions, the terms
                        of this agreement will take precedence in relation to
                        your operation and use of Hello Cashflow.
                    </span>
                </p>
                <p>
                    <span>
                        1.3. In this agreement, where we say &ldquo;Hello
                        Cashflow&rdquo;, we mean all Products and Services
                        offered by Hello Cashflow Ltd, which consist of
                        financial and non-financial forecasting, reporting,
                        monitoring, analysis, consolidation and benchmarking for
                        business enterprises, accountants and bookkeepers
                        (&ldquo;Hello Cashflow Partners&quot;), and include
                        other Services offered by Hello Cashflow from time to
                        time which you have selected and which are subject to
                        these Terms. Hello Cashflow primarily offers three
                        distinct types of subscriptions: pay as you go
                        (&ldquo;PAYG&quot;) subscriptions, tiered plan
                        (&ldquo;Partner&quot;) subscriptions and additional
                        (&ldquo;Additional&quot;) subscriptions for add-on
                        services, such as Whitelabelling and Consolidation where
                        appropriate.
                    </span>
                </p>
                <p>
                    <span>1.4. In this agreement:</span>
                </p>
                <p>
                    <span>
                        1.4.2 &ldquo;Customer Data&rdquo; shall mean the data,
                        information or material provided, inputted or submitted
                        by you or on your behalf into Hello Cashflow, which
                        includes any data which has been retrieved with your
                        permission by Hello Cashflow from one of our integration
                        partners (such as Xero or Quickbooks) which is processed
                        by Hello Cashflow and which may include data relating to
                        you or your clients&rsquo; customers and/or suppliers.
                    </span>
                </p>
                <p>
                    <span>
                        1.4.3 &ldquo;Customer Personal Data&rdquo; has the
                        meaning set out in clause 10.1.
                    </span>
                </p>
                <p>
                    <span>
                        1.4.4 &ldquo;Data Controller&rdquo; means the natural or
                        legal person, public authority, agency or other body
                        which, alone or jointly with others, determines the
                        purposes and means of the Processing of Personal Data;
                        where the purposes and means of such Processing are
                        determined by New Zealand law, the controller or the
                        specific criteria for its nomination may be provided for
                        by New Zealand law.
                    </span>
                </p>
                <p>
                    <span>
                        1.4.5 &ldquo;Data Processor&rdquo; means the natural or
                        legal person, public authority, agency or other body
                        which Processes Personal Data on behalf of the Data
                        Controller.
                    </span>
                </p>
                <p>
                    <span>
                        1.4.6 &ldquo;Data Protection Laws&rdquo; means all
                        applicable New Zealand laws and regulations governing
                        the use or processing of Personal Data, including (where
                        applicable) the Privacy Act 2020 and any national
                        implementing laws, regulations and secondary
                        legislation, as amended or updated from time to time.
                    </span>
                </p>
                <p>
                    <span>
                        1.4.7 &ldquo;Personal Data&rdquo; means any information
                        relating to an identified or identifiable natural person
                        (&ldquo;Data Subject&rdquo;); an identifiable natural
                        person is one who can be identified, directly or
                        indirectly, in particular by reference to an identifier
                        such as a name, an identification number, location data,
                        an online identifier or to one or more factors specific
                        to the physical, physiological, genetic, mental,
                        economic, cultural or social identity of that natural
                        person.
                    </span>
                </p>
                <p>
                    <span>
                        1.4.8 &ldquo;Privacy Notice&rdquo; means our privacy
                        notice posted on www.Hello Cashflow.com (or such other
                        URL as we may notify to you) and which may be amended by
                        Hello Cashflow Ltd from time to time.
                    </span>
                </p>
                <p>
                    <span>
                        1.4.9 &ldquo;Processing&rdquo; means any operation or
                        set of operations which is performed on Personal Data or
                        on sets of Personal Data, whether or not by automated
                        means, such as collection, recording, organisation,
                        structuring, storage, adaptation or alteration,
                        retrieval, consultation, use, disclosure by
                        transmission, dissemination or otherwise making
                        available, alignment or combination, restriction,
                        erasure or destruction and &ldquo;Process&rdquo;,
                        &ldquo;Processed&rdquo; and &ldquo;Processes&rdquo;
                        shall be construed accordingly.
                    </span>
                </p>
                <p>
                    <span>
                        1.4.10 &ldquo;Supervisory Authority&rdquo; means an
                        independent public authority which is established under
                        applicable New Zealand law and which concerns itself
                        with the Processing of Personal Data.
                    </span>
                </p>
                <p>
                    <span>
                        1.4.11 &ldquo;Organisation&rdquo; means a logically
                        separated area in Hello Cashflow Platform containing
                        data belonging to a single business. Users may
                        eventually have access to more than one Organisation
                        should they be using Hello Cashflow to manage the
                        affairs of more than one business.
                    </span>
                </p>
                <p>
                    <span>
                        1.4.12 &ldquo;Subscriber User&rdquo; means any user of
                        the Hello Cashflow Platform who is considered by us to
                        be responsible for paying the bill for any Pro features
                        in an Organisation.
                    </span>
                </p>
                <p>
                    <span>
                        1.5. We may change the terms and conditions of this
                        agreement and our Privacy Notice at any time. We will
                        make reasonable efforts to communicate any changes to
                        you via a notification in Hello Cashflow or by sending
                        an email to you, but it is up to you to ensure that you
                        regularly check, read, understand and agree to the most
                        recent version of this agreement and our Privacy Notice
                        on our website at www.HelloCashflow.com as you will be
                        deemed to have accepted all changes if you continue to
                        use Hello Cashflow.
                    </span>
                </p>
                <p>
                    <span>Additional definitions:</span>
                </p>
                <p>
                    <span>
                        &ldquo;Services&rdquo; means (a) the provision of
                        services including, but not limited to Hello Cashflow
                        Scenario Builder and Hello Cashflow Accounts services as
                        defined from time to time by Hello Cashflow; and (b)
                        other services provided by Hello Cashflow as detailed in
                        writing between the parties.
                    </span>
                </p>
                <p>
                    <span>
                        &ldquo;Service Fees&rdquo; means the fees payable by you
                        in respect of the Services.
                    </span>
                </p>
                <p>
                    <span>&zwj;</span>
                </p>
                <h3>
                    <span>2. Who this agreement is between</span>
                </h3>
                <p>
                    <span>
                        2.1. This agreement is between: you, the person or
                        organisation authorised to use Hello Cashflow; and us,
                        Hello Cashflow LTD, a limited company registered in New
                        Zealand (NZBN
                    </span>
                    <span>&nbsp;</span>
                    <span>9429049186628 </span>
                    <span>).</span>
                </p>
                <p>
                    <span>
                        2.2. By entering into this agreement, we both agree to
                        be bound by and keep to it.
                    </span>
                </p>
                <h3>
                    <span>
                        3. How you accept this agreement, and when this
                        agreement starts
                    </span>
                </h3>
                <p>
                    <span>
                        3.1. You accept every term and condition of this
                        agreement, and this agreement starts from the earliest
                        date you tick a box or click on a button (or something
                        similar) when you are asked to confirm that you accept
                        this agreement during sign up to the service, or when
                        you use Hello Cashflow (or any part of it). If you
                        accept this agreement and pay the relevant subscription
                        fees (where applicable), we give you the right to use
                        Hello Cashflow in the way described in this agreement.
                        You must not use Hello Cashflow in any other way.
                    </span>
                </p>
                <p>
                    <span>
                        3.2. This agreement will continue until terminated in
                        accordance with clause 17 or any other clause in this
                        agreement which by its express provisions allows a party
                        to terminate this agreement.
                    </span>
                </p>
                <p>
                    <span>
                        3.3. If you don&#39;t accept this agreement, you should
                        contact us or your Hello Cashflow Partner or accountant
                        immediately (where you have set up your Hello Cashflow
                        account through your Hello Cashflow Partner or
                        accountant and your Hello Cashflow Partner or accountant
                        administers it for you) and you should not use Hello
                        Cashflow.
                    </span>
                </p>
                <h3>
                    <span>4. Registering with Hello Cashflow</span>
                </h3>
                <p>
                    <span>
                        4.1. On registering with Hello Cashflow you will either
                        provide us with an email address and password (the
                        &ldquo;sign-in information&rdquo;) or you will
                        authenticate through Single Sign On (&ldquo;SSO&rdquo;)
                        via one of our authentication partners. You will be sent
                        an activation email to this email address during
                        registration and you will need to activate your account
                        within 28 days of receiving the activation email.
                        Failure to activate your account may lead to an
                        inability to access any data which may have been entered
                        into that account.
                    </span>
                </p>
                <p>
                    <span>
                        4.2. Hello Cashflow will use the personal data it
                        obtains from the relevant SSO authentication partner to
                        manage your Hello Cashflow account and shall not be
                        responsible for loss of any data from your Hello
                        Cashflow account or unauthorised access to your Hello
                        Cashflow account which arises as a result of failures in
                        the security of the relevant SSO authentication partner.
                    </span>
                </p>
                <p>
                    <span>
                        4.3. Once you have set up your Hello Cashflow account
                        via an SSO authentication partner, you will be able to
                        change your personal details by following the
                        instructions available within the settings area of Hello
                        Cashflow.
                    </span>
                </p>
                <h3>
                    <span>5. Setting up a Hello Cashflow account directly</span>
                </h3>
                <p>
                    <span>
                        5.1. If you have signed up for Hello Cashflow, you may
                        integrate with third parties and use any Free or demo
                        version of the product suite indefinitely. Adding an
                        integration with a third party for a Hello Cashflow
                        product effectively activates that product. The date on
                        which you provide billing details to allow us to charge
                        you for any paid component will become your
                        &ldquo;Billing Date&rdquo; and you will be invoiced
                        immediately and will be charged the current list price
                        in advance. Your subscription will automatically renew
                        on your Billing Date.
                    </span>
                </p>
                <p>
                    <span>
                        5.2. If at any time during your subscription you want to
                        include additional Hello Cashflow components within your
                        subscription you must pay the applicable fees for each
                        additional Hello Cashflow component and your
                        subscription fee will be prorated from the date access
                        to the additional Hello Cashflow component is made
                        available to you until your next Billing Date. If you
                        wish to reduce access to certain Hello Cashflow
                        components, you may do so from your next Billing Date
                        when your fees will be calculated to take account of
                        your reduced access to the Hello Cashflow components.
                    </span>
                </p>
                <p>
                    <span>
                        5.3. If you fail to pay any amount payable by you under
                        this agreement, we may charge you interest on the amount
                        overdue from the due date of payment up to and including
                        the date of actual payment (as well as before and after
                        judgment), at the rate of 4% per annum above the base
                        rate for the time being of BNZ Bank. Such interest shall
                        accrue on a daily basis and be compounded quarterly and
                        you will pay the interest to us immediately upon demand.
                        If at any time we charge you an incorrect price, we
                        reserve the right to rectify our invoice and claim
                        payment from you for the correct amount which you agree
                        to pay. If we have overcharged you, we will reimburse
                        you for the amount by which you have been overcharged.
                    </span>
                </p>
                <p>
                    <span>
                        5.4. We may increase the subscription fee for Hello
                        Cashflow at any time by giving you not less than 30
                        days&rsquo; written notice and such an increase will
                        take effect from your next payment date after this
                        notice period has ended.
                    </span>
                </p>
                <p>
                    <span>
                        5.5. By using the functionality within Hello Cashflow
                        you can invite others to register with Hello Cashflow
                        and grant them access to your Hello Cashflow workspace.
                        If you choose to do this, we cannot accept any liability
                        for the actions of those you invite including, without
                        limitation, their access to your Hello Cashflow
                        workspace, the data contained within it and the data
                        contained within any third party service with which your
                        Hello Cashflow account integrates.
                    </span>
                </p>
                <h3>
                    <span>
                        6. Setting up a Hello Cashflow account through a Hello
                        Cashflow Partner
                    </span>
                </h3>
                <p>
                    <span>
                        6.1. If your Hello Cashflow Partner sets up your Hello
                        Cashflow account for you, you will be invited to join
                        their workspace to view your organisation&rsquo;s data.
                        If you pay your Hello Cashflow Partner and not us (until
                        such time that we, and/or your Hello Cashflow Partner,
                        notifies you to pay us directly) for your use of some or
                        all of the Hello Cashflow components to which you have
                        access, your Hello Cashflow Partner will manage your
                        access rights to these Hello Cashflow components.{" "}
                    </span>
                </p>
                <p>
                    <span>
                        6.2. If your Hello Cashflow Partner sets up your Hello
                        Cashflow account for you and invites you to access some
                        Hello Cashflow components, depending on your agreement
                        with your Hello Cashflow Partner, should you require
                        access to other paid-for Hello Cashflow components that
                        your Hello Cashflow Partner does not provide, you may
                        need to pay for these yourself. In this event you will
                        be charged by us for these components as a direct
                        customer (as per clause 5 of this agreement) and you
                        will be responsible for managing who else has access to
                        these components.
                    </span>
                </p>
                <p>
                    <span>
                        6.3. You are responsible for working with your Hello
                        Cashflow Partner to set and manage access rights and
                        levels of access that your Hello Cashflow Partner has
                        over your Hello Cashflow account as agreed between you
                        and your Hello Cashflow Partner. You acknowledge that we
                        have no control over such access rights and therefore we
                        accept no liability for any loss or damage or other
                        liability that you suffer as a result of any act,
                        omission or failure of your Hello Cashflow Partner.
                    </span>
                </p>
                <h3>
                    <span>
                        7. Your rights to use Hello Cashflow and your
                        obligations
                    </span>
                </h3>
                <p>
                    <span>
                        7.1. You must only use Hello Cashflow for your internal
                        business purposes and only to sync your own business
                        information into Hello Cashflow, unless you are a Hello
                        Cashflow Partner. Hello Cashflow Partners may sync their
                        clients&rsquo; data into Hello Cashflow for the purpose
                        of analysing and gaining insights from this data on
                        behalf of such clients (where the client permits this)
                        and for making the Hello Cashflow service available to
                        them.
                    </span>
                </p>
                <p>
                    <span>
                        7.2. All rights of ownership of the information you or a
                        Hello Cashflow Partner syncs into Hello Cashflow remain
                        yours but your access to this information is dependent
                        upon you complying with these terms and conditions and
                        your applicable subscription fee being paid in full. We
                        follow good industry practice to prevent data loss;
                        however, you must keep copies of any information
                        inputted into Hello Cashflow (or generated by it) as we
                        cannot guarantee that your information will not be lost
                        or damaged.
                    </span>
                </p>
                <p>
                    <span>
                        7.3. You cannot transfer your Hello Cashflow
                        subscription (or your use of Hello Cashflow for
                        demonstration and evaluation purposes or any free trial,
                        promotion or activation code) to any other person or
                        organisation. For example, you cannot sell it if y
                    </span>
                    <span>
                        ou no longer want to use Hello Cashflow, or if you
                        become insolvent an insolvency practitioner may not pass
                        on your Hello Cashflow subscription (including your
                        sign-in information) as part of your business&rsquo;s
                        assets. Further information on this is set out in clause
                        17.4.
                    </span>
                </p>
                <p>
                    <span>
                        7.4. You must comply with all applicable laws and
                        legislation in respect of your use of Hello Cashflow.
                        When sharing data via Hello Cashflow, you must ensure
                        that the content of any data does not and will not
                        result in any injury, damage or harm to us or any third
                        party (including, without limitation, defamation or
                        breach of confidentiality) and the content does not
                        contain anything which is unlawful, obscene, indecent or
                        immoral or promotes illegal or unlawful activities. You
                        must not use the Hello Cashflow services to post, share
                        or disseminate any material unrelated to the use of
                        Hello Cashflow, including (but not limited to): offers
                        of goods or services for sale, unsolicited commercial
                        email, files that may damage any other person&rsquo;s
                        computing devices or software or material in violation
                        of any law (including material that is protected by
                        copyright or trade secrets which you do not have the
                        right to use). When you use Hello Cashflow to
                        communicate or share any information, you represent that
                        you are permitted to make such communication and share
                        such information. We are under no obligation to ensure
                        that communications sent by users of Hello Cashflow are
                        legitimate or that they are related only to the use of
                        Hello Cashflow services.{" "}
                    </span>
                </p>
                <p>
                    <span>
                        7.5. You acknowledge that we are not your accountant, we
                        do not provide accountancy services and Hello Cashflow
                        should not be used as a substitute for professional
                        accountancy advice.
                    </span>
                </p>
                <p>
                    <span>
                        7.6. Some features of Hello Cashflow rely on integration
                        with third party products or services or provide access
                        to technology, information or services not provided by
                        us (&ldquo;Additional Services&rdquo;). You may purchase
                        or subscribe to third party complementary products,
                        software or services that integrate or work with Hello
                        Cashflow and it is your responsibility to decide whether
                        or not to use these Additional Services. If you choose
                        to do so you must agree to the separate applicable terms
                        and conditions presented to you by the third party for
                        those Additional Services. If there is a conflict
                        between any of the terms of this agreement and the
                        Additional Services terms, the Additional Services terms
                        will apply in relation to your use of the Additional
                        Service in question. Except where other claus
                    </span>
                    <span>es in this agreement apply</span>
                    <span>
                        , we are not responsible for any issue with any
                        third-party technology, information and / or services
                        and will not be liable for those issues. We may withdraw
                        access to such third party technology, information or
                        services via Hello Cashflow at any time and without
                        notifying you.
                    </span>
                </p>
                <p>
                    <span>
                        7.7. Hello Cashflow may use insight gained from your
                        data to introduce you to Additional Services offered by
                        third parties with whom we partner. You acknowledge that
                        should this happen, it in no way represents a
                        recommendation or any form of financial advice from us.
                        It is your responsibility to decide whether these
                        Additional Services meet your particular needs. You
                        acknowledge that where Additional Services include the
                        provision of a quote, this may require us to share a
                        limited set of your data with a third party in order for
                        a personalised quote to be calculated and displayed in
                        Hello Cashflow. You agree that it is your sole
                        responsibility to ensure that the data used by any third
                        party to provide you with any Additional Services is
                        accurate and you acknowledge that these Additional
                        Services are not provided to you by Hello Cashflow nor
                        by your accounting software provider and in no way
                        constitute a recommendation to you from them.
                    </span>
                </p>
                <p>
                    <span>
                        7.8. You acknowledge that, where relevant, the third
                        party provider of the Additional Services may collect
                        from you the subscription fees due for Hello Cashflow.
                        In such circumstances, you agree to pay the applicable
                        subscription fee (plus any GST or applicable sales tax)
                        directly to the third party provider via the payment
                        method and subject to the payment terms specified during
                        registration or which may otherwise be notified to you
                        from time to time, in default of which the payment terms
                        set out in this agreement shall apply.
                    </span>
                </p>
                <p>
                    <span>
                        7.9. You agree that if you receive Hello Cashflow at a
                        special or discounted price you will only be able to
                        receive Hello Cashflow at that special or discounted
                        price if your subscription fees for Hello Cashflow are
                        paid continuously. Any delay or other failure in payment
                        which results in a suspension, termination or other
                        deactivation of your account may result in your
                        subscription fee being reactivated at our then-current
                        list price which will be higher than the special or
                        discounted price you previously paid.
                    </span>
                </p>
                <p>
                    <span>
                        7.10 You may invite anyone else to use or benefit from
                        Hello Cashflow and you may use Hello Cashflow with
                        someone else&rsquo;s information to provide an advisory
                        service to them. Should they register as a user of Hello
                        Cashflow, their use of Hello Cashflow will be subject to
                        these terms and conditions and they will be considered
                        by us to have rights equal to all other users of Hello
                        Cashflow.
                    </span>
                </p>
                <h3>
                    <span>8. Special Terms for Hello Cashflow Platform</span>
                </h3>
                <p>
                    <span>
                        If you subscribe to Hello Cashflow Platform (including
                        any applicable free trial period), the following terms
                        and conditions shall apply to you.
                    </span>
                </p>
                <p>
                    <span>8.1 Setting up Hello Cashflow Platform</span>
                </p>
                <p>
                    <span>
                        8.1.1 Unless you have been invited to join an existing
                        Organisation in Hello Cashflow Platform, when you
                        register with us we will create a new Organisation for
                        you. You may create as many additional Organisations as
                        you like and you may invite as many users are you like
                        to each Organisation, although we reserve the right to
                        cap this number at our discretion at any time in the
                        future.
                    </span>
                </p>
                <p>
                    <span>
                        8.1.2 In each Organisation you will be guided through
                        connecting to one or more third party data sources
                        (integrations, e.g. with Xero).
                    </span>
                </p>
                <p>
                    <span>
                        8.1.3 Depending on the Hello Cashflow Platform product
                        you have chosen, we may offer different tiers or
                        versions.{" "}
                    </span>
                </p>
                <p>
                    <span>
                        8.1.4 Depending on the product, we may provide access to
                        all tier features for a short period of time from when
                        you first start using it as a user, even if you
                        haven&rsquo;t chosen the premium tier. Such access to
                        premium features is provided at our discretion and we
                        reserve the right to change the period of time such
                        access is granted or to withdraw it altogether without
                        notice.
                    </span>
                </p>
                <p>
                    <span>
                        8.2 Accessing premium features of Hello Cashflow
                        Platform
                    </span>
                </p>
                <p>
                    <span>
                        8.2.1 The premium features of Hello Cashflow Platform
                        are paid-for features. Should you choose the premium
                        version or tier of a Hello Cashflow Platform product,
                        you may choose to be billed either monthly or annually
                        per integration. The subscription fee is payable to us
                        in advance. Should additional integrations be
                        subsequently be added to that product or should you
                        choose to subscribe to a Premium tier or version of
                        another Hello Cashflow Platform product, you will be
                        charged immediately in advance for any additional
                        integrations and the charge will be prorated to your
                        next billing date.
                    </span>
                </p>
                <p>
                    <span>
                        8.2.2 You may invite any number of additional users to
                        any of your Organisations in Hello Cashflow Platform and
                        you may grant them access to any of the Hello Cashflow
                        Platform products in that Organisation at no additional
                        cost.
                    </span>
                </p>
                <p>
                    <span>
                        8.2.3 You may grant users permission to add integrations
                        to Hello Cashflow Platform products within Organisations
                        they have access to and you may grant them permission to
                        activate premium features in these products. The role
                        you give each such user will determine whether newly
                        activated premium integrations will be paid for by you
                        or, should they not have permission to affect your bill,
                        by them.
                    </span>
                </p>
                <p>
                    <span>
                        8.2.4 It is possible for premium integrations used by
                        different Hello Cashflow Platform products in the same
                        Organisation to be paid for by different users. As a
                        Hello Cashflow Partner you may use this capability to
                        set up Organisations in Hello Cashflow Platform on
                        behalf of your clients and remain flexible on which
                        Premium integrations you pay us for and which
                        integrations your clients themselves pay us for.
                    </span>
                </p>
                <p>
                    <span>
                        8.3 Downgrading from Premium features in Hello Cashflow
                        Platform
                    </span>
                </p>
                <p>
                    <span>
                        8.3.1 Should you choose to downgrade from the Premium
                        version of a Hello Cashflow Platform product to the baic
                        version and you only have access to one Organisation in
                        Hello Cashflow Platform, the downgrade will be scheduled
                        automatically to take place on your next billing date
                        and you will have access to the premium features until
                        that date. A refund will not be available.
                    </span>
                </p>
                <p>
                    <span>
                        8.3.2 Should you choose to downgrade from the premium
                        version of a Hello Cashflow Platform product to the
                        basic version and you have access to more than one
                        Organisation in Hello Cashflow Platform, you will be
                        given the option to transfer the premium features to the
                        same product in another Organisation (potentially making
                        it available to different users you have invited).
                        Should you choose this option, the downgrade will take
                        place immediately and you will be able to upgrade the
                        same product in a different Organisation without your
                        bill being affected.
                    </span>
                </p>
                <h3>
                    <span>&zwj;</span>
                </h3>
                <h3>
                    <span>9. Special Terms for Hello Cashflow Advice</span>
                </h3>
                <p>
                    <span>
                        9.1 SERVICES. If you request Services from Hello
                        Cashflow these special terms will apply. Hello Cashflow
                        will provide, and you agree to accept and make payment
                        for the Services performed by Hello Cashflow.
                    </span>
                </p>
                <p>
                    <span>
                        9.1.2 Ownership of Work Product from the Services.
                        &nbsp;Ownership of the work product including, without
                        limitation, deliverables and Documentation developed by
                        Hello Cashflow as part of the Services delivered to you
                        under this Agreement, and all intellectual property
                        rights and interests created (&quot;Work Product&quot;),
                        shall be and remain with Hello Cashflow. &nbsp;Hello
                        Cashflow grants to you a non-exclusive, perpetual,
                        non-transferable, royalty-free license to use the Work
                        Product for your own internal business purposes and
                        solely in combination with the Hello Cashflow Service.
                        &nbsp;The rights granted to you under this Section are
                        subject to (i) payment in full of all Services Fees; and
                        (ii) you continuing to maintain a subscription with
                        Hello Cashflow. &nbsp;You retain ownership of all
                        Customer Data and remain responsible for the accuracy
                        and relevance of the Customer Data. You grant to Hello
                        Cashflow the limited license to use the Customer Data
                        for purposes of performing the Services.{" "}
                    </span>
                </p>
                <p>
                    <span>9.2 SERVICES WARRANT</span>
                </p>
                <p>
                    <span>
                        9.2.1 Our warranty to you. Hello Cashflow warrants that
                        the Services shall be performed with reasonable skill
                        and care by competent, qualified personnel and/or
                        contractors in a professional manner consistent with
                        industry standards. &nbsp;In performing the Services,
                        Hello Cashflow shall use reasonable commercial efforts,
                        and shall devote a reasonable amount of time, personnel
                        and resources, to deliver the Services. Hello
                        Cashflow&rsquo;s sole obligation, and your sole remedy,
                        for a breach of warranty relating to Services shall be
                        for Hello Cashflow to re-perform the Services without
                        additional charge to You.
                    </span>
                </p>
                <p>
                    <span>9.3 FEES AND PAYMENT FOR PURCHASED SERVICES</span>
                </p>
                <p>
                    <span>
                        9.3.1 Services Fees. Hello Cashflow shall notify you of
                        the monthly Service Fee and you shall pay all Services
                        Fees in accordance with this Agreement. For clarity,
                        payment obligations are non-cancellable and fees paid
                        are non-refundable during the term of Hello
                        Cashflow&rsquo;s provision of the Services. &nbsp;
                    </span>
                </p>
                <p>
                    <span>&zwj;</span>
                </p>
                <p>
                    <span>&zwj;</span>
                </p>
                <h3>
                    <span>10. Restrictions on your use of Hello Cashflow</span>
                </h3>
                <p>
                    <span>
                        10.1 The following list gives examples of things you
                        must not do with Hello Cashflow:
                    </span>
                </p>
                <p>
                    <span>
                        10.1.1 You must not introduce any viruses or harmful
                        technology to Hello Cashflow;
                    </span>
                </p>
                <p>
                    <span>
                        10.1.2 You must not try to gain unauthorised access to
                        Hello Cashflow or any underlying technology;
                    </span>
                </p>
                <p>
                    <span>
                        10.1.3 You must not try to affect the availability of
                        Hello Cashflow to our users (sometimes called &lsquo;a
                        denial-of-service attack&rsquo;);
                    </span>
                </p>
                <p>
                    <span>
                        10.1.4 You may not use Hello Cashflow to help you
                        develop your own software. For example, you must not use
                        or copy all or any part of Hello Cashflow&rsquo;s
                        graphical user interface, operating logic or code of any
                        kind for it to be part of any software or other product
                        or technology, unless that use or copying is allowed by
                        law.
                    </span>
                </p>
                <p>
                    <span>
                        10.1.5 You must not use Hello Cashflow&rsquo;s
                        integration with messaging platforms to send unsolicited
                        messages that may be considered spam.
                    </span>
                </p>
                <p>
                    <span>
                        10.2 It is impossible to provide an exhaustive list of
                        exactly what constitutes acceptable and unacceptable use
                        of Hello Cashflow. In general, we will not tolerate any
                        use which damages or is likely to damage our business or
                        reputation, the availability or integrity of Hello
                        Cashflow or which causes us or threatens to cause us to
                        incur any legal, tax or regulatory liability. We will
                        also not tolerate any conduct by you which is (or we
                        reasonably deem to be) offensive, malicious,
                        threatening, intimidating or otherwise unacceptable
                        behaviour (&ldquo;Unacceptable Conduct&rdquo;). If we
                        consider you have participated in any Unacceptable
                        Conduct, we may end this agreement by giving you 20
                        days&rsquo; notice in writing. In these circumstances
                        you will not be entitled to a refund of any amounts you
                        have paid to us in advance for your subscription period.
                    </span>
                </p>
                <h3>
                    <span>11. Our promises relating to Hello Cashflow</span>
                </h3>
                <p>
                    <span>
                        11.1 Whilst we aim to provide uninterrupted use of Hello
                        Cashflow, unfortunately we can&rsquo;t guarantee this.
                        For example, some interruptions may be caused by reasons
                        outside our control and in such circumstances, we will
                        not be responsible for any failure to perform our
                        obligations in this agreement, and we will be excused
                        from that failure for so long as those circumstances
                        continue.
                    </span>
                </p>
                <p>
                    <span>
                        11.2 In cases where Hello Cashflow includes information
                        supplied by third parties, we do not guarantee or verify
                        the accuracy or completeness of that information.
                    </span>
                </p>
                <p>
                    <span>11.3 We do not promise:</span>
                </p>
                <p>
                    <span>
                        11.3.1 that Hello Cashflow will be compatible with your
                        web browser or computer set-up;
                    </span>
                </p>
                <p>
                    <span>
                        11.3.2 that Hello Cashflow will meet your own needs;
                    </span>
                </p>
                <p>
                    <span>
                        11.3.3 that you will be able to use Hello Cashflow in
                        any particular way;
                    </span>
                </p>
                <p>
                    <span>
                        11.3.4 that you will get particular outputs from Hello
                        Cashflow;
                    </span>
                </p>
                <p>
                    <span>
                        11.3.5 that any forecasts or predictive analysis Hello
                        Cashflow performs will be an accurate representation of
                        the future;
                    </span>
                </p>
                <p>
                    <span>
                        11.3.6 that the standard of the results you get from
                        using Hello Cashflow will meet your expectations; or
                    </span>
                </p>
                <p>
                    <span>
                        11.3.7 that, where you use our technical support
                        services, we will be able to fix your problem or remedy
                        your issue.
                    </span>
                </p>
                <p>
                    <span>
                        The fact that you may have told our representative about
                        how you intend to use Hello Cashflow will not affect
                        this clause as Hello Cashflow has been developed for
                        many different types of users, and you are responsible
                        for setting up and accessing Hello Cashflow so that you
                        can use it in the way you need, and as best suits your
                        circumstances.
                    </span>
                </p>
                <p>
                    <span>
                        11.4 You are solely responsible for obtaining and
                        maintaining your internet and network connections and
                        any associated problems are your responsibility.
                    </span>
                </p>
                <p>
                    <span>
                        11.5 We will take reasonable steps to make sure that
                        Hello Cashflow is free from viruses but we cannot
                        guarantee this. We recommend that you use your own
                        virus-protection software as we will not be responsible
                        for any loss or damage caused by any viruses or other
                        harmful technology that may infect your computer
                        systems, data or other material owned by you.
                    </span>
                </p>
                <p>
                    <span>
                        11.6 You are responsible for controlling who can access
                        your Hello Cashflow account. We advise that you
                        don&rsquo;t allow anyone else to use your sign in
                        information and that you change your password at regular
                        intervals
                    </span>
                </p>
                <p>
                    <span>
                        11.7 From time to time we may temporarily suspend access
                        to Hello Cashflow, for maintenance, repairs or other
                        reasons. We will try to do this outside normal business
                        hours and provide advance notice but this might not
                        always be possible. If we become aware that there is an
                        issue with Hello Cashflow which affects you we may
                        contact you to discuss the steps required to remedy that
                        issue. You agree to provide all reasonable assistance in
                        helping us remedy that issue.
                    </span>
                </p>
                <p>
                    <span>
                        11.8 We promise that we will use our reasonable skill
                        and care to provide any service to you under this
                        agreement.
                    </span>
                </p>
                <p>
                    <span>
                        11.9 This agreement describes all of our promises
                        relating to Hello Cashflow. Unless this agreement says
                        otherwise, we are not bound by any other contract terms,
                        warranties or other type of promise. If, under any law,
                        a particular term, warranty or other type of promise
                        relating to Hello Cashflow would automatically be
                        included in this agreement, we will only be bound by
                        that term, warranty or promise to the extent prescribed
                        by law.
                    </span>
                </p>
                <h3>
                    <span>12. Data Protection</span>
                </h3>
                <p>
                    <span>
                        12.1 For the purposes of this agreement, the parties
                        agree that you are the Data Controller in respect of
                        Personal Data contained within Customer Data
                        (&ldquo;Customer Personal Data&rdquo;) and as Data
                        Controller, you have sole responsibility for its
                        legality, reliability, integrity, accuracy and quality.
                    </span>
                </p>
                <p>
                    <span>12.2 You warrant and represent that:</span>
                </p>
                <p>
                    <span>
                        12.2.1. you will comply with and will ensure that your
                        instructions for the Processing of Customer Personal
                        Data will comply with the relevant Data Protection Laws;
                    </span>
                </p>
                <p>
                    <span>
                        12.2.2. you are authorised pursuant to the Data
                        Protection Laws to disclose any Customer Personal Data
                        which you disclose or otherwise provide to us regarding
                        persons other than yourself;
                    </span>
                </p>
                <p>
                    <span>
                        12.2.3. &nbsp;you will where necessary, and in
                        accordance with the Data Protection Laws, obtain all
                        necessary consents and rights and provide all necessary
                        information and notices to Data Subjects in order for:
                    </span>
                </p>
                <p>
                    <span>
                        12.2.3.1. you to disclose the Customer Personal Data to
                        us;
                    </span>
                </p>
                <p>
                    <span>
                        12.2.3.2. us to Process the Customer Personal Data for
                        the purposes set out in this agreement; and
                    </span>
                </p>
                <p>
                    <span>
                        12.2.3.3. us to disclose the Customer Personal Data to:
                        (a) our service providers where necessary for us to
                        provide the Hello Cashflow service; (b) law enforcement
                        agencies; (c) any other person in order to meet any
                        legal obligations on us, including statutory or
                        regulatory reporting; and (d) any other person who has a
                        legal right to require disclosure of the information,
                        including where the recipients of the Customer Personal
                        Data are outside the European Economic Area.
                    </span>
                </p>
                <p>
                    <span>
                        12.3. To the extent that Hello Cashflow Processes any
                        Customer Personal Data, the terms of Appendix A shall
                        apply and the parties agree to comply with such terms.
                    </span>
                </p>
                <p>
                    <span>
                        12.4. Where, and to the extent we Process your Personal
                        Data as a Data Controller in accordance with our Privacy
                        Notice, we shall comply with all Data Protection Laws
                        applicable to us as Data Controller.
                    </span>
                </p>
                <p>
                    <span>
                        12.5. You agree that we may record, retain and use
                        Customer Data generated and stored during your use of
                        the Service (including Customer Personal Data, which we
                        shall Process as Data Controller as set out in our
                        Privacy Notice, on the basis of our legitimate business
                        interests), in order to:
                    </span>
                </p>
                <p>
                    <span>
                        12.5.1. &nbsp;deliver advertising, marketing (including
                        in-product messaging) or information to you which may be
                        useful to you, based on your use of Hello Cashflow;
                    </span>
                </p>
                <p>
                    <span>
                        12.5.2. carry out research and development to improve
                        our services, products and applications;
                    </span>
                </p>
                <p>
                    <span>
                        12.5.3 develop and provide new and existing
                        functionality and services (including statistical
                        analysis, benchmarking and forecasting services) to you
                        and other Hello Cashflow customers;
                    </span>
                </p>
                <p>
                    <span>
                        12.5.4 provide you with location based services (for
                        example location relevant content) where we collect
                        geo-location data to provide a relevant experience;
                    </span>
                </p>
                <p>
                    <span>
                        12.6 You agree that we may retain and use data generated
                        by you during your use of the Hello Cashflow service
                        once this agreement is terminated for the purposes
                        outlined in clause 11.5 provided that we shall only
                        retain and use such data in a pseudonymised form,
                        displayed at aggregated levels, which will not be linked
                        back to you or to any living individual.
                    </span>
                </p>
                <h3>
                    <span>
                        13. Anti-Bribery and <br />
                        Corruption
                    </span>
                </h3>
                <p>
                    <span>
                        13.1. Each party will and will procure that persons
                        associated with them shall:
                    </span>
                </p>
                <p>
                    <span>
                        13.1.1. comply with all applicable laws, statutes,
                        regulations, and codes relating to anti-bribery and
                        anti-corruption (the &ldquo;Relevant
                        Requirements&rdquo;);
                    </span>
                </p>
                <p>
                    <span>
                        13.1.2. not engage in any conduct which would constitute
                        an offence under any of the Relevant Requirements;
                    </span>
                </p>
                <p>
                    <span>
                        13.1.3. not do, or omit to do, any act that may lead the
                        other party to be in breach of any Relevant
                        Requirements;
                    </span>
                </p>
                <p>
                    <span>
                        13.1.4. promptly report to the other party any request
                        or demand for any undue financial or other advantage
                        received by it in connection with this agreement;
                    </span>
                </p>
                <p>
                    <span>
                        13.1.5. have and maintain in place during the term of
                        this agreement its own policies and procedures to ensure
                        compliance with the Relevant Requirements and will
                        enforce them where appropriate.
                    </span>
                </p>
                <h3>
                    <span>
                        14. Technical support and how we may access your Hello
                        Cashflow account
                    </span>
                </h3>
                <p>
                    <span>
                        14.1 During the period of your subscription, we aim to
                        give you 24-hour technical support 7 days a week
                        (although there may be times where we are unable to do
                        this for reasons outside our control) covering problems
                        you may have using Hello Cashflow. We may provide this
                        by telephone, email, web-chat, remote assistance (where
                        we will access your account and data online) or
                        self-help online support as described in the Help
                        Section of Hello Cashflow. You grant us the right to
                        access your data to provide such support. If we do not
                        have this access we may not be able to provide you with
                        support.
                    </span>
                </p>
                <p>
                    <span>
                        14.2 We may release enhancements or provide additional
                        features to Hello Cashflow (&ldquo;Updates&rdquo;). The
                        frequency and how we provide any Updates to you will be
                        at our discretion. We may tell you when we have provided
                        such Updates via a notification in Hello Cashflow or by
                        sending an email to you.
                    </span>
                </p>
                <p>
                    <span>
                        14.3 We will not at any time give you technical support
                        or other assistance for any hardware, third-party
                        software, services or other equipment used with Hello
                        Cashflow.
                    </span>
                </p>
                <h3>
                    <span>15. Intellectual Property Rights</span>
                </h3>
                <p>
                    <span>
                        15.1 Although you have rights to use Hello Cashflow as
                        described in this agreement, you do not own any of the
                        intellectual property rights in Hello Cashflow or any of
                        its related logos. We (or the third party from whom we
                        obtain our rights if we are not the owner) continue to
                        own the intellectual property rights in Hello Cashflow
                        and any related logos, including any software we provide
                        to replace all or part of Hello Cashflow. The only
                        rights you have to Hello Cashflow are as set out in this
                        agreement.
                    </span>
                </p>
                <p>
                    <span>
                        15.2 You undertake not to use Hello Cashflow&rsquo;s
                        name or brand in any promotion or marketing or other
                        announcement unless authorised to do so by us.
                    </span>
                </p>
                <h3>
                    <span>
                        16. Our liability and responsibility to you if something
                        goes wrong
                    </span>
                </h3>
                <p>
                    <span>
                        16.1. This clause sets out our entire liability to you
                        (including any additional users you may have invited)
                        which arises out of or in connection with this agreement
                        whether in contract, tort (including negligence or
                        breach of statutory duty), misrepresentation or
                        otherwise.
                    </span>
                </p>
                <p>
                    <span>
                        16.2 We make no warranty of assurance about the contents
                        of Hello Cashflow for accuracy or completeness.
                        Information may be incorrect or out of date. Your use of
                        Hello Cashflow is at your own risk. We accept no
                        liability for any error or omission in the data we
                        present to you.
                    </span>
                </p>
                <p>
                    <span>
                        16.3 Subject to clauses 16.5 and 16.6, our total
                        liability in contract, tort (including negligence or
                        breach of statutory duty), misrepresentation or
                        otherwise arising under or in connection with this
                        agreement will be limited to paying you an amount which
                        is equal to the total of all fees you have paid to us
                        for your use of the relevant Hello Cashflow product or
                        service during the 12 month period immediately preceding
                        the date on which the claim arose (such relevant Hello
                        Cashflow product being the product forming the subject
                        matter of the claim).
                    </span>
                </p>
                <p>
                    <span>
                        16.4 Subject to clauses 15.5 and 15.6, we will not be
                        responsible whether in contract, tort (including
                        negligence or breach of statutory duty),
                        misrepresentation or otherwise for any of the following
                        (even if we knew or should have known there was a
                        possibility you could suffer or incur such loss or
                        damage):
                    </span>
                </p>
                <p>
                    <span>
                        16.4.1 loss of profit, business or revenue and/or
                        depletion of goodwill or similar losses;
                    </span>
                </p>
                <p>
                    <span>
                        16.4.2 loss of use or loss of or damage to data /
                        information inputted by you into Hello Cashflow;
                    </span>
                </p>
                <p>
                    <span>
                        16.4.3 any interruption to your business or damage to
                        information, however that interruption or damage is
                        caused;
                    </span>
                </p>
                <p>
                    <span>
                        16.4.4 losses you suffer as a result of using Hello
                        Cashflow other than as described in the relevant
                        documents or instructions; and / or
                    </span>
                </p>
                <p>
                    <span>
                        16.4.5 any loss or damage which we could not have
                        reasonably known about at the time you entered into this
                        agreement including, without limitation any special,
                        indirect or consequential loss or damage.
                    </span>
                </p>
                <p>
                    <span>
                        16.5 Nothing in this agreement will exclude or limit our
                        liability for:
                    </span>
                </p>
                <p>
                    <span>16.5.1 fraud;</span>
                </p>
                <p>
                    <span>
                        16.5.2 death of or personal injury to any person as a
                        result of our negligence; or
                    </span>
                </p>
                <p>
                    <span>
                        16.5.3 any other matter which cannot be excluded or
                        limited under applicable law.
                    </span>
                </p>
                <p>
                    <span>
                        16.6 All warranties, conditions and other terms implied
                        by statute or common law are, to the fullest extent
                        permitted by law, excluded from this agreement.
                    </span>
                </p>
                <p>
                    <span>
                        16.7 Your and our responsibilities under this agreement
                        are reasonable because they reflect that:
                    </span>
                </p>
                <p>
                    <span>
                        16.7.1 we cannot control how, and for what purposes, you
                        use Hello Cashflow;
                    </span>
                </p>
                <p>
                    <span>
                        16.7.2 we have not developed Hello Cashflow specifically
                        for you; and
                    </span>
                </p>
                <p>
                    <span>
                        16.7.3 although we follow good industry practice, it is
                        not economically possible for us to carry out all the
                        tests necessary to make sure that Hello Cashflow is
                        problem or error free.
                    </span>
                </p>
                <h3>
                    <span>
                        17. How this agreement may be brought to an end and what
                        happens on termination
                    </span>
                </h3>
                <p>
                    <span>17.1. We may end this agreement:</span>
                </p>
                <p>
                    <span>
                        17.1.1. immediately if we or your Hello Cashflow Partner
                        (if appropriate) do not receive your subscription fee or
                        any other fees due to us under this agreement by the
                        relevant due date; or
                    </span>
                </p>
                <p>
                    <span>
                        17.1.2. at any time on giving you at least 30
                        days&rsquo; notice and if we do, we will refund to you
                        any amounts you have paid in advance for the applicable
                        subscription period calculated from the date of
                        termination.
                    </span>
                </p>
                <p>
                    <span>
                        17.2. You may end this agreement at any time by sending
                        us an email to info@HelloCashflow.com. In the case of
                        the Hello Cashflow Platform, the Subscriber User must
                        send this email. If you end this agreement, we will
                        confirm the date that this agreement will end. We will
                        not give you a refund for any amounts you have paid in
                        advance for the applicable subscription period, and you
                        must immediately pay all amounts you owe to us (or your
                        Hello Cashflow Partner as the case may be) by the date
                        this agreement ends. If you continue to use Hello
                        Cashflow after the expiry of any subscription period we
                        will be entitled to charge you for such use at our then
                        current applicable fees.
                    </span>
                </p>
                <p>
                    <span>
                        17.3. Should we have granted you access to paid-for
                        Hello Cashflow functionality on a free trial basis for
                        whatever reason, if at the end of the trial period you
                        choose not to pay the subscription fee to continue to
                        use the paid-for functionality, your access to such
                        functionality will immediately end.
                    </span>
                </p>
                <p>
                    <span>
                        17.4. If you or we discover that the other has done
                        something which is not allowed by this agreement, or has
                        not done something that must be done, the one making
                        such discovery can give the other written notice that
                        the matter must be put right within 30 days. If the
                        matter is put right within that time, no further action
                        will be taken. If it is not put right within that time,
                        the person who made the discovery may end this agreement
                        upon giving the other notice in writing.
                    </span>
                </p>
                <p>
                    <span>
                        17.5. This agreement will automatically (i.e. without us
                        having to tell you) and immediately end without refund
                        if you or your Hello Cashflow Partner become insolvent
                        &nbsp;(or something similar happens) or your business or
                        that of your Hello Cashflow Partner is not able to pay
                        its debts, stops trading or becomes insolvent (or
                        something similar happens). In those circumstances we
                        will have no further obligation to you under this
                        agreement and any monies due from you will become
                        immediately due and payable.
                    </span>
                </p>
                <p>
                    <span>
                        17.6. No matter how this agreement ends, the information
                        you store in Hello Cashflow remains your information and
                        you can access it in a format provided by Hello Cashflow
                        before the end of this agreement. If you (or your Hello
                        Cashflow Partner) wish to access your information after
                        this agreement has ended, you agree to pay our
                        reasonable charges for that access, but you accept that
                        we have automated processes that periodically delete
                        data belonging to customers with whom this agreement has
                        ended and therefore we may ourselves no longer have
                        access to your information.{" "}
                    </span>
                </p>
                <p>
                    <span>
                        17.7. In addition to our rights to end this agreement,
                        we may also suspend your use of Hello Cashflow at any
                        time if we (or your Hello Cashflow Partner if
                        applicable) do not receive payment in full when due or
                        if we suspect that you or your Hello Cashflow Partner
                        has breached any part of this agreement. If you are a
                        Hello Cashflow Platform customer, we may suspend your
                        use of Hello Cashflow Platform for all Organisations you
                        have access to, regardless of which business is in
                        breach of this agreement and / or has defaulted in
                        payment.
                    </span>
                </p>
                <p>
                    <span>
                        17.8. Any suspension of your Hello Cashflow account will
                        continue until such time that the breach in question has
                        been remedied to our reasonable satisfaction and/or we
                        have received payment from you in full. Where we suspend
                        or terminate your use of Hello Cashflow under this
                        clause, we may at our discretion agree to reactivate
                        your account subject to you paying to us a reactivation
                        fee. In accordance with clause 6.8 above, if you
                        received Hello Cashflow at a special or discounted price
                        your subscription fee may be reactivated at our
                        then-current list price which will be higher than the
                        special or discounted price you previously paid.
                    </span>
                </p>
                <h3>
                    <span>18. What else do you need to know?</span>
                </h3>
                <p>
                    <span>
                        18.1. If a court or similar body decides that any
                        wording in this agreement cannot be enforced, that
                        decision will not affect the rest of this agreement,
                        which will remain binding on both parties. However, if
                        the wording that cannot be enforced could be enforced if
                        part of it is deleted, we will both treat the relevant
                        part of the wording as if it is deleted.
                    </span>
                </p>
                <p>
                    <span>
                        18.2. If you or we fail to, or delay in, exercising any
                        rights under this agreement, that will not mean that
                        those rights cannot be exercised in the future.
                    </span>
                </p>
                <p>
                    <span>
                        18.3. This agreement and the documents we refer to above
                        constitute the entire agreement between you and us for
                        your use of Hello Cashflow, and replaces all documents,
                        information and other communications (whether spoken or
                        written) between us for such use.
                    </span>
                </p>
                <p>
                    <span>
                        18.4. As specified in clause 7.3, this agreement is
                        personal to you and may not be transferred, assigned,
                        subcontracted, licensed, charged or otherwise dealt with
                        or disposed of (whether in whole or in part) by you
                        without our prior written consent. We may transfer,
                        assign, subcontract, licence, charge or otherwise deal
                        with or dispose of (whether in whole or in part) this
                        agreement at any time without your consent.
                    </span>
                </p>
                <p>
                    <span>
                        18.5. A person who is not a party to this agreement has
                        no right to enforce any term of it.
                    </span>
                </p>
                <p>
                    <span>
                        18.6. Where either party is required to notify the other
                        party by email, the party shall be deemed to have
                        received the email on the first business day following
                        transmission.
                    </span>
                </p>
                <p>
                    <span>18. Which laws govern this agreement?</span>
                </p>
                <p>
                    <span>
                        18.1.1 This agreement is governed by the laws of New
                        Zealand and you and we both irrevocably agree that the
                        courts of New Zealand will have exclusive jurisdiction
                        to hear and decide any dispute, action, suit or
                        proceedings on all disputes arising out of this
                        agreement, including any disputes as to its existence,
                        validity or termination or claims about this agreement.
                    </span>
                </p>
                <h3>
                    <span>Appendix A &ndash; Data Protection</span>
                </h3>
                <h4>
                    <span>1. Interpretation</span>
                </h4>
                <p>
                    <span>
                        Where there is any inconsistency between the terms of
                        this Appendix A and any other terms of this agreement,
                        the terms of Appendix A shall take precedence.
                    </span>
                </p>
                <h4>
                    <span>2. Processing of Personal Data</span>
                </h4>
                <p>
                    <span>
                        2.1 During the term of this agreement we warrant and
                        represent that we:
                    </span>
                </p>
                <p>
                    <span>
                        2.1.1. shall comply with the Data Protection Laws
                        applicable to us whilst any Personal Data is in our
                        control;
                    </span>
                </p>
                <p>
                    <span>
                        2.1.2. when acting in the capacity of a Data Processor,
                        shall only Process Personal Data:
                    </span>
                </p>
                <p>
                    <span>
                        2.1.2.1 as is necessary for the provision of Hello
                        Cashflow under this agreement and the performance of our
                        obligations under this agreement; or
                    </span>
                </p>
                <p>
                    <span>
                        2.1.2.2 otherwise on your documented instructions.
                    </span>
                </p>
                <h4>
                    <span>3. Our Obligations</span>
                </h4>
                <p>
                    <span>3.1 We shall:</span>
                </p>
                <p>
                    <span>
                        3.1.1. taking into account the nature of the Processing,
                        assist you by appropriate technical and organisational
                        measures, insofar as this is possible, for the
                        fulfilment of your obligation to respond to requests
                        from individuals for exercising Data Subjects&rsquo;
                        rights; and
                    </span>
                </p>
                <p>
                    <span>
                        3.1.2. taking into account the nature of the Processing,
                        and the information available to us, provide reasonable
                        assistance to you in ensuring compliance with your
                        obligations relating to:
                    </span>
                </p>
                <p>
                    <span>
                        3.1.2.1. notifications to Supervisory Authorities;
                    </span>
                </p>
                <p>
                    <span>
                        3.1.2.2. prior consultations with Supervisory
                        Authorities;
                    </span>
                </p>
                <p>
                    <span>
                        3.1.2.3. communication of any breach to Data Subjects;
                        and
                    </span>
                </p>
                <p>
                    <span>3.1.2.4. privacy impact assessments.</span>
                </p>
                <h4>
                    <span>4. Personnel</span>
                </h4>
                <p>
                    <span>4.1 We shall:</span>
                </p>
                <p>
                    <span>
                        4.1.1 We shall: take reasonable steps to ensure the
                        reliability of any personnel who may have access to the
                        Personal Data;
                    </span>
                </p>
                <p>
                    <span>
                        4.1.2 We shall: ensure that access to the Personal Data
                        is strictly limited to those individuals who need to
                        know and/or access the Personal Data for the purposes of
                        this agreement; and
                    </span>
                </p>
                <p>
                    <span>
                        4.1.3 We shall: ensure that persons authorised to
                        Process the Personal Data have committed themselves to
                        confidentiality or are under an appropriate statutory
                        obligation of confidentiality.
                    </span>
                </p>
                <p>
                    <span>
                        4.2 If so required by Data Protection Laws, Hello
                        Cashflow shall appoint a data protection officer and
                        make details of the same publicly available.
                    </span>
                </p>
                <h4>
                    <span>5. Security and Audit</span>
                </h4>
                <p>
                    <span>
                        5.1. We shall implement and maintain appropriate
                        technical and organisational security measures
                        appropriate to the risks presented by the relevant
                        Processing activity to protect the Personal Data against
                        unauthorised or unlawful Processing and against
                        accidental loss, destruction, damage or disclosure. Such
                        measures include, without limitation, the security
                        measures set out in clause 5.3 below.
                    </span>
                </p>
                <p>
                    <span>
                        5.2. Subject to any existing obligations of
                        confidentiality owed to other parties, we shall make
                        available to you all information reasonably necessary to
                        demonstrate compliance with the obligations set out in
                        this Appendix B, which may include a summary of any
                        available third party security audit report, or shall,
                        at your sole cost and expense (including, for the
                        avoidance of doubt any expenses reasonably incurred by
                        us), allow for and contribute to independent audits,
                        including inspections, conducted by a suitably-qualified
                        third party auditor mandated by you and approved by us.
                    </span>
                </p>
                <p>
                    <span>
                        5.3. Hello Cashflow operates, maintains and enforces an
                        information security management programme
                        (&ldquo;ISMS&rdquo;) which is consistent with recognised
                        industry best practice. The ISMS contains appropriate
                        administrative, physical, technical and organisational
                        safeguards, policies and controls in the areas required
                        by ISO27001.
                    </span>
                </p>
                <h4>
                    <span>6. Data Breach</span>
                </h4>
                <p>
                    <span>
                        6.1.We shall notify you if we become aware of a breach
                        of security leading to the accidental or unlawful
                        destruction, loss, alteration, unauthorised disclosure
                        of, or access to the Personal Data arising from our, or
                        our sub-processors, acts or omissions.
                    </span>
                </p>
                <h4>
                    <span>7. Transfer of Personal Data outside the NZ</span>
                </h4>
                <p>
                    <span>
                        7.1. You acknowledge that the provision of Hello
                        Cashflow may require the Processing of Personal Data by
                        sub-processors in countries outside New Zealand. We
                        shall not transfer Personal Data outside New Zealand to
                        a sub-processor where such transfer is not subject to:
                        (a) an adequacy decision; or (b) appropriate safeguards;
                        or (c) binding corporate rules, without your prior
                        written consent.
                    </span>
                </p>
                <h4>
                    <span>8. Return and deletion</span>
                </h4>
                <p>
                    <span>
                        8.1. At your option, we shall delete or return all
                        Personal Data to you at the end of the provision of
                        Hello Cashflow and delete all existing copies of
                        Personal Data unless we are under a legal obligation to
                        require storage of that data or we have another
                        legitimate business reason for doing so.
                    </span>
                </p>
                <h4>
                    <span>9. Use of Sub-Processors</span>
                </h4>
                <p>
                    <span>
                        9.1.You agree that we have general authority to engage
                        third parties, partners, agents or service providers to
                        Process Personal Data on your behalf in order to provide
                        the applications, products, services and information you
                        have requested or which we believe is of interest to you
                        (&ldquo;Approved Sub-Processors&rdquo;). We shall not
                        engage a sub-processor to carry out specific Processing
                        activities which fall outside the general authority
                        granted above without your prior specific written
                        authorisation and, where such other sub-processor is so
                        engaged, we shall ensure that the same obligations set
                        out in this Appendix A shall be imposed on that
                        sub-processor.
                    </span>
                </p>
                <p>
                    9.2. We shall be liable for the acts and omissions of any
                    Approved Sub-Processor to the same extent we would be liable
                    if performing the services of each Approved Sub-Processor
                    directly under the terms of this agreement.For more general
                    information regarding how we engage with users, please
                    consult our General Terms and Conditions.
                </p>
            </Page>
            <Footer />
        </Container>
    );
};

export default TermsAndConditions;

const Container = styled.div`
    width: 1400px;
    background-color: ${props => props.theme.colors.Pink};
`;

const Page = styled.div`
    padding: 15px;
`;

const TopBar = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;
    height: 64px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.DarkBlue};
`;
