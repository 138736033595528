import { OrganisationIdentifier } from "common/types/objects/Organisation";
import { linkWithCredential, OAuthProvider } from "firebase/auth";
import { signInCustomToken } from "lib/firebase";
import { OnBoardingResult, XeroConnectProps } from "types/OnboardingTypes";

import { api } from "./api";

const onboardingSlice = api.injectEndpoints({
    endpoints: builder => ({
        getXeroLoginUrl: builder.query<string, void>({
            query: () => ({
                url: "xero/loginurl-signup",
                params: {
                    redirectUrl:
                        window.location.origin + "/onboarding/connected",
                },
            }),
        }),
        connectXero: builder.query<OrganisationIdentifier[], XeroConnectProps>({
            query: props => ({
                method: "POST",
                url: "xero/signup",
                params: {
                    redirectUrl:
                        window.location.origin + "/onboarding/connected",
                    ...props,
                },
            }),
            transformResponse: async (response: OnBoardingResult) => {
                const provider = new OAuthProvider("oidc.xero");
                //Sign the user in with the firebase account
                const authResult = await signInCustomToken(
                    response.firebaseToken,
                );
                //Link the xero provider using the id token
                const credential = provider.credential({
                    idToken: response.oidcIdToken,
                });
                try {
                    await linkWithCredential(authResult.user, credential);
                } catch (e: any) {
                    if (e.code !== "auth/provider-already-linked") {
                        throw e; // if the error is not provider-already-linked, throw it
                    }
                }
                return response.organisations;
            },
            transformErrorResponse: (error: any) => {
                console.log(error);
                if (error.data === "Email already exists") {
                    return "Email exists";
                }
            },
        }),
    }),
});

export const { useGetXeroLoginUrlQuery, useConnectXeroQuery } = onboardingSlice;
