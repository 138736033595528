import styled from "@emotion/styled";
import COLORS from "common/constants/COLORS";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import { monthItemByPriceDce } from "lib/apiDataHelpers/monthItemByPrice";
import { getOrgItem, sumOfMonth } from "lib/apiDataHelpers/sumOfMonths";
import { toMmmYy } from "lib/date/reStringifyMonth";
import { useRef, useState } from "react";
import { useAppSelector } from "redux/store";
import { theme } from "style/theme";
import { ItemObject, OrgData } from "types/OrganisationType";

interface Props {
    setKey: React.Dispatch<
        React.SetStateAction<{
            color: string;
            name?: string | undefined;
        }>
    >;
    actual: OrgData;
    items: ItemObject;
    compareMonth: MonthKey;
}

const BreakdownItemsList = ({ setKey, actual, compareMonth, items }: Props) => {
    const [showToolTip, setShowToolTip] = useState<boolean | number>(false);
    const cords = useRef<HTMLElement>(null);
    const reportDates = useAppSelector(state => state.dates);

    if (!reportDates) return null;

    const handleMouse = (e: any) => {
        if (cords.current) {
            cords.current.style.top = `${e.clientY}px`;
            cords.current.style.left = `${e.clientX}px`;
        }
    };

    return (
        <Container>
            <span>Click item to view on charts</span>
            <CatagoryNames key={4564357}>
                <b>Source</b>
                <ItemPrices>
                    <b>{toMmmYy(reportDates.selectedMonth)}</b>
                    <b>{toMmmYy(compareMonth)}</b>
                    <b>change</b>
                </ItemPrices>
            </CatagoryNames>
            <Items>
                {monthItemByPriceDce(actual[reportDates.selectedMonth]).map(
                    ([itemCode, value], index) => {
                        const itemValueCurrentMonth = getOrgItem(
                            actual,
                            reportDates.selectedMonth,
                            itemCode,
                        );
                        const itemValueCompareMonth = getOrgItem(
                            actual,
                            compareMonth,
                            itemCode,
                        );
                        return (
                            <Item
                                key={index}
                                onMouseOver={() => {
                                    setShowToolTip(index);
                                    document.addEventListener(
                                        "mousemove",
                                        handleMouse,
                                        false,
                                    );
                                }}
                                onMouseLeave={() => {
                                    setShowToolTip(false);
                                    document.removeEventListener(
                                        "mousemove",
                                        handleMouse,
                                    );
                                }}
                                onClick={() =>
                                    setKey({
                                        name: itemCode,
                                        color: COLORS.PIE_CHART[
                                            index % COLORS.PIE_CHART.length
                                        ],
                                    })
                                }>
                                <>
                                    {/* {showToolTip === index && (
                                    <ToolTip ref={cords}>
                                        Click here to update the line graph.
                                    </ToolTip>
                                )} */}
                                    <ItemNameColor
                                        color={
                                            COLORS.PIE_CHART[
                                                index % COLORS.PIE_CHART.length
                                            ]
                                        }
                                    />
                                    {items[itemCode]?.name}
                                    <ItemPrices>
                                        <span>
                                            {Writer.FormatCurrency(
                                                itemValueCurrentMonth,
                                            )}
                                        </span>
                                        <span>
                                            {Writer.FormatCurrency(
                                                itemValueCompareMonth,
                                            )}
                                        </span>
                                        <span>
                                            {Writer.FormatCurrency(
                                                (itemValueCurrentMonth -
                                                    itemValueCompareMonth) *
                                                    -1,
                                            )}
                                        </span>
                                    </ItemPrices>
                                </>
                            </Item>
                        );
                    },
                )}
            </Items>

            <Totals
                key={1234}
                onClick={() => setKey({ color: theme.colors.Yellow })}
                onMouseOver={() => {
                    setShowToolTip(true);
                    document.addEventListener("mousemove", handleMouse, false);
                }}
                onMouseLeave={() => {
                    setShowToolTip(false);
                    document.removeEventListener("mousemove", handleMouse);
                }}>
                {/* {showToolTip === true && (
                    <ToolTip ref={cords}>
                        Click here to update the line graph.
                    </ToolTip>
                )} */}
                <ItemNameColor color={theme.colors.Yellow} />
                Total
                <ItemPrices>
                    <span>
                        {Writer.FormatCurrency(
                            sumOfMonth(actual[reportDates.selectedMonth]),
                        )}
                    </span>
                    <span>
                        {Writer.FormatCurrency(
                            sumOfMonth(actual[compareMonth]),
                        )}
                    </span>
                    <span>
                        {Writer.FormatCurrency(
                            (sumOfMonth(actual[reportDates.selectedMonth]) -
                                sumOfMonth(actual[compareMonth])) *
                                -1,
                        )}
                    </span>
                </ItemPrices>
            </Totals>
        </Container>
    );
};
export default BreakdownItemsList;
const Container = styled.div`
    position: relative;
    > span {
        font-size: 12px;
        position: absolute;
        top: -15px;
        right: 0;
    }
`;
const Items = styled.ul`
    padding: 0;
    max-height: 450px;
    overflow-y: auto;
`;

const ToolTip = styled.div<{ x: number; y: number }>`
    position: absolute;
    top: ${props => props.y}px;
    left: ${props => props.x}px;
    background-color: ${props => props.theme.colors.DarkBlue};
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    z-index: 1;
    border: 1px solid ${props => props.theme.colors.DarkBlue};
`;

const CatagoryNames = styled.div`
    text-transform: uppercase;
    width: 100%;
    display: flex;
`;

const Item = styled.li`
    width: 100%;
    display: flex;
    margin-top: 9px;
    cursor: pointer;
    align-items: center;
    border-radius: 15px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
`;

const ItemNameColor = styled.span<{ color: string }>`
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    margin-left: 5px;
    background-color: ${props => props.color};
`;

const Totals = styled.div`
    cursor: pointer;
    width: 100%;
    display: flex;
    margin-top: 5px;
    align-items: center;
    padding-top: 4px;
    border-top: 1px solid ${theme.colors.DarkBlue};
    &:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
`;

const ItemPrices = styled.div`
    margin-left: auto;
    flex-basis: 40%;
    display: flex;
    justify-content: space-between;
    b,
    span {
        width: 30%;
        display: flex;
        justify-content: center;
    }
`;

const SwitchWords = styled.span`
    margin-right: 10px;
    letter-spacing: 3.2px;
`;

const SwitchMonths = styled.div`
    position: absolute;
    right: 35px;
    top: -30px;
    display: flex;
    align-items: center;
    height: 40px;

    color: ${({ theme }) => theme.colors.DarkBlue};
    text-transform: uppercase;
`;
