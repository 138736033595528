import RequireAuth from "components/auth/RequireAuth";
import PageNotFound from "components/general/404Stuff/PageNotFound";
import AppStoreSubscribe from "pages/Account/AppStoreSubscribe";
import HandleProviderLogin from "pages/Account/HandleProviderLogin";
import LinkProvider from "pages/Account/LinkProvider";
import { Route, Routes } from "react-router";

const AccountRoutes = () => (
    <Routes>
        <Route
            path='/providers'
            element={
                <RequireAuth>
                    <LinkProvider />
                </RequireAuth>
            }
        />
        <Route
            path='/appstore-subscribe'
            element={
                <RequireAuth>
                    <AppStoreSubscribe />
                </RequireAuth>
            }
        />
        <Route
            path='*'
            element={
                <RequireAuth>
                    <PageNotFound />
                </RequireAuth>
            }
        />

        {/* anon routes */}
        <Route path='/login/:provider' element={<HandleProviderLogin />} />
    </Routes>
);

export default AccountRoutes;
