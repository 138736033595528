import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { toMmmYy } from "lib/date/reStringifyMonth";
import { memo, useRef } from "react";
import { RootState, useAppSelector } from "redux/store";
import { OrgData } from "types/OrganisationType";

export interface MonthDataEditorProps {
    /**
     * A unique id for this editor INSTANCE
     * NOTE: the id should be unique to the data you are editing, so you probabily want to include the organisation id
     */
    id: string;

    /**
     * Key/value pairs representing rows in the editor
     * The key will be saved and the value shown to the user
     */
    items: { [key: string]: string }; //string[];

    /**
     * The current/focus month
     */
    startMonth: MonthKey;

    /**
     * How may months after the start month to show
     */
    forward: number;

    /**
     * How many month previous to the start month to show
     */
    back: number;

    /**
     * Do something with the modified data
     */
    setValue: (month: MonthKey, item: string, input: number) => void;

    modifyValue: (month: MonthKey, item: string, change: "+" | "-") => void;

    /**
     * Optional content builder for last row (e.g. a total or other helpful content)
     * Return an array where each item is content for a row (e.g. one item will add one row to the table)
     */
    summaryRowHelper?: (month: MonthKey, currentData: OrgData) => string[];

    /**
     * Labels for the summary rows, if set should corospond to the order of the items returned from summaryRowHelper
     */
    summaryRowLables?: string[];

    /**
     * items to enable fill helper for
     */
    enableFillHelper?: string[];

    reduxPath: (state: RootState) => OrgData | undefined;
}

interface InputButtons {
    continue: boolean;
    interval: number;
}

const MonthDataEditor = ({
    id,
    items,
    startMonth,
    forward,
    back,
    setValue,
    modifyValue,
    summaryRowLables,
    summaryRowHelper,
    enableFillHelper,
    reduxPath,
}: MonthDataEditorProps) => {
    const editorState = useAppSelector(reduxPath);
    const itemKeys = Object.keys(items);
    //const localStorageState = localStorage.getItem(`monthly_${id}`);
    // const [editorState, setEditorState] = useState<OrgData>(
    //     intialState, //(localStorageState && JSON.parse(localStorageState)) || {},
    // );
    const refId = useRef(id);
    const increment = useRef<InputButtons>({ continue: false, interval: 300 });
    const decrement = useRef<InputButtons>({ continue: false, interval: 300 });

    if (!editorState) return null;

    // //Persist the inputs
    // useEffect(() => {
    //     //If the id hasn't changed then update the local storage
    //     if (id === refId.current) {
    //         localStorage.setItem(`monthly_${id}`, JSON.stringify(editorState));
    //     } else {
    //         refId.current = id; // we are working with a new instance
    //         setEditorState(
    //             JSON.parse(localStorage.getItem(`monthly_${id}`) || "{}"),
    //         );
    //     }
    //     //Run the onchange handler if set so the parent can do something with the modified data
    //     //Suggest best to handle saving the data to teh server on parent component to keep this editor generic/reusable
    //     onChange && onChange(editorState);
    // }, [editorState, id, onChange]);

    //Check settings are valid
    if (back < 0 || forward < 0) {
        throw "non-negative input for forward and back months required!";
    }

    //Which months to show?
    const months: MonthKey[] = [];
    let i = -back + 1;
    while (i <= forward) {
        months.push(addMonthsTo(startMonth, i));
        i++;
    }

    //Handler for pasting data
    const cellPasteHandler = (
        p: React.ClipboardEvent<HTMLInputElement>,
        startRowIndex: number,
        startColIndex: number,
    ) => {
        const stringIsTableRe = /^[0-9-.]+[\t\r\n]{1,2}[0-9\t\r\n-.]+$/g; //numbers seperated by tabs and newlines, typical paste from excel etc
        const pastedData = p.clipboardData
            .getData("Text")
            .replace(/[^0-9\r\n\t.-]/g, ""); //strip any text and sudo numeric charactors from source that don't affect value (e.g. currency signs, commas)
        if (pastedData && stringIsTableRe.test(pastedData)) {
            const matched = pastedData.match(stringIsTableRe);
            if (!matched || matched.length < 1) return;

            const parsed = matched[0]
                .split("\r\n")
                //.filter((s) => s) //filter out any extra line breaks that result in null rows
                .map(strRow =>
                    strRow
                        .split("\t")
                        .map(strCell =>
                            !isNaN(Number(strCell)) ? Number(strCell) : null,
                        ),
                );
            if (
                parsed.length < 1 ||
                (parsed.length === 1 && parsed[0].length < 2)
            )
                return; // If less than two values pasted, just let the browser deal with it
            p.preventDefault(); //If we got this far we will handle value set, prevent browser doing so

            //Perform the update
            const newState = { ...editorState };
            for (let iRow = 0; iRow < parsed.length; iRow++) {
                const pastedRow = parsed[iRow];
                for (let iCol = 0; iCol < pastedRow.length; iCol++) {
                    const cellValue = pastedRow[iCol];
                    // A final check that we have a valad value, and we have not moved out of bounds (with the user pasting more data that cells to set)

                    if (
                        cellValue === null ||
                        itemKeys.length < startRowIndex + iRow + 1 ||
                        months.length < startColIndex + iCol + 1
                    )
                        continue;
                    const month = months[startColIndex + iCol];
                    setValue(
                        month,
                        itemKeys[startRowIndex + iRow],
                        Math.round(cellValue),
                    );
                }
            }
        }
    };

    //Fill accross handler
    const fillAccross = (item: string) => {
        let lastValue: number | undefined = undefined;
        for (let i = 0; i < months.length; i++) {
            const month = months[i];
            const monthItemValue =
                editorState[month] && editorState[month][item];
            if (lastValue) {
                setValue(month, item, lastValue);
            } else {
                lastValue = monthItemValue || lastValue;
            }
        }
    };

    //Clear row handler
    const clearRow = (item: string) => {
        for (let i = 0; i < months.length; i++) {
            const month = months[i];
            setValue(month, item, 0);
        }
    };

    //TODO
    const mthStyle = (m: MonthKey) =>
        m === startMonth ? { backgroundColor: "#fd6092" } : undefined;

    //Summary rows
    const summaryResults =
        summaryRowHelper && months.map(m => summaryRowHelper(m, editorState));
    const summaryRowLabels =
        (summaryResults?.length &&
            summaryResults[0].length &&
            summaryResults[0].map(
                (r, i) => (summaryRowLables && summaryRowLables[i]) || "",
            )) ||
        [];

    const buttonControl = (
        month: MonthKey,
        item: string,
        change: "+" | "-",
        step: number,
    ) => {
        let type: InputButtons;
        switch (change) {
            case "+":
                type = increment.current;
                break;
            case "-":
                type = decrement.current;
                break;
        }
        if (type.continue) {
            modifyValue(month, item, change);

            type.interval > 5 && (type.interval = type.interval - 15);
            setTimeout(() => {
                buttonControl(month, item, change, step);
            }, type.interval);
        }
    };

    return (
        <>
            <Container>
                <Item>
                    <Title>MONTH</Title>
                    <Items>
                        {months.map(month => (
                            <span key={month} style={mthStyle(month)}>
                                {toMmmYy(month)}
                            </span>
                        ))}
                    </Items>
                </Item>
                {itemKeys.map((item, rowIndex) => (
                    <Item key={rowIndex}>
                        <Title>
                            {items[item]}
                            <span
                                style={{ cursor: "help" }}
                                title='clear row'
                                onClick={() => clearRow(item)}>
                                &#8709;
                            </span>
                            {enableFillHelper &&
                            enableFillHelper.indexOf(item) !== -1 ? (
                                <span
                                    style={{ cursor: "help" }}
                                    title='Fill across to empty cells'
                                    onClick={() => fillAccross(item)}>
                                    {" "}
                                    &rarr;{" "}
                                </span>
                            ) : (
                                ""
                            )}
                        </Title>
                        <Items>
                            {months.map((month, colIndex) => (
                                <InPutContainer
                                    key={colIndex}
                                    style={mthStyle(month)}>
                                    <Input>
                                        <input
                                            value={
                                                (editorState[month] &&
                                                    editorState[month][item]) ??
                                                ""
                                            }
                                            type='number'
                                            step='1'
                                            style={{ width: "100%" }}
                                            onPaste={e =>
                                                cellPasteHandler(
                                                    e,
                                                    rowIndex,
                                                    colIndex,
                                                )
                                            }
                                            onChange={e => {
                                                const input = parseInt(
                                                    e.target.value,
                                                );
                                                setValue(month, item, input);
                                            }}
                                        />
                                        <Controls>
                                            <Increment
                                                onPointerDown={() => {
                                                    increment.current.continue =
                                                        true;
                                                    window.addEventListener(
                                                        "mouseup",
                                                        () => {
                                                            increment.current =
                                                                {
                                                                    interval: 300,
                                                                    continue:
                                                                        false,
                                                                };
                                                        },
                                                    );
                                                    buttonControl(
                                                        month,
                                                        item,
                                                        "+",
                                                        1,
                                                    );
                                                }}>
                                                <span>+</span>
                                            </Increment>
                                            <Decrement
                                                onPointerDown={() => {
                                                    decrement.current.continue =
                                                        true;
                                                    window.addEventListener(
                                                        "mouseup",
                                                        () => {
                                                            decrement.current =
                                                                {
                                                                    interval: 300,
                                                                    continue:
                                                                        false,
                                                                };
                                                        },
                                                    );
                                                    buttonControl(
                                                        month,
                                                        item,
                                                        "-",
                                                        1,
                                                    );
                                                }}>
                                                <span>-</span>
                                            </Decrement>
                                        </Controls>
                                    </Input>
                                </InPutContainer>
                            ))}
                        </Items>
                    </Item>
                ))}
                <Line />
                {!summaryResults ? (
                    ""
                ) : (
                    <>
                        {summaryRowLabels.map((lbl, summaryRowIndex) => (
                            <Item key={summaryRowIndex}>
                                <Title>{lbl}</Title>
                                <Items>
                                    {summaryResults.map(
                                        (r, summaryCellIndex) => (
                                            <span key={summaryCellIndex}>
                                                {r[summaryRowIndex]}
                                            </span>
                                        ),
                                    )}
                                </Items>
                            </Item>
                        ))}
                    </>
                )}
            </Container>
        </>
    );
};

export default memo(MonthDataEditor);

const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const Title = styled.span`
    width: 90px;
    margin-top: -1px;
    padding: 10px 3px;
    border-right: 1px solid #000000;
`;
const Line = styled.div`
    width: 100%;
    height: 2px;
    background-color: #a3a3a3;
    margin: 5px 0;
`;
const Item = styled.div`
    width: 100%;
    display: flex;
    flex-direction: row;
    border: 1px solid #000000;
`;

const Items = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    span {
        width: 100%;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
`;

const InPutContainer = styled.div`
    flex: 1;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 3px;
`;

const Input = styled.div`
    height: 22px;
    font-weight: bold;
    font-size: 11px;
    background-color: white;
    display: flex;
    flex-direction: row;
    flex-wrap: nowrap;
    justify-content: center;
    align-items: center;
    border: 1px solid #000000;
    border-radius: 5px;
    overflow: hidden;
    padding-left: 3px;
    input {
        /* height: 22px; */
        margin: 0;
        -moz-appearance: textfield;
        border: none;
    }
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        /* display: none; <- Crashes Chrome on hover */
        -webkit-appearance: none;
        margin: 0; /* <-- Apparently some margin are still there even though it's hidden */
    }
`;
const Controls = styled.div`
    width: 18px;
    display: flex;
    flex-direction: column;
    user-select: none;
`;
const BaseButton = styled.div`
    height: 11px;
    border-left: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    &:hover {
        background-color: #d6d6d6;
    }
    span {
        line-height: 0.1;
        text-align: center;
        text-justify: center;
    }
`;

const Increment = styled(BaseButton)`
    padding-top: 1px;
`;
const Decrement = styled(BaseButton)`
    padding-bottom: 2px;
    border-top: 1px solid #000000;
`;
