import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useRef, useState } from "react";

interface Props {
    delay?: number;
    children: React.ReactNode;
    content: React.ReactNode;
    position: Position;
}

type Position = "top" | "right" | "left" | "bottom";

const Tooltip = ({ delay, children, content, position }: Props) => {
    const timeout = useRef<NodeJS.Timeout>();
    const [active, setActive] = useState(false);

    const showTip = () => {
        timeout.current = setTimeout(() => {
            setActive(true);
        }, delay || 400);
    };

    const hideTip = () => {
        clearInterval(timeout.current);
        setActive(false);
    };

    return (
        <Wrapper
            // When to show the tooltip
            onMouseEnter={showTip}
            onMouseLeave={hideTip}>
            {/* Wrapping */}
            {children}🛈
            {active && (
                <ToolTip
                    position={position}
                    margin={10}
                    bgColor={"black"}
                    arrow={6}
                    textColor={"white"}>
                    {/* Content */}
                    {content}
                </ToolTip>
            )}
        </Wrapper>
    );
};

export default Tooltip;

const ToolTip = styled.div<{
    position: Position;
    margin: number;
    bgColor: string;
    arrow: number;
    textColor: string;
}>`
    position: absolute;
    border-radius: 4px;
    left: 50%;
    transform: translateX(-50%);
    padding: 6px;
    color: ${props => props.textColor};
    background-color: ${props => props.bgColor};
    font-size: 14px;
    font-family: sans-serif;
    line-height: 1;
    z-index: 100;
    white-space: pre-line;
    max-width: 300px;
    width: max-content;
    cursor: default;

    &::before {
        content: " ";
        left: 50%;
        border: solid transparent;
        height: 0;
        width: 0;
        position: absolute;
        pointer-events: none;
        border-width: ${props => props.arrow}px;
        margin-left: -${props => props.arrow}px;
    }

    ${props => {
        switch (props.position) {
            case "top":
                return css`
                    top: calc(-100% + -${props.margin}px);
                    &::before {
                        top: 100%;
                        border-top-color: ${props.bgColor};
                    }
                `;
            case "right":
                return css`
                    left: calc(100% + ${props.margin}px);
                    top: 50%;
                    transform: translateX(0) translateY(-50%);
                    &::before {
                        left: calc(${props.arrow}px * -1);
                        top: 50%;
                        transform: translateX(0) translateY(-50%);
                        border-right-color: ${props.bgColor};
                    }
                `;
            case "bottom":
                return css`
                    /* bottom: calc(-100% + -${props.margin}px); */
                    &::before {
                        bottom: 100%;
                        border-bottom-color: ${props.bgColor};
                    }
                `;
            case "left":
                return css`
                    left: auto;
                    right: calc(100% + ${props.margin}px);
                    top: 50%;
                    transform: translateX(0) translateY(-50%);
                    &::before {
                        left: auto;
                        right: calc(${props.arrow}px * -2);
                        top: 50%;
                        transform: translateX(0) translateY(-50%);
                        border-left-color: ${props.bgColor};
                    }
                `;
        }
    }}
`;

const Wrapper = styled.div`
    display: inline-block;
    position: relative;
    cursor: help;
`;
