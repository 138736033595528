import styled from "@emotion/styled";
import { ReactComponent as BackSVG } from "assets/general/backArrow.svg";
import image from "assets/img/backgrounds/home_stretch.png";
import { Writer } from "common/Writer";
import LogoHorizontal from "components/brand/LogoHorizontal";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { getUserType } from "lib/firebase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import {
    useCreatePortalLinkQuery,
    useSubscriptionsQuery,
} from "redux/rtkQuery/stripe";

const SubscribePage = () => {
    const [userType, setUserType] = useState<{
        isAppstore: boolean;
        isXero: boolean;
        isQuickbooks: boolean;
    }>();

    const { data: subscriptions } = useSubscriptionsQuery();
    const [goToPortal, setGoToPortal] = useState(false);
    const { data: portalURL } = useCreatePortalLinkQuery(undefined, {
        skip: !goToPortal,
    });

    const org = useCurrentOrg();

    useEffect(() => {
        getUserType().then(setUserType);
    }, []);

    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Subscription";
    }, []);

    if (goToPortal && portalURL) {
        window.location.href = portalURL;
    }

    const go_back = () => {
        // find a better way
        navigate("/reports/my-revenue");
    };

    return userType && org ? (
        <Container>
            <Logo>
                <LogoHorizontal asLink />
            </Logo>
            <Back>
                <BackSVG onClick={go_back} />
            </Back>

            {!userType.isAppstore && (
                <>
                    {" "}
                    <StripeCustomerPortal>
                        <button
                            onClick={() => {
                                document.body.style.cursor = "wait";
                                setGoToPortal(true);
                            }}>
                            Manage your subscription
                        </button>
                    </StripeCustomerPortal>
                    <h1>Your Subscriptions</h1>
                    <AllSubscriptions>
                        {subscriptions?.map(subscription => (
                            <SubscriptionContainer key={subscription.id}>
                                <h1>{subscription.description}</h1>
                                <span>Status: {subscription.status}</span>
                                {subscription.status === "trialing" &&
                                    subscription.trialEnd && (
                                        <span>
                                            Trial ends: {subscription.trialEnd}
                                        </span>
                                    )}
                                <span>
                                    Price:{" "}
                                    <span
                                        style={{ textTransform: "uppercase" }}>
                                        {subscription.price.currency}
                                    </span>{" "}
                                    {Writer.FormatCurrency(
                                        subscription.price.amount / 100,
                                    )}
                                </span>
                            </SubscriptionContainer>
                        ))}
                    </AllSubscriptions>
                </>
            )}

            {userType.isAppstore && userType.isXero && (
                <>
                    <h1>
                        Manage or cancell your subscription in the Xero Appstore
                    </h1>
                    <AppLink
                        href={
                            org.shortCode
                                ? `https://apps.xero.com/${org.shortCode}/my-apps/2a135089-3955-43d3-a17c-39a6fe12f642`
                                : `https://apps.xero.com/my-apps/2a135089-3955-43d3-a17c-39a6fe12f642`
                        }>
                        Go to app store
                    </AppLink>
                </>
            )}

            {/* <AllSubscriptions>
                {subscriptions?.map((subscription) => (
                    <SubscriptionContainer key={subscription.id}>
                        <Title>
                            {product.interval === "month"
                                ? "Monthly"
                                : "Annually"}
                        </Title>
                        <Price>
                            ${(price.unit_amount / 100).toFixed(2)}{" "}
                            {price.currency} / {price.interval}
                        </Price>
                        <Choose
                            onClick={() =>
                                !subscriptionStatus
                                    ? user?.uid &&
                                      createCheckoutSession(user.uid, price.id)
                                    : setGoToPortal(true)
                            }>
                            {product.interval === currentPrice
                                ? "Current Subscription"
                                : "Select"}
                        </Choose>
                        <Description>
                            {product.description
                                    .split("\\n")
                                    .map((line: string, index) => (
                                        <li key={index}>{line}</li>
                                    ))}
                        </Description>
                    </SubscriptionContainer>
                ))}
            </AllSubscriptions>
             */}
        </Container>
    ) : null;
};

export default SubscribePage;

//button looking link
const AppLink = styled.a`
    margin-left: 15px;
    color: ${props => props.theme.colors.DarkBlue};
    font-size: 18px;
    font-weight: 800;
    text-decoration: underline;
    &:hover {
        color: ${props => props.theme.colors.DarkPink};
    }
`;

const Container = styled.div`
    position: relative;
    background-color: transparent;
    background-image: url(${image});
    height: 650px;
    display: flex;
    flex-direction: column;
    > h1 {
        margin-left: 15px;
    }
`;
const AllSubscriptions = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-evenly;
`;
const SubscriptionContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    /* justify-content: space-evenly; */
    height: 350px;
    width: 250px;
    padding: 20px;
    border: 1px solid black;
    background-color: #fafafa;
    border-radius: 15px;
`;
const Title = styled.h1`
    margin: 0;
    margin-top: 30px;
    text-decoration: underline;
    text-align: center;
`;

const Price = styled.h1`
    font-size: 18px;
    margin: 0;
    margin-top: 30px;
    text-transform: uppercase;
`;

const Choose = styled.button`
    margin-top: 30px;
    background-color: #4ac2e0;
    color: white;
    border-radius: 5px;
    &:hover {
        background-color: #4ac2e0;
        color: black;
    }
`;

const Description = styled.ul`
    margin-right: 25px;
`;
const StripeCustomerPortal = styled.ul`
    width: 150px;
    position: absolute;
    top: 10px;
    right: 50px;
    button {
        background-color: #a44ae0;
        color: white;
        border-radius: 5px;
        &:hover {
            background-color: #a44ae0;
            color: black;
        }
    }
`;

const Back = styled.div`
    cursor: pointer;
    height: 50px;
    width: 50px;
    position: absolute;
    bottom: 10px;
    left: 10px;
    fill: ${props => props.theme.colors.DarkBlue};
    &:hover {
        fill: ${props => props.theme.colors.DarkPink};
    }
`;

const Logo = styled.div`
    margin: 20px;
`;
