import styled from "@emotion/styled";
import screenshoOnboarding from "assets/img/onboarding.png";
import arr from "assets/img/pink-arrow.png";
import React from "react";

// Define the styled-components
const Page = styled.div`
    padding: 20px;
    font-family: Arial, sans-serif;
`;
const NavMenu = styled.nav`
    display: flex;
    justify-content: space-around;
    margin-bottom: 30px;
`;
const MenuItem = styled.div`
    cursor: pointer;
`;
const Section = styled.section`
    margin-bottom: 30px;
`;
const Title = styled.h2`
    margin-bottom: 10px;
`;
const FAQ = styled.dl``;
const Question = styled.dt``;
const Answer = styled.dd`
    margin-bottom: 15px;
`;
const ImagePlaceholder = styled.div`
    width: 300px;
    height: 200px;
    background-color: #eee;
`;

const SupportPage: React.FC = () => {
    const scrollToSection = (id: string) => {
        document.getElementById(id)?.scrollIntoView();
    };

    return (
        <Page>
            {/* Navigation Menu */}
            <NavMenu>
                <MenuItem onClick={() => scrollToSection("getting-started")}>
                    Getting started
                </MenuItem>
                <MenuItem onClick={() => scrollToSection("data-flow")}>
                    Data flow diagram
                </MenuItem>
                <MenuItem onClick={() => scrollToSection("faq")}>FAQ</MenuItem>
            </NavMenu>

            {/* Getting started */}
            <Section id='getting-started'>
                <Title>Getting started</Title>
                <p>
                    Connecting Hello Cashflow to Xero is a breeze. Simply
                    navigate to{" "}
                    <a href='https://app.hellocashflow.com/'>
                        HelloCashflow.com
                    </a>{" "}
                    to register and approve the Xero access.
                </p>

                <p>
                    The import process will be completed in just a few minutes.
                    Hello Cashflow will automatically update to reflect any
                    changes made in Xero at every sync, with the option to
                    manually trigger an update whenever required.
                </p>

                <p>
                    With our tailored training, webinars, and introduction
                    sessions, you'll be ready to use the system in no time.
                </p>

                <p>
                    Hellow Cashflow does not alter any information on your Xero
                    account. The data transfer from Xero to Hello Cashflow is
                    strictly one-way, ensuring your data is always safe and
                    secure.
                </p>

                <img src={screenshoOnboarding} alt='screenshot' />
            </Section>

            {/* Data flow diagram */}
            <Section id='data-flow'>
                <Title>Data flow diagram</Title>
                <Diagram />
            </Section>

            {/* FAQ */}
            <Section id='faq'>
                <Title>FAQ</Title>
                <FAQ>
                    <Question>How do I disconnect from Xero?</Question>
                    <Answer>
                        You can disconnect Xero by visiting{" "}
                        <a href='https://app.hellocashflow.com/profile/integrations'>
                            app.hellocashflow.com/profile/integrations
                        </a>
                        . Locate the 'Disconnect' button for the Xero
                        integration and click on it. Please be aware that this
                        will disable the functionality of Hello Cashflow until
                        you reconnect Xero or another integration.
                    </Answer>
                    <Question>What doesn't your integration do?</Question>
                    <Answer>
                        We maintain the integrity of your data in Xero by using
                        a one-way data flow process. Therefore, we do not make
                        modifications or alterations to your information in
                        Xero.
                    </Answer>
                    <Question>
                        How do I reconcile sales data synced to Xero?
                    </Question>
                    <Answer>
                        Reconciliation conducted in Xero, and the results
                        seamlessly integrate with Hello Cashflow. This
                        streamlined system assures accuracy and simplicity in
                        managing your financial transactions.
                    </Answer>
                    <Question>
                        When and how does data sync occur? Is it manual,
                        automatic, on login, in real-time. Are there any
                        settings related to synchronizing?
                    </Question>
                    <Answer>
                        Data is automatically synchronized every time you use
                        the app, ensuring your information stays up-to-date. For
                        example, if you add a new account or reconcile a
                        transaction in Xero, you can immediately sync these
                        changes. Simply click on the synchronizing arrows
                        located at the top right corner of the app for instant
                        on-demand synchronization.
                    </Answer>
                </FAQ>
            </Section>
        </Page>
    );
};

export default SupportPage;

//Diagtram

interface BubbleProps {
    isFirst?: boolean;
}

const DiagramContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    position: relative;
`;

const Bubble = styled.div<BubbleProps>`
    width: 200px;
    height: 200px;
    background-color: #0c0341;
    border-radius: 50%;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    color: rgb(255, 224, 233);
    margin: 0 50px;
    position: relative;

    img {
        width: 100px;
        position: absolute;
        top: 80px;
        right: -100px;
    }
`;

const ItemList = styled.ul`
    list-style-type: circle;
    padding: 0;
    margin: 10px 0 0 0;
`;

const ListItem = styled.li``;

const Diagram = () => (
    <DiagramContainer>
        <Bubble isFirst>
            <h1>Xero</h1>
            <ItemList>
                <ListItem>Reports</ListItem>
                <ListItem>Accounts</ListItem>
                <ListItem>Transactions</ListItem>
                <img src={arr} alt='arrow' />
            </ItemList>
        </Bubble>
        <Bubble>
            <h1>Hello Cashflow</h1>
            <ItemList>
                <ListItem>Insights</ListItem>
                <ListItem>Budgeting</ListItem>
                <ListItem>Tools</ListItem>
            </ItemList>
        </Bubble>
    </DiagramContainer>
);
