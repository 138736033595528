import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import UIErrorBoundary from "components/error-boundaries/SectionErrorBoundary";
import useReport from "hooks/reports/useReport";
import useSize from "hooks/useSize";
import useUpdator from "hooks/useUpdator";
import { toMmmYy } from "lib/date/reStringifyMonth";
import { useEffect, useRef } from "react";
import { useUpdateBudgetMutation } from "redux/rtkQuery/reports";
import { useAppDispatch } from "redux/store";
import { Item, ItemType } from "types/OrganisationType";

import ExpensesActual from "./ExpensesActual";
import ExpensesEdit from "./ExpensesEdit";
import RevenueActual from "./RevenueActual";
import RevenueEdit from "./RevenueEdit";

interface RevenueAndExpensesEditorProps {
    actualMonths: MonthKey[];
    forecastMonths: MonthKey[];
}

/**
 * RevenueAndExpensesEditor component is a React functional component
 * that displays and enables editing of revenue and expense items for
 * actual and forecast months.
 *
 * @param {Object} props - Component properties
 * @param {MonthKey[]} props.actualMonths - Array of month keys for actual months
 * @param {MonthKey[]} props.forecastMonths - Array of month keys for forecast months
 */
const RevenueAndExpensesEditor = ({
    actualMonths,
    forecastMonths,
}: RevenueAndExpensesEditorProps) => {
    // Import the useAppDispatch hook from the app's store to dispatch actions
    const dispatch = useAppDispatch();

    // Refs for various elements to manage scrolling and sizing
    const parentRef = useRef<HTMLDivElement>(null);
    const namesRef = useRef<HTMLDivElement>(null);
    const monthsRef = useRef<HTMLDivElement>(null);
    const scrollRef = useRef<HTMLDivElement>(null);
    const childRef = useRef<HTMLDivElement>(null);

    // Custom hooks to get the width of the elements
    const parentWidth = useSize(parentRef);
    const namesWidth = useSize(namesRef);
    const scrollwidth = useSize(childRef);

    const report = useReport();

    const items = report?.items;

    const [updateBudget] = useUpdateBudgetMutation();

    // Use the useUpdator custom hook to update the budget data
    useUpdator(
        state => state.budget.present,
        (data, orgid) => {
            updateBudget({ ...data, organisationId: orgid });
        },
    );

    // Filter and sort revenue and expense items
    const revenueItems =
        items &&
        Object.entries(items)
            .filter(([, item]) => item.itemType === ItemType.revenue)
            .sort((a, b) => {
                if (a[1].name < b[1].name) {
                    return -1;
                }
                if (a[1].name > b[1].name) {
                    return 1;
                }
                return 0;
            });
    const expensesItems =
        items &&
        Object.entries(items)
            .filter(([, item]) => item.itemType === ItemType.expense)
            .sort((a, b) => {
                if (a[1].name < b[1].name) {
                    return -1;
                }
                if (a[1].name > b[1].name) {
                    return 1;
                }
                return 0;
            });

    /**
     * Helper function to navigate through the items.
     * @param {object} currentFocused - The currently focused element's dataset.
     * @param {Array} items - An array of items for navigation (revenueItems or expensesItems).
     * @param {string} type - The type of item ("revenue" or "expenses").
     */
    const navigate = (currentFocused: any, items: any[], type: string) => {
        // Find the index of the current focused element in the items array
        const oldIndex =
            items.findIndex(([x, _]) => x === currentFocused.code) + 1;

        // If there is a next element in the items array
        if (oldIndex < items.length) {
            const code = items[oldIndex][0];
            // Find the next element using the type, month, and code
            const element: any = document.querySelector(
                `[data-type="${type}"][data-month="${currentFocused.month}"][data-code="${code}"]`,
            );

            // Focus the next element if it exists
            if (element) {
                element.focus();
            }
        }
    };

    useEffect(() => {
        /**
         * Event handler for key press events.
         * @param {KeyboardEvent} event - The keyboard event object.
         */
        const handleKeypress = (event: KeyboardEvent) => {
            // Handle Enter key press for navigation
            if (event.key === "Enter") {
                const currentFocused = (document.activeElement as any).dataset;
                if (currentFocused && revenueItems && expensesItems) {
                    if (currentFocused.type === "revenue") {
                        // Navigate through revenue items
                        navigate(currentFocused, revenueItems, "revenue");
                    } else if (currentFocused.type === "expenses") {
                        // Navigate through expenses items
                        navigate(currentFocused, expensesItems, "expenses");
                    }
                }
            }

            // Handle Ctrl+Z and Ctrl+Y for undo and redo actions
            if (event.ctrlKey) {
                if (event.key === "z") {
                    dispatch({
                        type: "budgetUndo",
                    });
                }
                if (event.key === "y") {
                    dispatch({
                        type: "budgetRedo",
                    });
                }
            }
        };

        // Add the event listener for keydown events
        window.addEventListener("keydown", handleKeypress);

        // Clean up the event listener when the effect is unmounted
        return () => {
            window.removeEventListener("keydown", handleKeypress);
        };
    }, [dispatch, expensesItems, revenueItems]);

    // Create JSX elements for revenue and expense items and their respective months
    /**
     * Create a JSX element for an item (revenue or expense) and its number.
     * @param {Array} items - An array of items (revenue or expense).
     */
    const createItemsElements = (items: Array<[string, Item]> | undefined) =>
        items &&
        items.map(([number, item], index) => (
            <div key={index}>
                {item.name} ({number})
            </div>
        ));

    /**
     * Create a JSX element for a month with a formatted date string.
     * @param {Array} months - An array of months.
     */
    const createMonthElements = (months: MonthKey[]) =>
        months.map((month, index) => (
            <div key={index}>
                <h3>{toMmmYy(month)}</h3>
            </div>
        ));

    /**
     * Create a JSX element for a space (empty div).
     * @param {Array} months - An array of items.
     */
    const createSpaceElements = (months: MonthKey[]) =>
        months.map((_, index) => <div key={index}></div>);

    // Create JSX elements for revenue and expense items
    const revItems = createItemsElements(revenueItems);
    const expItems = createItemsElements(expensesItems);

    // Create JSX elements for actual and forecast months, as well as their spaces
    const monthsActual = createMonthElements(actualMonths);
    const spacesActual = createSpaceElements(actualMonths);
    const months = createMonthElements(forecastMonths);
    const spaces = createSpaceElements(forecastMonths);

    return (
        <EditorContainer>
            <Editor>
                {/* Header section with months, revenue and expenses */}
                <Header>
                    <div>
                        <div
                            data-type='name'
                            style={{ width: namesRef.current?.clientWidth }}>
                            <h3>Revenue</h3>
                        </div>
                        <div
                            data-type='months'
                            ref={monthsRef}
                            style={{ width: parentWidth?.width }}>
                            <div>
                                {/* Actual months */}
                                <Months>{monthsActual}</Months>
                                {/* Forecast months */}
                                <Months>{months}</Months>
                            </div>
                        </div>
                    </div>
                </Header>
                <div
                    style={{
                        display: "flex",
                        height: "fit-content",
                        width: "100%",
                    }}>
                    {/* Revenue and expenses items names */}
                    <EditorNames ref={namesRef}>
                        {revItems}
                        <div
                            style={{
                                top: 0,
                                position: "sticky",
                                height: 35,
                                zIndex: 2,
                            }}>
                            <h3>Expenses</h3>
                        </div>
                        {expItems}
                    </EditorNames>
                    {/* Editor inputs for actual and forecast months */}
                    <EditorInputs
                        onScroll={(e: any) => {
                            scrollRef.current?.scrollTo({
                                left: e.target?.scrollLeft,
                            });
                            monthsRef.current?.scrollTo({
                                left: e.target?.scrollLeft,
                            });
                        }}
                        ref={parentRef}>
                        <div ref={childRef}>
                            {/* Actual months inputs */}
                            <ActualInputs>
                                <UIErrorBoundary>
                                    <RevenueActual
                                        months={actualMonths}
                                        revenueItems={revenueItems}
                                    />
                                    <Headers>{spacesActual}</Headers>
                                    <ExpensesActual
                                        months={actualMonths}
                                        expensesItems={expensesItems}
                                    />
                                </UIErrorBoundary>
                            </ActualInputs>
                            {/* Forecast months inputs */}
                            <BudgetInputs>
                                <UIErrorBoundary>
                                    <RevenueEdit
                                        months={forecastMonths}
                                        revenueItems={revenueItems}
                                        numberOfMonths={forecastMonths.length}
                                    />
                                    <Headers>{spaces}</Headers>
                                    <ExpensesEdit
                                        months={forecastMonths}
                                        expensesItems={expensesItems}
                                        numberOfMonths={forecastMonths.length}
                                    />
                                </UIErrorBoundary>
                            </BudgetInputs>
                        </div>
                    </EditorInputs>
                </div>
            </Editor>
            {/* Scrollbar for the editor */}
            <Scroll
                ref={scrollRef}
                onScroll={(e: any) => {
                    parentRef.current?.scrollTo({
                        left: e.target?.scrollLeft,
                    });
                    monthsRef.current?.scrollTo({
                        left: e.target?.scrollLeft,
                    });
                }}
                width={parentWidth?.width}
                margin={(namesWidth?.width ?? 0) + 24}>
                <div style={{ width: scrollwidth?.width, height: 1 }}></div>
            </Scroll>
        </EditorContainer>
    );
};

export default RevenueAndExpensesEditor;

const BudgetInputs = styled.div``;
const ActualInputs = styled.div``;

const Months = styled.div`
    display: flex;

    div {
        background-color: ${props => props.theme.colors.Pink};
        height: 35px;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #686868;
    }
`;
const Headers = styled.div`
    display: flex;

    div {
        height: 35px;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #686868;
    }
`;

const Scroll = styled.div<{
    width: number | undefined;
    margin: number | undefined;
}>`
    width: ${props => props.width}px !important;
    margin-left: ${props => props.margin}px;
    z-index: 10;
    height: 18px !important;
    bottom: 0px;
    position: sticky !important;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    overflow-x: scroll;
    -ms-overflow-style: scrollbar; /* IE and Edge */
    scrollbar-width: auto;
    &::-webkit-scrollbar {
        display: auto;
    }
    div {
        flex-shrink: 0;
    }
`;

const EditorInputs = styled.div`
    display: flex;
    /* flex-direction: column; */
    width: 100%;
    overflow-x: auto;
    position: relative;

    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
        display: none;
    }
    > div {
        display: flex;
    }
`;

const EditorContainer = styled.div`
    padding: 15px;
    width: 100%;
    height: 100%;
    position: relative;
`;
const Editor = styled.div`
    width: 100%;
    height: inherit;
    /* position: relative; */
    display: flex;
    /* overflow: hidden;
    > div {
        width: 100%;
        height: 100%; */
    /* position: relative; */
    /* display: flex; */
    flex-direction: column;
    overflow-y: auto;
    overflow-x: hidden;
    /* } */
`;

const EditorNames = styled.div`
    margin-left: 24px;
    div {
        background-color: ${props => props.theme.colors.Pink};
        border: 1px solid #686868;
        padding: 4px;
        height: 30px;
        white-space: nowrap;
        h3 {
            margin: 5px 0;
        }
    }
`;

const Header = styled.div`
    display: flex;
    margin-left: 24px;
    position: sticky;
    top: 0px;
    width: 100%;
    z-index: 1;

    > div {
        display: flex;
        width: 100%;
    }

    h3 {
        margin: 5px 0;
    }
    div[data-type="name"] {
        background-color: ${props => props.theme.colors.Pink};
        border: 1px solid #686868;
        padding: 4px;
        height: 35px;
        white-space: nowrap;
        h3 {
            margin: 5px 0;
        }
    }
    div[data-type="months"] {
        width: 100%;
        overflow-x: scroll;

        > div {
            display: flex;
        }

        -ms-overflow-style: none; /* IE and Edge */
        scrollbar-width: none; /* Firefox */
        &::-webkit-scrollbar {
            display: none;
        }
    }
`;
