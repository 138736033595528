import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { useSetOrganisationQuarterlyMutation } from "redux/rtkQuery/organisations";
import { useAppSelector } from "redux/store";

const MonthQuarterSelect = () => {
    const currentOrg = useCurrentOrg();
    const [setShowQuarterly] = useSetOrganisationQuarterlyMutation();
    const showQuarterly = useAppSelector(state => state.dates?.showQuarterly);

    const handleChange: React.ChangeEventHandler<HTMLSelectElement> = e => {
        currentOrg &&
            setShowQuarterly({
                organisationId: currentOrg?.organisationId,
                changeTo: e.target.value === "q",
            });
    };

    return showQuarterly !== undefined ? (
        <select
            value={showQuarterly ? "q" : "m"}
            style={{
                color: "#0c0341",
                fontSize: "18px",
                border: "0",
                backgroundColor: "transparent",
                fontWeight: "bold",
            }}
            onChange={handleChange}>
            <option value='m'>monthly</option>
            <option value='q'>quarterly</option>
        </select>
    ) : null;
};

export default MonthQuarterSelect;
