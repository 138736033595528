import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { OrganisationTypes } from "common/types/objects/Organisation";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { useState } from "react";
import {
    useSetOrganisationQuarterlyMutation,
    useSetOrganisationTypeMutation,
} from "redux/rtkQuery/organisations";

interface Props {
    changePage: React.Dispatch<React.SetStateAction<number>>;
    index: number;
}

const ChooseOrgType = ({ changePage, index }: Props) => {
    const [page, setPage] = useState(0);
    const organisation = useCurrentOrg();
    const [setOrgType] = useSetOrganisationTypeMutation();
    const [setOrgQuarterly] = useSetOrganisationQuarterlyMutation();

    const organisationId = organisation?.organisationId;
    const orgType = organisation?.organisationType;
    const go = !!orgType || orgType === 0;
    const quarters = organisation?.quarters;

    const setQuarters = (bool: boolean) => {
        setOrgQuarterly({ organisationId, changeTo: bool });
    };

    const setType = (input: OrganisationTypes) => {
        setOrgType({ organisationId, changeTo: input });
    };

    return (
        <Container>
            <Menu>
                <Back onClick={() => changePage(index - 1)}>Back</Back>
                <h1>Your Organisation Settings</h1>
                <Continue go={go} onClick={() => go && changePage(index + 1)}>
                    Continue
                </Continue>
            </Menu>
            <Pages>
                <p>
                    {/* How do you like to review the data in your business? Monthly
                    or quarterly */}
                </p>
                <PageSelector>
                    <PageSelect
                        selected={page === 0}
                        onClick={() => setPage(0)}>
                        Organisation Months
                    </PageSelect>
                    <PageSelect
                        selected={page === 1}
                        onClick={() => setPage(1)}>
                        Organisation Type
                    </PageSelect>
                </PageSelector>
                {page === 0 && (
                    <Page>
                        <Option>
                            <div>
                                <Input
                                    type='radio'
                                    name='quarters'
                                    checked={!quarters}
                                    onChange={e => setQuarters(false)}
                                />
                                <span>Monthly</span>
                            </div>
                            <p>Click here if you budget your data monthly </p>
                        </Option>
                        <Option>
                            <div>
                                <Input
                                    type='radio'
                                    name='quarters'
                                    checked={quarters}
                                    onChange={e => setQuarters(true)}
                                />
                                <span>Quarterly</span>
                            </div>
                            <p>Click here if you budget your data quarterly </p>
                        </Option>
                    </Page>
                )}
                {page === 1 && (
                    <Page>
                        {/* <p>
                Does most of your revenue come from a subscription based product
                / service?
            </p> */}
                        <Option>
                            <div>
                                <Input
                                    type='radio'
                                    name='sub/tran'
                                    checked={
                                        orgType ===
                                        OrganisationTypes.subscription
                                    }
                                    onChange={e =>
                                        setType(OrganisationTypes.subscription)
                                    }
                                />
                                <span>Subscription</span>
                            </div>
                            <p>
                                {" "}
                                A subscription business model is based on
                                selling a product or service in return for a
                                recurring fee. Click here if you offer content,
                                software, services, or even physical products
                                via subscription.
                            </p>
                        </Option>
                        <Option>
                            <div>
                                <Input
                                    type='radio'
                                    name='sub/tran'
                                    checked={
                                        orgType ===
                                        OrganisationTypes.transactional
                                    }
                                    onChange={e =>
                                        setType(OrganisationTypes.transactional)
                                    }
                                />
                                <span>Transactional</span>
                            </div>
                            <p>
                                {" "}
                                Transactional marketing focuses on single,
                                "point of sale" transactions. Click here if you
                                offer a product or service that is not
                                subscription based.
                            </p>
                        </Option>
                    </Page>
                )}
            </Pages>
            <img
                src='/assets/horizental version.png'
                alt='HelloCashflow'
                height={50}
                width={160}
                style={{
                    marginRight: "auto",
                    marginBottom: 20,
                    marginLeft: 20,
                }}
            />
        </Container>
    );
};

export default ChooseOrgType;

const PageSelector = styled.div`
    display: flex;
    justify-content: space-evenly;
    width: 400px;
    border-bottom: 1px solid ${props => props.theme.colors.DarkBlue};
`;

const PageSelect = styled.span<{ selected: boolean }>`
    position: relative;
    margin: 7px;
    cursor: pointer;
    &:hover {
        &::after {
            left: 16px;
            width: calc(100%);
            background: #777777;
            top: 22px;
            left: 0;
            content: "";
            height: 3px;
            position: absolute;
            color: #000;
            font-family: Inter, X-LocaleSpecific, sans-serif;
            font-size: 16px;
            font-weight: 700;
            line-height: 1.5;
            border-radius: 12px;
        }
    }
    ${props =>
        props.selected &&
        css`
            &::after {
                left: 16px;
                width: calc(100%);
                background: #000;
                top: 22px;
                left: 0;
                content: "";
                height: 3px;
                position: absolute;
                color: #000;
                font-family: Inter, X-LocaleSpecific, sans-serif;
                font-size: 16px;
                font-weight: 700;
                line-height: 1.5;
                border-radius: 12px;
            }
        `}
`;

const Pages = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 100%;
    padding-top: 15%;
`;

const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Input = styled.input`
    cursor: pointer;
    margin: 0;
`;

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    position: relative;
    p {
    }

    > div {
        display: flex;
    }
`;

const Option = styled.div`
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    div {
        display: flex;
        align-items: center;
    }
    span {
        margin: 0;
        margin-left: 5px;
    }

    p {
        margin: 0;
        margin-left: 20px;
        font-size: 12px;
    }
`;
const Menu = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 0 10px;
    h1 {
        margin: 0;
    }
`;

const Continue = styled.button<{ go: boolean }>`
    padding: 10px;
    border-radius: 15px;
    width: 150px;
    height: 35px;
    ${props =>
        !props.go &&
        css`
            background-color: #575757;
            &:hover {
                background-color: #575757;
                color: #ceccd9;
                cursor: default;
            }
        `}
`;

const Back = styled.button`
    padding: 10px;
    height: 35px;
    border-radius: 15px;
    width: 150px;
    background-color: #636363;
    &:hover {
        background-color: #444444;
        color: white;
    }
`;
