import styled from "@emotion/styled";
import LogoHorizontal from "components/brand/LogoHorizontal";
import FullScreenNav from "components/navigation/fullScreenNav";
import PhoneNav from "components/navigation/PhoneNav";
import useIsAdmin from "hooks/firebase/useIsAdmin";
import { Route, Routes } from "react-router";
import { NavLink } from "react-router-dom";

import Account from "./account";
import AdminContent from "./AdminContent";
import AdvancedSettings from "./advancedSettings/advancedSettings";
import ExtraOptions from "./ExtraOptions";
import Integrations from "./integrations";
import Notifications from "./notifications";
import OrganisationSettings from "./orgsettings/OrganisationSettings";
import Password from "./password";

const Profile = () => {
    const isAdmin = useIsAdmin();
    return (
        <Container>
            <TopBar>
                <LogoHorizontal
                    absolute={false}
                    showText={false}
                    asLink={true}
                />
                <FullScreenNav />
                <PhoneNav />
            </TopBar>
            <Body>
                <SideMenu>
                    <div>
                        <NavLink to='/profile/account'>Account</NavLink>

                        <NavLink to='/profile/notifications'>
                            Notifications
                        </NavLink>

                        <NavLink to='/profile/extra-options'>
                            Extra options
                        </NavLink>
                        <NavLink to='/profile/password'>Password</NavLink>
                        <NavLink to='/profile/integrations'>
                            Integrations
                        </NavLink>
                        <NavLink to='/profile/advancedSettings'>
                            AdvancedSettings
                        </NavLink>

                        <NavLink to='/profile/organisationSettings'>
                            OrganisationSettings
                        </NavLink>
                    </div>

                    {isAdmin && (
                        <NavLink to='/profile/admin-settings'>
                            Admin Settings
                        </NavLink>
                    )}
                </SideMenu>
                <Page>
                    <Routes>
                        <Route path='/account' element={<Account />} />
                        <Route
                            path='/notifications'
                            element={<Notifications />}
                        />
                        <Route
                            path='/extra-options'
                            element={<ExtraOptions />}
                        />
                        <Route path='/password' element={<Password />} />
                        <Route
                            path='/integrations'
                            element={<Integrations />}
                        />
                        <Route
                            path='/advancedSettings'
                            element={<AdvancedSettings />}
                        />
                        <Route
                            path='/organisationSettings'
                            element={<OrganisationSettings />}
                        />
                        {isAdmin && (
                            <Route
                                path='/admin-settings'
                                element={<AdminContent />}
                            />
                        )}
                    </Routes>
                </Page>
            </Body>
        </Container>
    );
};

export default Profile;

const Body = styled.div`
    display: flex;
`;

const SideMenu = styled.div`
    border: 1.8px solid black;
    height: 500px;
    width: 200px;
    margin: 50px;
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    /* justify-content: center; */
    align-items: center;
    div {
        margin-top: 15px;
        display: flex;
        flex-direction: column;
        a {
            color: ${props => props.theme.colors.DarkBlue};
            text-decoration: none;
            &:hover {
                color: ${props => props.theme.colors.DarkPink};
                text-decoration: underline;
            }
        }
    }
`;

const Page = styled.div`
    margin: 50px;
    min-width: 50%;
`;

const Container = styled.div`
    width: 100%;
    background-color: ${props => props.theme.colors.Pink};
    min-height: 100vh;
`;

const TopBar = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;
    height: 64px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.DarkBlue};
`;
