import MonthKey from "common/types/monthKey";

import { getMonth } from "./reStringifyMonth";

/**
 *
 * @param month
 * @param add month before is -1, year before is -12
 * @returns
 */
export const addMonthsTo = (month: MonthKey, add: number): MonthKey => {
    if (add === 0) return month;
    const yearMonth = month.split("-");
    const startYear = parseInt(yearMonth[0]);
    const startMonth = parseInt(yearMonth[1]);

    let y = startYear + Math.trunc(add / 12);
    let m = startMonth + (add % 12);
    if (m < 1) {
        y--;
        m += 12;
    }
    if (m > 12) {
        y++;
        m -= 12;
    }
    return `${y}-${m < 10 ? "0" + m : m}` as MonthKey;
};

export const numberOfMonthsBetween = (
    start: MonthKey,
    end: MonthKey,
): number => {
    const startYear = parseInt(start.split("-")[0]);
    const startMonth = parseInt(start.split("-")[1]);
    const endYear = parseInt(end.split("-")[0]);
    const endMonth = parseInt(end.split("-")[1]);
    return (endYear - startYear) * 12 + (endMonth - startMonth);
};

export function getLastCompleteQuarterFromReportingMonth(
    reportingMonth: MonthKey,
    financialYearStarts: number,
): MonthKey {
    const monthPart = getMonth(reportingMonth);
    const financialYearEnds =
        financialYearStarts === 1 ? 12 : financialYearStarts - 1; //if the financial year starts in January, then it ends in December, otherwise it ends in the month before the start month
    const offset = monthPart - financialYearEnds;

    return addMonthsTo(
        reportingMonth,
        -Math.abs(offset % 3) - (offset < 0 ? 1 : 0),
    ); //subtract the absolute offset from the reporting month to get the last complete quarter
}
