import styled from "@emotion/styled";
import DropSelector from "components/general/dropselctor";
import useAuthState from "hooks/firebase/useAuthState";
import organsationSlice, {
    useGetOrganisationsQuery,
} from "redux/rtkQuery/organisations";
import { useAppDispatch } from "redux/store";

interface Props {
    changePage: React.Dispatch<React.SetStateAction<number>>;
    index: number;
}

const Intro = ({ changePage, index }: Props) => {
    const dispatch = useAppDispatch();
    const [user] = useAuthState();
    const { data } = useGetOrganisationsQuery(undefined, { skip: !user });
    const currentOrg = data?.currentOrganisation;

    const orgs = data?.organisations ?? [];

    return (
        <Container>
            <img
                src='/assets/horizental version.png'
                alt='HelloCashflow'
                height={100}
                style={{ marginBottom: 20 }}
            />
            {orgs.length > 1 && (
                <div style={{ display: "flex", flexDirection: "column" }}>
                    <span style={{ textAlign: "center", width: "100%" }}>
                        Select organisation
                    </span>
                    <DropSelector
                        selected={currentOrg?.name}
                        onChange={name => {
                            const isOrg = orgs?.find(org => org.name === name);
                            if (isOrg) {
                                localStorage.setItem(
                                    "org_id",
                                    isOrg.organisationId,
                                );
                                dispatch(
                                    organsationSlice.util.updateQueryData(
                                        "getOrganisations",
                                        undefined,
                                        old => {
                                            old.currentOrganisation = isOrg;
                                        },
                                    ),
                                );
                            }
                        }}
                        options={orgs.map(org => org.name)}
                    />
                </div>
            )}

            <p style={{ textAlign: "center" }}>
                Welcome to Hello Cashflow! Before you get started, let's get
                your organisation set up.
                <br />
                We will ask you some basic questions - don't worry if you don't
                know the answers - everything can be changed later under your
                settings.
            </p>
            <Continue onClick={() => changePage(index + 1)}>Continue</Continue>
        </Container>
    );
};

export default Intro;

const Continue = styled.button`
    padding: 10px;
    border-radius: 15px;
    width: 150px;
    font-size: 16px;
    font-weight: 400;
`;

const Container = styled.div`
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
    }
`;
