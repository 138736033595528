import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import { toMmmYyyy } from "lib/date/reStringifyMonth";
import { useEffect, useState } from "react";
import { theme } from "style/theme";
import { OrgData } from "types/OrganisationType";

import OutsideAlerter from "./OutsideAlerter";

const months = [
    "Jan",
    "Feb",
    "Mar",
    "Apr",
    "May",
    "Jun",
    "Jul",
    "Aug",
    "Sep",
    "Oct",
    "Nov",
    "Dec",
];

interface Props {
    inputMonths: OrgData[];
    currentPeriod: {
        startDate?: MonthKey;
        endDate?: MonthKey;
    };
    onChange: React.Dispatch<
        React.SetStateAction<
            | {
                  startDate: MonthKey;
                  endDate: MonthKey;
              }
            | undefined
        >
    >;
    defaultPeriod: {
        startDate: MonthKey;
        endDate: MonthKey;
    };
}
interface Pages {
    [year: string]: MonthKey[];
}

export default ({
    onChange,
    inputMonths,
    currentPeriod,
    defaultPeriod,
}: Props) => {
    useEffect(() => {
        onChange(defaultPeriod);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const [EPYear, setSPYear] = useState(+defaultPeriod.endDate.slice(0, 4));
    const [SPYear, setEPYear] = useState(+defaultPeriod.startDate.slice(0, 4));
    const [showCalendar, setShowCalendar] = useState(false);

    const pages = inputMonths
        .reduce((output, data) => {
            Object.keys(data).forEach(month => {
                if (!output.includes(month as MonthKey)) {
                    output.push(month as MonthKey);
                }
            });
            return output;
        }, [] as MonthKey[])
        .reduce((output, month) => {
            const year = month.slice(0, 4);
            if (!output[year]) {
                output[year] = [];
            }
            output[year].unshift(month);
            return output;
        }, {} as Pages);

    return (
        <Container>
            <OutsideAlerter callBack={() => setShowCalendar(false)}>
                <IntterStyle>
                    <CalTitle onClick={() => setShowCalendar(p => !p)}>
                        <Calendar />
                        <p>
                            {toMmmYyyy(currentPeriod.endDate)} -{" "}
                            {toMmmYyyy(currentPeriod.startDate)}
                        </p>
                        <Open>
                            <Arrow />
                        </Open>
                    </CalTitle>
                    {showCalendar && (
                        <Postiontioned>
                            <BoxNames>
                                <span> Start Month</span>
                                <span> End Month</span>
                            </BoxNames>
                            <Calenter>
                                <MonthSelect>
                                    <MonthTitle>
                                        <LeftArrow
                                            onClick={() => {
                                                setSPYear(p =>
                                                    checkYear(
                                                        p,
                                                        p - 1,
                                                        pages,
                                                        SPYear,
                                                        "S",
                                                    ),
                                                );
                                            }}>
                                            <Arrow />
                                        </LeftArrow>
                                        {EPYear}
                                        <RightArrow
                                            onClick={() => {
                                                setSPYear(p =>
                                                    checkYear(
                                                        p,
                                                        p + 1,
                                                        pages,
                                                        SPYear,
                                                        "S",
                                                    ),
                                                );
                                            }}>
                                            <Arrow />
                                        </RightArrow>
                                    </MonthTitle>
                                    <MonthOptions>
                                        {months.map(month => (
                                            <Month
                                                key={month}
                                                style={{
                                                    backgroundColor:
                                                        colorChooser(
                                                            pages[EPYear]?.find(
                                                                m =>
                                                                    toMmmYyyy(
                                                                        m,
                                                                    ).slice(
                                                                        0,
                                                                        3,
                                                                    ) === month,
                                                            ) as MonthKey,
                                                            currentPeriod,
                                                            !pages[
                                                                EPYear
                                                            ]?.find(
                                                                m =>
                                                                    toMmmYyyy(
                                                                        m,
                                                                    ).slice(
                                                                        0,
                                                                        3,
                                                                    ) === month,
                                                            ),
                                                        ),
                                                }}
                                                disabled={
                                                    !pages[EPYear]?.find(
                                                        m =>
                                                            toMmmYyyy(m).slice(
                                                                0,
                                                                3,
                                                            ) === month,
                                                    )
                                                }
                                                onClick={() => {
                                                    updateMonth({
                                                        endDate: pages[
                                                            EPYear
                                                        ]?.find(
                                                            m =>
                                                                toMmmYyyy(
                                                                    m,
                                                                ).slice(
                                                                    0,
                                                                    3,
                                                                ) === month,
                                                        ) as MonthKey,
                                                        onChange,
                                                        defaultPeriod,
                                                    });
                                                }}>
                                                {month}
                                            </Month>
                                        ))}
                                    </MonthOptions>
                                </MonthSelect>
                                <MonthSelect>
                                    <MonthTitle>
                                        <LeftArrow
                                            onClick={() => {
                                                setEPYear(p =>
                                                    checkYear(
                                                        p,
                                                        p - 1,
                                                        pages,
                                                        EPYear,
                                                        "E",
                                                    ),
                                                );
                                            }}>
                                            <Arrow />
                                        </LeftArrow>
                                        {SPYear}
                                        <RightArrow
                                            onClick={() => {
                                                setEPYear(p =>
                                                    checkYear(
                                                        p,
                                                        p + 1,
                                                        pages,
                                                        EPYear,
                                                        "E",
                                                    ),
                                                );
                                            }}>
                                            <Arrow />
                                        </RightArrow>
                                    </MonthTitle>
                                    <MonthOptions>
                                        {months.map(month => (
                                            <Month
                                                key={month}
                                                style={{
                                                    backgroundColor:
                                                        colorChooser(
                                                            pages[SPYear]?.find(
                                                                m =>
                                                                    toMmmYyyy(
                                                                        m,
                                                                    ).slice(
                                                                        0,
                                                                        3,
                                                                    ) === month,
                                                            ) as MonthKey,
                                                            currentPeriod,
                                                            !pages[
                                                                SPYear
                                                            ]?.find(
                                                                m =>
                                                                    toMmmYyyy(
                                                                        m,
                                                                    ).slice(
                                                                        0,
                                                                        3,
                                                                    ) === month,
                                                            ),
                                                        ),
                                                }}
                                                disabled={
                                                    !pages[SPYear]?.find(
                                                        m =>
                                                            toMmmYyyy(m).slice(
                                                                0,
                                                                3,
                                                            ) === month,
                                                    )
                                                }
                                                onClick={() => {
                                                    updateMonth({
                                                        startDate: pages[
                                                            SPYear
                                                        ]?.find(
                                                            m =>
                                                                toMmmYyyy(
                                                                    m,
                                                                ).slice(
                                                                    0,
                                                                    3,
                                                                ) === month,
                                                        ) as MonthKey,
                                                        onChange,
                                                        defaultPeriod,
                                                    });
                                                }}>
                                                {month}
                                            </Month>
                                        ))}
                                    </MonthOptions>
                                </MonthSelect>
                            </Calenter>
                        </Postiontioned>
                    )}
                </IntterStyle>
            </OutsideAlerter>
        </Container>
    );
};

const IntterStyle = styled.div`
    width: 500px;
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
`;

const Open = styled.div`
    margin: 0 auto;
    cursor: pointer;
    &:hover {
        fill: ${({ theme }) => theme.colors.DarkPink};
    }
`;

const BoxNames = styled.div`
    display: flex;
    justify-content: space-around;
    padding: 10px;
    border: 2px solid ${({ theme }) => theme.colors.DarkBlue};
    background-color: white;
    width: 500px;
`;

const Calenter = styled.div`
    border: 2px solid ${({ theme }) => theme.colors.DarkBlue};
    display: flex;
    flex-direction: row;
    width: 500px;
`;

const Postiontioned = styled.div`
    position: absolute;
    top: 45px;
`;

const CalTitle = styled.div`
    display: flex;
    padding: 10px;
    background-color: ${({ theme }) => theme.colors.Pink};
    border: 2px solid ${({ theme }) => theme.colors.DarkBlue};
    width: 230px;
    margin-bottom: 10px;
    p {
        margin: 0;
        margin-left: 10px;
    }
    &:hover {
        cursor: pointer;
        color: ${({ theme }) => theme.colors.DarkPink};
        svg {
            fill: ${theme.colors.DarkPink};
        }
    }
`;

const Container = styled.div`
    width: 500px;
    z-index: 1;
    position: relative;
    margin-left: auto;
`;
const RightArrow = styled.div`
    transform: rotate(270deg);
    margin-left: 40px;
    svg {
        height: 25px;
        width: 25px;
    }
    &:hover {
        cursor: pointer;
        svg {
            fill: ${theme.colors.DarkPink};
        }
    }
`;
const LeftArrow = styled.div`
    transform: rotate(90deg);
    margin-right: 40px;
    svg {
        height: 25px;
        width: 25px;
    }
    &:hover {
        cursor: pointer;
        svg {
            fill: ${theme.colors.DarkPink};
        }
    }
`;

const MonthSelect = styled.div`
    flex: 1;
    background: white;
`;
const MonthTitle = styled.div`
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    height: 40px;
    border: 1px solid #e6e6e6;
`;
const MonthOptions = styled.div`
    background: white;
    height: 200px;
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
`;

const Month = styled.button`
    font-size: 14px;
    font-weight: bold;
    display: flex;
    align-items: center;
    justify-content: center;
    border: 1px solid #e6e6e6;
    background: white;
    color: ${theme.colors.DarkBlue};
    &:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
    &:disabled {
        color: #e6e6e6 !important;
    }
`;

const updateMonth = ({
    startDate,
    endDate,
    onChange,
    defaultPeriod,
}: {
    startDate?: MonthKey;
    endDate?: MonthKey;
    onChange?: React.Dispatch<
        React.SetStateAction<
            | {
                  startDate: MonthKey;
                  endDate: MonthKey;
              }
            | undefined
        >
    >;
    defaultPeriod: {
        startDate: MonthKey;
        endDate: MonthKey;
    };
}) => {
    if (onChange) {
        if (startDate) {
            onChange(p => ({
                startDate: startDate,
                endDate: check(
                    startDate,
                    p?.endDate || defaultPeriod.endDate,
                    true,
                ),
            }));
        } else if (endDate) {
            onChange(p => ({
                startDate: check(
                    p?.startDate || defaultPeriod.startDate,
                    endDate,
                ),
                endDate: endDate,
            }));
        }
    }
};

const check = (startDate: MonthKey, endDate: MonthKey, end?: boolean) => {
    if (end) {
        if (startDate < endDate) {
            return startDate;
        } else {
            return endDate;
        }
    } else {
        if (startDate > endDate) {
            return startDate;
        } else {
            return endDate;
        }
    }
};

const Arrow = () => {
    return (
        <svg
            height='20'
            width='20'
            viewBox='0 0 20 20'
            aria-hidden='true'
            focusable='false'>
            <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
        </svg>
    );
};
const Calendar = () => {
    return (
        <svg height='20' width='20' viewBox='0 0 512 512'>
            <path d='M458.668 42.666h-42.667V32c0-17.645-14.356-31.999-32-31.999-17.646 0-32.002 14.354-32.002 31.999v10.666H160.001V32c0-17.645-14.356-31.999-32.002-31.999C110.355.001 96.001 14.355 96.001 32v10.666H53.334C23.926 42.666 0 66.59 0 95.998v405.335C0 507.223 4.777 512 10.667 512h490.667c5.891 0 10.667-4.777 10.667-10.667V95.998C512 66.59 488.077 42.666 458.668 42.666zM373.332 32c0-5.882 4.785-10.666 10.669-10.666 5.882 0 10.667 4.784 10.667 10.666v42.669c0 5.882-4.785 10.666-10.667 10.666-5.884 0-10.669-4.784-10.669-10.666V32zm-255.998 0c0-5.882 4.785-10.666 10.667-10.666S138.668 26.118 138.668 32v42.669c0 5.882-4.785 10.666-10.667 10.666h-.002c-5.882 0-10.665-4.784-10.665-10.666V32zm373.333 458.665H21.333V149.333h403.066c5.889 0 10.667-4.777 10.667-10.667S430.288 128 424.399 128H21.333V95.998c0-17.644 14.354-31.998 32-31.998H96v10.67c0 17.645 14.354 31.999 32 31.999s32-14.354 32-31.999V64h191.997v10.67c0 17.645 14.356 31.999 32.002 31.999 17.644 0 32-14.354 32-31.999V64h42.667c17.644 0 31.998 14.354 31.998 31.998v394.667z' />
            <path d='M463.775 128h-6.44c-5.889 0-10.667 4.777-10.667 10.667s4.778 10.667 10.667 10.667h6.44c5.891 0 10.667-4.777 10.667-10.667S469.667 128 463.775 128zm-15.776 53.334H64.001c-5.891 0-10.667 4.777-10.667 10.667v192c0 5.891 4.776 10.667 10.667 10.667h53.332v42.665h-15.865c-5.891 0-10.667 4.777-10.667 10.667s4.775 10.667 10.667 10.667H448c5.891 0 10.667-4.777 10.667-10.667V192.001c-.001-5.891-4.775-10.667-10.668-10.667zm-181.334 21.333h42.665v42.664h-42.665v-42.664zm0 127.999h42.665v42.668h-42.665v-42.668zm-149.333 42.668H74.667v-42.668h42.665v42.668zm0-64.001H74.667v-42.668h42.665v42.668zm0-64.002H74.667v-42.664h42.665v42.664zm64.001 192.002h-42.667v-42.665h42.667v42.665zm0-63.999h-42.667v-42.668h42.667v42.668zm0-64.001h-42.667v-42.668h42.667v42.668zm0-64.002h-42.667v-42.664h42.667v42.664zm63.998 192.002h-42.665v-42.665h42.665v42.665zm0-63.999h-42.665v-42.668h42.665v42.668zm0-64.001h-42.665v-42.668h42.665v42.668zm0-64.002h-42.665v-42.664h42.665v42.664zm63.999 192.002h-42.665v-42.665h42.665v42.665zm0-128h-42.665v-42.668h42.665v42.668zm64 128h-42.667v-42.665h42.667v42.665zm0-63.999h-42.667v-42.668h42.667v42.668zm0-64.001h-42.667v-42.668h42.667v42.668zm0-64.002h-42.667v-42.664h42.667v42.664zm64.003 192.002h-42.669v-42.665h42.669v42.665zm0-63.999h-42.669v-42.668h42.669v42.668zm0-64.001h-42.669v-42.668h42.669v42.668zm0-64.002h-42.669v-42.664h42.669v42.664zM68.444 437.333H64c-5.891 0-10.667 4.777-10.667 10.667S58.109 458.667 64 458.667h4.444c5.889 0 10.667-4.777 10.667-10.667s-4.777-10.667-10.667-10.667z' />
        </svg>
    );
};

const checkYear = (
    oldValue: number,
    year: number,
    pages: Pages,
    other: number,
    type: "S" | "E",
): number => {
    const years = Object.keys(pages);
    if (type === "S") {
        if (year <= other) {
            if (years.includes(year.toString())) {
                return year;
            } else {
                return oldValue;
            }
        }
    } else if (type === "E") {
        if (year >= other) {
            if (years.includes(year.toString())) {
                return year;
            } else {
                return oldValue;
            }
        }
    }
    return oldValue;
};

const colorChooser = (
    month: string,
    currentPeriod: { startDate?: string; endDate?: string },
    disabled: boolean,
) => {
    if (currentPeriod.startDate && currentPeriod.endDate) {
        if (
            month == currentPeriod.startDate ||
            (month == currentPeriod.endDate && !disabled)
        ) {
            return "#4a75e0";
        } else if (
            month < currentPeriod.startDate &&
            month > currentPeriod.endDate &&
            !disabled
        ) {
            return "#c5e3ff";
        } else {
            return;
        }
    }
};
