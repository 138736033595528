import styled from "@emotion/styled";
import ItemSection from "components/general/itemSection/ItemSection";
import ReportsModal from "components/navigation/expandableSection/ReportsModal";
import DoughnutChart from "components/recharts/DoughnutChart";
import useReport from "hooks/reports/useReport";

import RevenueDetailIncomeBreakdown from "./ModelIncomeBreakdown";
import DescribeMonthItems from "../../shared/DescribeMonthItems";

const RevenueSectionIncomeBreakdown = () => {
    const report = useReport();

    if (!report) return null;

    const {
        revenue: { actual },
        reportDates: { selectedMonth },
    } = report;

    return (
        <ItemSection id='month-breakdown' minHeight={505}>
            <LeftBox>
                <DescribeMonthItems
                    organisation={report.organisation}
                    data={actual}
                    dataName='revenue'
                />
            </LeftBox>
            <RightBox>
                <h1>
                    Total revenue for{" "}
                    {selectedMonth.selectByMonths(
                        selectedMonth.toTextMonth(),
                        selectedMonth.toTextMonth(),
                        selectedMonth.toMmmYy(),
                    )}
                </h1>

                <Chart>
                    <DoughnutChart actual={actual} month={selectedMonth} />
                </Chart>
            </RightBox>

            <ReportsModal path='breakdown-model'>
                <RevenueDetailIncomeBreakdown />
            </ReportsModal>
        </ItemSection>
    );
};

export default RevenueSectionIncomeBreakdown;
const LeftBox = styled.div``;
const RightBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
`;
const Chart = styled.div`
    width: 350px;
    height: 350px;
    margin: 0 auto;
`;
