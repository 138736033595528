import styled from "@emotion/styled";
import UIErrorBoundary from "components/error-boundaries/SectionErrorBoundary";

import TransfersEditor from "./TransfersEditor";

const TransfersContainer = () => {
    return (
        <Body>
            <div style={{ display: "flex" }}>
                {/* <FillForward /> removed temp as doesnt work */}

                <p style={{ marginLeft: 10 }}>
                    Budget your cashflow for the cashflow page to show and
                    predict your data.
                </p>
            </div>
            <UIErrorBoundary>
                <TransfersEditor />
            </UIErrorBoundary>
        </Body>
    );
};

export default TransfersContainer;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;
