import CreateAccount from "components/auth/CreateAccount";
import ForgotPassword from "components/auth/ForgotPassword";
import Login from "components/auth/Login";
import PageNotFound from "components/general/404Stuff/PageNotFound";
import { UserCredential } from "firebase/auth";
import { NavigateFunction, Route, Routes } from "react-router";

import { handleSignIn as defaultSignIn } from "../../components/auth/helpers/handleSignIn";

const LoginComponent = ({
    handleSignIn = defaultSignIn,
}: {
    handleSignIn?: (result: UserCredential, navigate: NavigateFunction) => void;
}) => {
    return (
        <Routes>
            <Route path='/' element={<Login handleSignIn={handleSignIn} />} />
            <Route
                path='/create'
                element={<CreateAccount handleSignIn={handleSignIn} />}
            />
            <Route path='/forgot-password' element={<ForgotPassword />} />
            <Route path='*' element={<PageNotFound />} />
        </Routes>
    );
};

export default LoginComponent;
