import { api } from "./api";

export type Interval = "day" | "month" | "week" | "year";

export type Status =
    | "active"
    | "canceled"
    | "incomplete"
    | "incomplete_expired"
    | "past_due"
    | "paused"
    | "trialing"
    | "unpaid";

export interface Product {
    id: string;
    name: string;
    description: string;
    defaultPrice: {
        currency: string;
        amount: number;
    };
    interval: Interval;
    prices: Array<{
        currency: string;
        amount: number | null;
    }>;
}

export interface Subscription {
    id: string;
    status: Status;
    description: string | null;
    orgId: string;
    price: {
        currency: string;
        amount: number;
    };
    trialEnd: string | null;
}

export interface OrgsCheckOut {
    orgs: Array<{ name: string; id: string }>;
    success_url: string;
    cancel_url: string;
}

export const stripeSlice = api.injectEndpoints({
    endpoints: builder => ({
        products: builder.query<Product[], void>({
            query: () => ({ url: "stripe/active-products", method: "GET" }),
        }),
        subscriptions: builder.query<Subscription[], void>({
            query: () => ({ url: "stripe/subscriptions", method: "GET" }),
            transformResponse: (res: Subscription[]) => {
                return res.map(sub => ({
                    ...sub,
                    trialEnd: sub.trialEnd
                        ? new Date(
                              (sub.trialEnd as unknown as number) * 1000,
                          ).toLocaleString()
                        : null,
                }));
            },
        }),
        createPortalLink: builder.query<string, void>({
            query: () => ({
                url: "stripe/portal",
                method: "GET",
                params: {
                    url: window.location.href,
                },
            }),
        }),
        createCheckoutSession: builder.query<string, OrgsCheckOut["orgs"]>({
            query: orgs => ({
                url: "stripe/orgs-check-out",
                method: "PUT",
                data: {
                    orgs,
                    success_url: `${window.location.origin}/onboarding/finished`,
                    cancel_url: `${window.location.origin}/onboarding/stripe?error=cancelled`,
                },
            }),
        }),
    }),
});

export const {
    useCreateCheckoutSessionQuery,
    useCreatePortalLinkQuery,
    useProductsQuery,
    useSubscriptionsQuery,
} = stripeSlice;
