import useAuthState from "hooks/firebase/useAuthState";
import { ReactNode } from "react";
import { Navigate } from "react-router-dom";

interface RequireAuthProps {
    children: ReactNode;
    redirectTo?: string;
}

export let lastPage = "/";

const RequireAuth = ({ children, redirectTo }: RequireAuthProps) => {
    const [user, loading] = useAuthState();

    if (!user) {
        lastPage = window.location.pathname;
        return <Navigate to={redirectTo || "/login"} />;
    }
    return <>{children}</>;

    // switch (userStatus) {
    //     case "authenticated":
    //         return <>{children}</>;

    //     case "anonymous":
    //         return <Navigate to={redirectTo ?? "/login"} />;

    //     case "not yet known":
    //         /** We should never end up here or we are going to be mounting components unnecessarally and could cause sideeffects */
    //         throw new Error("Need user information to proceed!!");
    // }
};

export default RequireAuth;
