import MonthKey from "common/types/monthKey";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { MonthItems, OrgData } from "types/OrganisationType";

import { filterByMonths } from "./filterByMonths";

/**
 * Will sum by code for all the months provided
 * @param transfersItemData
 * @param months
 * @returns
 */
export function addItemMonths(transfersItemData: OrgData, months: MonthKey[]) {
    return months.reduce((result, m) => {
        const monthItems = transfersItemData[m];
        if (monthItems) {
            Object.keys(monthItems).forEach(i => {
                result[i] = (result[i] ?? 0) + monthItems[i];
            });
        }
        return result;
    }, {} as { [code: string]: number });
}

/**
 * return total of all the items in a month as a number
 * @param month
 * @returns
 */
export const sumOfMonth = (month?: MonthItems | null): number => {
    if (!month) return 0;
    try {
        return Object.values(month).reduce((a, b) => a + (isNaN(b) ? 0 : b), 0);
    } catch (error) {
        return 0;
    }
};

export const sumOfQuarter = (data: OrgData, quarterEnded: MonthKey): number => {
    return [
        quarterEnded,
        addMonthsTo(quarterEnded, -1),
        addMonthsTo(quarterEnded, -2),
    ].reduce((s, m) => s + ((data[m] && sumOfMonth(data[m])) || 0), 0);
};

/**
 * return object with monthkeys and value is total of that months items
 * @param months
 * @returns
 */
export const sumOfMonths = (months: OrgData): { [month: MonthKey]: number } => {
    return (Object.entries(months) as Array<[MonthKey, MonthItems]>).reduce(
        (output: any, [month, value]) => {
            output[month] = sumOfMonth(value);
            return output;
        },
        {} as any,
    );
};

/**
 * return sum of all items for months given
 * @param months
 * @returns
 */
export const totalOfGivenMonths = (
    data: OrgData,
    months: MonthKey[],
): number => {
    return months.reduce(
        (s, m) => s + ((data[m] && sumOfMonth(data[m])) || 0),
        0,
    );
};

//Seems to return incorrect numbers
// /**
//  * total of all items in months given
//  * @param months
//  * @returns
//  */
// export const totalSumOfMonths = (months: OrgData): number => {
//     const allMonths = sumOfMonths(months);
//     return Object.values(allMonths).reduce((a, b) => a + b, 0);
// };

interface TotalForMonthsProps {
    org: OrgData;
    latestMonth: MonthKey;
    earliestMonth?: MonthKey;
    totalMonths?: number;
}

/**
 * total of all items in months given
 * @param org input data
 * @param latestMonth period start
 * @param earliestMonth period end
 * @param monthCount month count inclusive of start and end
 * @returns the total for all items in the given period
 */
export const totalForMonths = ({
    org,
    latestMonth,
    earliestMonth,
    totalMonths,
}: TotalForMonthsProps): number => {
    const filteredMonths = filterByMonths({
        obj: org,
        latestMonth,
        earliestMonth,
        totalMonths,
    });

    return Object.values(filteredMonths).reduce((total, months) => {
        Object.values(months).forEach(month => {
            total += month;
        });
        return total;
    }, 0);
};

/**
 * returns total of each item in months given
 * @param months
 * @returns
 */
export const sumOfItemsforMonths = (months: OrgData): MonthItems => {
    return Object.values(months).reduce((totals, month: MonthItems) => {
        Object.entries(month).forEach(([type, value]) => {
            totals[type] ??= 0;
            totals[type] += value;
        });
        return totals;
    }, {});
};

/**
 * merges orgDatas together by subtracting type 2 from type 1
 * @param type1 orgData 1
 * @param type2 orgData 2
 * @returns
 */
export const sumOfTwoOrgDatas = (type1: OrgData, type2: OrgData) => {
    return (Object.keys(type1) as MonthKey[]).reduce((totals, month) => {
        totals[month] = {};
        Object.entries(type1[month]).forEach(([type, value]) => {
            const d = type2[month] ?? {};
            totals[month][type] = value - (d[type] ?? 0);
        });
        return totals;
    }, {} as OrgData);
};

export const getOrgItem = (
    data: OrgData | undefined,
    month: MonthKey,
    itemCode: string,
) => (data && data[month] && data[month][itemCode]) ?? 0;
