import styled from "@emotion/styled";
import LogoHorizontal from "components/brand/LogoHorizontal";
import FullScreenNav from "components/navigation/fullScreenNav";
import PhoneNav from "components/navigation/PhoneNav";
import Footer from "components/reports/ReportsFooter/ReportsFooter";

const Privacy = () => {
    return (
        <Container>
            <TopBar>
                <LogoHorizontal
                    absolute={false}
                    showText={false}
                    asLink={true}
                />
                <FullScreenNav />
                <PhoneNav />
            </TopBar>
            <Page>
                <h4>
                    <span>
                        This privacy note was last updated on: 5 September 2022
                    </span>
                </h4>
                <p>
                    <span>
                        At Hello Cashflow we know that our users care about how
                        your personal information is used and shared, and we
                        take your privacy seriously. This Privacy Notice is
                        drafted with reference to the Privacy Act 2020 and
                        describes how Hello Cashflow collects and uses the
                        personal information you provide on Hello Cashflow.com
                        and all of our domains (&ldquo;the Website&quot;). By
                        visiting or using the Website or the Services in any
                        manner, you acknowledge that you accept the practices
                        and policies outlined in this Privacy Notice. Any
                        capitalized terms used herein without definition shall
                        have the meaning given to them in the Hello Cashflow
                        Terms of Use, references to &ldquo;we&quot;,
                        &ldquo;us&quot;, &ldquo;our&quot; are to Hello Cashflow
                        and references to &ldquo;you&quot;, &ldquo;your&quot;,
                        &ldquo;users&quot; are to your users and any individuals
                        whose personal data we process pursuant to this Privacy
                        Notice.
                    </span>
                </p>
                <h3>
                    <span>1. What does this Privacy Notice cover?</span>
                </h3>
                <p>
                    <span>
                        a. This Privacy Notice covers our treatment of
                        personally identifiable information (&ldquo;Personal
                        Information&quot;) that we gather or is provided to us
                        by you when you are accessing or using our Website or
                        the Services. This Privacy Notice also sets out the
                        rights you have in relation to your Personal
                        Information. This Privacy Notice does not apply to the
                        practices of companies that we do not own or control, to
                        individuals that we do not employ or manage or to any
                        other third party websites whose links may appear on the
                        Website. We do not knowingly collect or solicit Personal
                        Information from anyone under the age of 16 or knowingly
                        allow such persons to register for the Services (as that
                        term is defined in our Terms of Use). No one under age
                        16 may provide any Personal Information to us or on the
                        Services.
                    </span>
                </p>
                <p>
                    <span>
                        b. Hello Cashflow are the &ldquo;agency&quot; (as such
                        term is defined in the Privacy Act 2020) of any Personal
                        Information collected via the Website and our contact
                        details are set out below in Section 7 below.
                    </span>
                </p>
                <h3>
                    <span>
                        2. What information does Hello Cashflow collect?
                    </span>
                </h3>
                <p>
                    <span>
                        a. We gather various types of Personal Information from
                        our users, as explained more fully below. We use your
                        Personal Information to:
                    </span>
                </p>
                <p>
                    <span>
                        i. allow our users to set up a user account and profile;
                    </span>
                </p>
                <p>
                    <span>
                        ii. personalise and improve the Services &ndash; we
                        analyse and log user login patterns, threats, risks,
                        location and device data to identify and prevent
                        security breaches. We also track user activity to better
                        understand user behavior and improve the Services;
                    </span>
                </p>
                <p>
                    <span>
                        iii. manage user accounts and provide customer service
                        &ndash; we log activity notes and email conversations in
                        order to provide users with an enhanced experience. The
                        Personal Information we collect includes user location,
                        number of clients, requested features, accountancy
                        package, lead source and company size in order to
                        optimize our relationship with users. We also use a live
                        chat feature which stores conversations we have with our
                        users along with user location, device operating system,
                        page last browsed on our website and details of when the
                        user was last contacted or contacted us. We do this to
                        maximise our efficiency so that our service to users is
                        seamless;
                    </span>
                </p>
                <p>
                    <span>
                        iv. process payments and for other billing purposes
                        &ndash; we take payments using third party software. We
                        collect user email, billing email, name and location. We
                        also use this information for internal processes such as
                        account management, sales and customer support and
                        marketing;
                    </span>
                </p>
                <p>
                    <span>
                        v. send users product-related communications and
                        marketing communications &ndash; Hello Cashflow uses
                        user email, name and status (business owner or
                        accountant) to facilitate product-related communications
                        and marketing communications. Please see Section 2.4
                        below for more information on this and your right to
                        object to marketing;
                    </span>
                </p>
                <p>
                    <span>
                        vi. contact users in relation to the Website and the
                        Services;
                    </span>
                </p>
                <p>
                    <span>vii. fulfill your requests for the Service;</span>
                </p>
                <p>
                    <span>
                        viii. analyse how users utilise the Website, and as
                        otherwise set forth in this Privacy Notice;
                    </span>
                </p>
                <p>
                    <span>
                        ix. provide account management, education, success and
                        engagement services. We analyse and log activity across
                        our Services and with our team, such as login pattern,
                        number of users added, number of organisations added,
                        number of Certification modules completed, last
                        contacted point, billing patterns and subscription
                        details.
                    </span>
                </p>
                <p>
                    <span>a. Information you provide to us</span>
                </p>
                <p>
                    <span>
                        i. Your Personal Information: We receive and store any
                        information you knowingly provide to us. For example, we
                        collect Personal Information such as your name, email
                        address, and browser information. You can choose not to
                        provide us with certain information such as your credit
                        card information, but then you may not be able to
                        register with us or to take advantage of some of our
                        features.
                    </span>
                </p>
                <p>
                    <span>
                        ii. Your users&rsquo; Personal Information: In order to
                        provide you with the Services, we also receive and store
                        any information you choose to provide us with respect to
                        your users (&ldquo;End Users&quot;). The End User
                        information (&ldquo;End User Information&quot;) we
                        receive and store includes names and email addresses of
                        your users and will consist of any other information you
                        choose to provide us with.
                    </span>
                </p>
                <p>
                    <span>b. Information collected automatically</span>
                </p>
                <p>
                    <span>
                        i. Whenever you interact with our Website, we
                        automatically receive and record information on our
                        server logs from your browser including your,
                        &ldquo;cookie&quot; information, and the page you
                        requested. When you use the Services, whether as a
                        registered Hello Cashflow customer or a non-registered
                        user just browsing, our servers or third party partners
                        automatically record information that your browser sends
                        whenever you visit a website. This information is called
                        Log Data and will include information such as your
                        device, your device&rsquo;s IP address, browser type and
                        the webpage you were visiting before you came to our
                        Website, pages of our Website that you visit, the time
                        spent on those pages, information you search for on our
                        Website, access times and dates. We use this information
                        to monitor and analyse use of the Website and the
                        Services and for the Website&rsquo;s technical
                        administration, to increase our Website&rsquo;s
                        functionality and user-friendliness, and to better
                        tailor it to our visitors&rsquo; needs.
                    </span>
                </p>
                <p>
                    <span>c. E-mail, marketing and other communications</span>
                </p>
                <p>
                    <span>
                        We may use your Personal Information to contact you, by
                        email about your use of the Website or the Services. If
                        you provide us with your consent to subscribe to our
                        newsletter or marketing emails, we will use your name
                        and email address to send the newsletter to you via
                        email. You may choose to stop receiving our newsletter
                        or marketing emails by following the unsubscribe
                        instructions included in these emails. Please note that
                        if you do not want to receive newsletter or marketing
                        emails from us, we may still send you legal notices
                        which will govern your use of the Website and you are
                        responsible for reviewing such legal notices for any
                        changes.
                    </span>
                </p>
                <h3>
                    <span>3. Where do we store your personal information?</span>
                </h3>
                <p>
                    <span>
                        a. If you sign up for our services from New Zealand or
                        any other country in the Southern Hemisphere, your
                        personal Data will be processed on servers based in
                        Sydney, Australia. The data centres are SOC1, SOC2 Type
                        1 &amp; 2 and SOC3 compliant. In these data centres your
                        data will remain encrypted at all times when in transit
                        and at rest.{" "}
                    </span>
                </p>
                <p>
                    <span>
                        b. Hello Cashflow uses various cloud-based systems and
                        tools, including certain customer relationship
                        management and marketing automation services
                        (&ldquo;Tools&quot;) to allow us to provide the Hello
                        Cashflow Tool to our users quickly and efficiently. As
                        part of our use of the Tools, certain limited client and
                        user profile information is sent to the providers of the
                        Tools, some of whom are based outside of New Zealand.
                        Where users&rsquo; Personal Information is sent by Hello
                        Cashflow to Tool providers based outside of New Zealand,
                        we ensure such transfers are conducted in accordance
                        with Hello Cashflow&rsquo;s obligations under the
                        Privacy Act 2020.{" "}
                    </span>
                </p>
                <p>
                    <span>
                        c. By accepting this Privacy Notice, users&rsquo;
                        acknowledge that their Personal Information will be sent
                        outside of New Zealand in accordance with the practices
                        described in this Privacy Notice. We will take all steps
                        reasonably necessary to ensure that such Personal
                        Information is kept confidential, secure and only used
                        for the purposes that we have specified and informed you
                        of in this Privacy Notice.
                    </span>
                </p>
                <h3>
                    <span>
                        4. Will Hello Cashflow share any of the Personal
                        Information it receives?
                    </span>
                </h3>
                <p>
                    <span>
                        a. We do not rent nor sell your Personal Information or
                        your End Users&rsquo; Personal Information to anyone.
                        However, we may share such Personal Information with
                        third parties for the purposes described below.
                    </span>
                </p>
                <p>
                    <span>
                        i. To assist us in providing the Services and/or the
                        Website
                    </span>
                </p>
                <p>
                    <span>
                        We employ other companies and people to perform tasks on
                        our behalf and may need to share your Personal
                        Information with them to provide the Services to you.
                        Unless we tell you differently in this Privacy Notice,
                        such third parties do not have any right to use the
                        Personal Information we share with them beyond what is
                        necessary to assist us and they shall only process your
                        Personal Information in accordance with this Privacy
                        Notice. These third parties include third party
                        companies and individuals employed by us to facilitate
                        the Services and our Website, including the provision of
                        maintenance services, database management, Web analytics
                        and general improvement of the Services.
                    </span>
                </p>
                <p>
                    <span>ii. Users&rsquo; accountants and bookkeepers</span>
                </p>
                <p>
                    <span>
                        If a user is using a Hello Cashflow product that has
                        been made available to them by a service provider who
                        has signed as one of our partners (e.g., their
                        accountant or bookkeeper), then all Personal Information
                        uploaded by such users will be available to that partner
                        and its authorised employees and agents who have access
                        to the relevant partner dashboard site.
                    </span>
                </p>
                <p>
                    <span>iii. Business transfers</span>
                </p>
                <p>
                    <span>
                        We may choose to buy or sell assets. In these types of
                        transactions, customer information (including Personal
                        Information) is typically one of the business assets
                        that is transferred. Also, if we (or substantially all
                        of our assets) are acquired, or if we go out of
                        business, enter bankruptcy, or go through some other
                        change of control, Personal Information would be one of
                        the assets transferred to or acquired by a third party.
                        You will be notified via email and/or a prominent notice
                        on our Website of any change in ownership or uses of
                        your Personal Information, as well as any choices you
                        may have regarding your Personal Information.
                    </span>
                </p>
                <p>
                    <span>vi. Protection of Hello Cashflow and others</span>
                </p>
                <p>
                    <span>
                        We reserve the right to access, read, preserve, and
                        disclose any information that we reasonably believe is
                        necessary to comply with law or a court order; enforce
                        or apply our conditions of use and other agreements; or
                        protect the rights, property, or safety of Hello
                        Cashflow, our employees, our users, or others. This
                        includes exchanging information with other companies and
                        organizations for fraud protection and credit risk
                        reduction. We also may be required to disclose Personal
                        Information in response to a lawful request by public
                        authorities, including meeting national security or law
                        enforcement requirements.
                    </span>
                </p>
                <p>
                    <span>v. With Your consent</span>
                </p>
                <p>
                    <span>
                        Except as set forth above, you will be notified when
                        your Personal Information may be shared with third
                        parties, and will be able to prevent the sharing of this
                        information where we need your &ldquo;consent&quot; to
                        share your Personal Information, unless we have to
                        disclose your Personal Information in the circumstances
                        set out in this Privacy Notice or required by law.
                    </span>
                </p>
                <h3>
                    <span>5. Is your Personal Information secure?</span>
                </h3>
                <p>
                    <span>
                        a. Your account is protected by a password for your
                        privacy and security. You must prevent unauthorized
                        access to your account and Personal Information by
                        selecting and protecting your password appropriately and
                        limiting access to your computer or device and browser
                        by signing off after you have finished accessing your
                        account.
                    </span>
                </p>
                <p>
                    <span>
                        b. We endeavor to protect the privacy of your account
                        and other Personal Information we hold in our records,
                        but we cannot guarantee complete security. The
                        transmission of information via the Internet or over
                        email is not completely secure. Although we will do our
                        best to try and protect your Personal Information,
                        unauthorized entry or use, hardware or software failure,
                        and other factors, may compromise the security of User
                        information at any time. We cannot guarantee the
                        security of the Personal Information you transmit to our
                        Website and any transmission is at your own risk. When
                        you enter your information (including Personal
                        Information) on our website we encrypt the transmission
                        of that information using transport layer security
                        (TLS). The Website may contain links to other websites.
                        We are not responsible for the privacy policies and/or
                        practices on other websites. When following a link to
                        another website you should read that site&rsquo;s
                        privacy policy and any other relevant policies or terms.
                    </span>
                </p>
                <h3>
                    <span>6. What happens if there is a data breach?</span>
                </h3>
                <p>
                    <span>
                        a. If we become aware of a data breach or are notified
                        of a data breach, we shall notify the Privacy
                        Commissioner&rsquo;s Office and provide details to them
                        of the data breach where we are required to do so. In
                        most cases we are not required to provide any Personal
                        Information on our users, however the Privacy
                        Commissioner may request contact details for users who
                        are or may be affected. If we encounter a data breach
                        users will be notified where there is likely to be a
                        high risk of any harm or damage to them as a result of
                        the data breach. Should we be requested to provide
                        Personal Information as part of the data breach process
                        we will notify those users who we believe are affected.
                    </span>
                </p>
                <h3>
                    <span>7. What data subject rights do I have?</span>
                </h3>
                <p>
                    <span>
                        a. Under the Privacy Act 2020, individuals have a number
                        of rights in relation to the processing of their
                        Personal Information. Brief details of these rights are
                        set out below and further details can be obtained from
                        the sources set out in Section 7.2.
                    </span>
                </p>
                <p>
                    <span>i. Right of access</span>
                </p>
                <p>
                    <span>
                        You have the right to apply for a copy of the Personal
                        Information we hold about you. This is called a request
                        for personal information and you can make a request by
                        emailing info@HelloCashflow.com. We will require you to
                        verify your identity before we can disclose any Personal
                        Information to you.
                    </span>
                </p>
                <p>
                    <span>ii.</span>
                    <span>&nbsp;Right to rectification</span>
                </p>
                <p>
                    <span>
                        You have the right to have any Personal Information
                        which is inaccurate that Hello
                    </span>
                    <span>
                        Cashflow hold rectified, or any incomplete Personal
                        Information which Hello Cashflow hold completed.
                        Alternatively, through your account settings, you may
                        access, and, in some cases, edit or delete the following
                        information you&rsquo;ve provided to us:
                    </span>
                </p>
                <ul>
                    <li>
                        <span>name and password</span>
                    </li>
                    <li>
                        <span>email address</span>
                    </li>
                    <li>
                        <span>profile photo</span>
                    </li>
                    <li>
                        <span>company name</span>
                    </li>
                    <li>
                        <span>credit card information</span>
                    </li>
                </ul>
                <p>
                    <span></span>
                </p>
                <p>
                    <span>
                        a. For more information about your rights under the
                        Privacy Act 2020 please see
                    </span>
                    <span>
                        <a href='https://www.google.com/url?q=https://www.privacy.org.nz&amp;sa=D&amp;source=editors&amp;ust=1667446560538140&amp;usg=AOvVaw1EXfYOqC4HnYCgUSvaelg1'>
                            https://www.privacy.org.nz
                        </a>
                    </span>
                    <span>&nbsp;</span>
                </p>
                <p>
                    <span>
                        b. You can find out more information about your rights
                        under the GDPR by emailing
                    </span>
                    <span>info@HelloCashflow.com</span>
                    <span>.</span>
                </p>
                <p>
                    <span>
                        c. You also have the right to apply or a copy of the
                        information we hold about you. This is called a data
                        subject access request and you can make a request by
                        writing to us using the contact details above. We may
                        require you to verify your identify before we can
                        disclose any Personal Information to you. If your
                        request is repetitive or excessive you may be required
                        to pay a reasonable fee for this service.
                    </span>
                </p>
                <h3>
                    <span>8. Special categories of data</span>
                </h3>
                <p>
                    <span>
                        a. We ask that users do not send us, and that users do
                        not disclose, or upload any special categories of data
                        about themselves or their End Users (e.g., social
                        security numbers, information related to racial or
                        ethnic origin, political opinions, religion or other
                        beliefs, health, biometrics or genetic characteristics,
                        criminal background or trade union membership) on or
                        through the Services.
                    </span>
                </p>
                <h3>
                    <span>9. Testimonials and blogs</span>
                </h3>
                <p>
                    <span>
                        a. We post customer testimonials/comments/reviews on our
                        Website which may contain Personal Information. We will
                        use the reviews that you have posted on review websites
                        in relation to our Services and by using the Services
                        you consent to this usage. Alternatively, we will post
                        the comments that you have supplied to us after we have
                        obtained your consent in order for us to do the same. To
                        request removal of your Personal Information from
                        Testimonials or comments please contact us at{" "}
                    </span>
                    <span>info@HelloCashflow.com</span>
                    <span>
                        &nbsp;with the subject &lsquo;Data Protection&rsquo;.
                    </span>
                </p>
                <p>
                    <span>
                        b. Our Website offers publicly accessible blogs. You
                        should be aware that any information including Personal
                        Information) you provide in these areas may be read,
                        collected, and used by others who access them. To
                        request removal of your Personal Information from our
                        blog or community forum, contact us at
                        dpo@HelloCashflow.com. In some cases, we may not be able
                        to remove your Personal Information, in which case we
                        will let you know if we are unable to do so and why.
                    </span>
                </p>
                <h3>
                    <span>10. What choices do I have?</span>
                </h3>
                <p>
                    <span>
                        a. You can always choose not to disclose Personal
                        Information to us, but keep in mind some Personal
                        Information may be needed to register with us or to take
                        advantage of some of our special features.
                    </span>
                </p>
                <p>
                    <span>
                        b. You may be able to add, update, or delete information
                        (including Personal Information) as explained in Section
                        7 above. When you update information, however, we may
                        maintain a copy of the unrevised information in our
                        records.
                    </span>
                </p>
                <p>
                    <span>
                        c. Except as set out in Section 7 above, we will retain
                        your information (including Personal Information) for as
                        long as your account is active or as needed to provide
                        you Services.
                    </span>
                </p>
                <p>
                    <span>
                        d. You may request deletion of your account and Personal
                        Information by contacting us at info@HelloCashflow.com.
                        Please note that some Personal Information may remain in
                        our private records after your deletion of such
                        information (including Personal Information) from your
                        account due to our disaster recovery and backup
                        purposes. &nbsp;We will retain and use your information
                        and Personal Information as necessary to comply with our
                        legal obligations, resolve disputes, and enforce our
                        agreements but barring legal requirements, we will
                        delete your Personal Information within 90 days.
                    </span>
                </p>
                <p>
                    <span>
                        e. We may use any aggregated data derived from or
                        incorporating your Personal Information after you update
                        or delete it, but not in a manner that would identify
                        you personally.
                    </span>
                </p>
                <h3>
                    <span>11. Changes to this Privacy Notice</span>
                </h3>
                <p>
                    <span>
                        a. We may amend or update this Privacy Notice from time
                        to time and the date it was last updated will be shown
                        at the top of the page. Use of Personal Information we
                        collect now is subject to the Privacy Notice in effect
                        at the time such Personal Information is used. If we
                        make any significant changes or changes in the way we
                        use Personal Information, we will notify you by posting
                        an announcement on our Website or sending you an email
                        prior to the change becoming effective. You are bound by
                        any changes to the Privacy Notice when you use the
                        Website after such changes have been first posted.
                    </span>
                </p>
                <h3>
                    <span>12. Questions or concerns</span>
                </h3>
                <p>
                    <span>
                        a. If you have any questions or concerns regarding our
                        Privacy Notice, please send us a detailed message at
                        info@HelloCashflow.com. We will make every effort to
                        resolve your concerns.
                    </span>
                </p>
                <p>
                    <span>
                        b. You also have the right to complain directly to the
                        Privacy Commissioner&rsquo;s Office in relation to the
                        Processing of Personal Information. You can do this by
                        visiting{" "}
                    </span>
                    <span>
                        <a href='https://www.google.com/url?q=http://www.privacy.org.nz&amp;sa=D&amp;source=editors&amp;ust=1667446560541675&amp;usg=AOvVaw3DArN3-5EZ1CEMDT2-Sue7'>
                            www.privacy.org.nz
                        </a>
                    </span>
                    <span>
                        , calling 0800 803 909, or writing to PO Box 10 094
                        Wellington 6143. &nbsp;{" "}
                    </span>
                </p>
                <p>
                    <span></span>
                </p>
            </Page>
            <Footer />
        </Container>
    );
};

export default Privacy;

const Container = styled.div`
    width: 1400px;
    background-color: ${props => props.theme.colors.Pink};
`;

const Page = styled.div`
    padding: 15px;
`;

const TopBar = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;
    height: 64px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.DarkBlue};
`;
