import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import Switch from "components/general/Switch";
import ReportsModal from "components/navigation/expandableSection/ReportsModal";
import useReport from "hooks/reports/useReport";
import { filterByMonths } from "lib/apiDataHelpers/filterByMonths";
import { sumOfMonths } from "lib/apiDataHelpers/sumOfMonths";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { toMmmYy } from "lib/date/reStringifyMonth";
import { useState } from "react";
import {
    CartesianAxis,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { theme } from "style/theme";
import { OrgData } from "types/OrganisationType";

import BreakDownsRevVsExpModel from "./ModelRevVsExp";

// const getBudgetActualTimeSeries = (
//     endDate: Month,
//     monthCount: number,
//     option1: RevenueAndExpenses,
//     option2: RevenueAndExpenses,
//     title: {
//         options1: string;
//         options2: string;
//     },
// ): MonthlyDataArray => {
//     const rows: any[] = [];
//     for (let i = monthCount - 1; i > -1; i--) {
//         const monthClass = endDate.addMonthsTo(-i);
//         rows.push([
//             monthClass.ToMmmYy(),
//             option1.getRowTotal(monthClass),
//             option2.getRowTotal(monthClass),
//         ]);
//     }
//     return [["Month", title.options1, title.options2], ...rows];
// };

const getChartData = (
    startMonth: MonthKey,
    monthCount: number,
    revenue: OrgData,
    expenses: OrgData,
) => {
    const rows: any[] = [];
    const filteredRevenue = sumOfMonths(
        filterByMonths({
            obj: revenue,
            latestMonth: startMonth,
            totalMonths: monthCount,
        }),
    );
    const filteredExpenses = sumOfMonths(
        filterByMonths({
            obj: expenses,
            latestMonth: startMonth,
            totalMonths: monthCount,
        }),
    );
    (Object.keys(filteredRevenue) as MonthKey[]).forEach(month => {
        rows.unshift({
            Revenue: filteredRevenue[month],
            Expenses: filteredExpenses[month],
            NetProfit: filteredRevenue[month] - filteredExpenses[month],
            name: month,
        });
    });
    return rows;
};

const BreakDownsSectionRevenueVsExpenses = () => {
    const [profitLine, setProfitLine] = useState(false);
    const report = useReport();
    if (!report) {
        return null;
    }
    const {
        revenue,
        expenses,
        reportDates: { selectedMonth },
    } = report;

    return (
        <ItemSection
            light
            column={true}
            styles={{ paddingMoblie: "12px" }}
            minHeight={405}>
            <Label>
                Here's how your total monthly revenue is tracking against your
                expenses:
            </Label>
            <Toggle>
                <span>
                    {!profitLine ? <>Revenue and Expenses</> : <>Net Profit</>}
                </span>
                <div>
                    <Switch
                        toggle={profitLine}
                        cbSwitch={setProfitLine}
                        width={50}
                        border={theme.colors.DarkPink}
                        colorOption1={theme.colors.DarkPink}
                        colorOption2={theme.colors.Pink}
                        borderWideth={1}
                    />
                </div>
            </Toggle>
            <ResponsiveContainer width='100%' height={250}>
                <LineChart
                    data={getChartData(
                        selectedMonth,
                        12,
                        revenue.actual,
                        expenses.actual,
                    )}
                    margin={{ top: 5, right: 30, left: 0, bottom: 5 }}>
                    <CartesianAxis axisLine={false} />
                    <XAxis
                        interval={0}
                        style={{ fill: theme.colors.DarkBlue }}
                        stroke={"none"}
                        dataKey='name'
                        tick={tickMonthWrap}
                    />
                    <YAxis
                        style={{ fill: theme.colors.DarkBlue }}
                        stroke={"none"}
                        tickFormatter={(value: number) =>
                            Writer.FormatCurrency(value)
                        }
                    />
                    <ReferenceLine
                        y={0}
                        label=''
                        stroke='red'
                        strokeDasharray='3 3'
                    />
                    <Tooltip
                        labelStyle={{
                            color: theme.colors.DarkBlue,
                        }}
                        labelFormatter={props => {
                            return toMmmYy(props);
                        }}
                        formatter={value =>
                            Writer.FormatCurrency(value as number)
                        }
                    />
                    <Legend />
                    {profitLine ? (
                        <Line
                            strokeWidth={3}
                            type='monotone'
                            dataKey='NetProfit'
                            name='Net Profit'
                            stroke={theme.colors.DarkPink}
                        />
                    ) : (
                        <>
                            <Line
                                strokeWidth={3}
                                type='monotone'
                                dataKey='Revenue'
                                stroke={theme.colors.Yellow}
                            />
                            <Line
                                strokeWidth={3}
                                type='monotone'
                                dataKey='Expenses'
                                stroke={theme.colors.DarkBlue}
                            />
                        </>
                    )}
                </LineChart>
            </ResponsiveContainer>

            <ReportsModal light path='rev-vs-exp-model'>
                <BreakDownsRevVsExpModel />
            </ReportsModal>
        </ItemSection>
    );
};

export default BreakDownsSectionRevenueVsExpenses;

const Toggle = styled.div`
    position: absolute;
    top: 50px;
    right: 70px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 300px;
    font-size: 15px;
    > div {
        width: 50px;
    }
    > span {
        margin-right: 10px;
    }
`;

const Label = styled.h1`
    margin-right: auto;
    margin-top: -15px;
    margin-bottom: 30px;
    font-size: 24px;
`;
