import React, { useEffect, useRef } from "react";

/**
 * Hook that alerts clicks outside of the passed ref
 */
export function useOutsideAlerter(ref: any, callBack: () => void) {
    useEffect(() => {
        /**
         * Alert if clicked on outside of element
         */

        function handleClickOutside(event: any) {
            if (ref.current && !ref.current.contains(event.target)) {
                callBack();
            }
        }

        // Bind the event listener
        document.addEventListener("mousedown", handleClickOutside);
        return () => {
            // Unbind the event listener on clean up
            document.removeEventListener("mousedown", handleClickOutside);
        };
    }, [callBack, ref]);
}
interface Props {
    children: React.ReactNode;
    callBack: () => void;
}

export default function OutsideAlerter({ children, callBack }: Props) {
    const wrapperRef = useRef(null);
    useOutsideAlerter(wrapperRef, callBack);

    return (
        <div style={{ width: "100%", height: "100%" }} ref={wrapperRef}>
            {children}
        </div>
    );
}
