import styled from "@emotion/styled";
import { Portal } from "components/general/portal";
import OutsideAlerter from "components/inputs/OutsideAlerter";
import { useCallback, useState } from "react";

interface ComponentProps {
    children?: React.ReactNode;
}

const useModal = (startOpen = false) => {
    const [show, setShow] = useState(startOpen);
    const [exit, setExit] = useState(false);

    const handleClose = () => {
        setExit(true);
        setTimeout(() => {
            setShow(false);
            setExit(false);
            document.body.style.overflow = "auto";
            const element = document.getElementById("portal");
            if (element) {
                element.style.zIndex = "0";
            }
        }, 400);
    };

    const handleShow = () => {
        document.body.style.overflow = "hidden";
        const element = document.getElementById("portal");
        if (element) {
            element.style.zIndex = "10";
        }
        setShow(true);
    };

    const Modal = useCallback(
        ({ children }: ComponentProps) => {
            return show ? (
                <Portal>
                    <Container exit={exit}>
                        <InnerContainer>
                            <OutsideAlerter
                                callBack={() => {
                                    handleClose();
                                }}>
                                {children}
                            </OutsideAlerter>
                        </InnerContainer>
                    </Container>
                </Portal>
            ) : null;
        },
        [show, exit],
    );

    return {
        Modal,
        show,
        setClose: handleClose,
        setOpen: handleShow,
    };
};

export default useModal;

const InnerContainer = styled.div``;
const Container = styled.div<{ exit: boolean }>`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);

    animation: fadeInAnimation ease-in-out 0.4s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;
    z-index: 10000;

    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    ${props => props.exit && `animation: fadeOutAnimation ease-in-out 0.4s;`}

    @keyframes fadeOutAnimation {
        0% {
            opacity: 1;
        }
        100% {
            opacity: 0;
        }
    }
`;
