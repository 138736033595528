import styled from "@emotion/styled";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import ReportsModal from "components/navigation/expandableSection/ReportsModal";
import SpeedoGraph from "components/recharts/test/SpeedoGraph";
import useReport from "hooks/reports/useReport";
import { sumOfMonth } from "lib/apiDataHelpers/sumOfMonths";
import {
    Bar,
    BarChart,
    CartesianAxis,
    Cell,
    LabelList,
    ResponsiveContainer,
    XAxis,
    YAxis,
} from "recharts";
import { theme } from "style/theme";

import ExpensesDetailBudget from "./ModelBudget";

const ExpensesSectionBudget = () => {
    const report = useReport();

    if (!report) {
        return null;
    }

    const {
        expenses: { actual, budget },
        reportDates: { selectedMonth },
    } = report;

    const selectedMonthTotal = sumOfMonth(actual[selectedMonth]);

    const selectedMonthBudget = sumOfMonth(budget[selectedMonth]);

    const selectedMonthAgainstBudget = selectedMonthTotal - selectedMonthBudget;

    const BudgetOverRev = Math.round(
        (selectedMonthTotal / selectedMonthBudget) * 100,
    );

    const data = [
        {
            name: "Actual",
            color: theme.colors.Yellow,
            key: selectedMonthTotal,
        },
        {
            name: "Budget",
            color: theme.colors.DarkPink,
            key: selectedMonthBudget,
        },
    ];

    return (
        <ItemSection light minHeight={355}>
            <div className='col-md-8'>
                <h1>
                    Here's your total expenses compared to your budget in{" "}
                    {selectedMonth.selectByMonths(
                        selectedMonth.toTextMonth(),
                        selectedMonth.toTextMonth(),
                        selectedMonth.toMmmmYyyy(),
                    )}
                </h1>
                <figure>
                    <ResponsiveContainer width='100%' height={200}>
                        <BarChart
                            barCategoryGap={20}
                            data={data}
                            layout='vertical'
                            margin={{ top: 5, right: 100, left: 0, bottom: 5 }}>
                            <CartesianAxis axisLine={false} />
                            <XAxis
                                type='number'
                                style={{ fill: theme.colors.DarkBlue }}
                                stroke={"none"}
                                tickFormatter={(value: number) =>
                                    Writer.FormatCurrency(value)
                                }
                            />
                            <YAxis
                                type='category'
                                dataKey='name'
                                style={{ fill: theme.colors.DarkBlue }}
                                stroke={"none"}
                            />
                            <Bar
                                isAnimationActive={false}
                                dataKey='key'
                                fill={theme.colors.Yellow}>
                                <LabelList
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                    }}
                                    fill={theme.colors.DarkBlue}
                                    formatter={(value: number) =>
                                        Writer.FormatCurrency(value)
                                    }
                                    dataKey='key'
                                    position='right'
                                />
                                {data.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={entry.color}
                                    />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </figure>
            </div>
            <SpeedoContainer>
                <SpeedoGraph
                    reverse
                    chartValue={
                        BudgetOverRev === -Infinity ||
                        BudgetOverRev === Infinity
                            ? 0
                            : BudgetOverRev
                    }
                    chartMax={200}
                />
                <h3>
                    {selectedMonthBudget ? (
                        <span>
                            This month you spent{" "}
                            {Writer.FormatCurrency(
                                Math.abs(selectedMonthAgainstBudget),
                            )}{" "}
                            {selectedMonthAgainstBudget > 0 ? "more" : "less"}{" "}
                            than you budgeted
                        </span>
                    ) : (
                        "You have no Budget please do your budget to use this graph"
                    )}
                </h3>
            </SpeedoContainer>

            <ReportsModal light path='budget-model'>
                <ExpensesDetailBudget />
            </ReportsModal>
        </ItemSection>
    );
};

export default ExpensesSectionBudget;

const SpeedoContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 250px;
    text-align: center;
    margin: 0 auto;
`;
