import MonthKey from "common/types/monthKey";
import { endOfMonth, format, parseISO } from "date-fns";

const ShortMonth: { [key: string]: string } = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec", //Seems cleaner than worrying about date parsing
};
const LongMonth: { [key: string]: string } = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December",
};

export const toTwoDigitMonth = (month: number) =>
    month < 10 ? `0${month}` : month.toString();

export const getMonthParts = (month: MonthKey) => {
    const parts = month.split("-");
    return {
        yearPart: parts[0],
        monthPart: parts[1],
    };
};

export const getValues = (month: MonthKey) => {
    const parts = month.split("-");
    return {
        yearValue: parseInt(parts[0]),
        monthValue: parseInt(parts[1]),
    };
};

export const monthsElapsed = (month: MonthKey, previousMonth: MonthKey) => {
    return (
        getMonth(month) -
        getMonth(previousMonth) +
        12 * (getYear(month) - getYear(previousMonth))
    );
};

/**
 *
 * @param month
 * @returns month as number i.e. 1
 */
export const getMonth = (month: MonthKey) => parseInt(month.split("-")[1]);

/**
 *
 * @param month
 * @returns year as number i.e. 2020
 */
export const getYear = (month: MonthKey) => parseInt(month.split("-")[0]);
/**
 * @param month
 * @returns  the date in format January
 */
export function toTextMonth(month: MonthKey): string;
export function toTextMonth(month?: MonthKey): string | undefined;
export function toTextMonth(month?: MonthKey): string | undefined {
    if (!month) return;
    const parts = getMonthParts(month);
    return LongMonth[parts.monthPart];
}

export function toTextShortMonth(month?: MonthKey): string | undefined {
    if (!month) return;
    const parts = getMonthParts(month);
    return ShortMonth[parts.monthPart];
}

/**
 * @param month
 * @returns the date in format Jan '20
 */
export function toMmmYy(month: MonthKey): string;
export function toMmmYy(month?: MonthKey): string | undefined;
export function toMmmYy(month?: MonthKey): string | undefined {
    if (!month) return;
    const parts = getMonthParts(month);
    return `${ShortMonth[parts.monthPart]} '${parts.yearPart.substring(2, 4)}`;
}

/**
 * @param month
 * @returns the date in format Jan 2020
 */
export function toMmmYyyy(month: MonthKey): string;
export function toMmmYyyy(month?: MonthKey): string | undefined;
export function toMmmYyyy(month?: MonthKey): string | undefined {
    if (!month) return;
    const parts = getMonthParts(month);
    return `${ShortMonth[parts.monthPart]} ${parts.yearPart}`;
}

/**
 * @param month
 * @returns date in format January 2020
 */
export function toMmmmYyyy(month: MonthKey): string;
export function toMmmmYyyy(month?: MonthKey): string | undefined;
export function toMmmmYyyy(month?: MonthKey): string | undefined {
    if (!month) return;
    const parts = getMonthParts(month);
    return `${LongMonth[parts.monthPart]} ${parts.yearPart}`;
}

export function toTextEndOfMonth(month: MonthKey) {
    return format(endOfMonth(parseISO(`${month}-01`)), "d MMMM yyyy");
}
