import { HttpClient } from "adapters/HttpClient";
import {
    getRedirectResult,
    GoogleAuthProvider,
    OAuthProvider,
    signInWithPopup,
} from "firebase/auth";
import { auth } from "lib/firebase";
import { NavigateFunction } from "react-router";

import { handleSignIn } from "./handleSignIn";

export const signInWithGoogle = (navigate: NavigateFunction) => {
    signInWithPopup(auth, new GoogleAuthProvider())
        .then(result => {
            handleSignIn(result, navigate);
        })
        .catch(error => {
            alert(`failed to sign in with google: ${error.message}`);
        });
};

export const signInWithXero = (navigate: NavigateFunction) => {
    const provider = new OAuthProvider("oidc.xero");
    provider.addScope("email");
    provider.addScope("profile");

    signInWithPopup(auth, provider)
        .then(result => {
            console.log(result);
            handleSignIn(result, navigate, true);
        })
        .catch(error => {
            alert(`failed to sign in with xero: ${error.message}`);
        });
};

//Handlers for creating an account or logging in with intuit (quickbooks)
export const signInWithIntuit = (navigate: NavigateFunction) => {
    HttpClient.get<string>("quickbooks/intuit-loginurl").then(response => {
        location.href = response;
    });
};

export const handleRedirect = (navigate: NavigateFunction) =>
    getRedirectResult(auth)
        .then(result => {
            result && handleSignIn(result, navigate);
        })
        .catch(error => {
            alert(
                `failed to sign in: ${JSON.stringify(error)}, ${error.message}`,
            );
        });

//HAndlers for connecting to an organisation in quickbooks after account is created
export const connectWithIntuit = (navigate: NavigateFunction) => {
    const provider = new OAuthProvider("oidc.qb-sandbox");
    provider.addScope("openid");

    HttpClient.get<string>("quickbooks/intuit-connecturl").then(response => {
        location.href = response;
    });
};
