declare module "@emotion/react" {
    export interface Theme {
        colors: {
            Yellow: string;
            DarkBlue: string;
            LightBlue: string;
            Pink: string;
            PinkMed: string;
            DarkPink: string;
            DarkerPink: string;
            LightAqua: string;
            DarkAqua: string;
            Purple: string;
            WhiteYellow: string;
            WhiteBlue: string;
            BlackBlue: string;
            WhitePink: string;
            WhiteBrown: string;
            WhiteAqua: string;
            DarkPurple: string;

            Pie: string;
            Pie2: string;
            Pie3: string;
            Pie4: string;
            Pie5: string;

            BrownYellow: string;
            PalePink: string;

            textLight: string;

            backgroundColor: string;
        };
        fonts: {
            defaultFontSize: string;
            defaultHeaderFontSize: string;
            defaultSubHeaderFontSize: string;
            fontFamilyRaleway: string;
            fontFamilyProxima: string;
            fontWeight: string;
        };
        sizes: {
            contentWidth: string;
            fullPageHeight: string;
            contentTopMargin: string;
            contentPaddingX: string;
            contentWidthTablet: string;
            contentWidthPhone: string;
            contentWidthPhoneNumber: number;
            contentWidthTabletNumber: number;
            modelWidth: string;
        };
    }
}

export const theme = {
    colors: {
        /* text */
        textLight: "#ceccd9",
        /* white */
        WhiteYellow: "#F7F5E6",
        WhiteBlue: "#F6F7F9",
        BlackBlue: "#0D0340",
        WhitePink: "#F2C5D4",
        WhiteBrown: " #F5D499",
        WhiteAqua: " #EACEA1",

        /* pink */

        PalePink: "#FD7EA9",
        Pink: "#ffe0e9",
        PinkMed: "#febfd4",
        DarkPink: "#fd6092",
        DarkerPink: "#e74b7e",

        // purple
        DarkPurple: "#0E7B81",
        Purple: "#6d658e",

        /* blue */
        DarkBlue: "#0c0341",
        LightAqua: "#bcf8ec",
        DarkAqua: "#5de1e6",
        LightBlue: "#2176d8",

        /* yellow */
        Yellow: "#f3a748",
        BrownYellow: "#F0A226",

        /* other */
        Pie: "#7A9CC6",
        Pie2: "#C2EABD",
        Pie3: "#08605F",
        Pie4: "#E88873",
        Pie5: "#344055",

        backgroundColor: "#f9f8fc",
    },
    fonts: {
        /* fonts */
        defaultFontSize: "12px",
        defaultSubHeaderFontSize: "18px",
        defaultHeaderFontSize: "24px",
        fontFamilyRaleway: "Raleway",
        fontFamilyProxima: "Prox",
        fontWeight: "300",
    },

    sizes: {
        contentWidth: "960px",
        fullPageHeight: "530px",
        contentTopMargin: "10px",
        contentPaddingX: "30px",

        /* tablet */

        contentWidthTablet: "700px",
        contentWidthPhone: "640px",
        contentWidthPhoneNumber: 640,
        contentWidthTabletNumber: 700,

        /* model */
        modelWidth: "900px",
    },
};
