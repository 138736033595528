import styled from "@emotion/styled";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import Switch from "components/general/Switch";
import useReport from "hooks/reports/useReport";
import { totalForMonths } from "lib/apiDataHelpers/sumOfMonths";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { toMmmmYyyy } from "lib/date/reStringifyMonth";
import { useState } from "react";
import {
    Bar,
    BarChart,
    Cell,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { theme } from "style/theme";

import Bubble, { bubbleBlueClear, bubbleDark } from "../Bubble";

const RevenueSectionLastYear = () => {
    const [useFullYear, setUseFullYear] = useState(true);
    const report = useReport();

    if (!report) {
        return null;
    }

    const {
        revenue: { actual, budget },
        reportDates: { monthsInToYear, selectedMonth, sameMonthLastYear },
    } = report;

    const howManyMonths = useFullYear ? 12 : monthsInToYear + 1;
    const showToggle = monthsInToYear < 12; //Don't sho the toggle if both options are the same (end of financial year)

    const startPeriod = addMonthsTo(selectedMonth, -howManyMonths + 1);
    const compareStartPeriod = addMonthsTo(
        sameMonthLastYear,
        -howManyMonths + 1,
    );
    //debugger;
    const totals = {
        budget: totalForMonths({
            org: budget,
            earliestMonth: startPeriod,
            latestMonth: selectedMonth,
        }),
        actual: totalForMonths({
            org: actual,
            earliestMonth: startPeriod,
            latestMonth: selectedMonth,
        }),
    };
    const totalsCompare = {
        budget: totalForMonths({
            org: budget,
            earliestMonth: compareStartPeriod,
            latestMonth: sameMonthLastYear,
        }),
        actual: totalForMonths({
            org: actual,
            earliestMonth: compareStartPeriod,
            latestMonth: sameMonthLastYear,
        }),
    };

    const difBudget = totals.actual - totals.budget;

    const annualDiff = totals.actual - totalsCompare.actual;

    const textMonthsCovered = Writer.writeAsWord(howManyMonths, true);
    //const difComparePeriod = totals.actual - totalsCompare.actual;
    const bubbleColor = useFullYear ? bubbleDark : bubbleBlueClear;

    const data = [
        {
            color: theme.colors.Yellow,
            name: "Actual",
            key: totals.actual,
        },
        {
            color: theme.colors.DarkPink,
            name: "Budget",
            key: totals.budget,
        },
        {
            color: theme.colors.Yellow,
            name: "Actual last year",
            key: totalsCompare.actual,
        },
        {
            color: theme.colors.DarkPink,
            name: "Budget last year",
            key: totalsCompare.budget,
        },
    ];

    return (
        <>
            <ItemSection column light>
                <h1 style={{ marginTop: 0 }}>
                    {howManyMonths > 1
                        ? `For the ${textMonthsCovered.toLowerCase()} months ended `
                        : `For the month of `}
                    {toMmmmYyyy(selectedMonth)} and{" "}
                    {toMmmmYyyy(sameMonthLastYear)}
                </h1>
                <Container>
                    <SwitchMonths>
                        <SwitchWords>
                            <b>
                                {useFullYear
                                    ? "last 12 months"
                                    : "year to date"}
                            </b>
                        </SwitchWords>
                        <Switch
                            cbSwitch={boo => setUseFullYear(!boo)}
                            toggle={!useFullYear}
                            width={39}
                            border={theme.colors.DarkBlue}
                            colorOption1={theme.colors.Pink}
                            colorOption2={theme.colors.DarkBlue}
                            borderWideth={1}
                        />
                    </SwitchMonths>

                    <ResponsiveContainer width='100%' height={350}>
                        <BarChart
                            layout='vertical'
                            barCategoryGap={10}
                            margin={{ top: 5, right: 80, left: 10, bottom: 0 }}
                            data={data}>
                            <Tooltip
                                content={props => {
                                    return (
                                        <div
                                            style={{
                                                backgroundColor: "white",
                                                padding: "10px",
                                                color: theme.colors.DarkBlue,
                                                borderRadius: "5px",
                                            }}>
                                            <div>{props.label}</div>
                                            <div>
                                                {props.payload &&
                                                    props.payload[0] &&
                                                    props.payload[0].value &&
                                                    Writer.FormatCurrency(
                                                        +props.payload[0].value,
                                                    )}
                                            </div>
                                        </div>
                                    );
                                }}
                            />
                            <XAxis
                                type='number'
                                style={{ fill: theme.colors.DarkBlue }}
                                stroke={"none"}
                                tickFormatter={(value: number) =>
                                    Writer.FormatCurrency(value)
                                }
                            />
                            <YAxis
                                type='category'
                                dataKey='name'
                                style={{ fill: theme.colors.DarkBlue }}
                                stroke={"none"}
                            />
                            <Bar
                                isAnimationActive={false}
                                dataKey='key'
                                fill={theme.colors.Yellow}>
                                {data.map((entry, index) => (
                                    <Cell
                                        key={`cell-${index}`}
                                        fill={data[index].color}
                                    />
                                ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>

                    <Bubbles>
                        <Bubble style={bubbleColor}>
                            <h3>You generated</h3>
                            <mark>
                                {Writer.FormatCurrency(Math.abs(difBudget))}
                            </mark>
                            <span>
                                {difBudget > 0 ? "MORE" : "LESS"} than you
                                budgeted
                            </span>
                        </Bubble>
                        <Bubble style={bubbleColor}>
                            <h3>You generated</h3>
                            <mark>
                                {Writer.FormatCurrency(Math.abs(annualDiff))}
                            </mark>
                            <span>
                                {Writer.DescribeChange(
                                    howManyMonths,
                                    "MORE than",
                                    "LESS than",
                                    "against",
                                )}{" "}
                                last year
                            </span>
                        </Bubble>
                    </Bubbles>
                </Container>
            </ItemSection>
        </>
    );
};

export default RevenueSectionLastYear;

const Container = styled.div`
    display: flex;
    width: 100%;
`;
const Bubbles = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
`;

const SwitchWords = styled.span`
    margin-right: 10px;
    letter-spacing: 3.2px;
`;

const SwitchMonths = styled.div`
    position: absolute;
    right: 35px;
    top: 10px;
    display: flex;
    align-items: center;
    height: 40px;

    color: ${({ theme }) => theme.colors.DarkBlue};
    text-transform: uppercase;
`;
