import styled from "@emotion/styled";
import whiteArrow from "assets/img/backgrounds/arrow-right-white.png";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import Switch from "components/general/Switch";
import useCashflow from "hooks/reports/useCashflow";
import useReport from "hooks/reports/useReport";
import { addMonthsTo, numberOfMonthsBetween } from "lib/date/addMonthsTo";
import { toMmmmYyyy, toTextEndOfMonth } from "lib/date/reStringifyMonth";
import { useEffect, useState } from "react";
import { theme } from "style/theme";
import { Item, ItemType } from "types/OrganisationType";

import Bubble from "../Bubble";

const MovementsSection = () => {
    const [useFullYear, setUseFullYear] = useState(true);
    const report = useReport();

    useEffect(() => {
        document.title = "My Cashflow";
    }, []);

    const cashflow = useCashflow();

    if (!(report && cashflow)) return null;

    const { cashTaxSummary, cashflowData } = cashflow;

    const {
        items,
        organisation,
        balanceSheet: { bankBalance },
        reportDates: {
            financialYearToDate,
            lastTwelveMonths,
            selectedMonth,
            financialYearStart,
            sameMonthLastYear,
        },
    } = report;

    const periodCovered: MonthKey[] = useFullYear
        ? lastTwelveMonths
        : financialYearToDate;

    //Which are operating activities (net cash) vs assets/drawings/loan payments etc?
    // const activeItemCodes: string[]
    //     = (cashflow.cashflowData && Object.keys(cashflow.cashflowData)) || [];

    // const balanceSheetItemCodes = activeItemCodes.filter(
    //     i =>
    //         items[i] &&
    //         (items[i].itemType === ItemType.asset ||
    //             items[i].itemType === ItemType.liability ||
    //             items[i].itemType === ItemType.equity),
    // );

    // Create the chart series
    // const avgTransactionGraphData: Array<{
    //     name: string;
    //     movement: number;
    // }> | null = null;
    // cashflow.cashflowData &&
    // balanceSheetItemCodes
    //     .map(k => ({
    //         name: items[k]?.name ?? k,
    //         movement:
    //             (cashflow.cashflowData[selectedMonth]?.[k] | 0) -
    //             (cashflow.cashflowData[sameMonthLastYear]?.[k] | 0),
    //     }))
    //     .filter(d => d.movement !== 0)
    //     .sort((a, b) => a.movement - b.movement);

    //Cashflow related data
    const bankBalanceMonth: MonthKey = addMonthsTo(periodCovered[0], -1); //Balance is end of month so this is the balance going into the current year
    const balanceStart = bankBalance?.[bankBalanceMonth] ?? 0;
    const bankBalanceCurrent = bankBalance?.[selectedMonth] ?? 0;
    const changeInBank = bankBalanceCurrent - balanceStart;

    const cashTotalsForSelectedPeriod = periodCovered.reduce(
        (state, month) => {
            //Add tax data for the month
            const cashFlowForMonth = cashTaxSummary[month];
            if (cashFlowForMonth) {
                state.totalTaxCollected += cashFlowForMonth.taxComponentCashIn;
                state.totalTaxPaid += cashFlowForMonth.taxComponentCashOut;
            }
            //Add item data for the month
            const cashFlowItemForMonth = cashflowData[month];
            if (cashFlowItemForMonth) {
                Object.entries(cashFlowItemForMonth).forEach(
                    ([itemCode, value]) => {
                        state.itemTotals[itemCode] =
                            (state.itemTotals[itemCode] || 0) + value;
                    },
                );
            }

            return state;
        },
        {
            totalTaxCollected: 0,
            totalTaxPaid: 0,
            itemTotals: {} as Record<string, number>,
        },
    );

    //Get the itemCodes for the items that have been used in the cashflow data
    const cashflowItems = Object.keys(cashTotalsForSelectedPeriod.itemTotals)
        //map to item objects
        .map(k => ({ ...items[k], code: k }))
        //Sort by cashTotalsForSelectedPeriod value
        .sort((a, b) => {
            const aValue = Math.abs(
                cashTotalsForSelectedPeriod.itemTotals[a.code],
            );
            const bValue = Math.abs(
                cashTotalsForSelectedPeriod.itemTotals[b.code],
            );
            return bValue - aValue;
        })
        //map to record with item type and array of items
        .reduce((acc, curr) => {
            if (curr) {
                const itemType = curr.itemType;
                if (acc[itemType]) {
                    acc[itemType].push(curr);
                } else {
                    acc[itemType] = [curr];
                }
            }
            return acc;
        }, {} as Record<number, Array<Item & { code: string }>>);

    const hasNonOperatingItems =
        cashflowItems[ItemType.asset] ||
        cashflowItems[ItemType.liability] ||
        cashflowItems[ItemType.equity];

    //cashTotalsForSelectedPeriod.itemTotal has the total for each item code
    //This function sums each item of the given type
    const calculateTotal = (itemType: ItemType) => {
        return Object.entries(cashTotalsForSelectedPeriod.itemTotals)
            .filter(([k, v]) => items[k]?.itemType === itemType)
            .reduce((acc, [k, v]) => acc + v, 0);
    };

    const totalCashIn = calculateTotal(ItemType.revenue);
    const totalCashOut = calculateTotal(ItemType.expense);
    const totalNetAssetSales = calculateTotal(ItemType.asset);
    const totalNetLiabilities = calculateTotal(ItemType.liability);
    const totalNetEquity = calculateTotal(ItemType.equity);
    const netCashForSelectedPeriod = totalCashIn + totalCashOut;

    return (
        <>
            <ItemSection column light padding='48px 48px 48px 48px'>
                <SwitchMonths>
                    <SwitchWords>
                        <b>{useFullYear ? "last 12 months" : "year to date"}</b>
                    </SwitchWords>
                    <Switch
                        cbSwitch={boo => setUseFullYear(!boo)}
                        toggle={!useFullYear}
                        width={39}
                        border={theme.colors.DarkBlue}
                        colorOption1={theme.colors.Pink}
                        colorOption2={theme.colors.DarkBlue}
                        borderWideth={1}
                    />
                </SwitchMonths>

                <Title>
                    Before we forecast your cashflow, let's take a look at the{" "}
                    {useFullYear ? "last 12 months" : "financial year so far"}.
                </Title>
                <TitleCaption>
                    {/* But first, here's the{" "} */}(
                    {`1st of ${toMmmmYyyy(periodCovered[0])} - ${toMmmmYyyy(
                        periodCovered[periodCovered.length - 1],
                    )}`}
                    ).
                </TitleCaption>
                <Balances>
                    <Bubble>
                        <h3 style={{ fontSize: 14 }}>
                            1 {toMmmmYyyy(addMonthsTo(bankBalanceMonth, 1))}{" "}
                            bank balance:{" "}
                        </h3>
                        <mark style={{ fontSize: 22 }}>
                            {Writer.FormatCurrency(balanceStart)}
                        </mark>
                    </Bubble>
                    <LastTwelveChange>
                        <strong>
                            {changeInBank > 0 ? "Increased" : "Decreased"}
                        </strong>{" "}
                        {Writer.FormatCurrency(changeInBank)}
                        <br />
                        last{" "}
                        {useFullYear
                            ? 12
                            : numberOfMonthsBetween(
                                  financialYearStart,
                                  selectedMonth,
                              ) + 1}{" "}
                        month
                        {useFullYear
                            ? "s"
                            : numberOfMonthsBetween(
                                  financialYearStart,
                                  selectedMonth,
                              ) > 1
                            ? "s"
                            : ""}
                    </LastTwelveChange>
                    <Bubble>
                        <h3 style={{ fontSize: 14 }}>
                            {toTextEndOfMonth(selectedMonth)} bank balance:{" "}
                        </h3>
                        <mark style={{ fontSize: 22 }}>
                            {Writer.FormatCurrency(bankBalanceCurrent)}
                        </mark>
                    </Bubble>
                </Balances>
            </ItemSection>
            <ItemSection column padding='48px 48px 48px 48px'>
                <h1>
                    Why did my bank balance{" "}
                    {changeInBank > 0 ? "Increase" : "Decrease"}?
                </h1>
                <Info>
                    <BankBalanceDescription>
                        <div>
                            <h2>1: Operating activities</h2>
                            <p>
                                You generated a net cash (profit as measured on
                                a payments basis)
                                <strong>
                                    {Writer.FormatCurrency(
                                        netCashForSelectedPeriod,
                                    )}
                                </strong>{" "}
                                from your business activities over the last 12
                                months. That's an average of{" "}
                                {Writer.FormatCurrency(
                                    netCashForSelectedPeriod /
                                        periodCovered.length,
                                )}{" "}
                                per month.
                            </p>
                            {
                                //Were there cashin items?
                                cashflowItems[ItemType.revenue] && (
                                    <ItemList>
                                        Your business generated{" "}
                                        {Writer.FormatCurrency(totalCashIn)} in
                                        cash from:{" "}
                                        <ul>
                                            {cashflowItems[
                                                ItemType.revenue
                                            ].map((i, idx) => (
                                                <li key={idx}>
                                                    {i.name}:{" "}
                                                    {Writer.FormatCurrency(
                                                        cashTotalsForSelectedPeriod
                                                            .itemTotals[i.code],
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </ItemList>
                                )
                            }
                            {
                                //Were there cashout items?
                                cashflowItems[ItemType.expense] && (
                                    <ItemList>
                                        You spent{" "}
                                        {Writer.FormatCurrency(
                                            Math.abs(totalCashOut),
                                        )}{" "}
                                        cash on:{" "}
                                        <ul>
                                            {cashflowItems[
                                                ItemType.expense
                                            ].map((i, idx) => (
                                                <li key={idx}>
                                                    {i.name}:{" "}
                                                    {Writer.FormatCurrency(
                                                        Math.abs(
                                                            cashTotalsForSelectedPeriod
                                                                .itemTotals[
                                                                i.code
                                                            ],
                                                        ),
                                                    )}
                                                </li>
                                            ))}
                                        </ul>
                                    </ItemList>
                                )
                            }
                            <p>
                                You collected{" "}
                                {Writer.FormatCurrency(
                                    cashTotalsForSelectedPeriod.totalTaxCollected,
                                )}{" "}
                                in sales tax (GST) over this period, and paid{" "}
                                {Writer.FormatCurrency(
                                    cashTotalsForSelectedPeriod.totalTaxPaid,
                                )}{" "}
                                tax on your outgoings.
                            </p>
                        </div>
                        <div>
                            <h3>2: Non-operating activities</h3>
                            <p>
                                Anything outside of your business activities
                                that affects your bank balance will show here,
                                including loan payments, drawings, and asset
                                purchases.
                            </p>
                            {hasNonOperatingItems ? (
                                <>
                                    {cashflowItems[ItemType.asset] && (
                                        <ItemList>
                                            You{" "}
                                            {totalNetAssetSales < 0
                                                ? " bought "
                                                : " sold "}
                                            a net{" "}
                                            {Writer.FormatCurrency(
                                                Math.abs(totalNetAssetSales),
                                            )}{" "}
                                            in assets:
                                            <ul>
                                                {cashflowItems[
                                                    ItemType.asset
                                                ].map((i, idx) => {
                                                    const itemTotal =
                                                        cashTotalsForSelectedPeriod
                                                            .itemTotals[i.code];
                                                    return (
                                                        <li key={idx}>
                                                            {i.name}:
                                                            {itemTotal < 0
                                                                ? " cash out "
                                                                : " cash in "}
                                                            {Writer.FormatCurrency(
                                                                Math.abs(
                                                                    itemTotal,
                                                                ),
                                                            )}
                                                            {i.name
                                                                .toLowerCase()
                                                                .includes(
                                                                    "tax",
                                                                ) && (
                                                                <i>
                                                                    {" "}
                                                                    (Note: Some
                                                                    types of tax
                                                                    become an
                                                                    asset on
                                                                    your balance
                                                                    sheet when
                                                                    you pay
                                                                    them)
                                                                </i>
                                                            )}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </ItemList>
                                    )}
                                    {cashflowItems[ItemType.liability] && (
                                        <ItemList>
                                            You{" "}
                                            {totalNetLiabilities < 0
                                                ? " paid "
                                                : " received "}
                                            a net{" "}
                                            {Writer.FormatCurrency(
                                                Math.abs(totalNetLiabilities),
                                            )}{" "}
                                            in liabilities:
                                            <ul>
                                                {cashflowItems[
                                                    ItemType.liability
                                                ].map((i, idx) => {
                                                    const itemTotal =
                                                        cashTotalsForSelectedPeriod
                                                            .itemTotals[i.code];
                                                    return (
                                                        <li key={idx}>
                                                            {i.name}:
                                                            {itemTotal < 0
                                                                ? " cash out "
                                                                : " cash in "}
                                                            {Writer.FormatCurrency(
                                                                Math.abs(
                                                                    itemTotal,
                                                                ),
                                                            )}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </ItemList>
                                    )}
                                    {cashflowItems[ItemType.equity] && (
                                        <ItemList>
                                            You{" "}
                                            {totalNetEquity < 0
                                                ? " paid "
                                                : " received "}
                                            a net{" "}
                                            {Writer.FormatCurrency(
                                                Math.abs(totalNetEquity),
                                            )}{" "}
                                            in equity:
                                            <ul>
                                                {cashflowItems[
                                                    ItemType.equity
                                                ].map((i, idx) => {
                                                    const itemTotal =
                                                        cashTotalsForSelectedPeriod
                                                            .itemTotals[i.code];
                                                    return (
                                                        <li key={idx}>
                                                            {i.name}:
                                                            {itemTotal < 0
                                                                ? " cash out "
                                                                : " cash in "}
                                                            {Writer.FormatCurrency(
                                                                Math.abs(
                                                                    itemTotal,
                                                                ),
                                                            )}
                                                        </li>
                                                    );
                                                })}
                                            </ul>
                                        </ItemList>
                                    )}
                                </>
                            ) : (
                                <p>
                                    There were no non-operating activities for
                                    the selected period. This means your net
                                    cash from business activities should be the
                                    same as your change in bank balance.
                                </p>
                            )}

                            {hasNonOperatingItems && (
                                <>
                                    <h2>Summary</h2>
                                    <strong>
                                        <SummaryTable>
                                            {" "}
                                            <tbody>
                                                <tr>
                                                    <td>
                                                        Cash received from
                                                        operating activities:
                                                    </td>
                                                    <td>
                                                        {Writer.FormatCurrency(
                                                            totalCashIn,
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        LESS Cash spent on
                                                        operating activities:
                                                    </td>
                                                    <td>
                                                        {Writer.FormatCurrency(
                                                            totalCashOut,
                                                        )}
                                                    </td>
                                                </tr>
                                                <tr>
                                                    <td>
                                                        Net cash from operating
                                                        activities:
                                                    </td>
                                                    <td>
                                                        <strong>
                                                            {Writer.FormatCurrency(
                                                                netCashForSelectedPeriod,
                                                            )}
                                                        </strong>
                                                    </td>
                                                </tr>
                                                {cashflowItems[
                                                    ItemType.asset
                                                ] && (
                                                    <tr>
                                                        <td>
                                                            Net cash from
                                                            assets:
                                                        </td>
                                                        <td>
                                                            <strong>
                                                                {Writer.FormatCurrency(
                                                                    totalNetAssetSales,
                                                                )}
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                )}
                                                {cashflowItems[
                                                    ItemType.liability
                                                ] && (
                                                    <tr>
                                                        <td>
                                                            Net cash from
                                                            liabilities:
                                                        </td>
                                                        <td>
                                                            <strong>
                                                                {Writer.FormatCurrency(
                                                                    cashTotalsForSelectedPeriod
                                                                        .itemTotals[
                                                                        cashflowItems[
                                                                            ItemType
                                                                                .liability
                                                                        ][0]
                                                                            .code
                                                                    ],
                                                                )}
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                )}
                                                {cashflowItems[
                                                    ItemType.equity
                                                ] && (
                                                    <tr>
                                                        <td>
                                                            Net cash from
                                                            equity:
                                                        </td>
                                                        <td>
                                                            <strong>
                                                                {Writer.FormatCurrency(
                                                                    cashTotalsForSelectedPeriod
                                                                        .itemTotals[
                                                                        cashflowItems[
                                                                            ItemType
                                                                                .equity
                                                                        ][0]
                                                                            .code
                                                                    ],
                                                                )}
                                                            </strong>
                                                        </td>
                                                    </tr>
                                                )}
                                                <tr>
                                                    <td>
                                                        Total change in cash
                                                        reserves
                                                    </td>
                                                    <td>
                                                        <strong>
                                                            {Writer.FormatCurrency(
                                                                changeInBank,
                                                            )}
                                                        </strong>
                                                    </td>
                                                </tr>
                                            </tbody>
                                        </SummaryTable>
                                    </strong>
                                </>
                            )}
                        </div>
                    </BankBalanceDescription>
                </Info>
            </ItemSection>
            {/* {avgTransactionGraphData && (
                <CashMovementsGraph
                    avgTransactionGraphData={avgTransactionGraphData}
                />
            )} */}
        </>
    );
};

export default MovementsSection;

const SummaryTable = styled.table`
    width: auto;
    margin: 0 20px 20px 0;
    font-size: 16px;
    tr {
        td {
            padding: 5px 10px;
            border: 1px solid #000;
        }
    }
`;

const ItemList = styled.p`
    margin-top: 10px;
    ul {
        padding-left: 20px;
        max-height: 200px;
        overflow-y: auto;
    }
    li {
        margin-bottom: 5px;
    }
`;

const LastTwelveChange = styled.div`
    background-image: url(${whiteArrow});
    background-repeat: no-repeat;
    background-position: right center;
    width: 250px;
    text-align: center;
    position: relative;
    font-size: 18px;
    line-height: 30px;
`;

const Balances = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
    margin-top: 10px;
    padding: 0 30px;
`;
const BankBalanceDescription = styled.div`
    margin-top: 15px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    > div {
        display: flex;
        flex-direction: column;
        width: 450px;
    }
    /* justify-content: space-between; */
    /* align-items: center; */
`;
const Info = styled.div`
    display: flex;
    flex-direction: column;
    flex-basis: 66%;
    padding-right: 48px;
    h3,
    h2 {
        margin: 0;
        font-size: 17px;
    }
`;

const BankBalance = styled.div`
    text-align: center;
    h3 {
        width: 150px;
        margin-bottom: 0;
        font-size: 15px;
        font-weight: normal;
    }
    span {
        font-size: 20px;
        font-weight: bold;
    }
`;

const Title = styled.h1`
    position: absolute;
    top: 10px;
    left: 20px;
    font-size: 18px;
`;
const TitleCaption = styled.h2`
    position: absolute;
    top: 35px;
    left: 20px;
    font-size: 12px;
    font-style: Italic;
`;

const Emphasis = styled.mark`
    background-color: transparent;
    font-weight: bold;
    font-size: 1.4em;
    display: inline-block;
    color: #0c0341;
    vertical-align: middle;
`;

const SwitchWords = styled.span`
    margin-right: 10px;
    letter-spacing: 3.2px;
`;

const SwitchMonths = styled.div`
    position: absolute;
    right: 35px;
    top: 10px;
    display: flex;
    align-items: center;
    height: 40px;

    color: ${({ theme }) => theme.colors.DarkBlue};
    text-transform: uppercase;
`;
