import styled from "@emotion/styled";

const ItemSection = ({
    light,
    children,
    styles,
    column,
    id,
    minHeight,
    phoneMinHeight,
    padding,
}: {
    light?: boolean;
    children?: React.ReactNode;
    styles?: { flexWrap?: boolean; paddingMoblie?: string };
    column?: boolean;
    id?: string;
    phoneMinHeight?: number;
    minHeight?: number;
    padding?: string;
}) => {
    return (
        <Container
            id={id}
            column={column}
            flexWrap={styles?.flexWrap}
            paddingMoblie={styles?.paddingMoblie}
            light={light}
            minHeight={minHeight}
            padding={padding}
            phoneMinHeight={phoneMinHeight}>
            {children}
        </Container>
    );
};

export default ItemSection;

const Container = styled.div<{
    light?: boolean;
    paddingMoblie?: string;
    flexWrap?: boolean;
    phoneMinHeight?: number;
    minHeight?: number;
    column?: boolean;
    padding?: string;
}>`
    z-index: 2;
    position: relative;
    font-size: 12px;
    padding: ${props => props.padding ?? "48px"};
    display: flex;
    flex-direction: ${props => (props.column ? "column" : "row")};
    flex-wrap: ${props => (props.flexWrap ? "wrap" : "nowrap")};
    fill: ${props =>
        props.light ? props.theme.colors.DarkBlue : props.theme.colors.Pink};

    background-color: ${props =>
        props.light ? props.theme.colors.Pink : props.theme.colors.DarkBlue};
    color: ${props =>
        props.light ? props.theme.colors.DarkBlue : props.theme.colors.Pink};

    min-height: ${props => (props.minHeight ? props.minHeight + "px" : "auto")};

    @media (max-width: ${props => props.theme.sizes.contentWidthPhone}) {
        flex-direction: column;
        padding: ${props =>
            props.paddingMoblie ? props.paddingMoblie : "48px"};
        min-height: ${props =>
            props.phoneMinHeight ? props.phoneMinHeight + "px" : "auto"};
    }

    // a:not(.button) {
    //     color: $color-hc-dark-blue;
    //     &.active,
    //     &:hover {
    //         color: $color-hc-dark-pink;
    //     }
    // }

    // h1 {
    //     font-weight: 400;
    //     font-size: $default-font-size + 8px;
    //     margin: 0 0 40px 0;
    // }

    // h2 {
    //     font-weight: 700;
    //     font-size: $default-font-size;
    // }

    // h3 {
    //     font-weight: 500;
    //     font-size: $default-font-size;
    // }

    // figure {
    //     margin: 0;
    //     padding: 0;
    //     > div,
    //     > div img {
    //         width: 100%;

    //         &.square {
    //             width: 80%;
    //             margin: 0 auto;
    //         }
    //     }

    //     h1 {
    //         text-transform: uppercase;
    //         font-size: $default-font-size + 2px;
    //         margin-bottom: 30px;
    //     }

    //     &.pie-chart {
    //         h1 {
    //             width: 350px;
    //             text-align: center;
    //         }

    //         .chart-wrapper {
    //             width: 350px;
    //             height: 350px;
    //         }
    //     }
    // }

    // p,
    // ul {
    //     &.indent {
    //         margin-left: 30px;
    //     }

    //     &.featured {
    //         margin: 20px 0;
    //         font-size: $default-font-size + 4px;
    //     }

    //     &.featured-extra {
    //         margin: 20px 0;
    //         font-size: $default-font-size + 15px;
    //         font-weight: 500;
    //     }

    //     &.centered {
    //         text-align: center;
    //         max-width: 90%;
    //         margin-left: auto;
    //         margin-right: auto;

    //         &.narrow {
    //             max-width: 0.4 * $content-width;
    //         }
    //     }

    //     &.light {
    //         color: $color-hc-dark-pink;
    //     }
    // }

    // ul li {
    //     max-width: 75%;
    //     padding: 2px 0;
    // }

    // > .expand-content {
    //     position: absolute;
    //     bottom: 5px;
    //     left: 5px;
    // }
`;
