import { HttpClient } from "adapters/HttpClient";
import { getAdditionalUserInfo, UserCredential } from "firebase/auth";
import { NavigateFunction } from "react-router";

import { lastPage } from "../RequireAuth";

export const handleSignIn = (
    result: UserCredential,
    navigate: NavigateFunction,
    isXero?: boolean,
) => {
    const user = result.user;
    if (user) {
        const isNewUser = getAdditionalUserInfo(result)?.isNewUser;
        const params = new URLSearchParams(window.location.search);
        const handle = params.get("handle");

        if (handle) {
            // switch (handle) {
            //     case "annual":
            //         if (store.getState().stripe.activePrices.length) {
            //             await createCheckoutSession(user.uid, store.getState().stripe.activePrices[1].id);
            //         } else {
            //             const id = ([] as ProductPrice[]).concat(
            //                 ...(await getProducts())
            //                     .filter(product => product.active)
            //                     .map(product => product.prices),
            //             ).sort((a, b) => {
            //                 if (a.interval < b.interval) return -1;
            //                 if (a.interval > b.interval) return 1;
            //                 return 0;
            //             })[1].id;
            //             await createCheckoutSession(user.uid, id);
            //         }
            //         break;
            //     case "monthly":
            //         if (store.getState().stripe.activePrices.length) {
            //             await createCheckoutSession(user.uid, store.getState().stripe.activePrices[0].id);
            //         } else {
            //             const id = ([] as ProductPrice[]).concat(
            //                 ...(await getProducts())
            //                     .filter(product => product.active)
            //                     .map(product => product.prices),
            //             ).sort((a, b) => {
            //                 if (a.interval < b.interval) return -1;
            //                 if (a.interval > b.interval) return 1;
            //                 return 0;
            //             })[0].id;
            //             await createCheckoutSession(user.uid, id);
            //         }
            //         break;
            //     default:
            //         navigate(lastPage);
            // }
        } else if (isNewUser) {
            //If the user is signing in with xero for the first time, add the claim. This is done on the server if the user connects through the app store, but if they sign in directly we need to do it here
            //A custom claim allows us to differenciate between xero, quickbooks, and local account users if required
            if (isXero) {
                HttpClient.post("xero/add-claim").then(() => {
                    //reload firebase user to get the new claims
                    user.getIdToken(true).then(() => {
                        navigate("/account/providers"); //Now when they land on this page for the first time, we can do things like show them the xero button (and hide the quickbooks button by default)
                    });
                });
            } else {
                //This page meets the requirements of the xero and quickooks terms for users that have signed up but not yet connected their accounting provider
                navigate("/account/providers");
            }
        } else {
            navigate(lastPage);
        }
    }
};
