import styled from "@emotion/styled";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import COLORS from "common/constants/COLORS";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { filterByMonths } from "lib/apiDataHelpers/filterByMonths";
import { monthItemByPriceDce } from "lib/apiDataHelpers/monthItemByPrice";
import {
    sumOfItemsforMonths,
    sumOfMonth,
} from "lib/apiDataHelpers/sumOfMonths";
import { useEffect, useState } from "react";
import { useGetItemsQuery } from "redux/rtkQuery/reports";
import { useAppSelector } from "redux/store";
import { theme } from "style/theme";
import { MonthItems, OrgData } from "types/OrganisationType";

const sumOf = (data: OrgData, startPeriod: MonthKey, endPeriod: MonthKey) => {
    const months = filterByMonths({
        obj: data,
        latestMonth: startPeriod,
        earliestMonth: endPeriod,
    });
    return sumOfItemsforMonths(months);
};

interface Props {
    actual: OrgData;
    budget: OrgData;
    period?: {
        startDate: MonthKey;
        endDate: MonthKey;
    };
}

const ItemsList = ({ budget, actual, period }: Props) => {
    const currentOrganisation = useCurrentOrg();
    const { data: items } = useGetItemsQuery(
        currentOrganisation?.organisationId ?? skipToken,
    );
    const reportDates = useAppSelector(state => state.dates);

    const [budgetItems, setBudgetItems] = useState<MonthItems>({});
    const [actualItems, setActualItems] = useState<MonthItems>({});
    useEffect(() => {
        if (period && budget && actual) {
            setBudgetItems(sumOf(budget, period.startDate, period.endDate));
            setActualItems(sumOf(actual, period.startDate, period.endDate));
        }
    }, [actual, budget, period]);

    if (!(items && reportDates)) return null;

    const breakdownData =
        period &&
        filterByMonths({
            obj: actual,
            latestMonth: period.startDate,
            earliestMonth: period.endDate,
        });

    const breakdownBudget =
        period &&
        filterByMonths({
            obj: budget,
            latestMonth: period.startDate,
            earliestMonth: period.endDate,
        });

    return (
        <Container>
            <CatagoryNames key={4564357}>
                <b>Source</b>
                <ItemPrices>
                    <b>budget</b>
                    <b>actual</b>
                    <b>change</b>
                </ItemPrices>
            </CatagoryNames>
            <Items>
                {!(period && breakdownData && breakdownBudget) ? null : (
                    <>
                        {monthItemByPriceDce(
                            actual[reportDates.selectedMonth],
                        ).map(([itemCode, value], index) => {
                            return (
                                <Item key={index}>
                                    <>
                                        <ItemNameColor
                                            color={
                                                COLORS.PIE_CHART[
                                                    index %
                                                        COLORS.PIE_CHART.length
                                                ]
                                            }
                                        />
                                        {items[itemCode].name}
                                        <ItemPrices>
                                            <span>
                                                {Writer.FormatCurrency(
                                                    budgetItems[itemCode],
                                                )}
                                            </span>
                                            <span>
                                                {Writer.FormatCurrency(
                                                    actualItems[itemCode],
                                                )}
                                            </span>
                                            <span>
                                                {Writer.FormatCurrency(
                                                    (budgetItems[itemCode] -
                                                        actualItems[itemCode]) *
                                                        -1,
                                                )}
                                            </span>
                                        </ItemPrices>
                                    </>
                                </Item>
                            );
                        })}
                    </>
                )}
            </Items>

            <Totals>
                Total
                <ItemPrices>
                    <span>
                        {Writer.FormatCurrency(sumOfMonth(budgetItems))}
                    </span>
                    <span>
                        {Writer.FormatCurrency(sumOfMonth(actualItems))}
                    </span>
                    <span>
                        {Writer.FormatCurrency(
                            (sumOfMonth(budgetItems) -
                                sumOfMonth(actualItems)) *
                                -1,
                        )}
                    </span>
                </ItemPrices>
            </Totals>
        </Container>
    );
};
export default ItemsList;

const Container = styled.div`
    position: relative;
    > span {
        font-size: 12px;
        position: absolute;
        top: -15px;
        right: 0;
    }
`;
const Items = styled.ul`
    padding: 0;
    max-height: 450px;
    overflow-y: auto;
`;

const ToolTip = styled.div<{ x: number; y: number }>`
    position: absolute;
    top: ${props => props.y}px;
    left: ${props => props.x}px;
    background-color: ${props => props.theme.colors.DarkBlue};
    color: white;
    padding: 5px;
    border-radius: 5px;
    font-size: 12px;
    z-index: 1;
    border: 1px solid ${props => props.theme.colors.DarkBlue};
`;

const CatagoryNames = styled.div`
    text-transform: uppercase;
    width: 100%;
    display: flex;
`;

const Item = styled.li`
    width: 100%;
    display: flex;
    margin-top: 9px;
    align-items: center;
    border-radius: 15px;
    &:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
`;

const ItemNameColor = styled.span<{ color: string }>`
    display: inline-block;
    border-radius: 50%;
    width: 10px;
    height: 10px;
    margin-right: 10px;
    margin-left: 5px;
    background-color: ${props => props.color};
`;

const Totals = styled.div`
    width: 100%;
    display: flex;
    margin-top: 5px;
    padding-top: 4px;
    border-top: 1px solid ${theme.colors.DarkBlue};
    &:hover {
        background-color: rgba(0, 0, 0, 0.3);
    }
`;

const ItemPrices = styled.div`
    margin-left: auto;
    flex-basis: 40%;
    display: flex;
    justify-content: space-between;
    b,
    span {
        width: 30%;
        display: flex;
        justify-content: center;
    }
`;

const SwitchWords = styled.span`
    margin-right: 10px;
    letter-spacing: 3.2px;
`;

const SwitchMonths = styled.div`
    position: absolute;
    right: 35px;
    top: -30px;
    display: flex;
    align-items: center;
    height: 40px;

    color: ${({ theme }) => theme.colors.DarkBlue};
    text-transform: uppercase;
`;
