import UIErrorBoundary from "components/error-boundaries/SectionErrorBoundary";
import CustomersCoverPage from "components/reports/customers/CustomerCoverPage";
import CustomersOverview from "components/reports/customers/CustomerOverview";
import Footer from "components/reports/ReportsFooter/ReportsFooter";
import { useEffect } from "react";

const MyCustomersPage = () => {
    useEffect(() => {
        document.title = "My Customers";
    }, []);
    return (
        <>
            <UIErrorBoundary>
                <CustomersCoverPage />
                <CustomersOverview />
            </UIErrorBoundary>
            <Footer />
        </>
    );
};

export default MyCustomersPage;
