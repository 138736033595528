import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { v4 as uuidv4 } from "uuid";

export interface NotificationContent {
    message: string;
    type: "success" | "error" | "info";
    persist?: boolean;
    onClick?: () => void;
}
interface NotificationObject {
    [id: string]: NotificationContent;
}

export const notifications: NotificationObject = {};
export const persistNotifications: NotificationObject = {};

export interface NotificationState {
    notificationsIds: string[];
    persistantNotificationsIds: string[];
}

const initialState: NotificationState = {
    notificationsIds: [],
    persistantNotificationsIds: [],
};

const NotificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: {
        addNotification: (
            state,
            action: PayloadAction<NotificationContent>,
        ) => {
            const id = uuidv4();
            notifications[id] = action.payload;
            state.notificationsIds.push(id);
        },
        removeNotification: (state, action: PayloadAction<string>) => {
            const item = state.notificationsIds.find(
                item => item === action.payload,
            );
            if (item && notifications[item].persist) {
                state.persistantNotificationsIds.push(item);
                persistNotifications[item] = notifications[item];
            }
            delete notifications[action.payload];
            state.notificationsIds = state.notificationsIds.filter(
                item => item !== action.payload,
            );
        },
        fullRemoveNotification: (state, action: PayloadAction<string>) => {
            delete notifications[action.payload];
            state.notificationsIds = state.notificationsIds.filter(
                item => item !== action.payload,
            );
        },
        removePersistantNotification: (
            state,
            action: PayloadAction<string>,
        ) => {
            delete persistNotifications[action.payload];
            state.persistantNotificationsIds =
                state.persistantNotificationsIds.filter(
                    item => item !== action.payload,
                );
        },
    },
});

export const {
    addNotification,
    removeNotification,
    removePersistantNotification,
    fullRemoveNotification,
} = NotificationSlice.actions;

export default NotificationSlice.reducer;
