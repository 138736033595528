import styled from "@emotion/styled";
import {
    EmailAuthProvider,
    reauthenticateWithCredential,
    updatePassword,
} from "firebase/auth";
import { auth } from "lib/firebase";
import { useState } from "react";

const Password = () => {
    const [newPassword, setNewPassword] = useState("");
    const [oldPassword, setOldPassword] = useState("");
    const [res, setRes] = useState("Please provide a password");
    const [loading, setLoading] = useState(false);

    return (
        <Container>
            <h2>Change Password</h2>
            <Inputs>
                <label>Current Password</label>
                <input
                    onChange={e => setOldPassword(e.target.value)}
                    type='password'
                />
                {res === "Incorrect Password" ||
                res === "Please provide your current password" ? (
                    <BadRequest>{res}</BadRequest>
                ) : null}
                <label>New Password</label>
                <input
                    onChange={e => setNewPassword(e.target.value)}
                    type='password'
                />
                {res === "Password successfully updated" && (
                    <Success>{res}</Success>
                )}

                {res === "Please provide a new password" ? (
                    <BadRequest>{res}</BadRequest>
                ) : null}
                <button
                    onClick={() =>
                        validateAndUpdatePassword(
                            oldPassword,
                            newPassword,
                            setLoading,
                            setRes,
                        )
                    }>
                    Update Password
                </button>
            </Inputs>
        </Container>
    );
};

export default Password;

const validateAndUpdatePassword = async (
    oldPassword: string,
    newPassword: string,
    loading: (loading: boolean) => void,
    res: (incorrectPassword: string) => void,
) => {
    if (auth.currentUser && auth.currentUser.email) {
        loading(true);
        const credential = EmailAuthProvider.credential(
            auth.currentUser.email,
            oldPassword,
        );
        if (!oldPassword) {
            res("Please provide your current password");
            loading(false);
            return;
        }
        if (!newPassword) {
            res("Please provide a new password");
            loading(false);
            return;
        }
        try {
            await reauthenticateWithCredential(auth.currentUser, credential);
            await updatePassword(auth.currentUser, newPassword);
            res("Password successfully updated");
        } catch (error: any) {
            if (error.code === "auth/wrong-password") {
                res("Incorrect Password");
            }
            if (error.code === "auth/internal-error") {
                res("Please provide a password");
            }
        }
        loading(false);
    }
};

const Success = styled.div`
    width: 205px;
    font-size: 14px;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    background-image: linear-gradient(to right, #d0ffbb, #8cff57);
`;

const BadRequest = styled.div`
    width: 205px;
    font-size: 14px;
    border: 1px solid #000000;
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 3px;
    background-image: linear-gradient(to right, #ffbbbb, #ff5757);
`;

const Inputs = styled.div`
    display: flex;
    flex-direction: column;
    input {
        width: 205px;
        margin-top: 7px;
    }
    label {
        margin-top: 7px;
    }
    button {
        width: 105px;
        margin: 10px;
        background-color: #4a75e0;
        color: white;
        border-radius: 5px;
        &:hover {
            background-color: #4a75e0;
            color: black;
        }
    }
`;

const Container = styled.div`
    border: 1.8px solid black;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
`;
