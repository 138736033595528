import styled from "@emotion/styled";
import backgroundLine from "assets/img/backgrounds/styalised-graph-stretched.png";

export const StylisedGraph = styled.span`
    z-index: 0;
    position: absolute;
    bottom: 25px;
    left: 0;
    width: 100%;
    height: 83px;
    background-image: url(${backgroundLine});
    @extend .single-background-top-left;

    animation-name: animate-width;
    animation-duration: 20s;
    animation-iteration-count: infinite;
`;
