import { HttpClient } from "adapters/HttpClient";
import { signInCustomToken } from "lib/firebase";
import { useEffect } from "react";
import { useNavigate, useParams } from "react-router";
import { useAppDispatch } from "redux/store";

let isUpdating = false;

const HandleProviderLogin = () => {
    const { provider } = useParams();
    const navigate = useNavigate();

    const dispatch = useAppDispatch();

    const sendToServer = async () => {
        if (!isUpdating) {
            isUpdating = true;
            switch (provider) {
                case "quickbooks":
                    {
                        try {
                            document.body.style.cursor = "wait";
                            const loginResult = await HttpClient.post<{
                                error?: string;
                                firebaseToken?: string;
                                email: string
                            } | undefined>(
                                `/quickbooks/handle-login`,
                                undefined,
                                {
                                    ...window.location,
                                    receiveEmail: true,
                                },
                            );
                            document.body.style.cursor = "default";
                            if(loginResult?.error === "auth/email-already-exists") {
                                    alert(`The email tied to your Quickbooks (${loginResult.email}) has already been used to create an account. Please login with your previous method and then connect your quickbooks account.`);
                                    navigate("/login");
                            }
                            else if (loginResult?.firebaseToken) {
                                await signInCustomToken(loginResult.firebaseToken);
                                navigate("/");
                            }
                            else {
                                navigate("/login");
                            }                       
                        } catch (e) {
                            console.error(`ERROR: ${e}`);
                            isUpdating = false;
                        }
                    }
                    break;
                default:
                    break;
            }
        }
    };

    useEffect(() => {
        sendToServer();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return null;
};

export default HandleProviderLogin;
