import styled from "@emotion/styled";
import ItemSection from "components/general/itemSection/ItemSection";
import Bubble from "components/reports/Bubble";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { toMmmYy } from "lib/date/reStringifyMonth";
import {
    CartesianAxis,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { useAppSelector } from "redux/store";
import { theme } from "style/theme";
import { OrgData } from "types/OrganisationType";

import { ChartHeader } from "./Styled";

interface CustomerGrowthProps {
    customerName: string;
    customerInputs: OrgData;
}

const CustomerGrowth = ({
    customerInputs,
    customerName,
}: CustomerGrowthProps) => {
    const lastTwelveMonths = useAppSelector(
        state => state.dates?.lastTwelveMonths,
    );

    if (!lastTwelveMonths) return null;

    const customerGrowth = lastTwelveMonths.map(m => ({
        name: m,
        customerGrowth:
            ((customerInputs[m]?.actual -
                customerInputs[addMonthsTo(m, -1)]?.actual) /
                customerInputs[addMonthsTo(m, -1)]?.actual) *
            100,
    }));

    return (
        <ItemSection padding='0' light column>
            <ChartHeader>
            <h1>Here's your {customerName.toLowerCase()} growth</h1>
            <h2>
                Growth in {customerName.toLowerCase()} as a percentage of total {customerName.toLowerCase()}
            </h2>
            </ChartHeader>
            <ResponsiveContainer width='100%' height={300}>
                <LineChart
                    barCategoryGap={20}
                    data={customerGrowth}
                    layout='horizontal'
                    margin={{
                        top: 5,
                        right: 100,
                        left: 0,
                        bottom: 5,
                    }}>
                    <CartesianAxis axisLine={false} />
                    <YAxis
                        type='number'
                        style={{ fill: theme.colors.DarkBlue }}
                        // stroke={"none"}
                        tickFormatter={(value: number) =>
                            `${Math.round(value)}%`
                        }
                    />
                    <ReferenceLine
                        y={0}
                        label=''
                        stroke='red'
                        strokeDasharray='3 3'
                    />
                    <XAxis
                        type='category'
                        dataKey='name'
                        style={{ fill: theme.colors.DarkBlue }}
                        tick={tickMonthWrap}
                        // stroke={"none"}
                    />
                    <Tooltip
                        labelStyle={{
                            color: theme.colors.DarkBlue,
                        }}
                        labelFormatter={props => toMmmYy(props)}
                        formatter={value => `${Math.round(value as number)}%`}
                    />
                    <Line
                        strokeWidth={3}
                        type='monotone'
                        dataKey='customerGrowth'
                        name='Customer Growth'
                        stroke={theme.colors.Yellow}
                    />
                    <Legend />
                </LineChart>
            </ResponsiveContainer>
            <Row>
                <Bubble>
                    <h3>Average Customer Growth</h3>
                    <mark>
                        {(
                            customerGrowth.reduce((output, input) => {
                                return output + input.customerGrowth || 0;
                            }, 0) / 12
                        ).toFixed(1)}
                        %
                    </mark>
                    <span></span>
                </Bubble>
            </Row>
        </ItemSection>
    );
};

export default CustomerGrowth;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
`;
