import MonthKey from "common/types/monthKey";

const ShortMonth: { [key: string]: string } = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec", //Seems cleaner than worrying about date parsing
};
const LongMonth: { [key: string]: string } = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December",
};

declare global {
    interface String {
        /**
         * @returns  a month
         */
        toMonth(): Month;
    }
}

class Month {
    private month: MonthKey;

    constructor(month: MonthKey) {
        this.month = this.isRealMonth(month);
    }

    intoMonthKey(): MonthKey {
        return this.month;
    }

    generateMonthsKeysBackFromMonth(months: number): Month[] {
        const array = Array.from(Array(months).keys());
        return array.map(i => this.addMonthsTo(-months + i + 1));
    }

    generateMonthsKeysForwardFromMonth(months: number): Month[] {
        const array = Array.from(Array(months).keys());
        return array.map(i => this.addMonthsTo(i + 1));
    }

    addMonthsTo(add: number) {
        const yearMonth = this.month.split("-");
        const startYear = parseInt(yearMonth[0]);
        const startMonth = parseInt(yearMonth[1]);

        let y = startYear + Math.trunc(add / 12);
        let m = startMonth + (add % 12);
        if (m < 1) {
            y--;
            m += 12;
        }
        if (m > 12) {
            y++;
            m -= 12;
        }
        return new Month(`${y}-${m < 10 ? "0" + m : m}` as MonthKey);
    }

    /**
     * @returns  the date in format January
     */
    toTextMonth(): string {
        const parts = this.getMonthParts();
        return LongMonth[parts.monthPart];
    }

    toTextShortMonth(): string {
        const parts = this.getMonthParts();
        return ShortMonth[parts.monthPart];
    }

    /**
     * @returns the date in format Jan '20
     */
    toMmmYy(): string {
        const parts = this.getMonthParts();
        return `${ShortMonth[parts.monthPart]} '${parts.yearPart.substring(
            2,
            4,
        )}`;
    }
    /**
     * @returns the date in format Jan 2020
     */
    toMmmYyyy(): string {
        const parts = this.getMonthParts();
        return `${ShortMonth[parts.monthPart]} ${parts.yearPart}`;
    }

    /**
     * @returns date in format January 2020
     */
    toMmmmYyyy(): string {
        const parts = this.getMonthParts();
        return `${LongMonth[parts.monthPart]} ${parts.yearPart}`;
    }

    getMonthParts() {
        const parts = this.month.split("-");
        return {
            yearPart: parts[0],
            monthPart: parts[1],
        };
    }

    getValues = (month: MonthKey) => {
        const parts = month.split("-");
        return {
            yearValue: parseInt(parts[0]),
            monthValue: parseInt(parts[1]),
        };
    };

    /**
     *
     * @returns month as number i.e. 1
     */
    getMonth() {
        return parseInt(this.month.split("-")[1]);
    }

    /**
     *
     * @returns year as number i.e. 2020
     */
    getYear() {
        return parseInt(this.month.split("-")[0]);
    }

    private isRealMonth(month: MonthKey): MonthKey {
        const parts = month.split("-");
        if (parts[0].length !== 4 || parts[1].length !== 2) {
            throw new Error(`${month} in not a real month please change`);
        }

        return month;
    }
}

export default Month;
