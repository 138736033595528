import styled from "@emotion/styled";

interface switchProps {
    toggle: boolean;
    cbSwitch: (toggle: boolean) => void;
    width?: number;
    colorOption1?: string;
    colorOption2?: string;
    textOption1?: string;
    textOption2?: string;
    textColor?: string;
    border?: string;
    borderWideth?: number;
    styles?: any;
}

const Switch = ({
    toggle,
    cbSwitch,
    width = 80,
    colorOption1 = "white",
    colorOption2 = "#6ab04c",
    textOption1,
    textOption2,
    textColor = "black",
    border = colorOption1,
    borderWideth = 0,
    styles,
}: switchProps) => {
    return (
        <Container
            border={border}
            borderWideth={borderWideth}
            width={width}
            toggle={toggle}
            colorOption1={colorOption1}
            onClick={() => cbSwitch(!toggle)}
            colorOption2={colorOption2}
            style={styles}>
            <SliderContainer
                borderWideth={borderWideth}
                width={width}
                toggle={toggle}
                colorOption1={colorOption1}
                colorOption2={colorOption2}>
                <TextOption1 width={width} color={textColor}>
                    {textOption1}
                </TextOption1>
                <Slider
                    width={width}
                    toggle={toggle}
                    colorOption1={colorOption1}
                    colorOption2={colorOption2}
                />
                <TextOption2 width={width} color={textColor}>
                    {textOption2}
                </TextOption2>
            </SliderContainer>
        </Container>
    );
};

export default Switch;

const TextOption1 = styled.span<{ color: string; width: number }>`
    height: 20px;
    padding: 2px;
    color: ${props => props.color};
    width: ${props => props.width - 20}px;
    overflow: hidden;
    text-align: center;
`;
const TextOption2 = styled.span<{ color: string; width: number }>`
    height: 20px;
    padding: 2px;
    color: ${props => props.color};
    overflow: hidden;
    text-align: center;
    width: ${props => props.width - 20}px;
`;

const SliderContainer = styled.div<{
    width: number;
    toggle: boolean;
    colorOption1: string;
    colorOption2: string;
    borderWideth: number;
}>`
    // min-width: ${props => props.width}px;
    border-radius: 15px;
    display: flex;
    align-items: center;
    justify-content: center;
    transform: translateX(
        ${props =>
            props.toggle ? -(props.borderWideth + 3) : -props.width + 23}px
    );

    transition: 0.4s;
`;

const Slider = styled.span<{
    width: number;
    toggle: boolean;
    colorOption1: string;
    colorOption2: string;
}>`
    display: flex;

    width: 20px;
    height: 20px;
    border-radius: 100%;

    background-color: ${props =>
        props.toggle ? props.colorOption2 : props.colorOption1};
`;

const Container = styled.div<{
    width: number;
    toggle: boolean;
    colorOption1: string;
    colorOption2: string;
    border: string;
    borderWideth: number;
}>`
    overflow: hidden;
    position: relative;
    width: ${props => props.width}px;
    height: 24px;
    display: flex;
    align-items: center;
    border-radius: 15px;
    border: ${props => props.border} ${props => props.borderWideth}px solid;
    cursor: pointer;
    background-color: ${props =>
        props.toggle ? props.colorOption1 : props.colorOption2};
`;
