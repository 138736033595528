import styled from "@emotion/styled";
import { HttpClient } from "adapters/HttpClient";
import {
    deleteUser,
    EmailAuthProvider,
    reauthenticateWithCredential,
    User,
} from "firebase/auth";
import { auth } from "lib/firebase";
import { useEffect, useState } from "react";

const DeleteAccount = () => {
    const [showDeleteModal, setShowDeleteModal] = useState(false);
    const [invalidPassword, setInvalidPassword] = useState(false);
    const [password, setPassword] = useState("");

    useEffect(() => {
        if (showDeleteModal) {
            window.addEventListener("keydown", e => {
                if (e.key === "Escape") {
                    setShowDeleteModal(false);
                }
            });
        } else {
            window.removeEventListener("keydown", e => {
                if (e.key === "Escape") {
                    setShowDeleteModal(false);
                }
            });
        }
    }, [showDeleteModal]);

    const testPass = () => {
        if (auth.currentUser && auth.currentUser.email) {
            const credential = EmailAuthProvider.credential(
                auth.currentUser.email,
                password,
            );
            reauthenticateWithCredential(auth.currentUser, credential)
                .then(async () => {
                    if (auth.currentUser) {
                        await deleteAccount(auth.currentUser);
                    } else {
                        throw new Error("User not found");
                    }
                })
                .catch(error => {
                    if (error.code === "auth/wrong-password") {
                        setInvalidPassword(true);
                    }
                    console.error("reauthenticateWithCredential error:", error);
                });
        }
    };

    return (
        <Container>
            <DeleteAccountButton>
                <button onClick={() => setShowDeleteModal(true)}>
                    Delete Account
                </button>
            </DeleteAccountButton>
            {showDeleteModal && (
                <DeleteModal>
                    <Exit onClick={() => setShowDeleteModal(false)}>X</Exit>
                    <div>
                        <label>
                            <b>
                                Are you sure you want to delete your account?
                                All data associated with this account will be
                                permanently deleted and cannot be recovered.
                            </b>
                        </label>
                        <input
                            type='password'
                            placeholder='password'
                            onChange={e => setPassword(e.target.value)}
                        />
                        {invalidPassword && (
                            <BadPassword>Invalid password</BadPassword>
                        )}
                        <SaveButton onClick={testPass}>
                            Delete Account
                        </SaveButton>
                    </div>
                </DeleteModal>
            )}
        </Container>
    );
};

export default DeleteAccount;
/**
 * delete users account and all data
 * @param user
 */
const deleteAccount = async (user: User) => {
    try {
        await deleteUser(user);
        console.log("deleted account");
    } catch (error) {
        console.error("delete account error:", error);
    }
};
const BadPassword = styled.span`
    width: 147px;
    background-color: #ff000065;
    text-align: center;
`;
const SaveButton = styled.button`
    background-color: #e04a4a;
    color: white;
    border-radius: 5px;
    &:hover {
        background-color: #e04a4a;
        color: black;
    }
`;
const DeleteModal = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    div {
        position: relative;
        width: 400px;
        height: 200px;
        border-radius: 15px;
        background-color: #fff;
        border: 1.8px solid #000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button {
            margin-top: 10px;
        }
    }
    label {
        text-align: center;
        font-size: 13px;
        margin-bottom: 10px;
    }
`;

const DeleteAccountButton = styled.div`
    button {
        background-color: #e04a4a;
        color: white;
        border-radius: 5px;
        &:hover {
            background-color: #e04a4a;
            color: black;
        }
    }
`;
const Container = styled.div`
    margin-top: auto;
    margin-left: auto;
`;
const Exit = styled.span`
    position: absolute;
    top: 0;
    right: 0;
    margin: 20px;
    color: #fff;
    font-size: 30px;
    cursor: pointer;
`;
