import styled from "@emotion/styled";
import ItemSection from "components/general/itemSection/ItemSection";
import EditTransfers from "components/reports/cashflow/EditTransfers";
import ExpandButton from "components/ui/ExpandButton";
import { useState } from "react";

const BudgetTransfers = () => {
    const [editorOpen, setEditorOpen] = useState(false);
    return (
        <ItemSection light padding='0 48px 48px 48px'>
            <Container>
                <h1 style={{ fontSize: 18, marginTop: 20 }}>
                    Will the next 12 months look like the last 12 months?
                </h1>
                <p>
                    Expand the section below to budget your balance sheet for
                    the year ahead.
                </p>
                <div>
                    <ExpandButton
                        title='Budget your cashflow'
                        onClick={() => setEditorOpen(!editorOpen)}
                    />
                    {editorOpen ? <EditTransfers /> : ""}
                </div>
            </Container>
        </ItemSection>
    );
};

export default BudgetTransfers;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    h1 {
        font-size: 24px;
        font-weight: bold;
        margin: 0;
    }
`;
