import styled from "@emotion/styled";
import { Writer } from "common/Writer";
import useReport from "hooks/reports/useReport";
import { sumOfMonth } from "lib/apiDataHelpers/sumOfMonths";

import Bubble from "../../Bubble";
import BudgetBox from "../../shared/budgetBox";

const ExpensesDetailBudget = () => {
    const report = useReport();

    if (!report) return null;

    const {
        expenses: { actual, budget },
        reportDates: { selectedMonth },
        items,
    } = report;

    const selectedMonthTotal = sumOfMonth(actual[selectedMonth]);

    const selectedMonthBudget = sumOfMonth(budget[selectedMonth]);

    const selectedMonthAgainstBudget = selectedMonthTotal - selectedMonthBudget;

    return (
        <Container>
            <Top>
                <h1>
                    Here's your expenses vs your budget for{" "}
                    {selectedMonth.selectByMonths(
                        selectedMonth.toTextMonth(),
                        selectedMonth.toTextMonth(),
                        selectedMonth.toMmmmYyyy(),
                    )}
                </h1>

                <Bubble>
                    <h3>
                        Overall in {selectedMonth?.toTextMonth()}, you spent:
                    </h3>
                    <mark>
                        {Writer.FormatCurrency(
                            Math.abs(selectedMonthAgainstBudget),
                        )}
                    </mark>
                    <span>
                        {Writer.DescribeChange(
                            selectedMonthAgainstBudget,
                            "MORE than you",
                            "LESS than you",
                            "over/under",
                        )}{" "}
                        budgeted
                    </span>
                </Bubble>
            </Top>
            <Bottom>
                <p>
                    With this graph you can see a breakdown of each expense for
                    the month of {selectedMonth?.toTextMonth()}.
                </p>
                <BudgetBox budget={budget} actual={actual} items={items} />
            </Bottom>
        </Container>
    );
};

export default ExpensesDetailBudget;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Top = styled.div`
    width: 40%;
    display: flex;
    flex-direction: column;
    align-items: center;
    max-width: ${({ theme }) => theme.sizes.modelWidth};
    width: 100%;
`;

const Bottom = styled.div`
    width: 100%;
    max-width: ${({ theme }) => theme.sizes.modelWidth};
    margin-top: 30px;
    p {
        margin: 20px auto;
        text-align: center;
    }
`;
