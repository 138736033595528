import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import MonthKey from "common/types/monthKey";
import { OrgData } from "types/OrganisationType";

export interface TransfersState {
    transfers?: OrgData;
}

const initialState: TransfersState = {};

export const TransfersSlice = createSlice({
    name: "customers",
    initialState,
    reducers: {
        incrementTransfers: (
            state,
            action: PayloadAction<{
                month: MonthKey;
                item: string;
            }>,
        ) => {
            state.transfers ??= {};
            state.transfers[action.payload.month] ??= {};
            state.transfers[action.payload.month][action.payload.item] ??= 0;

            state.transfers[action.payload.month][action.payload.item] += 1;
        },
        decrementTransfers: (
            state,
            action: PayloadAction<{
                month: MonthKey;
                item: string;
            }>,
        ) => {
            state.transfers ??= {};
            state.transfers[action.payload.month] ??= {};
            state.transfers[action.payload.month][action.payload.item] ??= 0;

            state.transfers[action.payload.month][action.payload.item] -= 1;
        },
        setTransfers: (
            state,
            action: PayloadAction<{
                month: MonthKey;
                item: string;
                input?: number;
            }>,
        ) => {
            state.transfers ??= {};
            state.transfers[action.payload.month] ??= {};
            state.transfers[action.payload.month][action.payload.item] ??= 0;
            if (!action.payload.input)
                delete state.transfers[action.payload.month][
                    action.payload.item
                ];
            else
                state.transfers[action.payload.month][action.payload.item] =
                    action.payload.input;
        },
        setAllTransfers: (state, action: PayloadAction<OrgData>) => {
            state.transfers = action.payload;
        },
    },
});

export const {
    setTransfers,
    setAllTransfers,
    incrementTransfers,
    decrementTransfers,
} = TransfersSlice.actions;

export default TransfersSlice.reducer;
