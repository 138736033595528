import styled from "@emotion/styled";
import { ReactComponent as XSVG } from "assets/general/X.svg";
import MonthKey from "common/types/monthKey";
import useModal from "components/general/CoverPage/Modal/useModal";
import useReport from "hooks/reports/useReport";
import { yearEndedLastCompleteMonth } from "lib/date/dateConst";
import { useState } from "react";
import { setBudget } from "redux/slices/BudgetSlice";
import { useAppDispatch } from "redux/store";

const FutureFillRevExp = () => {
    const data = useReport();
    const [overwrite, setOverwrite] = useState(false);
    const { Modal, show, setClose, setOpen } = useModal();
    const dispatch = useAppDispatch();

    const futureFill = (months: MonthKey[], force?: boolean) => {
        console.log("future fill");
        if (data) {
            months.forEach(month => {
                const lastYearRev = data.revenue.actual[month] ?? {};
                Object.entries(lastYearRev).forEach(([code, value]) => {
                    if (
                        (data.revenue.budget[month] &&
                            data.revenue.budget[month][code] === undefined) ||
                        force
                    ) {
                        dispatch(
                            setBudget({
                                stateItem: "revenue",
                                month: month.addMonthsTo(12),
                                item: code,
                                input: value,
                            }),
                        );
                    }
                });

                const lastYearExp = data.expenses.actual[month] ?? {};
                Object.entries(lastYearExp).forEach(([code, value]) => {
                    if (
                        (data.expenses.budget[month] &&
                            data.expenses.budget[month][code] === undefined) ||
                        force
                    ) {
                        dispatch(
                            setBudget({
                                stateItem: "expenses",
                                month: month.addMonthsTo(12),
                                item: code,
                                input: value,
                            }),
                        );
                    }
                });
            });
        }
        setClose();
    };
    return (
        <>
            <AutoFill onClick={setOpen}>AutoFill</AutoFill>
            <Modal>
                <ModalContent>
                    <ExitSVg onClick={setClose} />
                    <div style={{ textAlign: "center" }}>
                        Fill next twelve months with last 12 months data.
                        <br />
                        <br />
                    </div>
                    <span>
                        overwrite existing budget:
                        <input
                            type='checkbox'
                            onChange={e => setOverwrite(e.target.checked)}
                        />
                    </span>
                    <FillFuture
                        onClick={() =>
                            futureFill(yearEndedLastCompleteMonth, overwrite)
                        }>
                        Fill future
                    </FillFuture>
                </ModalContent>
            </Modal>
        </>
    );
};

export default FutureFillRevExp;

const ExitSVg = styled(XSVG)`
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:hover {
        fill: ${props => props.theme.colors.DarkPink};
    }
`;

const AutoFill = styled.div`
    margin: 10px 0 0 10px;
    padding: 10px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #e6e6e6;
    }
`;

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 350px;
    background-color: ${props => props.theme.colors.Pink};
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    overflow: hidden;
`;

const FillFuture = styled.div`
    margin: 10px 0 0 10px;
    padding: 10px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #e6e6e6;
    }
`;
