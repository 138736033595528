import { QueryClient } from "react-query";

const APIQueryClient = new QueryClient({
    defaultOptions: {
        queries: {
            refetchOnWindowFocus: false,
            cacheTime: 1000 * 60 * 60,
            staleTime: 1000 * 60 * 60,
        },
    },
});

export default APIQueryClient;

// const localStoragePersistor = createWebStoragePersistor({
//     storage: window.localStorage,
// });

// persistQueryClient({
//     queryClient: APIQueryClient,

//     persistor: localStoragePersistor,
// });
