import { HttpClient } from "adapters/HttpClient";
import { MouseEvent } from "react";

/**
 * Helper for initating xero connect from user action or code
 * @param e the interaction event if applicible
 * @param isAuthenticated if the user is not logged in we initiate the signup with xero flow
 */
const initiateXeroConnect = (e: MouseEvent<SVGElement> | null) => {
    //prevent multiple clicks on the svg
    if (e) {
        const target = e.currentTarget as SVGSVGElement;
        target.style.pointerEvents = "none";
    }

    HttpClient.get<string>("xero/connect-data-url")
        .then(url => {
            window.location.href = url;
        })
        .catch(err => alert(err.message));
};

export default initiateXeroConnect;
