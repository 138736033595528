export const GA_TRACKING_ID = process.env.REACT_APP_ANALYTICS as string;

// https://developers.google.com/analytics/devguides/collection/gtagjs/pages
export const pageview = (url: URL) => {
    window.gtag("config", GA_TRACKING_ID, {
        page_path: url,
    });
};

interface GTagEvent {
    action: string;
    category: string;
    label: string;
    value: number;
}

// https://developers.google.com/analytics/devguides/collection/gtagjs/events
export const event = ({ action, category, label, value }: GTagEvent) => {
    window.gtag("event", action, {
        event_category: category,
        event_label: label,
        value: value,
    });
};

export const createScript1 = () => {
    const script = document.createElement("script");

    script.src = `https://www.googletagmanager.com/gtag/js?id=${GA_TRACKING_ID}`;
    script.async = true;

    document.body.appendChild(script);
};
export const createScript2 = () => {
    const script = document.createElement("script");

    script.innerHTML = `window.dataLayer = window.dataLayer || [];
    function gtag(){window.dataLayer.push(arguments);}
    gtag('js', new Date());
  
    gtag('config', '${GA_TRACKING_ID}');
  `;
    script.async = true;

    document.body.appendChild(script);
};
