import { ORGANIZATION_TAG, TRANSFER_TAG } from "common/constants/TAGS";
import { setAllTransfers } from "redux/slices/TransfersSlice";
import { OrgData } from "types/OrganisationType";

import { api } from "./api";

const transferSlice = api.injectEndpoints({
    endpoints: builder => ({
        getTransfers: builder.query<OrgData, string>({
            query: organisationId => ({
                url: `transfer-inputs`,
                params: {
                    organisationId,
                },
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setAllTransfers(data));
                } catch (error) {
                    dispatch(setAllTransfers({}));
                }
            },
            providesTags: (result, error, organisationId) => [
                { type: TRANSFER_TAG, id: organisationId },
                ORGANIZATION_TAG,
            ],
        }),
        updateTransfers: builder.mutation<void, [string, OrgData]>({
            query: ([organisationId, data]) => ({
                url: `transfer-inputs`,
                method: "PUT",
                params: {
                    organisationId,
                },
                data,
            }),
            onQueryStarted: async (
                [organisationId],
                { dispatch, queryFulfilled },
            ) => {
                // if failed will try refetching the data from the server
                // to make sure the data is synced
                try {
                    await queryFulfilled;
                } catch (error) {
                    api.util.invalidateTags([
                        { type: TRANSFER_TAG, id: organisationId },
                    ]);
                }
            },
        }),
    }),
});

export default transferSlice;

export const { useGetTransfersQuery, useUpdateTransfersMutation } =
    transferSlice;
