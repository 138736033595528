import styled from "@emotion/styled";
import ItemSection from "components/general/itemSection/ItemSection";
import ReportsModal from "components/navigation/expandableSection/ReportsModal";
import DoughnutChart from "components/recharts/DoughnutChart";
import useReport from "hooks/reports/useReport";

import ExpensesDetailIncomeBreakdown from "./ModelIncomeBreakdown";
import DescribeMonthItems from "../../shared/DescribeMonthItems";

const ExpensesSectionIncomeBreakdown = () => {
    const report = useReport();

    if (!report) return null;

    const {
        expenses: { actual },
        reportDates: { selectedMonth },
    } = report;

    return (
        <ItemSection id='month-breakdown' minHeight={505}>
            <LeftBox>
                <DescribeMonthItems
                    organisation={report.organisation}
                    data={actual}
                    dataName='expenses'
                />
            </LeftBox>
            <RightBox>
                <h1>
                    Total expenses for {report && selectedMonth?.toTextMonth()}
                </h1>
                {report && (
                    <Chart>
                        <DoughnutChart actual={actual} month={selectedMonth} />
                    </Chart>
                )}
            </RightBox>

            <ReportsModal path='breakdown-model'>
                <ExpensesDetailIncomeBreakdown />
            </ReportsModal>
        </ItemSection>
    );
};

export default ExpensesSectionIncomeBreakdown;
const LeftBox = styled.div``;
const RightBox = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0 20px;
`;
const Chart = styled.div`
    width: 350px;
    height: 350px;
    margin: 0 auto;
`;
