import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { HttpClient } from "adapters/HttpClient";
import { useEffect, useState } from "react";
import { useGetNotificationSettingsQuery } from "redux/rtkQuery/notifications";

const Notifications = () => {
    const subscribedTo = useGetNotificationSettingsQuery();
    const [monthlyUpdate, setMonthlyUpdate] = useState<NotificationOptions>(-1);
    useEffect(() => {
        if (subscribedTo.data) {
            setMonthlyUpdate(
                subscribedTo.data.monthlyUpdate
                    ? NotificationOptions.Email
                    : NotificationOptions.False,
            );
        }
    }, [subscribedTo.data]);

    return (
        <Container>
            Notifications
            <NotificationSettings>
                <h3>Subscibe to monthly update email</h3>
                <p>
                    This is user and does not include organisations settings. if
                    this is off you won't get any notifications
                </p>
                <NotificationsOptions>
                    <Option>
                        <span>Email</span>
                        <NotificationsButton
                            backgroundColor={
                                monthlyUpdate === NotificationOptions.Email
                                    ? "black"
                                    : "white"
                            }
                            onClick={() => {
                                if (
                                    monthlyUpdate !== NotificationOptions.Email
                                ) {
                                    setMonthlyUpdate(NotificationOptions.Email);
                                    changeSupscription(
                                        "monthlyUpdate",
                                        NotificationOptions.Email,
                                    );
                                }
                            }}>
                            <NotificationSelect
                                backgroundColor={
                                    monthlyUpdate === NotificationOptions.Email
                                        ? "black"
                                        : "white"
                                }
                            />
                        </NotificationsButton>
                    </Option>
                    <Option>
                        <span>Unsubscribed</span>
                        <NotificationsButton
                            backgroundColor={
                                monthlyUpdate === NotificationOptions.False
                                    ? "black"
                                    : "white"
                            }
                            onClick={() => {
                                if (
                                    monthlyUpdate !== NotificationOptions.False
                                ) {
                                    setMonthlyUpdate(NotificationOptions.False);
                                    changeSupscription(
                                        "monthlyUpdate",
                                        NotificationOptions.False,
                                    );
                                }
                            }}>
                            <NotificationSelect
                                backgroundColor={
                                    monthlyUpdate === NotificationOptions.False
                                        ? "black"
                                        : "white"
                                }
                            />
                        </NotificationsButton>
                    </Option>
                </NotificationsOptions>
                {/* <button onClick={subscribeTo}>Send</button> */}
            </NotificationSettings>
        </Container>
    );
};

export default Notifications;

const NotificationsOptions = styled.div`
    display: flex;
    justify-content: space-between;
    width: 150px;
`;

const NotificationSelect = styled.div<{ backgroundColor: string }>`
    background-color: ${props => props.backgroundColor};
    height: 100%;
    width: 100%;
`;

const NotificationsButton = styled.button<{ backgroundColor: string }>`
    width: 10px;
    height: 10px;
    padding: 2px;
    transform: rotate(45deg);
    background-color: #f5f5f5;
    border: 1px solid ${props => props.theme.colors.DarkBlue};
    &:hover {
        background-color: #f5f5f5;
        div {
            ${props =>
                props.backgroundColor === "white" &&
                css`
                    background-color: #00000055;
                `}
        }
    }
`;

const Option = styled.div`
    display: flex;
    flex-direction: column;
    height: 40px;
    align-items: center;
    justify-content: space-evenly;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    border: 1.8px solid black;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
`;
const NotificationSettings = styled.div``;

type NotificationTypes = "monthlyUpdate";
export enum NotificationOptions {
    False = 0,
    Email = 1,
    SMS = 2,
    Push = 3,
    EmailAndSMS = 4,
    EmailAndPush = 5,
    SMSAndPush = 6,
    EmailAndSMSAndPush = 7,
}

const changeSupscription = async (
    notification: NotificationTypes,
    NotificationOptions: NotificationOptions,
) => {
    await HttpClient.put("/notification/change-subscribed", {
        subscribeTo: notification,
        NotificationOptions,
    });
};
