import styled from "@emotion/styled";
import { ReactComponent as XSVG } from "assets/general/X.svg";
import useModal from "components/general/CoverPage/Modal/useModal";
import useReport from "hooks/reports/useReport";
import { addMonthsTo } from "lib/date/addMonthsTo";
import {
    generateMonthsKeysForwardFromMonth,
    latestCompleteMonth,
} from "lib/date/dateConst";
import { toMmmYy } from "lib/date/reStringifyMonth";
import { useState } from "react";
import { CSVLink } from "react-csv";
import { useAppDispatch } from "redux/store";
import { ItemType } from "types/OrganisationType";

const ExportXeroBudget = () => {
    const { Modal, show, setClose, setOpen } = useModal();
    const dispatch = useAppDispatch();
    const [numberOfMonths, setNumberOfMonths] = useState<number>();
    const data = useReport();

    const months =
        numberOfMonths &&
        generateMonthsKeysForwardFromMonth(
            addMonthsTo(latestCompleteMonth, 1),
            numberOfMonths,
        );

    const reveneueItems =
        data &&
        Object.entries(data.items).filter(
            ([code, item]) => item.itemType === ItemType.revenue,
        );
    const expensesItems =
        data &&
        Object.entries(data.items).filter(
            ([code, item]) => item.itemType === ItemType.expense,
        );

    const csvData = months &&
        expensesItems &&
        reveneueItems &&
        data && [
            ["*Account", ...months],
            ...reveneueItems.map(([number, name]) => {
                return [
                    `${name.name} (${number})`,
                    ...months.map(month => {
                        const backmonth = addMonthsTo(month, -12);
                        return data.revenue.actual[backmonth]?.[number] || 0;
                    }),
                ];
            }),
            ...expensesItems.map(([number, name]) => {
                return [
                    `${name.name} (${number})`,
                    ...months.map(month => {
                        const backmonth = addMonthsTo(month, -12);
                        return data.expenses.actual[backmonth]?.[number] || 0;
                    }),
                ];
            }),
        ];
    return (
        <>
            <Export onClick={setOpen}>Export budget</Export>
            <Modal>
                <ModalContent>
                    <ExitSVg onClick={setClose} />
                    <CSVDownload>
                        <span>Download Budget to import into xero.</span>
                        <div>
                            Select number of months starting from current month
                        </div>
                        <input
                            onChange={e => setNumberOfMonths(+e.target.value)}
                        />
                        {numberOfMonths && (
                            <div>
                                You have selected <br />
                                {`${toMmmYy(
                                    addMonthsTo(latestCompleteMonth, 1),
                                )} - ${toMmmYy(
                                    addMonthsTo(
                                        latestCompleteMonth,
                                        numberOfMonths,
                                    ),
                                )}`}
                            </div>
                        )}
                        {csvData && (
                            <CSVLink
                                filename={`${latestCompleteMonth}_to_${addMonthsTo(
                                    latestCompleteMonth,
                                    12,
                                )}_budget.csv`}
                                data={csvData}>
                                Download
                            </CSVLink>
                        )}
                    </CSVDownload>
                </ModalContent>
            </Modal>
        </>
    );
};

export default ExportXeroBudget;

const CSVDownload = styled.div`
    font-size: 13px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
    a {
        background-color: #f5f5f5;
        padding: 8px;
        border-radius: 4px;
        text-decoration: none;
        color: ${props => props.theme.colors.DarkBlue};
        font-size: 14px;
        font-weight: bold;
        &:hover {
            text-decoration: underline;
            background-color: ${props => props.theme.colors.LightBlue};
            color: ${props => props.theme.colors.Pink};
        }
        &:active {
            text-decoration: underline;
        }
        &:visited {
            text-decoration: underline;
        }
    }
`;
const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 350px;
    background-color: ${props => props.theme.colors.Pink};
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    overflow: hidden;
`;
const Export = styled.div`
    margin: 10px 0 0 10px;
    padding: 10px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: ${props => props.theme.colors.LightBlue};
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #e6e6e6;
    }
`;
const ExitSVg = styled(XSVG)`
    position: absolute;
    top: 15px;
    right: 15px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:hover {
        fill: ${props => props.theme.colors.DarkPink};
    }
`;
