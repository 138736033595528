import { css } from "@emotion/react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import styled from "@emotion/styled";
import { ReactComponent as OpenSVG } from "assets/img/icons/popout-hover.svg";
import { Portal } from "components/general/portal";
import { useOutsideAlerter } from "components/inputs/OutsideAlerter";
import { useEffect, useRef, useState } from "react";
import { Route, Routes, useLocation, useNavigate } from "react-router";
import { NavLink } from "react-router-dom";

interface Props {
    path: string;
    light?: boolean;
    children: EmotionJSX.Element;
}

const Element = ({ children }: { children: EmotionJSX.Element }) => {
    const [exit, setExit] = useState(false);
    const location = useLocation();
    const navigate = useNavigate();
    const wrapperRef = useRef(null);
    const pathname = location.pathname.split("/").slice(0, -1).join("/");
    const handleClose = () => {
        setExit(true);
        setTimeout(() => navigate(pathname), 500);
    };
    useOutsideAlerter(wrapperRef, handleClose);
    useEffect(() => {
        document.body.style.overflow = "hidden";
        (
            document.getElementById("portal") as unknown as HTMLElement
        ).style.zIndex = "10";
        window.addEventListener("keydown", e => {
            if (e.key === "Escape") {
                handleClose();
            }
        });

        return () => {
            document.body.style.overflow = "auto";
            window.removeEventListener("keydown", e => {
                if (e.key === "Escape") {
                    handleClose();
                }
            });
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Portal>
            <Container>
                <Content exit={exit} ref={wrapperRef}>
                    <CloseButton onClick={handleClose} title='Close window'>
                        X
                    </CloseButton>
                    <div>{children}</div>
                </Content>
            </Container>
        </Portal>
    );
};

const ReportsModal = ({ path, children, light }: Props) => {
    return (
        <>
            <ExpandContent
                light={light === undefined ? light : light.toString()}
                to={path}>
                <OpenSVG />
                <span>more</span>
            </ExpandContent>
            <Routes>
                <Route path={path} element={<Element>{children}</Element>} />
            </Routes>
        </>
    );
};

export default ReportsModal;

const CloseButton = styled.button`
    display: flex;
    justify-content: center;
    align-items: center;

    position: absolute;
    right: 10px;
    top: 10px;
    border-radius: 50%;
    font-size: 15px;
    line-height: 12px;
    width: 25px;
    height: 25px;
    padding: 9px;
    z-index: 99999;
    margin: 20px;

    background-color: ${props => props.theme.colors.DarkBlue};
    border: 0;
    color: ${props => props.theme.colors.textLight};
    text-decoration: none;
    cursor: pointer;
    font-weight: 700;

    &:hover {
        background-color: ${props => props.theme.colors.Yellow};
        color: ${props => props.theme.colors.DarkBlue};
    }
`;

const Container = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background-color: rgba(0, 0, 0, 0.5);
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 20px;
`;

const Content = styled.div<{ exit: boolean }>`
    background-color: ${props => props.theme.colors.Pink};
    position: relative;
    height: 100%;
    width: 100%;
    border-radius: 10px;
    box-shadow: 2px 2px 20px 5px #888888;
    animation: expand-box 1s;
    animation-fill-mode: forwards;

    > div {
        padding-top: 30px;
        position: relative;
        width: 100%;
        height: 100%;
        animation: fadein 1s;
        animation-fill-mode: forwards;
        overflow-y: auto;
        overflow-x: hidden;
        /* firefox */
        scrollbar-width: thin;
        scrollbar-color: ${({ theme }) => theme.colors.DarkBlue} transparent;
        /* webkit browsers */
        &::-webkit-scrollbar {
            width: 0.3vw;
        }

        &::-webkit-scrollbar-thumb {
            background-color: ${({ theme }) => theme.colors.DarkBlue};
        }
        &::-webkit-scrollbar-track {
            background-color: transparent;
        }
    }

    @keyframes fadein {
        from {
            opacity: 0;
        }
        75% {
            opacity: 0;
        }
        to {
            opacity: 1;
        }
    }

    @keyframes expand-box {
        from {
            max-height: 0;
            margin-left: 0;
            overflow: hidden;
        }
        to {
            overflow: visible;
            overflow-y: auto;
            max-height: 95vh;
        }
    }

    ${({ exit }) =>
        exit &&
        css`
            animation: fadeout 0.5s;
            animation-fill-mode: forwards;
        `}

    @keyframes fadeout {
        from {
            overflow: visible;
            overflow-y: auto;
            max-height: 95vh;
        }
        to {
            max-height: 0;
            margin-left: 0;
            overflow: hidden;
        }
    }
`;

const ExpandContent = styled(NavLink)<{ light?: string }>`
    position: absolute;
    bottom: 5px;
    left: 5px;
    display: flex;
    align-items: center;
    text-transform: uppercase;
    text-decoration: none;
    color: inherit;
    cursor: pointer;
    fill: ${props =>
        props.light ? props.theme.colors.DarkBlue : props.theme.colors.Pink};

    background-color: ${props => props.theme.colors.DarkPink};
    padding: 5px 15px 5px 15px;
    border-radius: 5px;

    svg {
        width: 25px;
        height: auto;
        margin-right: 5px;
    }
    &:visited,
    &:active {
        color: inherit;
    }
    &:hover {
        fill: ${({ theme, light }) =>
            light ? theme.colors.Yellow : theme.colors.DarkBlue};
        color: ${({ theme, light }) =>
            light ? theme.colors.Yellow : theme.colors.DarkBlue};
    }
`;
