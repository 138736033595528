import styled from "@emotion/styled";
import { HttpClient } from "adapters/HttpClient";
import MonthKey from "common/types/monthKey";
import UIErrorBoundary from "components/error-boundaries/SectionErrorBoundary";
import useModal from "components/general/CoverPage/Modal/useModal";
import { format } from "date-fns";
import useUpdator from "hooks/useUpdator";
import { addMonthsTo } from "lib/date/addMonthsTo";
import {
    generateMonthsKeysBackFromMonth,
    generateMonthsKeysForwardFromMonth,
    latestCompleteMonth,
} from "lib/date/dateConst";
import { toMmmYy } from "lib/date/reStringifyMonth";
import { useEffect, useState } from "react";
import { setCustomers } from "redux/slices/CustomersSlice";
import { useAppDispatch, useAppSelector } from "redux/store";

import { InputEdit } from "../BudgetEditor";
import FillCells from "../FillCells";

const customerItems = ["target", "actual", "cancellations"];

const CustomerEditor = () => {
    const dispatch = useAppDispatch();

    useUpdator(
        state => state.customers.present.customers,
        (data, organisationId) => {
            HttpClient.put("/customer-inputs", data, {
                organisationId,
            });
        },
    );

    const customers = useAppSelector(
        state => state.customers.present.customers,
    );
    const currentMonth = format(new Date(), "yyyy-MM") as MonthKey;

    const [codeNow, setShow] = useState("");
    const [data, setData] = useState<{
        month: MonthKey;
        item: string;
        baseValue?: number;
    }>();
    const { Modal, show, setClose, setOpen } = useModal();

    const HandleOpen = (data: {
        month: MonthKey;
        item: string;
        baseValue?: number;
    }) => {
        setData(data);
        setOpen();
    };

    const months = [
        ...generateMonthsKeysBackFromMonth(latestCompleteMonth, 12),
        ...generateMonthsKeysForwardFromMonth(latestCompleteMonth, 12),
    ];

    /**
     * Helper function to navigate through the items.
     * @param {object} currentFocused - The currently focused element's dataset.
     * @param {Array} items - An array of items for navigation (revenueItems or expensesItems).
     * @param {string} type - The type of item ("revenue" or "expenses").
     */
    const navigate = (currentFocused: any, items: any[], type: string) => {
        // Find the index of the current focused element in the items array
        const oldIndex = items.findIndex(x => x === currentFocused.code) + 1;

        // If there is a next element in
        if (oldIndex < items.length) {
            const code = items[oldIndex];
            // Find the next element using the type, month, and code
            const element: any = document.querySelector(
                `[data-type="${type}"][data-month="${currentFocused.month}"][data-code="${code}"]`,
            );

            // Focus the next element if it exists
            if (element) {
                element.focus();
            }
        }
    };

    useEffect(() => {
        const handleKeypress = (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                const currentFocused = (document.activeElement as any).dataset;
                if (currentFocused && customerItems) {
                    if (currentFocused.type === "customer") {
                        // Navigate through revenue items
                        navigate(currentFocused, customerItems, "customer");
                    }
                }
            }
            if (event.ctrlKey) {
                if (event.key === "z") {
                    dispatch({
                        type: "customerUndo",
                    });
                }
                if (event.key === "y") {
                    dispatch({
                        type: "customerRedo",
                    });
                }
            }
        };
        window.addEventListener("keydown", handleKeypress);
        return () => {
            window.removeEventListener("keydown", handleKeypress);
        };
    }, [dispatch]);

    /**
     * Create a JSX element for a month with a formatted date string.
     * @param {Array} months - An array of months.
     */
    const createMonthElements = (months: MonthKey[]) =>
        months.map((month, index) => (
            <div key={index}>
                <h3>{toMmmYy(month)}</h3>
            </div>
        ));

    const showMonths = createMonthElements(months);

    const customerName = customerItems.map((item, index) => {
        let name = "";
        switch (item) {
            case "actual":
                name = "Total number of customers";
                break;
            case "target":
                name = "Target number of customers";
                break;
            case "cancellations":
                name = "Customer cancellations";
                break;
        }

        return <span key={index}>{name}</span>;
    });

    const customersEditing =
        customers &&
        customerItems.map((item, index) => {
            return (
                <EditorInputs key={index}>
                    {months.map((month, index) => (
                        <BlankInputs
                            onMouseEnter={() => setShow(`${item} ${month}`)}
                            onMouseLeave={() => setShow("")}
                            key={index}>
                            {month > currentMonth && item !== "target" ? (
                                <div></div>
                            ) : (
                                <>
                                    <InputEdit
                                        data-type='customer'
                                        data-month={month}
                                        data-code={item}
                                        style={{ backgroundColor: "white" }}
                                        onChange={event => {
                                            if (
                                                parseInt(event.target.value) ||
                                                event.target.value === ""
                                            ) {
                                                dispatch(
                                                    setCustomers({
                                                        month,
                                                        item,
                                                        input: +event.target
                                                            .value,
                                                    }),
                                                );
                                            }
                                        }}
                                        type='number'
                                        value={customers[month]?.[item] || ""}
                                    />
                                    {codeNow === `${item} ${month}` && (
                                        <Arrow
                                            onClick={() =>
                                                HandleOpen({
                                                    month,
                                                    item,
                                                    baseValue:
                                                        +customers[month]?.[
                                                            item
                                                        ] ?? undefined,
                                                })
                                            }>
                                            <svg
                                                version='1.1'
                                                xmlns='http://www.w3.org/2000/svg'
                                                width='20'
                                                height='10'
                                                viewBox='-0.709 -0.235 213 71'>
                                                <polygon points='0,26.488 0,44.144 167.747,44.144 167.747,70.631 211.89,35.316 167.747,0 167.747,26.488 ' />
                                            </svg>
                                        </Arrow>
                                    )}
                                </>
                            )}
                        </BlankInputs>
                    ))}
                </EditorInputs>
            );
        });

    return (
        <UIErrorBoundary>
            <Body>
                <div style={{ display: "flex" }}>
                    <Modal>
                        {data && (
                            <FillCells
                                close={setClose}
                                data={{ type: "customers", ...data }}
                            />
                        )}
                    </Modal>
                    <p style={{ marginLeft: 25 }}>
                        Budget and write in your customers in to view summary
                        data on customer page.
                    </p>
                </div>
                <Editor>
                    <EditorNames>
                        <span style={{ height: 35 }}>
                            <b>Customers</b>
                        </span>
                        {customerName}
                        <span>monthly change</span>
                        <span>new customers</span>
                    </EditorNames>

                    <BudgetingContainer>
                        <div>
                            <Months>{showMonths}</Months>
                            {customersEditing}
                            <EditorInputs>
                                {customers &&
                                    currentMonth &&
                                    months.map((month, index) => {
                                        const value =
                                            month <=
                                            addMonthsTo(currentMonth, 1)
                                                ? (customers[month]?.actual ??
                                                      0) -
                                                  (customers[
                                                      addMonthsTo(month, -1)
                                                  ]?.actual ?? 0)
                                                : "";
                                        return (
                                            <BlankInputs key={index}>
                                                {value}
                                            </BlankInputs>
                                        );
                                    })}
                            </EditorInputs>
                            <EditorInputs>
                                {customers &&
                                    currentMonth &&
                                    months.map((month, index) => {
                                        const change =
                                            (customers[month]?.actual ?? 0) -
                                            (customers[addMonthsTo(month, -1)]
                                                ?.actual ?? 0);

                                        const cancellations =
                                            customers[month]?.cancellations ??
                                            0;

                                        const value =
                                            month <=
                                            addMonthsTo(currentMonth, 1)
                                                ? change + cancellations
                                                : "";

                                        return (
                                            <BlankInputs key={index}>
                                                {value}
                                            </BlankInputs>
                                        );
                                    })}
                            </EditorInputs>
                        </div>
                    </BudgetingContainer>
                </Editor>
            </Body>
        </UIErrorBoundary>
    );
};

export default CustomerEditor;

const Body = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Editor = styled.div`
    display: flex;
    padding: 15px;
    width: 100%;
    height: 100%;
`;
const Months = styled.div`
    display: flex;
    width: 100%;

    div {
        background-color: ${props => props.theme.colors.Pink};
        height: 35px;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #686868;
    }
`;
const EditorNames = styled.div`
    display: flex;
    flex-direction: column;

    > span {
        display: flex;
        justify-content: flex-start;
        align-items: center;
        border: 1px solid #686868;
        border-right: 2px solid #686868;
        padding: 4px;
        height: 30px;
        white-space: nowrap;
        text-transform: capitalize;
    }
`;

const BudgetingContainer = styled.div`
    overflow-x: auto;
    overflow-y: hidden;
    width: 100%;
    > div {
        width: fit-content;
    }
`;

const BlankInputs = styled.div`
    border: 1px solid #686868;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 30px;
    width: 120px;
    font-size: 16px;
    position: relative;
`;

const EditorInputs = styled.div`
    display: flex;
    width: 100%;
    height: max-content;
`;
const Arrow = styled.div`
    position: absolute;
    z-index: 10;
    right: -10px;
    top: 0px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
