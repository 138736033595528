import styled from "@emotion/styled";

import DeleteAccount from "./deleteAccount";
import SyncAccount from "./SyncAccount";

const AdvancedSettings = () => {
    return (
        <Container>
            <h1>AdvancedSettings</h1>
            <Items>
                <SyncAccount />
                <DeleteAccount />
            </Items>
        </Container>
    );
};

export default AdvancedSettings;

const Items = styled.div`
    display: flex;
`;

const Container = styled.div`
    border: 1.8px solid black;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
`;
