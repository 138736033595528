import styled from "@emotion/styled";
import { Writer } from "common/Writer";
import useReport from "hooks/reports/useReport";
import { sumOfMonth } from "lib/apiDataHelpers/sumOfMonths";

import Bubble from "../../Bubble";
import BudgetBox from "../../shared/budgetBox";

const RevenueDetailBudget = () => {
    const report = useReport();
    if (!report) return null;

    const {
        revenue: { actual, budget },
        reportDates: { selectedMonth },
        items,
    } = report;

    const againstBudget =
        sumOfMonth(actual[selectedMonth]) - sumOfMonth(budget[selectedMonth]);

    return (
        <Container>
            <Top>
                <h1>
                    Here's your revenue vs your budget for{" "}
                    {selectedMonth.selectByMonths(
                        selectedMonth.toTextMonth(),
                        selectedMonth.toTextMonth(),
                        selectedMonth.toMmmmYyyy(),
                    )}
                </h1>
                <Bubble>
                    <h3>
                        Overall in {selectedMonth?.toTextMonth()}, you
                        generated:
                    </h3>
                    <mark>
                        {Writer.FormatCurrency(Math.abs(againstBudget))}
                    </mark>
                    <span>
                        {Writer.DescribeChange(
                            againstBudget,
                            "More than you",
                            "Less than you",
                            "over/under",
                        )}{" "}
                        budgeted
                    </span>
                </Bubble>
            </Top>
            <Bottom>
                <p>
                    With this graph you can see a breakdown of where you landed
                    for each revenue stream for the month of{" "}
                    {selectedMonth?.toTextMonth()}, compared to what you
                    budgeted.
                </p>

                <BudgetBox budget={budget} actual={actual} items={items} />
            </Bottom>
        </Container>
    );
};

export default RevenueDetailBudget;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
`;

const Top = styled.div`
    width: 100%;
    max-width: ${({ theme }) => theme.sizes.modelWidth};
    display: flex;
    flex-direction: column;
    align-items: center;
    > div {
        margin: 50px;
    }
`;

const Bottom = styled.div`
    width: 100%;
    max-width: ${({ theme }) => theme.sizes.modelWidth};
    p {
        text-align: center;
        margin: 20px auto;
    }
`;
