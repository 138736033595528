import styled from "@emotion/styled";
import { HttpClient } from "adapters/HttpClient";
import { useNavigate } from "react-router";

const StripeConnected = () => {
    const navigate = useNavigate();

    return (
        <Container>
            <h1>Thank you for subscribing to Hello Cashflow</h1>
            <p style={{ textAlign: "center" }}>
                It's now time to connect your xero account.
            </p>

            <div>
                <Button onClick={handleXero}>Connect to Xero</Button>
            </div>
            <img
                src='/assets/horizental version.png'
                alt='HelloCashflow'
                height={50}
                width={160}
                style={{
                    position: "absolute",
                    bottom: 0,
                    left: 0,
                    marginRight: "auto",
                    marginBottom: 20,
                    marginLeft: 20,
                    marginTop: 20,
                }}
            />
        </Container>
    );
};

const handleXero = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.currentTarget.disabled = true; //prevent multiple clicks
    HttpClient.get<string>("xero/loginurl")
        .then(url => {
            window.location.href = url;
        })
        .catch(err => alert(err.message));
};

export default StripeConnected;
const Container = styled.div`
    padding: 10px;
    position: relative;
    height: 75vh;
    background-color: ${props => props.theme.colors.Pink};
    background-position: center;
    min-height: 400px;
    max-height: 686px;
    color: ${props => props.theme.colors.DarkBlue};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    > p {
        width: 550px;
    }
`;

const Button = styled.button`
    margin: 20px;
    border-radius: 10px;
    color: white;
    background-color: ${props => props.theme.colors.DarkBlue};
    &:hover {
        background-color: ${props => props.theme.colors.DarkPink};
    }
`;
