import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useState } from "react";
import {
    persistNotifications,
    removePersistantNotification,
} from "redux/slices/NotificationSlice";
import { useAppDispatch } from "redux/store";

import { ReactComponent as XSVG } from "../../assets/general/X.svg";

const PersistantNotification = ({ id }: { id: string }) => {
    const { type, onClick, message } = persistNotifications[id];
    const dispatch = useAppDispatch();
    const [exit, setExit] = useState(false);

    const handleOnClick = () => {
        if (onClick) {
            setExit(true);
            onClick();
            setTimeout(() => {
                dispatch(removePersistantNotification(id));
            }, 400);
        }
    };

    return (
        <Container>
            <ExitButton
                onClick={() => {
                    setExit(true);
                    setTimeout(() => {
                        dispatch(removePersistantNotification(id));
                    }, 400);
                }}
            />
            <Message
                type={type}
                exit={exit}
                onClick={handleOnClick}
                clickable={onClick !== undefined}>
                {message}
            </Message>
        </Container>
    );
};

export default PersistantNotification;

const Message = styled.div<{
    clickable: boolean;
    exit: boolean;
    type: "success" | "error" | "info";
}>`
    padding: 0.5rem 1rem;
    border-top: 2px solid #c0c0c0;
    cursor: ${props => (props.clickable ? "pointer" : "default")};

    @keyframes SlideLeft {
        0% {
            opacity: 0%;
        }

        100% {
            opacity: 100%;
        }
    }

    &:hover {
        ${({ clickable, type }) =>
            clickable &&
            css`
                cursor: pointer;
                background-color: ${((): string => {
                    switch (type) {
                        case "success":
                            return "#549754";
                        case "error":
                            return "#a05252";
                        case "info":
                            return "#dadada";
                    }
                })()};
            `}
    }
    ${({ exit }) =>
        exit &&
        css`
            animation: SlideRight 0.4s;
            animation-fill-mode: forwards;
        `}

    @keyframes SlideRight {
        0% {
            opacity: 100%;
        }

        100% {
            opacity: 0%;
        }
    }
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    background-color: #fff;
`;

const ExitButton = styled(XSVG)`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    cursor: pointer;
`;
