import MonthKey from "common/types/monthKey";

import Month from "./Month";
import {
    currentMonth as currentMonthDate,
    latestCompleteMonth,
} from "../dateConst";

const ShortMonth: { [key: string]: string } = {
    "01": "Jan",
    "02": "Feb",
    "03": "Mar",
    "04": "Apr",
    "05": "May",
    "06": "Jun",
    "07": "Jul",
    "08": "Aug",
    "09": "Sep",
    "10": "Oct",
    "11": "Nov",
    "12": "Dec", //Seems cleaner than worrying about date parsing
};
const LongMonth: { [key: string]: string } = {
    "01": "January",
    "02": "February",
    "03": "March",
    "04": "April",
    "05": "May",
    "06": "June",
    "07": "July",
    "08": "August",
    "09": "September",
    "10": "October",
    "11": "November",
    "12": "December",
};

declare global {
    interface String {
        /**
         * @returns  a month
         */
        toMonth(): Month;

        generateMonthsKeysBackFromMonth(months: number): MonthKey[];
        generateMonthsKeysForwardFromMonth(months: number): MonthKey[];
        addMonthsTo(add: number): MonthKey;
        /**
         * @returns  the date in format January
         */
        toTextMonth(): string;
        toTextShortMonth(): string;
        /**
         * @returns the date in format Jan '20
         */
        toMmmYy(): string;
        /**
         * @returns the date in format Jan 2020
         */
        toMmmYyyy(): string;
        /**
         * @returns date in format January 2020
         */
        toMmmmYyyy(): string;
        getMonthParts(): {
            yearPart: string;
            monthPart: string;
        };
        /**
         *
         * @returns month as number i.e. 1
         */
        getMonth(): number;
        /**
         *
         * @returns year as number i.e. 2020
         */
        getYear(): number;
        /**
         * return param based on the current month
         * @param currentMonth
         * @param LastCompletedMonth
         * @param AllOtherMonths
         **/
        selectByMonths<T>(
            currentMonth: T,
            LastCompletedMonth: T,
            AllOtherMonths: T,
        ): T;
    }
}

String.prototype.toMonth = function (this: string): Month {
    return new Month(this as MonthKey);
};

String.prototype.selectByMonths = function <T>(
    this: string,
    currentMonth: T,
    LastCompletedMonth: T,
    AllOtherMonths: T,
) {
    switch (this) {
        case currentMonthDate:
            return currentMonth;
        case latestCompleteMonth:
            return LastCompletedMonth;
        default:
            return AllOtherMonths;
    }
};

String.prototype.generateMonthsKeysBackFromMonth = function (
    this: string,
    months: number,
) {
    const array = Array.from(Array(months).keys());
    return array.map(i => this.addMonthsTo(-months + i + 1));
};

String.prototype.generateMonthsKeysForwardFromMonth = function (
    this: string,
    months: number,
) {
    const array = Array.from(Array(months).keys());
    return array.map(i => this.addMonthsTo(i + 1));
};

String.prototype.addMonthsTo = function (this: string, add: number) {
    const yearMonth = this.split("-");
    const startYear = parseInt(yearMonth[0]);
    const startMonth = parseInt(yearMonth[1]);

    let y = startYear + Math.trunc(add / 12);
    let m = startMonth + (add % 12);
    if (m < 1) {
        y--;
        m += 12;
    }
    if (m > 12) {
        y++;
        m -= 12;
    }
    return `${y}-${m < 10 ? "0" + m : m}` as MonthKey;
};

String.prototype.toTextMonth = function (this) {
    const parts = this.getMonthParts();
    return LongMonth[parts.monthPart];
};
String.prototype.toTextShortMonth = function (this) {
    const parts = this.getMonthParts();
    return ShortMonth[parts.monthPart];
};
String.prototype.toMmmYy = function (this) {
    const parts = this.getMonthParts();
    return `${ShortMonth[parts.monthPart]} '${parts.yearPart.substring(2, 4)}`;
};
String.prototype.toMmmYyyy = function (this) {
    const parts = this.getMonthParts();
    return `${ShortMonth[parts.monthPart]} ${parts.yearPart}`;
};
String.prototype.toMmmmYyyy = function (this) {
    const parts = this.getMonthParts();
    return `${LongMonth[parts.monthPart]} ${parts.yearPart}`;
};
String.prototype.getMonthParts = function (this) {
    const parts = this.split("-");
    return {
        yearPart: parts[0],
        monthPart: parts[1],
    };
};
String.prototype.getMonth = function (this) {
    return parseInt(this.split("-")[1]);
};
String.prototype.getMonth = function (this) {
    return parseInt(this.split("-")[0]);
};
