/** A component that displays a page to the user with an optional title and message. */
import styled from "@emotion/styled";
import image from "assets/img/backgrounds/home_stretched.png";
import LogoHorizontal from "components/brand/LogoHorizontal";

interface SplashPageProps {
    title?: string;
    message?: string;
    showAnimation?: boolean;
}

const SplashPage = ({ title, message, showAnimation }: SplashPageProps) => {
    return (
        <div>
            <Div>
                <LogoHorizontal showText={false} />
                {showAnimation ? (
                    <>
                        <span className='animated-symbol'></span>
                        <span className='styalised-graph'></span>
                    </>
                ) : (
                    ""
                )}
                <div className='feature'>
                    {title ? <p className='intense-quote'>{title}</p> : ""}
                    {message ? <p>{message}</p> : ""}
                </div>
            </Div>
        </div>
    );
};

export default SplashPage;

const Div = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 75vh;
    background: url(${image}) no-repeat center center fixed;
    background-size: cover;
    height: 686px;
    color: ${props => props.theme.colors.DarkBlue};

    .logo {
        position: absolute;
        top: 10px;
        left: 30px;
    }

    .frontpage-tagline {
        position: absolute;
        top: 100px;
        right: 30px;
        font-size: ${props => props.theme.fonts.defaultFontSize};
    }
`;
