import styled from "@emotion/styled";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import Switch from "components/general/Switch";
import ReportsModal from "components/navigation/expandableSection/ReportsModal";
import useReport from "hooks/reports/useReport";
import { sumOfMonth } from "lib/apiDataHelpers/sumOfMonths";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { generateMonthsKeysForwardFromMonth } from "lib/date/dateConst";
import { toMmmYy } from "lib/date/reStringifyMonth";
import { useState } from "react";
import {
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { theme } from "style/theme";

import BudgetGraphsModal from "./BudgetGraphsModal";

const BudgetGraphs = () => {
    const report = useReport();
    const [alternate, setAlternate] = useState(false);

    if (!report) return null;
    const {
        reportDates: { lastTwelveMonths, selectedMonth },
    } = report;

    const months = [
        ...lastTwelveMonths,
        ...generateMonthsKeysForwardFromMonth(selectedMonth, 12),
    ];
    const BudgetGraph = report
        ? months.map(month => ({
              month,
              revenue: sumOfMonth(report.revenue.budget[month]),
              expenses: sumOfMonth(report.expenses.budget[month]),
          }))
        : undefined;

    return (
        <ItemSection column>
            <Title>
                {!alternate
                    ? "Here's how much you've budgeted to earn and spend"
                    : "Here's how much net profit you've budgeted to receive"}
            </Title>
            {!alternate ? (
                <ResponsiveContainer width='100%' height={350}>
                    <LineChart data={BudgetGraph} margin={{ right: 10 }}>
                        <XAxis
                            interval={0}
                            type='category'
                            dataKey='month'
                            style={{ fill: theme.colors.Pink }}
                            stroke={"none"}
                            tick={tickMonthWrap}
                        />
                        <YAxis
                            type='number'
                            style={{ fill: theme.colors.Pink }}
                            stroke={"none"}
                            tickFormatter={(value: number) =>
                                Writer.FormatCurrency(value)
                            }
                        />
                        <ReferenceLine
                            x={selectedMonth}
                            stroke='white'
                            label=''
                        />
                        <Tooltip content={tooltip} />
                        <ReferenceLine
                            y={0}
                            label=''
                            stroke='red'
                            strokeDasharray='3 3'
                        />
                        <Line
                            type='monotone'
                            strokeWidth={2}
                            stroke={theme.colors.Yellow}
                            dataKey='revenue'
                            name='Revenue'
                            dot={false}
                        />
                        <Line
                            type='monotone'
                            strokeWidth={2}
                            stroke={theme.colors.DarkPink}
                            dataKey='expenses'
                            name='Expenses'
                            dot={false}
                        />
                        <Legend />
                    </LineChart>
                </ResponsiveContainer>
            ) : (
                <ResponsiveContainer width='100%' height={350}>
                    <LineChart
                        data={BudgetGraph?.map(
                            ({ month, revenue, expenses }) => ({
                                month,
                                netProfit: revenue - expenses,
                            }),
                        )}
                        margin={{ right: 10 }}>
                        <XAxis
                            interval={0}
                            type='category'
                            dataKey='month'
                            style={{ fill: theme.colors.Pink }}
                            stroke={"none"}
                            tick={tickMonthWrap}
                        />
                        <YAxis
                            type='number'
                            style={{ fill: theme.colors.Pink }}
                            stroke={"none"}
                            tickFormatter={(value: number) =>
                                Writer.FormatCurrency(value)
                            }
                        />
                        <ReferenceLine
                            x={selectedMonth}
                            stroke='white'
                            label=''
                        />
                        <ReferenceLine
                            y={0}
                            label=''
                            stroke='red'
                            strokeDasharray='3 3'
                        />
                        <Tooltip content={tooltip} />
                        <Line
                            type='monotone'
                            strokeWidth={2}
                            stroke={theme.colors.DarkAqua}
                            dataKey='netProfit'
                            name='Net profit'
                            dot={false}
                        />
                        <Legend />
                    </LineChart>
                </ResponsiveContainer>
            )}
            <Alternate>
                {!alternate ? <span>Budgets</span> : <span>Net profit</span>}
                <Switch
                    toggle={alternate}
                    cbSwitch={setAlternate}
                    colorOption1={theme.colors.DarkAqua}
                    colorOption2={theme.colors.DarkPink}
                    width={50}
                />
            </Alternate>
            <ReportsModal path='rev-vs-exp-budget-model'>
                <BudgetGraphsModal />
            </ReportsModal>
        </ItemSection>
    );
};

export default BudgetGraphs;

const Alternate = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 50px;
    right: 50px;
    width: 130px;
    > span {
        font-size: 15px;
        /* text-transform: uppercase; */
    }
`;

const Title = styled.h1`
    margin-top: 0;
`;

const tooltip = (props: any) => {
    return (
        <div
            style={{
                backgroundColor: "white",
                padding: "10px",
            }}>
            <div>
                <strong
                    style={{
                        color: theme.colors.DarkBlue,
                    }}>
                    {toMmmYy(props.label)}
                </strong>
            </div>
            <div>
                {props.payload &&
                    props.payload.map((p: any, index: number) => {
                        const color =
                            p.dataKey === "BankBalance"
                                ? theme.colors.DarkAqua
                                : p.color;
                        return (
                            <div
                                key={index}
                                style={{
                                    color: color,
                                }}>
                                <strong>{p.name}</strong>{" "}
                                <span>{Writer.FormatCurrency(p.value)}</span>
                            </div>
                        );
                    })}
                {/* <strong>{props.payload[0].value}</strong> */}
            </div>
        </div>
    );
};
