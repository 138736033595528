import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import useModal from "components/general/CoverPage/Modal/useModal";
import { useState } from "react";
import { setBudget } from "redux/slices/BudgetSlice";
import { useAppDispatch, useAppSelector } from "redux/store";
import { Item } from "types/OrganisationType";

import { InputEdit } from "../BudgetEditor";
import FillCells from "../FillCells";

const RevenueEdit = ({
    months,
    revenueItems,
    numberOfMonths,
}: {
    months: MonthKey[];
    revenueItems: Array<[string, Item]> | undefined;
    numberOfMonths: number | undefined;
}) => {
    const [hovered, setShow] = useState<{ code?: string; month?: string }>({});
    const [data, setData] = useState<{
        month: MonthKey;
        item: string;
        baseValue?: number;
    }>();
    const dispatch = useAppDispatch();

    const revenueBudget = useAppSelector(state => state.budget.present.revenue);

    const { Modal, show, setClose, setOpen } = useModal();

    const HandleOpen = (data: {
        month: MonthKey;
        item: string;
        baseValue?: number;
    }) => {
        setData(data);
        setOpen();
    };

    return (
        <>
            <Modal>
                {data && (
                    <FillCells
                        close={setClose}
                        data={{
                            type: "revenue",
                            numberOfMonths,
                            ...data,
                        }}
                    />
                )}
            </Modal>
            <Container>
                {revenueItems &&
                    revenueItems.map(([code, item], index) => {
                        return (
                            <RevenueItemDiv key={`revenue ${code}`}>
                                {months.map(month => (
                                    <RevenueMonthDiv
                                        onMouseEnter={() =>
                                            setShow({ code, month })
                                        }
                                        onMouseLeave={() => setShow({})}
                                        key={`revenue ${month} ${code}`}>
                                        <InputEdit
                                            data-type='revenue'
                                            data-month={month}
                                            data-code={code}
                                            onChange={event => {
                                                const value =
                                                    event.target.value === ""
                                                        ? undefined
                                                        : +event.target.value;
                                                dispatch(
                                                    setBudget({
                                                        stateItem: "revenue",
                                                        month,
                                                        item: code,
                                                        input: value as unknown as number,
                                                    }),
                                                );
                                            }}
                                            type='number'
                                            value={
                                                revenueBudget?.[month]?.[code]
                                                    ? revenueBudget[month][code]
                                                    : ""
                                            }
                                        />
                                        {hovered.code === code &&
                                            hovered.month === month && (
                                                <Arrow
                                                    onClick={() =>
                                                        HandleOpen({
                                                            month,
                                                            item: code,
                                                            baseValue:
                                                                revenueBudget &&
                                                                revenueBudget[
                                                                    month
                                                                ][code],
                                                        })
                                                    }>
                                                    <svg
                                                        version='1.1'
                                                        xmlns='http://www.w3.org/2000/svg'
                                                        width='20'
                                                        height='10'
                                                        viewBox='-0.709 -0.235 213 71'>
                                                        <polygon points='0,26.488 0,44.144 167.747,44.144 167.747,70.631 211.89,35.316 167.747,0 167.747,26.488 ' />
                                                    </svg>
                                                </Arrow>
                                            )}
                                    </RevenueMonthDiv>
                                ))}
                            </RevenueItemDiv>
                        );
                    })}
            </Container>
        </>
    );
};

export default RevenueEdit;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const RevenueItemDiv = styled.div`
    display: flex;
    flex-direction: row;
`;
const RevenueMonthDiv = styled.div`
    position: relative;
    height: 30px;
    width: 120px;
    border: 1px solid #686868;
    background-color: white;
`;

const Arrow = styled.div`
    position: absolute;
    z-index: 10;
    right: -10px;
    top: 0px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
