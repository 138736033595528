import styled from "@emotion/styled";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import COLORS from "common/constants/COLORS";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import OutsideAlerter from "components/inputs/OutsideAlerter";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import useReport from "hooks/reports/useReport";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { toMmmYy } from "lib/date/reStringifyMonth";
import { useEffect, useRef, useState } from "react";
import {
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import {
    useGetBankAccountsQuery,
    useGetBankBalancesQuery,
} from "redux/rtkQuery/reports";
import { useAppSelector } from "redux/store";
import { theme } from "style/theme";
import { BankBalances } from "types/OrganisationType";

const chartData = (accounts?: BankBalances, month?: MonthKey) => {
    if (!accounts || !month) {
        return undefined;
    }

    return month.generateMonthsKeysBackFromMonth(12).map(month => {
        const data = Object.entries(accounts).reduce(
            (output, [bankAccount, bankAccountMonths]) => {
                output[bankAccount] = bankAccountMonths[month];
                return output;
            },
            {} as { [bankAccount: string]: number | undefined },
        );
        return { month, ...data };
    });
};

const BankAccounts = () => {
    const currentOrg = useCurrentOrg();
    const { data: accounts } = useGetBankBalancesQuery(
        currentOrg?.organisationId ?? skipToken,
    );

    const { data: bankAccounts } = useGetBankAccountsQuery(
        currentOrg?.organisationId ?? skipToken,
    );

    const report = useReport();

    const items = report?.items;

    const first = useRef(true);

    const selectedMonth = useAppSelector(state => state.dates?.selectedMonth);

    const chart = chartData(accounts, selectedMonth);

    const [showOptions, setShowOptions] = useState(false);
    const [selected, setSelected] = useState<string[]>([]);

    useEffect(() => {
        if (accounts && selected.length === 0 && first.current) {
            first.current = false;
            setSelected(Object.keys(accounts).slice(0, 5));
        }
    }, [accounts, selected]);

    const handleChange: React.ChangeEventHandler<HTMLInputElement> = props => {
        const name = props.target.name;
        const value = props.target.checked;

        if (value) {
            setSelected(p => [name, ...p]);
        } else {
            setSelected(p => {
                const index = p.indexOf(name);
                p.splice(index, 1);
                return [...p];
            });
        }
    };

    return (
        <BodyContainer>
            <ItemSection>
                <div style={{ position: "relative", width: "100%" }}>
                    <h1>Here's how your bank accounts are tracking</h1>
                    <ResponsiveContainer height={350} width='100%'>
                        <LineChart data={chart} margin={{ right: 10 }}>
                            <XAxis
                                interval={0}
                                type='category'
                                dataKey='month'
                                style={{ fill: theme.colors.Pink }}
                                stroke={"none"}
                                tick={props => {
                                    if (
                                        props.payload.value === "auto" ||
                                        props.payload.value === 0
                                    )
                                        return <></>;
                                    return tickMonthWrap(props);
                                }}
                            />
                            <YAxis
                                type='number'
                                style={{ fill: theme.colors.Pink }}
                                stroke={"none"}
                                tickFormatter={(value: number) =>
                                    Writer.FormatCurrency(value)
                                }
                            />
                            <ReferenceLine
                                y={0}
                                label=''
                                stroke='red'
                                strokeDasharray='3 3'
                            />

                            <Tooltip
                                labelStyle={{
                                    color: theme.colors.DarkBlue,
                                }}
                                itemSorter={item => {
                                    return (item.value as number) * -1;
                                }}
                                labelFormatter={props => {
                                    if (props === 0 || props === "auto")
                                        return null;
                                    return toMmmYy(props);
                                }}
                                formatter={(value, name) => {
                                    if (
                                        value === 0 ||
                                        typeof value === "string"
                                    )
                                        return "";
                                    return items
                                        ? [
                                              Writer.FormatCurrency(
                                                  value as number,
                                              ),
                                              items[name]?.name,
                                          ]
                                        : Writer.FormatCurrency(
                                              value as number,
                                          );
                                }}
                            />
                            <Legend
                                formatter={value => {
                                    if (items) {
                                        console.assert(
                                            items[value]?.name !== undefined,
                                            "item doesnt have: ",
                                            value,
                                        );
                                        return items[value]?.name;
                                    } else {
                                        return undefined;
                                    }
                                }}
                            />

                            {selected.map((accountId, index) => {
                                return (
                                    <Line
                                        key={index}
                                        dot={false}
                                        dataKey={accountId}
                                        strokeWidth={2}
                                        type='monotone'
                                        stroke={
                                            COLORS.PIE_CHART[
                                                index % COLORS.PIE_CHART.length
                                            ]
                                        }
                                    />
                                );
                            })}
                        </LineChart>
                    </ResponsiveContainer>
                    <Postion>
                        <OutsideAlerter callBack={() => setShowOptions(false)}>
                            <Container>
                                <Title
                                    onClick={() => setShowOptions(pre => !pre)}>
                                    <SelectInput>Select Accounts</SelectInput>
                                    <SelectDown>
                                        <span></span>
                                        <div aria-hidden='true'>
                                            <svg
                                                height='20'
                                                width='20'
                                                viewBox='0 0 20 20'
                                                aria-hidden='true'
                                                focusable='false'>
                                                <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
                                            </svg>
                                        </div>
                                    </SelectDown>
                                </Title>
                                {showOptions && (
                                    <SelectOptions>
                                        <div>
                                            {accounts &&
                                                items &&
                                                //Has balances, get rid of the empty ones
                                                Object.entries(accounts)
                                                    .filter(
                                                        ([
                                                            accountCode,
                                                            monthlyBalances,
                                                        ]) =>
                                                            Object.keys(
                                                                monthlyBalances,
                                                            ).length > 0,
                                                    )
                                                    .map((o, index) => (
                                                        <li>
                                                            <input
                                                                key={o[0]}
                                                                name={o[0]}
                                                                type='checkbox'
                                                                checked={
                                                                    selected.findIndex(
                                                                        x =>
                                                                            x ===
                                                                            o[0],
                                                                    ) !== -1
                                                                }
                                                                onChange={
                                                                    handleChange
                                                                }
                                                            />
                                                            {bankAccounts?.find(
                                                                x =>
                                                                    x.AccountNumber ===
                                                                    o[0],
                                                            )?.name ?? o[0]}
                                                        </li>
                                                    ))}
                                        </div>
                                    </SelectOptions>
                                )}
                            </Container>
                        </OutsideAlerter>
                    </Postion>
                </div>
            </ItemSection>
        </BodyContainer>
    );
};

export default BankAccounts;
const BodyContainer = styled.div`
    border-bottom: 2px solid ${props => props.theme.colors.Pink};
`;
const Container = styled.div`
    position: relative;
    width: 240px;
    height: 100%;
`;
const Postion = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const SelectOptions = styled.ul`
    margin: 0;
    z-index: 1;
    position: absolute;
    top: 38px;
    width: 100%;
    height: 250px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    overflow-y: auto;
    background-color: white;
    border: 2px solid ${theme.colors.Pink};
    color: ${theme.colors.DarkBlue};
    list-style: none;
    padding: 10px;

    li {
        padding: 2px 0;
        label {
            cursor: pointer;
        }
    }

    > div {
        height: 100%;
    }

    /* firefox */
    scrollbar-width: thin;
    scrollbar-color: ${({ theme }) => theme.colors.DarkBlue} transparent;
    /* webkit browsers */
    &::-webkit-scrollbar {
        width: 0.3vw;
    }

    &::-webkit-scrollbar-thumb {
        background-color: ${({ theme }) => theme.colors.DarkBlue};
    }
    &::-webkit-scrollbar-track {
        background-color: transparent;
    }

    animation: fadeInAnimation ease-in-out 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const SelectInput = styled.div`
    -moz-box-align: center;
    align-items: center;
    display: grid;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
`;

const Title = styled.div`
    padding-left: 10px;
    font-size: 14px;
    -moz-box-align: center;
    align-items: center;
    background-color: ${theme.colors.DarkBlue};
    border: 2px solid ${theme.colors.Pink};
    display: flex;
    flex-wrap: wrap;
    -moz-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: currentcolor none 0px !important;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    &:hover {
        color: ${theme.colors.DarkPink};
        cursor: pointer;
        svg {
            fill: ${theme.colors.DarkPink};
        }
        span {
            background: ${theme.colors.DarkPink};
        }
    }
`;
const SelectDown = styled.div`
    -moz-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    span {
        align-self: stretch;
        background-color: ${theme.colors.Pink};
        margin-bottom: 8px;
        margin-top: 8px;
        width: 1px;
        box-sizing: border-box;
    }
    div {
        color: rgb(204, 204, 204);
        display: flex;
        padding: 8px;
        transition: color 150ms ease 0s;
        box-sizing: border-box;
    }
`;
const Label = styled.h1`
    margin-right: auto;
    margin-top: -15px;
    margin-bottom: 30px;
    font-size: 24px;
`;
