import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import MonthKey from "common/types/monthKey";
import { OrganisationIdentifier } from "common/types/objects/Organisation";
import {
    getFinancialYear,
    getLastCompleteQuarter,
    getMonthInfo,
} from "lib/date/reportHelper";

import { ReportDateInfo } from "../../types/ReportDataInfo";

const DataSlice = createSlice({
    name: "cashflow",
    initialState: null as ReportDateInfo | null,
    reducers: {
        setSelectedMonth: (
            state,
            action: PayloadAction<[MonthKey, OrganisationIdentifier]>,
        ) => {
            const [month, currentOrganisation] = action.payload;
            localStorage.setItem(
                `selected month ${currentOrganisation.organisationId}`,
                month,
            );
            // need to return or else the state is not updated
            return (state = {
                showQuarterly: !!localStorage.getItem(
                    buildStorageKeyQtr(currentOrganisation.organisationId),
                ),
                ...getLastCompleteQuarter(
                    currentOrganisation.reportingYearBeginsMonth,
                    month,
                ),
                ...getMonthInfo(month),
                ...getFinancialYear(
                    month,
                    currentOrganisation.reportingYearBeginsMonth,
                ),
            });
        },
    },
});

export const { setSelectedMonth } = DataSlice.actions;

export default DataSlice.reducer;

function buildStorageKeyQtr(organisationId: string) {
    return `show_quarterly_${organisationId}`;
}
