import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import OutsideAlerter from "components/inputs/OutsideAlerter";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { latestCompleteMonth } from "lib/date/dateConst";
import { useState } from "react";

const SelectMonths = ({
    months,
    callback,
    actual,
}: {
    months: number;
    callback: React.Dispatch<React.SetStateAction<MonthKey[]>>;
    actual?: boolean;
}) => {
    const possibleMonths = actual ? [0, 3, 6, 12, 18, 24] : [6, 12, 18, 24, 36];
    const [show, setShow] = useState(false);
    const setMonths = (length: number) => {
        localStorage.setItem(
            `budget-editor months ${actual}`,
            length.toString(),
        );
        const months = Array.from(Array(length).keys()).map(i =>
            addMonthsTo(latestCompleteMonth, actual ? -i : i + 1),
        );
        callback(actual ? months.reverse() : months);
    };

    return (
        <SelectMonthContainer>
            <MonthShown onClick={() => setShow(true)}>
                {months} {actual ? "actual" : "budget"} months
            </MonthShown>
            {show && (
                <div
                    style={{
                        position: "absolute",
                        top: "calc(100%)",
                        left: "-50%",
                    }}>
                    <OutsideAlerter callBack={() => setShow(false)}>
                        <PossibleMonthsContainer>
                            {!actual && (
                                <span>
                                    A minimum of 12 months is recommended
                                </span>
                            )}
                            <div>
                                {possibleMonths.map(number => {
                                    return (
                                        <PossibleMonths
                                            key={number}
                                            selected={months === number}
                                            onClick={() => setMonths(number)}>
                                            <span>{number}</span>
                                        </PossibleMonths>
                                    );
                                })}
                            </div>
                        </PossibleMonthsContainer>
                    </OutsideAlerter>
                </div>
            )}
        </SelectMonthContainer>
    );
};

export default SelectMonths;
const SelectMonthContainer = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    z-index: 1;
`;

const MonthShown = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    flex-direction: column;
    height: 100%;
    padding: 10px;
    margin-top: 10px;
    margin-left: 10px;
    background-color: #fff;
    border-radius: 10px;
    cursor: pointer;
    &:hover {
        background-color: #e6e6e6;
    }
`;

const PossibleMonthsContainer = styled.div`
    width: 250px;
    padding: 8px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid ${props => props.theme.colors.DarkBlue};
    span {
        font-size: 12px;
    }
    > div {
        display: flex;
        justify-content: space-evenly;
    }
`;

const PossibleMonths = styled.div<{ selected: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    text-align: center;
    background-color: ${props =>
        props.selected ? props.theme.colors.LightBlue : "#c5c5c5"};
    cursor: pointer;

    &:hover {
        ${props => !props.selected && "background-color: #6b6b6b97"}
    }
`;
