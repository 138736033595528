import {
    BUDGET_TAG,
    ITEM_TAG,
    ORGANIZATION_TAG,
    REPORT_TAG,
    TRANSACTION_TAG,
} from "common/constants/TAGS";
import MonthKey from "common/types/monthKey";
import {
    BudgetSources,
    OrganisationIdentifier,
    OrganisationTypes,
    OrgSubscriptionStatus,
} from "common/types/objects/Organisation";
import { latestCompleteMonth } from "lib/date/dateConst";
import { setSelectedMonth } from "redux/slices/DateSlice";
import { store } from "redux/store";

import { api } from "./api";
import customerSlice from "./customers";

const organsationSlice = api.injectEndpoints({
    endpoints: builder => ({
        getOrganisations: builder.query<
            {
                organisations: OrganisationIdentifier[];
                currentOrganisation: OrganisationIdentifier;
            },
            void
        >({
            query: () => ({
                url: "organisations",
            }),
            transformResponse: (response: OrganisationIdentifier[]) => {
                const previouslySelected = localStorage.getItem("org_id");
                const isIn = response.find(
                    org => org.organisationId === previouslySelected,
                );
                const firstPaidOrg = response.find(
                    org =>
                        org.subscriptionStatus !== OrgSubscriptionStatus.none,
                );
                if (
                    isIn &&
                    isIn.subscriptionStatus !== OrgSubscriptionStatus.none
                ) {
                    return {
                        organisations: response,
                        currentOrganisation: isIn,
                    };
                } else if (firstPaidOrg) {
                    return {
                        organisations: response,
                        currentOrganisation: firstPaidOrg,
                    };
                } else {
                    return {
                        organisations: response,
                        currentOrganisation: response[0],
                    };
                }
            },
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    const {
                        data: { currentOrganisation },
                    } = await queryFulfilled;

                    const selectedMonth =
                        (localStorage.getItem(
                            `selected month ${currentOrganisation?.organisationId}`,
                        ) as MonthKey | null) ??
                        store.getState().dates?.selectedMonth ??
                        latestCompleteMonth;

                    dispatch(
                        setSelectedMonth([selectedMonth, currentOrganisation]),
                    );

                    // Invalidate the current organisation tag so that the organisation
                    dispatch(
                        customerSlice.util.invalidateTags([ORGANIZATION_TAG]),
                    );
                } catch (error) {
                    console.error("org", error);
                }
            },
        }),
        syncData: builder.mutation<
            UpdateResult,
            {
                organisation: OrganisationIdentifier;
                years?: number;
                email?: boolean;
            }
        >({
            query: ({ organisation, years, email }) => ({
                url: "organisations/sync-provider-data",
                method: "PUT",
                params: {
                    organisationId: organisation.organisationId,
                    years: years ?? 1,
                    email: !!email,
                },
            }),
            onQueryStarted: async (
                { organisation, years },
                { dispatch, queryFulfilled },
            ) => {
                try {
                    const { data: updated } = await queryFulfilled;
                    if (updated.budgedUpdated) {
                        dispatch(api.util.invalidateTags([BUDGET_TAG]));
                    }

                    if (updated.reportsUpdated) {
                        dispatch(api.util.invalidateTags([REPORT_TAG]));
                    }

                    if (updated.itemsUpdated) {
                        dispatch(api.util.invalidateTags([ITEM_TAG]));
                    }

                    if (updated.transactionsUpdated) {
                        dispatch(api.util.invalidateTags([TRANSACTION_TAG]));
                    }
                } catch (error) {
                    console.error(error);
                }
            },
        }),
        SetOrganisationType: builder.mutation<
            void,
            {
                organisationId: string | undefined;
                changeTo: OrganisationTypes;
            }
        >({
            query: ({ organisationId, changeTo }) => ({
                url: "organisations/type",
                method: "PUT",
                params: {
                    organisationId,
                    changeTo,
                },
            }),
            onQueryStarted: async (
                { changeTo },
                { dispatch, queryFulfilled },
            ) => {
                const patchResult = dispatch(
                    organsationSlice.util.updateQueryData(
                        "getOrganisations",
                        undefined,
                        draft => {
                            draft.currentOrganisation.organisationType =
                                changeTo;
                        },
                    ),
                );
                try {
                    await queryFulfilled;
                } catch (error) {
                    patchResult.undo();
                }
            },
        }),
        SetOrganisationQuarterly: builder.mutation<
            void,
            {
                organisationId: string | undefined;
                changeTo: boolean;
            }
        >({
            query: ({ organisationId, changeTo }) => ({
                url: "organisations/setQuarter",
                method: "PUT",
                params: {
                    organisationId,
                    changeTo,
                },
            }),
            onQueryStarted: async (
                { changeTo },
                { dispatch, queryFulfilled },
            ) => {
                const patchResult = dispatch(
                    organsationSlice.util.updateQueryData(
                        "getOrganisations",
                        undefined,
                        draft => {
                            draft.currentOrganisation.quarters = changeTo;
                        },
                    ),
                );
                try {
                    await queryFulfilled;
                } catch (error) {
                    patchResult.undo();
                }
            },
        }),
        SetOrganisationBudget: builder.mutation<
            void,
            {
                organisationId: string | undefined;
                type: BudgetSources;
            }
        >({
            query: ({ organisationId, type }) => ({
                url: "organisations/budgetSource",
                method: "PUT",
                params: {
                    organisationId,
                    type,
                },
            }),
            onQueryStarted: async ({ type }, { dispatch, queryFulfilled }) => {
                const patchResult = dispatch(
                    organsationSlice.util.updateQueryData(
                        "getOrganisations",
                        undefined,
                        draft => {
                            draft.currentOrganisation.budgetSource = type;
                        },
                    ),
                );
                try {
                    await queryFulfilled;
                } catch (error) {
                    patchResult.undo();
                }
            },
        }),
        SetOrganisationFirstTime: builder.mutation<
            void,
            {
                organisationId: string | undefined;
                changeTo: boolean;
            }
        >({
            query: ({ organisationId, changeTo }) => ({
                url: "organisations/firstTime",
                method: "PUT",
                params: {
                    organisationId,
                    changeTo,
                },
            }),
            onQueryStarted: async (
                { changeTo },
                { dispatch, queryFulfilled },
            ) => {
                const patchResult = dispatch(
                    organsationSlice.util.updateQueryData(
                        "getOrganisations",
                        undefined,
                        draft => {
                            draft.currentOrganisation.firstTime = changeTo;
                        },
                    ),
                );
                try {
                    await queryFulfilled;
                } catch (error) {
                    patchResult.undo();
                }
            },
        }),
    }),
});

export default organsationSlice;

export const {
    useGetOrganisationsQuery,
    useSyncDataMutation,
    useSetOrganisationTypeMutation,
    useSetOrganisationQuarterlyMutation,
    useSetOrganisationBudgetMutation,
    useSetOrganisationFirstTimeMutation,
} = organsationSlice;

export interface UpdateResult {
    budgedUpdated: boolean;
    reportsUpdated: boolean;
    itemsUpdated: boolean;
    transactionsUpdated: boolean;
}
