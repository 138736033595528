import styled from "@emotion/styled";
import { BudgetSources } from "common/types/objects/Organisation";
import CustomerEditor from "components/inputs/BudgetEditor/CustomerEdit/CustomerEditor";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
interface Props {
    changePage: React.Dispatch<React.SetStateAction<number>>;
    index: number;
}

const EditCustomers = ({ changePage, index }: Props) => {
    const organisation = useCurrentOrg();

    const hellocashflowbudget =
        organisation?.budgetSource === BudgetSources.hellocashflow ? 0 : 1;
    const change = 1 + hellocashflowbudget;

    return (
        <Container>
            <Menu>
                <Back onClick={() => changePage(index - change)}>Back</Back>
                <h1>It's time to add in your customer data.</h1>
                <Continue onClick={() => changePage(index + 1)}>
                    Continue
                </Continue>
            </Menu>

            <CustomerEditor />
            <p style={{ width: "90%", marginLeft: 25 }}>
                Our reports show you amazing info that you may have not seen
                before. If you currently track customers, you can plug data in
                here, and once you sync your xero it will show you some extra
                reports which will blow your mind. Do you prefer to count
                transactions instead of customers? Use what is relevant to your
                business!
            </p>
            <p style={{ width: "90%", marginLeft: 25 }}>
                Not ready? Skip this part and you can adjust this later.
            </p>
            <img
                src='/assets/horizental version.png'
                alt='HelloCashflow'
                height={50}
                width={160}
                style={{
                    position: "absolute",
                    bottom: 0,
                    marginRight: "auto",
                    marginBottom: 20,
                    marginLeft: 20,
                    marginTop: 20,
                }}
            />
        </Container>
    );
};

export default EditCustomers;

const Menu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 0 10px;
    h1 {
        margin: 0;
    }
`;

const Continue = styled.button`
    padding: 10px;
    border-radius: 15px;
    width: 150px;
    height: 35px;
`;

const Back = styled.button`
    padding: 10px;
    height: 35px;
    border-radius: 15px;
    width: 150px;
    background-color: #636363;
    &:hover {
        background-color: #444444;
        color: white;
    }
`;
const Container = styled.div`
    position: relative;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    padding: 10px;
`;
