import SetSelectedMonth from "components/helper/SetSelectedMonth";
import ReportsNavbar from "components/navigation/ReportsNavbar";
import useAuthState from "hooks/firebase/useAuthState";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { theme } from "style/theme";

import ReportsCoverPage from "../ReportsCoverPage";

const CashflowSectionCoverPage = () => {
    const [user] = useAuthState();
    const currentOrganisation = useCurrentOrg();

    return (
        <>
            <ReportsCoverPage name='Money'>
                <header>
                    <h1>Hello {user?.displayName}</h1>
                    Welcome To Your <SetSelectedMonth /> Cashflow Reports for{" "}
                    {currentOrganisation?.name}
                </header>
                <p className='footnote'>*</p>
            </ReportsCoverPage>
            <ReportsNavbar
                navBackgroundColor={theme.colors.DarkPink}></ReportsNavbar>
        </>
    );
};
export default CashflowSectionCoverPage;
