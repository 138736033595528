import styled from "@emotion/styled";
import COLORS from "common/constants/COLORS";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import Switch from "components/general/Switch";
import BreakdownItemsList from "components/reports/shared/breakdownItemsList";
import useReport from "hooks/reports/useReport";
import { filterByMonths } from "lib/apiDataHelpers/filterByMonths";
import { monthItemByPriceDce } from "lib/apiDataHelpers/monthItemByPrice";
import {
    sumOfItemsforMonths,
    sumOfMonth,
    totalForMonths,
    totalOfGivenMonths,
} from "lib/apiDataHelpers/sumOfMonths";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import Period from "lib/date/dateClasses/Period";
import { toMmmmYyyy, toMmmYy } from "lib/date/reStringifyMonth";
import { useState } from "react";
import {
    Bar,
    BarChart,
    CartesianAxis,
    Cell,
    LabelList,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { theme } from "style/theme";
import { OrgData } from "types/OrganisationType";

import Bubble, { bubbleBlueClear, bubblePink } from "../../Bubble";

const ChartData = (
    selectedMonth: MonthKey,
    actual: OrgData,
    budget: OrgData,
    key: { color: string; name?: string },
) => {
    const data: Array<{ name: string; actual: number; budget: number }> = [];
    const { name } = key;
    const filtered = filterByMonths({
        obj: actual,
        latestMonth: selectedMonth,
        totalMonths: 12,
    });
    if (!name) {
        Object.entries(filtered).forEach(month =>
            data.unshift({
                name: month[0],
                actual: sumOfMonth(month[1]),
                budget: sumOfMonth(budget[month[0] as MonthKey] ?? {}),
            }),
        );
    } else {
        Object.entries(filtered).forEach(month =>
            data.unshift({
                name: month[0],
                actual: month[1]?.[name] ?? 0,
                budget: budget[month[0] as MonthKey]?.[name] ?? 0,
            }),
        );
    }
    return data;
};

const ExpensesDetailIncomeBreakdown = () => {
    const [compareLastMonth, setCompareLastMonth] = useState(false);
    const [comparePeriod, setComparePeriod] = useState(true);
    const report = useReport();
    const [key, setKey] = useState<{ color: string; name?: string }>({
        color: theme.colors.Yellow,
    });
    if (!report) {
        return null;
    }

    const {
        expenses: { budget, actual },
        items,
        reportDates: {
            financialYearStart,
            selectedMonth,
            sameMonthLastYear,
            lastMonth,
        },
    } = report;

    const compareDate = compareLastMonth ? lastMonth : sameMonthLastYear;
    const earlyMonth = comparePeriod ? sameMonthLastYear : financialYearStart;
    const period = new Period(earlyMonth, selectedMonth);

    const displayCategory = key.name && items[key.name].name; // || latestBrekdown[0][0]

    const data = () => {
        const d = monthItemByPriceDce(actual[selectedMonth]).reduce(
            (output, [name, value]) => {
                if (Object.keys(output).length < 9) {
                    output[items[name].name] = value;
                    return output;
                }
                const old = output.other ?? 0;
                output.other = old + value;
                return output;
            },
            {} as { [name: string]: number },
        );
        const c = monthItemByPriceDce(actual[compareDate]).reduce(
            (output, [name, value]) => {
                output[items[name].name] = value;
                return output;
            },
            {} as { [name: string]: number },
        );

        return [
            {
                name: toMmmYy(selectedMonth),
                ...d,
            },
            {
                name: toMmmYy(compareDate),
                ...c,
            },
        ];
    };
    const totalItem = (item: string, obj: OrgData) => {
        return period.getMonths().reduce((totals, month) => {
            totals += obj?.[month]?.[item] ?? 0;
            return totals;
        }, 0);
    };

    const barChartData = () => {
        return key.name
            ? [
                  {
                      name: "Actual",
                      color: key.color,
                      key: totalItem(key.name, actual),
                  },
                  {
                      name: "Budget",
                      color: theme.colors.DarkPink,
                      key: totalItem(key.name, budget),
                  },
              ]
            : [
                  {
                      name: "Actual",
                      color: theme.colors.Yellow,
                      key: totalForMonths({
                          org: actual,
                          latestMonth: selectedMonth,
                          earliestMonth: earlyMonth,
                      }),
                  },
                  {
                      name: "Budget",
                      color: theme.colors.DarkPink,
                      key: totalForMonths({
                          org: budget,
                          latestMonth: selectedMonth,
                          earliestMonth: earlyMonth,
                      }),
                  },
              ];
    };

    const averageSpeedOverPeriod = key.name
        ? totalItem(key.name, actual) / period.getMonths().length
        : totalOfGivenMonths(actual, period.getMonths()) / 12;

    return (
        <Container>
            <h1>
                Here's how your expenses are broken down between{" "}
                {toMmmmYyyy(selectedMonth)} and {toMmmmYyyy(compareDate)}
            </h1>
            <div>
                <Column>
                    <div
                        style={{
                            width: "100%",
                            position: "relative",
                        }}>
                        <SwitchMonths>
                            <SwitchWords>
                                <b>
                                    {compareLastMonth
                                        ? "last month"
                                        : "last year"}
                                </b>
                            </SwitchWords>
                            <Switch
                                cbSwitch={setCompareLastMonth}
                                toggle={compareLastMonth}
                                width={39}
                                border={theme.colors.DarkBlue}
                                colorOption1={theme.colors.Pink}
                                colorOption2={theme.colors.DarkBlue}
                                borderWideth={1}
                            />
                        </SwitchMonths>
                        <ResponsiveContainer width='100%' height={200}>
                            <BarChart
                                barCategoryGap={20}
                                data={data()}
                                layout='vertical'
                                margin={{
                                    top: 5,
                                    right: 10,
                                    left: 0,
                                    bottom: 5,
                                }}>
                                <Tooltip
                                    labelStyle={{
                                        color: theme.colors.DarkBlue,
                                    }}
                                    formatter={value =>
                                        Writer.FormatCurrency(value as number)
                                    }
                                />
                                <CartesianAxis axisLine={false} />
                                <XAxis
                                    tickCount={10}
                                    type='number'
                                    style={{ fill: theme.colors.DarkBlue }}
                                    stroke={"none"}
                                    tickFormatter={(value: number) =>
                                        Writer.FormatCurrency(value)
                                    }
                                />
                                <YAxis
                                    width={70}
                                    type='category'
                                    dataKey='name'
                                    style={{ fill: theme.colors.DarkBlue }}
                                    stroke={"none"}
                                />
                                {Object.keys(data()[0]).map((key, index) => {
                                    if (key === "name") return null;
                                    return (
                                        <Bar
                                            key={index}
                                            dataKey={key}
                                            stackId='a'
                                            fill={
                                                COLORS.PIE_CHART[
                                                    (index - 1) %
                                                        COLORS.PIE_CHART.length
                                                ]
                                            }
                                        />
                                    );
                                })}
                            </BarChart>
                        </ResponsiveContainer>
                    </div>

                    <h3>
                        {displayCategory
                            ? `Here's a look at your total ${displayCategory} expenses`
                            : "Here's a look at your total expenses"}
                    </h3>
                    <div style={{ width: "100%" }}>
                        <ResponsiveContainer width='100%' height={200}>
                            <LineChart
                                margin={{
                                    right: 15,
                                }}
                                data={ChartData(
                                    selectedMonth,
                                    actual,
                                    budget,
                                    key,
                                )}>
                                <Tooltip
                                    labelStyle={{
                                        color: theme.colors.DarkBlue,
                                    }}
                                    labelFormatter={props => {
                                        return toMmmYy(props);
                                    }}
                                    formatter={value =>
                                        Writer.FormatCurrency(value as number)
                                    }
                                />
                                <XAxis
                                    interval={0}
                                    tick={tickMonthWrap}
                                    type='category'
                                    dataKey='name'
                                    style={{ fill: theme.colors.DarkBlue }}
                                    stroke={"none"}
                                />
                                <YAxis
                                    width={70}
                                    type='number'
                                    style={{ fill: theme.colors.DarkBlue }}
                                    stroke={"none"}
                                    tickFormatter={(value: number) =>
                                        Writer.FormatCurrency(value)
                                    }
                                />
                                <Line
                                    type='monotone'
                                    dataKey='actual'
                                    name='Actual'
                                    stroke={key.color}
                                    strokeWidth={2}
                                />
                                <Line
                                    type='monotone'
                                    dataKey='budget'
                                    name='Budget'
                                    stroke={theme.colors.DarkPink}
                                    strokeWidth={2}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <ItemGraphs>
                        <h3>
                            Here's how{" "}
                            {key.name
                                ? items[key.name].name
                                : "total expense's"}{" "}
                            is tracking against your budget for{" "}
                            {comparePeriod
                                ? "the last twelve months"
                                : "year to date"}
                        </h3>
                        <div>
                            <ResponsiveContainer width='100%' height={200}>
                                <BarChart
                                    barCategoryGap={20}
                                    data={barChartData()}
                                    layout='vertical'
                                    margin={{
                                        top: 5,
                                        right: 100,
                                        left: 0,
                                        bottom: 5,
                                    }}>
                                    <CartesianAxis axisLine={false} />
                                    <XAxis
                                        type='number'
                                        style={{ fill: theme.colors.DarkBlue }}
                                        stroke={"none"}
                                        tickFormatter={(value: number) =>
                                            Writer.FormatCurrency(value)
                                        }
                                    />
                                    <YAxis
                                        type='category'
                                        dataKey='name'
                                        style={{ fill: theme.colors.DarkBlue }}
                                        stroke={"none"}
                                    />
                                    <Bar
                                        isAnimationActive={false}
                                        dataKey='key'
                                        fill={theme.colors.Yellow}>
                                        <LabelList
                                            style={{
                                                fontWeight: "bold",
                                                fontSize: "18px",
                                            }}
                                            fill={theme.colors.DarkBlue}
                                            formatter={(value: number) =>
                                                Writer.FormatCurrency(value)
                                            }
                                            dataKey='key'
                                            position='right'
                                        />
                                        {barChartData().map((entry, index) => (
                                            <Cell
                                                key={`cell-${index}`}
                                                fill={entry.color}
                                            />
                                        ))}
                                    </Bar>
                                </BarChart>
                            </ResponsiveContainer>
                            <div style={{ width: 175 }}>
                                <Bubble style={bubblePink}>
                                    <h1>
                                        Average monthly spend for{" "}
                                        {comparePeriod
                                            ? "last 12 months"
                                            : "year to date"}
                                    </h1>
                                    <mark>
                                        {Writer.FormatCurrency(
                                            averageSpeedOverPeriod,
                                        )}
                                    </mark>
                                </Bubble>
                            </div>
                        </div>
                        <Toggle>
                            <span>
                                {comparePeriod ? (
                                    <>Last 12 Months</>
                                ) : (
                                    <>Year to Date</>
                                )}
                            </span>
                            <div>
                                <Switch
                                    toggle={comparePeriod}
                                    cbSwitch={setComparePeriod}
                                    width={50}
                                    border={theme.colors.DarkPink}
                                    colorOption1={theme.colors.DarkPink}
                                    colorOption2={theme.colors.Pink}
                                    borderWideth={1}
                                />
                            </div>
                        </Toggle>
                    </ItemGraphs>

                    <h3>
                        <>
                            Here's how{" "}
                            {displayCategory ? displayCategory : "the total"}{" "}
                            expenses is tracking against your revenue
                        </>
                    </h3>
                    <Bubbles>
                        <Bubble style={bubbleBlueClear}>
                            <h1>
                                <>
                                    This month{" "}
                                    {displayCategory
                                        ? displayCategory
                                        : "total expenses"}{" "}
                                    was{" "}
                                </>
                            </h1>
                            <mark>
                                {displayCategory
                                    ? key.name &&
                                      Math.round(
                                          (actual[selectedMonth][key.name] /
                                              sumOfMonth(
                                                  report?.revenue.actual[
                                                      selectedMonth
                                                  ],
                                              )) *
                                              100 *
                                              10,
                                      ) / 10
                                    : Math.round(
                                          (sumOfMonth(actual[selectedMonth]) /
                                              sumOfMonth(
                                                  report?.revenue.actual[
                                                      selectedMonth
                                                  ],
                                              )) *
                                              100 *
                                              10,
                                      ) / 10}
                                %
                            </mark>
                            <span>Of your revenue</span>
                        </Bubble>
                        <Bubble style={bubbleBlueClear}>
                            <h1>
                                <>
                                    So far this year to date,{" "}
                                    {displayCategory
                                        ? displayCategory
                                        : "total expenses"}{" "}
                                    is{" "}
                                </>
                            </h1>
                            <mark>
                                {displayCategory
                                    ? report &&
                                      key.name &&
                                      Math.round(
                                          (sumOfItemsforMonths(
                                              filterByMonths({
                                                  obj: actual,
                                                  latestMonth: selectedMonth,
                                                  earliestMonth:
                                                      financialYearStart,
                                              }),
                                          )[key.name] /
                                              totalForMonths({
                                                  org: report.revenue.actual,
                                                  latestMonth: selectedMonth,
                                                  earliestMonth:
                                                      financialYearStart,
                                              })) *
                                              100 *
                                              10,
                                      ) / 10
                                    : report &&
                                      Math.round(
                                          (totalForMonths({
                                              org: actual,
                                              latestMonth: selectedMonth,
                                              earliestMonth: financialYearStart,
                                          }) /
                                              totalForMonths({
                                                  org: report.revenue.actual,
                                                  latestMonth: selectedMonth,
                                                  earliestMonth:
                                                      financialYearStart,
                                              })) *
                                              100 *
                                              10,
                                      ) / 10}
                                %
                            </mark>
                            <span>Of your revenue</span>
                        </Bubble>
                        <Bubble style={bubbleBlueClear}>
                            <h1>
                                <>
                                    You budgeted{" "}
                                    {displayCategory
                                        ? displayCategory
                                        : "total expenses"}{" "}
                                    to be
                                </>
                            </h1>
                            <mark>
                                {report &&
                                    (displayCategory
                                        ? key.name &&
                                          Math.round(
                                              (sumOfItemsforMonths(
                                                  filterByMonths({
                                                      obj: budget,
                                                      latestMonth:
                                                          selectedMonth,
                                                      earliestMonth:
                                                          sameMonthLastYear,
                                                  }),
                                              )[key.name] /
                                                  totalForMonths({
                                                      org: report.revenue
                                                          .actual,
                                                      latestMonth:
                                                          selectedMonth,
                                                      earliestMonth:
                                                          sameMonthLastYear,
                                                  })) *
                                                  100 *
                                                  10,
                                          ) / 10
                                        : Math.round(
                                              (totalForMonths({
                                                  org: budget,
                                                  latestMonth: selectedMonth,
                                                  earliestMonth:
                                                      sameMonthLastYear,
                                              }) /
                                                  totalForMonths({
                                                      org: report.revenue
                                                          .actual,
                                                      latestMonth:
                                                          selectedMonth,
                                                      earliestMonth:
                                                          sameMonthLastYear,
                                                  })) *
                                                  100 *
                                                  10,
                                          ) / 10)}
                                %
                            </mark>
                            <span>Of your revenue for the last 12 months</span>
                        </Bubble>
                    </Bubbles>
                    <ItemList>
                        <BreakdownItemsList
                            setKey={setKey}
                            actual={actual}
                            items={items}
                            compareMonth={compareDate}
                        />
                    </ItemList>
                </Column>
            </div>
        </Container>
    );
};

export default ExpensesDetailIncomeBreakdown;

const ItemGraphs = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    position: relative;
    padding-top: 23px;

    > h3 {
        margin: 0;
    }
    > div {
        display: flex;
        width: 100%;
    }
`;

const ItemList = styled.div`
    width: 100%;
    margin: 20px 0;
    padding: 20px;
    border: 2px solid ${theme.colors.DarkBlue};
    border-radius: 5px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    > h1 {
        font-size: ${theme.fonts.defaultHeaderFontSize};
    }
`;

const Bubbles = styled.div`
    display: flex;
    flex-direction: row;
    align-items: center;
    width: 100%;
    justify-content: space-evenly;
    h1 {
        font-size: ${({ theme }) => theme.fonts.defaultFontSize};
        margin: 0;
    }
`;

const Column = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: ${({ theme }) => theme.sizes.modelWidth};
    > div {
        margin-top: 40px;
    }
    h3 {
        margin: 0;
        margin-top: 80px;
        font-size: ${theme.fonts.defaultSubHeaderFontSize};
    }
`;

const SwitchWords = styled.span`
    margin-right: 10px;
    font-size: 12px;
    letter-spacing: 3.2px;
`;

const SwitchMonths = styled.div`
    position: absolute;
    right: 35px;
    top: -30px;
    display: flex;
    align-items: center;
    height: 40px;

    color: ${({ theme }) => theme.colors.DarkBlue};
    text-transform: uppercase;
`;
const Toggle = styled.div`
    position: absolute;
    top: 0px;
    right: 0px;
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 300px;
    font-size: 15px;
    > div {
        width: 50px;
    }
    > span {
        margin-right: 10px;
    }
`;
