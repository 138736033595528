import MonthKey from "common/types/monthKey";
import { sumOfMonth } from "lib/apiDataHelpers/sumOfMonths";
import { addMonthsTo } from "lib/date/addMonthsTo";
import {
    CashflowData,
    MonthlySalesTaxCashSummary,
    OrgData,
} from "types/OrganisationType";

/**
 * Calculate projected cashflow based on historical data and budgets.
 *
 * @param {MonthKey} selectedMonth - The selected month for the report.
 * @param {OrgData} expensesBudget - Expenses budget data indexed by MonthKey.
 * @param {OrgData} revenueBudget - Revenue budget data indexed by MonthKey.
 * @param {OrgData} transfers - Transfers data indexed by MonthKey.
 * @param {CashflowData} cashflowHistory - Historical cashflow data.
 * @returns {CashflowData} - Projected cashflow data.
 */
export function getProjectedCashflow(
    selectedMonth: MonthKey,
    expensesBudget: OrgData,
    revenueBudget: OrgData,
    cashflowHistory: { [month: string]: MonthlySalesTaxCashSummary },
): CashflowData {
    // Initialize an object to store the historical totals for spend, receive, tax paid, and tax collected.
    const totals = {
        spend: 0,
        recieve: 0,
        taxPaid: 0,
        taxCollected: 0,
        recieveTransfer: 0,
        sendTransfer: 0,
    };

    // Iterate through historical cashflow data and sum the spend, receive, tax paid, and tax collected.
    Object.keys(cashflowHistory).forEach(m => {
        const monthData = cashflowHistory[m];
        totals.recieve += monthData.cashIn;
        totals.spend += monthData.cashOut;
        totals.taxPaid += monthData.taxComponentCashOut;
        totals.taxCollected += monthData.taxComponentCashIn;
        totals.recieveTransfer += monthData.recieveTransfer;
        totals.sendTransfer += monthData.sendTransfer;
    });

    // Calculate effective tax rates for spend and receive based on historical data.
    // To avoid division by zero issues, check if totals.spend or totals.recieve are non-zero before dividing.
    const etrSpend = totals.spend !== 0 ? totals.taxPaid / totals.spend : 0;
    const etrRecieve =
        totals.recieve !== 0 ? totals.taxCollected / totals.recieve : 0;

    // Initialize an empty object for the CashflowData result.
    const rtn: CashflowData = {};

    // Loop through the next 12 months.
    for (let monthAdd = 1; monthAdd <= 12; monthAdd++) {
        // Calculate the future month based on the selected month and the current loop iteration.
        const futureMonth: MonthKey = addMonthsTo(selectedMonth, monthAdd);

        // Calculate the total transfers, spend, and receive for each future month.
        const spend = sumOfMonth(expensesBudget[futureMonth]) ?? 0;
        const recieve = sumOfMonth(revenueBudget[futureMonth]) ?? 0;

        // Calculate tax collected and tax paid for each future month based on the effective tax rates.
        const taxCollected = etrRecieve * recieve;
        const taxPaid = etrSpend * spend;

        // Store spend, receive, taxCollected, taxPaid, and net values for each future month in the CashflowData result object.
        rtn[futureMonth] = {
            spend,
            recieve,
            netCashMovement: recieve - spend,
            //TODO
            recieveExTax: 0,
            spendExTax: 0,
            taxCollected: taxCollected,
            taxPaid: taxPaid,
            netTaxMovement: taxCollected - taxPaid,
        };
    }

    return rtn;
}
