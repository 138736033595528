import styled from "@emotion/styled";
import { OrganisationTypes } from "common/types/objects/Organisation";
import RevenueAndExpensesEditor from "components/inputs/BudgetEditor/revenuenexpenses/RevenueAndExpensesEditor";
import useBudgetFill from "hooks/budget/useBudgetFill";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import useReport from "hooks/reports/useReport";
import { addMonthsTo } from "lib/date/addMonthsTo";
import {
    generateMonthsKeysBackFromMonth,
    generateMonthsKeysForwardFromMonth,
    latestCompleteMonth,
    yearEndedLastCompleteMonth,
} from "lib/date/dateConst";
import { useEffect, useMemo, useState } from "react";
import { useAppSelector } from "redux/store";
import { OrgData } from "types/OrganisationType";
const monthsActual = generateMonthsKeysBackFromMonth(latestCompleteMonth, 3);
const monthsForecast = generateMonthsKeysForwardFromMonth(
    latestCompleteMonth,
    12,
);
interface Props {
    changePage: React.Dispatch<React.SetStateAction<number>>;
    index: number;
    xeroBudget:
        | {
              revenue: OrgData;
              expenses: OrgData;
          }
        | undefined;
}
const possibleBudgetMonths = [6, 12, 18, 24, 36];
const possibleActualMonths = [0, 3, 6, 12, 18, 24];

const EditBudget = ({ changePage, index, xeroBudget }: Props) => {
    const organisation = useCurrentOrg();

    console.log(xeroBudget);
    const setMonths = (length: number, actual: boolean) => {
        localStorage.setItem(
            `budget-editor months ${actual}`,
            length.toString(),
        );
        const months = Array.from(Array(length).keys()).map(i =>
            addMonthsTo(latestCompleteMonth, actual ? -i : i + 1),
        );
        if (actual) {
            setActualMonth(length);
            setActualMonths(months.reverse());
        } else {
            setForecastMonth(length);
            setForecastMonths(months);
        }
    };
    const data = useReport();
    const [addedBudget, setAddedBudget] = useState(false);
    const { futureFill, clearBudget } = useBudgetFill();
    const dates = useAppSelector(state => state.dates);
    const [actualMonth, setActualMonth] = useState(
        localStorage.getItem(`budget-editor months ${true}`)
            ? +(localStorage.getItem(`budget-editor months ${true}`) as string)
            : 3,
    );
    const [forecastMonth, setForecastMonth] = useState(
        localStorage.getItem(`budget-editor months ${false}`)
            ? +(localStorage.getItem(`budget-editor months ${false}`) as string)
            : 12,
    );
    const [actualMonths, setActualMonths] = useState(monthsActual);
    const [forecastMonths, setForecastMonths] = useState(monthsForecast);

    const hasBudget = useMemo(() => {
        if (data && dates) {
            const forwardRevBudget = Object.keys(data.revenue.budget).filter(
                e => e > dates?.selectedMonth,
            );
            const forwardExpensesBudget = Object.keys(
                data.revenue.budget,
            ).filter(e => e > dates?.selectedMonth);
            return (
                forwardRevBudget.length < 5 && forwardExpensesBudget.length < 5
            );
        }
    }, [data, dates]);

    useEffect(() => {
        if (!hasBudget) {
            setAddedBudget(true);
            if (xeroBudget && xeroBudget.expenses)
                futureFill(yearEndedLastCompleteMonth, false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const subchange =
        organisation?.organisationType === OrganisationTypes.subscription
            ? 0
            : 1;
    const change = 1 + subchange;

    return (
        <Container>
            <Menu>
                <Back onClick={() => changePage(index - 1)}>Back</Back>
                <h1>It's time to Budget your Revenue and Expenses</h1>
                <Continue onClick={() => changePage(index + change)}>
                    Continue
                </Continue>
            </Menu>
            <div style={{ marginLeft: 30, marginTop: 20 }}>
                {addedBudget &&
                    "We have gone ahead and filled your budget with last years data."}
                {/* {true ? "last years data" : "your xero budget"} */}
                <br />
                {/* if you would like to auto fill another way click below
                <button></button> */}
            </div>
            <div style={{ marginLeft: 30 }}>
                This is your last year's actual numbers (used as your budget for
                this coming year). You can edit it now, or just click continue
                (you can always edit it later if you need).
            </div>
            <div style={{ marginBottom: 10 }}>
                <button
                    onClick={() =>
                        clearBudget(
                            generateMonthsKeysForwardFromMonth(
                                latestCompleteMonth,
                                12,
                            ),
                        )
                    }>
                    Clear Budget
                </button>{" "}
            </div>
            <Editor>
                <RevenueAndExpensesEditor
                    actualMonths={actualMonths}
                    forecastMonths={forecastMonths}
                />
            </Editor>
            <div
                style={{
                    width: 600,
                    display: "flex",
                    flexDirection: "column",
                }}>
                <h3
                    style={{
                        width: "100%",
                        textAlign: "center",
                        display: "inline-block",
                    }}>
                    Select Months to show
                </h3>
                <div
                    style={{ display: "flex", justifyContent: "space-evenly" }}>
                    <PossibleMonthsContainer>
                        <h3>Actual Months</h3>
                        <div>
                            {possibleActualMonths.map(number => {
                                return (
                                    <PossibleMonths
                                        key={number}
                                        selected={actualMonth === number}
                                        onClick={() => setMonths(number, true)}>
                                        <span>{number}</span>
                                    </PossibleMonths>
                                );
                            })}
                        </div>
                        <span>A minimum of 12 months is recommended</span>
                    </PossibleMonthsContainer>
                    <PossibleMonthsContainer>
                        <h3>Budget Months</h3>
                        <div>
                            {possibleBudgetMonths.map(number => {
                                return (
                                    <PossibleMonths
                                        key={number}
                                        selected={forecastMonth === number}
                                        onClick={() =>
                                            setMonths(number, false)
                                        }>
                                        <span>{number}</span>
                                    </PossibleMonths>
                                );
                            })}
                        </div>
                        <span>
                            <br />
                        </span>
                    </PossibleMonthsContainer>
                </div>
            </div>
            <img
                src='/assets/horizental version.png'
                alt='HelloCashflow'
                height={50}
                width={160}
                style={{
                    marginRight: "auto",
                    marginBottom: 20,
                    marginLeft: 20,
                    marginTop: 20,
                }}
            />
        </Container>
    );
};

export default EditBudget;

const Editor = styled.div`
    height: 100%;
    /* padding: px; */
    border: 7px solid ${props => props.theme.colors.DarkBlue};
`;

const Container = styled.div`
    position: relative;
    overflow-y: auto;
    height: 100%;
    width: 100%;
    padding: 10px;
    /* > div {
        height: 55vh !important;
    } */
`;
const Menu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 0 10px;
    h1 {
        margin: 0;
    }
`;

const Continue = styled.button`
    padding: 10px;
    border-radius: 15px;
    width: 150px;
    height: 35px;
`;

const Back = styled.button`
    padding: 10px;
    height: 35px;
    border-radius: 15px;
    width: 150px;
    background-color: #636363;
    &:hover {
        background-color: #444444;
        color: white;
    }
`;

const PossibleMonthsContainer = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 8px;
    background-color: white;
    border-radius: 10px;
    border: 1px solid ${props => props.theme.colors.DarkBlue};
    h3 {
        margin: 0;
    }

    span {
        font-size: 12px;
        text-align: center;
        width: 100%;
    }
    > div {
        display: flex;
        width: 250px;
        justify-content: space-evenly;
    }
`;

const PossibleMonths = styled.div<{ selected: boolean }>`
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 4px;
    border-radius: 4px;
    width: 24px;
    height: 24px;
    text-align: center;
    background-color: ${props =>
        props.selected ? props.theme.colors.LightBlue : "#c5c5c5"};
    cursor: pointer;

    &:hover {
        ${props => !props.selected && "background-color: #6b6b6b97"}
    }
`;
