import UIErrorBoundary from "components/error-boundaries/SectionErrorBoundary";
import BreakDownsBalanceSheet from "components/reports/breakdowns/BalanceSheet";
import BreakDownsBugetAccuracy from "components/reports/breakdowns/BugetAccuracy";
import BreakDownsCoverPage from "components/reports/breakdowns/CoverPage";
import BreakDownsSectionRevenueVsExpenses from "components/reports/breakdowns/RevneueVsExpenses/RevenueVsExpensesMonthly";
import BudgetGraphs from "components/reports/breakdowns/revvsexpenbudget/BudgetGraphs";
import BreakDownsSectionIntroPage from "components/reports/breakdowns/SectionIntroPage";
import Footer from "components/reports/ReportsFooter/ReportsFooter";
import { useEffect } from "react";

const ProfitPage = () => {
    useEffect(() => {
        document.title = "My Profit";
    }, []);
    return (
        <>
            <UIErrorBoundary>
                <BreakDownsCoverPage />
                <BreakDownsSectionIntroPage />
                <BudgetGraphs />
                <BreakDownsSectionRevenueVsExpenses />
                <BreakDownsBalanceSheet />
                <BreakDownsBugetAccuracy />
            </UIErrorBoundary>
            <Footer />
        </>
    );
};

export default ProfitPage;
