import styled from "@emotion/styled";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import Switch from "components/general/Switch";
import useCashflow from "hooks/reports/useCashflow";
import useReport from "hooks/reports/useReport";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { toMmmYy } from "lib/date/reStringifyMonth";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import {
    Bar,
    Cell,
    ComposedChart,
    Legend,
    Line,
    ReferenceArea,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { useGetBankBalancesQuery, useGetTaxQuery } from "redux/rtkQuery/reports";
import { theme } from "style/theme";
import {
    BankBalances,
    MonthlySalesTaxCashSummary,
    OrgData,
} from "types/OrganisationType";

import { getProjectedCashflow } from "./getProjectedCashflow";

interface ChartItem {
    name: string;
    bankBalance?: number;
    spend?: number;
    recieve?: number;
}

const CashflowGraphSection = () => {
    const cashflow = useCashflow();
    const report = useReport();

    const [showBars, setShowBars] = useState(true);

    const { data: taxData } = useGetTaxQuery(
        report?.organisation.organisationId ?? skipToken,
    );

    const bankAccounts = useGetBankBalancesQuery(report?.organisation.organisationId ?? skipToken);

    if (!(report && cashflow && taxData && bankAccounts.data)) return null;

    const {
        organisation,
        reportDates: { selectedMonth, lastTwelveMonths },
        expenses: { budget: expensesBudget },
        revenue: { budget: revenueBudget },
    } = report;

    const projectedCashflow = getProjectedCashflow(
        selectedMonth,
        expensesBudget,
        revenueBudget,
        cashflow.cashTaxSummary,
    );

    const chartdata = getChartData(
        lastTwelveMonths,
        cashflow.cashTaxSummary,
        bankAccounts.data,
        projectedCashflow,
    );

    const linedata = 6; //actual ? (showBars ? actual : actual + 0.0225) : undefined;
    return (
        <>
            <ItemSection column minHeight={496}>
                <Custom>
                    <h1>Here is your cashflow forecast</h1>
                    <p>
                        Cashflow is the money entering and leaving your bank
                        account(s). Cashflow dosen't tell you if you are
                        profitable (see{" "}
                        <NavLink to={"/reports/my-profit"}>my-profit</NavLink>)
                        but will tell you if you have money in the bank.
                    </p>
                    <p></p>
                </Custom>
                <ResponsiveContainer height={400} width='100%'>
                    <ComposedChartStyled
                        data={chartdata}
                        margin={{
                            top: 20,
                            right: 20,
                            bottom: 20,
                        }}>
                        <ReferenceLine y={0} stroke='#fff' />
                        {!showBars && (
                            <ReferenceLine
                                x={selectedMonth.addMonthsTo(1)}
                                offset={showBars ? 0 : 100}
                                stroke='#ffffff'
                            />
                        )}
                        <ReferenceArea
                            x1={selectedMonth.addMonthsTo(1)}
                            fill={"#3B3853"}
                            ifOverflow='extendDomain'
                            label={({ viewBox }) => {
                                return (
                                    <text
                                        x={viewBox.x * 1.05}
                                        y={viewBox.y - 10}
                                        textAnchor='middle'
                                        fontSize={12}
                                        fill='white'>
                                        Projection →
                                    </text>
                                );
                            }}
                        />
                        <XAxis
                            type='category'
                            interval={0}
                            dataKey='name'
                            style={{ fill: theme.colors.Pink }}
                            stroke={"none"}
                            tick={tickMonthWrap}
                        />
                        <YAxis
                            type='number'
                            style={{ fill: theme.colors.Pink }}
                            stroke={"none"}
                            tickFormatter={(value: number) =>
                                Writer.FormatCurrency(value)
                            }
                        />
                        <Tooltip content={tooltip} />
                        <Legend />
                        {showBars && (
                            <>
                                <Bar
                                    dataKey='recieve'
                                    name='Cash in'
                                    fill={theme.colors.LightBlue}>
                                    {chartdata.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            strokeWidth={index === 2 ? 4 : 1}
                                        />
                                    ))}
                                </Bar>
                                <Bar
                                    dataKey='spend'
                                    name='Cash out'
                                    fill={theme.colors.Pie2}>
                                    {chartdata.map((entry, index) => (
                                        <Cell key={`cell-${index}`} />
                                    ))}
                                </Bar>
                            </>
                        )}
                        <Line
                            strokeWidth={3}
                            stroke={theme.colors.PalePink}
                            dot={false}
                            type='monotone'
                            dataKey='bankBalance'
                            name='Bank Balance'
                        />
                    </ComposedChartStyled>
                </ResponsiveContainer>
                <SwitchContainer>
                    <Switch
                        colorOption1={theme.colors.Yellow}
                        colorOption2={theme.colors.DarkPink}
                        textOption1='hide'
                        textOption2='show'
                        width={70}
                        toggle={showBars}
                        cbSwitch={setShowBars}
                    />
                </SwitchContainer>
                <Info>
                    This graph shows your cash on hand for the last 12 months,
                    including your cashflow forecast entered above.
                </Info>
            </ItemSection>
        </>
    );
};
export default CashflowGraphSection;
const EditBudget = styled.div`
    margin-top: 10px;
    color: ${({ theme }) => theme.colors.DarkBlue};
    fill: ${({ theme }) => theme.colors.DarkBlue};
`;

const EditContainer = styled.div`
    background-color: ${({ theme }) => theme.colors.Pink};
    padding: 7px;
    border-radius: 5px;
`;

const Custom = styled.div`
    a {
        position: relative;
        text-decoration: none;
        color: ${props => props.theme.colors.DarkPink};
        &:hover {
            &::after {
                left: 16px;
                width: calc(100%);
                background: ${props => props.theme.colors.DarkPink};
                top: 14px;
                left: 0;
                content: "";
                height: 2px;
                position: absolute;
                color: ${props => props.theme.colors.DarkPink};
                font-family: Inter, X-LocaleSpecific, sans-serif;
                font-size: 16px;
                font-weight: 700;
                line-height: 1.5;
            }
        }
    }
`;

const ComposedChartStyled = styled(ComposedChart)`
    margin-left: -20px;
`;

const Info = styled.p`
    margin: 0;
`;
const SwitchContainer = styled.div`
    position: absolute;
    top: 50px;
    right: 50px;
`;

const Button = styled.div`
    width: 190px;
    font-size: 14px;
    -moz-box-align: center;
    align-items: center;
    background-color: ${props => props.theme.colors.Pink};
    border: 2px solid ${props => props.theme.colors.DarkBlue};
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -moz-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: currentcolor none 0px !important;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.colors.DarkPink};
        svg {
            fill: ${props => props.theme.colors.DarkPink};
        }
        span {
            background: ${props => props.theme.colors.DarkPink};
        }
    }
`;

const SelectDown = styled.div`
    -moz-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;

    span {
        align-self: stretch;
        background-color: ${props => props.theme.colors.DarkBlue};
        margin-bottom: 8px;
        margin-top: 8px;
        width: 1px;
        box-sizing: border-box;
    }
    div {
        color: ${props => props.theme.colors.DarkBlue};
        display: flex;
        padding: 8px;
        transition: color 150ms ease 0s;
        box-sizing: border-box;
    }
`;
const SelectInput = styled.div`
    -moz-box-align: center;
    align-items: center;
    display: grid;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    text-align: center;
`;

const tooltip = (props: any) => {
    return (
        <div
            style={{
                backgroundColor: "white",
                padding: "10px",
            }}>
            <div>
                <strong
                    style={{
                        color: theme.colors.DarkBlue,
                    }}>
                    {toMmmYy(props.label)}
                </strong>
            </div>
            <div>
                {props.payload.map((p: any) => {
                    const color =
                        p.dataKey === "BankBalance"
                            ? theme.colors.DarkAqua
                            : p.color;
                    return (
                        <div
                            style={{
                                color: color,
                            }}>
                            <strong>{p.name}</strong>{" "}
                            <span>{Writer.FormatCurrency(p.value)}</span>
                        </div>
                    );
                })}
                <strong>{/* {props.payload[0].value} */}</strong>
            </div>
        </div>
    );
};
function getChartData(
    historyToShow: MonthKey[],
    cashflow: Record<MonthKey, MonthlySalesTaxCashSummary>,
    bankBalance: BankBalances,
    cashflowForecast?: OrgData,
): ChartItem[] {
    const accounts = Object.keys(bankBalance);

    const data: ChartItem[] = historyToShow.map(m => ({
        name: m,
        spend: cashflow[m]?.cashOutExTax ?? 0,
        recieve: cashflow[m]?.cashInExTax ?? 0,
        bankBalance: accounts.reduce(
            (acc, account) => acc + (bankBalance[account][m] || 0),
            0,
        ),
    }));

    if (cashflowForecast) {
        let closingBalance = data[data.length - 1]?.bankBalance || 0;
        let m = addMonthsTo(historyToShow[historyToShow.length - 1], 1);
        while (cashflowForecast[m]) {
            const projected = cashflowForecast[m];
            closingBalance =
                closingBalance + (projected.recieve - projected.spend);
            data.push({
                name: m,
                spend: projected.spend,
                recieve: projected.recieve,
                bankBalance: closingBalance,
            });
            m = addMonthsTo(m, 1);
        }
    }

    return data;
}
