import styled from "@emotion/styled";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import ReportsModal from "components/navigation/expandableSection/ReportsModal";
import useReport from "hooks/reports/useReport";
import { sumOfMonth, sumOfQuarter } from "lib/apiDataHelpers/sumOfMonths";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { toMmmmYyyy, toMmmYy } from "lib/date/reStringifyMonth";
import {
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { theme } from "style/theme";

import RevenueDetailYtdProgress from "./ModelYtdProgress";

interface ChartItem {
    name: string;
    Actual: number;
    Budget: number;
}
// const getChartData = (
//     startMonth: MonthKey,
//     monthCount: number,
//     actual: OrgData,
//     budget: OrgData,
// ) => {
//     const rows: ChartItem[] = [];
//     const filteredActual = sumOfMonths(
//         filterByMonths({
//             obj: actual,
//             latestMonth: startMonth,
//             totalMonths: monthCount,
//         }),
//     );
//     const filteredBudget = sumOfMonths(
//         filterByMonths({
//             obj: budget,
//             latestMonth: startMonth,
//             totalMonths: monthCount,
//         }),
//     );
//     (Object.keys(filteredActual) as MonthKey[]).forEach(month => {
//         rows.unshift({
//             Actual: filteredActual[month],
//             Budget: filteredBudget[month],
//             name: month,
//         });
//     });
//     return rows;
// };
const RevenueSectionYtdProgress = () => {
    const report = useReport();

    if (!report) {
        return null;
    }
    const {
        revenue: { actual, budget },
        reportDates: {
            financialYearStart,
            monthsInToYear,
            selectedMonth,
            lastTwelveMonths,
            showQuarterly,
            lastEightQuarters,
        },
    } = report;

    const chartData = showQuarterly
        ? lastEightQuarters.map<ChartItem>(m => ({
              Actual: sumOfQuarter(actual, m),
              Budget: sumOfQuarter(budget, m),
              name: m,
          }))
        : lastTwelveMonths.map<ChartItem>(m => ({
              Actual: sumOfMonth(actual[m]),
              Budget: sumOfMonth(budget[m]),
              name: m,
          }));

    return (
        <>
            <ItemSection id='progress-ytd' minHeight={393}>
                <Container>
                    {/* <h1>Here's how your revenue is tracking for the {useFullYear ? "last twelve months" : "year so far"}</h1> */}
                    <h1>Here's how your revenue is tracking</h1>
                    <ResponsiveContainer height={200} width='100%'>
                        <LineChart
                            // data={getChartData(
                            //     selectedMonth,
                            //     12,
                            //     actual,
                            //     budget,
                            // )}
                            data={chartData}
                            margin={{
                                right: 10,
                            }}>
                            <XAxis
                                interval={0}
                                type='category'
                                dataKey='name'
                                style={{ fill: theme.colors.Pink }}
                                stroke={"none"}
                                tick={tickMonthWrap}
                            />
                            <YAxis
                                type='number'
                                style={{ fill: theme.colors.Pink }}
                                stroke={"none"}
                                tickFormatter={(value: number) =>
                                    Writer.FormatCurrency(value)
                                }
                            />
                            <Tooltip
                                labelStyle={{
                                    color: theme.colors.DarkBlue,
                                }}
                                labelFormatter={props => toMmmYy(props)}
                                formatter={value =>
                                    Writer.FormatCurrency(value as number)
                                }
                            />
                            <Legend verticalAlign='top' />
                            <Line
                                strokeWidth={2}
                                type='monotone'
                                dataKey='Actual'
                                stroke={theme.colors.Yellow}
                            />
                            <Line
                                strokeWidth={2}
                                type='monotone'
                                dataKey='Budget'
                                stroke={theme.colors.DarkPink}
                            />
                        </LineChart>
                    </ResponsiveContainer>
                    <p>
                        The chart shows actual and budgeted revenue for the last
                        12 months. Your current financial year began
                        {" " + toMmmmYyyy(financialYearStart)} and you are{" "}
                        {Writer.writeAsWord(monthsInToYear)} month
                        {monthsInToYear === 1 ? "" : "s"} in.
                    </p>

                    <ReportsModal path='YTD-progress-model'>
                        <RevenueDetailYtdProgress />
                    </ReportsModal>
                </Container>
            </ItemSection>
        </>
    );
};

export default RevenueSectionYtdProgress;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;
