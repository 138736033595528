import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import MonthSelect from "components/inputs/MonthSelect";
import useReport from "hooks/reports/useReport";
import { filterByMonths } from "lib/apiDataHelpers/filterByMonths";
import { sumOfMonths, totalForMonths } from "lib/apiDataHelpers/sumOfMonths";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { toMmmYy } from "lib/date/reStringifyMonth";
import { useState } from "react";
import {
    Bar,
    BarChart,
    CartesianAxis,
    Cell,
    LabelList,
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { theme } from "style/theme";
import { OrgData } from "types/OrganisationType";

import AverageMonthlyRnE from "./AverageMonthlyRnE";

const getChartData = ({
    startMonth,
    endMonth,
    revenue,
    expenses,
}: {
    startMonth?: MonthKey;
    endMonth?: MonthKey;
    revenue: OrgData;
    expenses: OrgData;
}) => {
    if (!startMonth || !endMonth) {
        return [];
    }
    const rows: any[] = [];
    const filteredRevenue = sumOfMonths(
        filterByMonths({
            obj: revenue,
            latestMonth: startMonth,
            earliestMonth: endMonth,
        }),
    );
    const filteredExpenses = sumOfMonths(
        filterByMonths({
            obj: expenses,
            latestMonth: startMonth,
            earliestMonth: endMonth,
        }),
    );
    (Object.keys(filteredRevenue) as MonthKey[]).forEach(month => {
        rows.unshift({
            Revenue: filteredRevenue[month],
            Expenses: filteredExpenses[month],
            name: month,
        });
    });
    return rows;
};

const BreakDownsRevVsExpModel = () => {
    const [period, setPeriod] = useState<{
        startDate: MonthKey;
        endDate: MonthKey;
    }>();
    const report = useReport();

    if (!report) {
        return null;
    }
    const {
        revenue,
        expenses,
        reportDates: { selectedMonth },
    } = report;

    const totalRevenue =
        period &&
        totalForMonths({
            org: revenue.actual,
            latestMonth: period.startDate,
            earliestMonth: period.endDate,
        });
    const totalExpenses =
        period &&
        totalForMonths({
            org: expenses.actual,
            latestMonth: period.startDate,
            earliestMonth: period.endDate,
        });
    const data =
        totalRevenue && totalExpenses
            ? [
                  {
                      name: "Revenue",
                      color: theme.colors.Yellow,
                      value: totalRevenue,
                  },
                  {
                      name: "Expenses",
                      color: theme.colors.DarkBlue,
                      value: totalExpenses,
                  },
                  {
                      name: "Net Profit",
                      color: theme.colors.DarkPink,
                      value: totalRevenue - totalExpenses,
                  },
              ]
            : undefined;

    return (
        <Container>
            <Coloumn>
                <h1>
                    Here's how your total monthly revenue is tracking against
                    your expenses
                </h1>
                <MonthSelect
                    currentPeriod={{
                        startDate: period?.startDate,
                        endDate: period?.endDate,
                    }}
                    inputMonths={[expenses.actual, revenue.actual]}
                    onChange={setPeriod}
                    defaultPeriod={{
                        startDate: selectedMonth,
                        endDate: addMonthsTo(selectedMonth, -11),
                    }}
                />
                <ResponsiveContainer width='100%' height={250}>
                    <LineChart
                        data={getChartData({
                            startMonth: period?.startDate,
                            endMonth: period?.endDate,
                            revenue: revenue.actual,
                            expenses: expenses.actual,
                        })}
                        margin={{ top: 5, right: 30, left: 40, bottom: 5 }}>
                        <CartesianAxis axisLine={false} />
                        <XAxis
                            interval={0}
                            type='category'
                            dataKey='name'
                            style={{ fill: theme.colors.DarkBlue }}
                            stroke={"none"}
                            tick={tickMonthWrap}
                        />
                        <YAxis
                            style={{ fill: theme.colors.DarkBlue }}
                            stroke={"none"}
                            tickFormatter={(value: number) =>
                                Writer.FormatCurrency(value)
                            }
                        />
                        <Tooltip
                            labelStyle={{
                                color: theme.colors.DarkBlue,
                            }}
                            labelFormatter={props => {
                                if (props === 0 || props === "auto")
                                    return null;
                                return toMmmYy(props);
                            }}
                            formatter={(value: number | string) => {
                                if (value === 0 || typeof value === "string")
                                    return "";
                                return Writer.FormatCurrency(value);
                            }}
                        />
                        <Legend />
                        <Line
                            strokeWidth={3}
                            type='monotone'
                            dataKey='Revenue'
                            stroke={theme.colors.Yellow}
                        />
                        <Line
                            strokeWidth={3}
                            type='monotone'
                            dataKey='Expenses'
                            stroke={theme.colors.DarkBlue}
                        />
                    </LineChart>
                </ResponsiveContainer>
                <BarContainer>
                    <h2>Total Revenue and Expenses</h2>
                    <ResponsiveContainer width='100%' height={200}>
                        <BarChart
                            barCategoryGap={20}
                            data={data}
                            layout='vertical'
                            margin={{ top: 5, right: 70, left: 30, bottom: 5 }}>
                            <CartesianAxis axisLine={false} />
                            <XAxis
                                type='number'
                                style={{ fill: theme.colors.DarkBlue }}
                                stroke={"none"}
                                tickFormatter={(value: number) =>
                                    Writer.FormatCurrency(value)
                                }
                            />
                            <YAxis
                                type='category'
                                dataKey='name'
                                style={{ fill: theme.colors.DarkBlue }}
                                stroke={"none"}
                            />
                            <Bar
                                isAnimationActive={false}
                                dataKey='value'
                                fill={theme.colors.Yellow}>
                                <LabelList
                                    style={{
                                        fontWeight: "bold",
                                        fontSize: "18px",
                                    }}
                                    fill={theme.colors.DarkBlue}
                                    formatter={(value: number) =>
                                        Writer.FormatCurrency(value)
                                    }
                                    dataKey='value'
                                    position='right'
                                />
                                {data &&
                                    data.map((entry, index) => (
                                        <Cell
                                            key={`cell-${index}`}
                                            fill={entry.color}
                                        />
                                    ))}
                            </Bar>
                        </BarChart>
                    </ResponsiveContainer>
                </BarContainer>
                <AverageMonthlyRnE
                    monthStart={period?.startDate}
                    monthEnd={period?.endDate}
                />
            </Coloumn>
        </Container>
    );
};

export default BreakDownsRevVsExpModel;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Coloumn = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: ${({ theme }) => theme.sizes.modelWidth};
    h1 {
        text-align: center;
    }
`;
const BarContainer = styled.div`
    width: 100%;
    margin: 50px auto;
    h2 {
        margin: auto;
        margin-right: 50px;
        text-align: center;
    }
`;
