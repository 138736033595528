import { BaseQueryFn } from "@reduxjs/toolkit/query/react";
import axios, { AxiosError, AxiosRequestConfig, Method } from "axios";
import { getAuth } from "firebase/auth";

export const axiosBaseQuery =
    (): BaseQueryFn<
        {
            url: string;
            method?: Method;
            data?: AxiosRequestConfig["data"];
            params?: AxiosRequestConfig["params"];
        },
        unknown,
        {
            status?: number;
            data?: unknown | string;
        }
    > =>
    async ({ url, method, data, params }) => {
        const token = await getAuth().currentUser?.getIdToken();
        const baseUrl = process.env.REACT_APP_API_URL as string;
        // const baseUrl = "http://localhost:8081/";
        try {
            const result = await axios({
                url: baseUrl + url,
                method,
                data,
                params,
                headers: {
                    Authorization: `Bearer ${token}`,
                },
            });
            return { data: result.data };
        } catch (axiosError) {
            const err = axiosError as AxiosError;
            return {
                error: {
                    status: err.response?.status,
                    data: err.response?.data || err.message,
                },
            };
        }
    };
