import styled from "@emotion/styled";
import xeroLogin from "assets/img/xero/xero-logo.png";
import useAuthState from "hooks/firebase/useAuthState";
import { Navigate } from "react-router";
import { useGetXeroLoginUrlQuery } from "redux/rtkQuery/onboarding";

import { Container } from "./BaseStyles";
import Progress from "./Progress";
import { useState } from "react";
import { Link } from "react-router-dom";

const LandingPage = () => {
    const [user] = useAuthState();
    const [clicked, setClicked] = useState(false);
    const { data: url, isFetching } = useGetXeroLoginUrlQuery();

    if (clicked && url) {
        window.location.href = url;
    }

    if (user) {
        return <Navigate to='/' />;
    }

    return (
        <Container>
            <Progress progress={0} />
            {/* <Title>Start a 7 day free trial</Title> */}
            {/* <SubTitle>Cancel Anytime</SubTitle> */}
            <Content>
                <Explain>
                    Start your free trial today and see how easy it is to manage
                    your cashflow.
                </Explain>
                <Component>
                    <h1>Sign in with Xero</h1>
                    {isFetching ? (
                        <span>Loading ...</span>
                    ) : (
                        <XeroLogin
                            onClick={() => {
                                setClicked(true);
                            }}>
                            <div>
                                <img src={xeroLogin} alt='' />
                                <b>Login with Xero</b>
                            </div>
                            {/* <Recommend>--recommended--</Recommend> */}
                        </XeroLogin>
                    )}
                </Component>
                {/* <Component>
                    <h1>Create an account <br /> connect your Xero</h1>
                    <Explain>
                        Or Else Login with another provider then connect your
                        Xero
                    </Explain> 
                    <LoginComponent />
                </Component>*/}
            </Content>
            <AlreadyHaveAccount>
                Already have an account? <Link to='/login'>Login</Link>
            </AlreadyHaveAccount>
        </Container>
    );
};

export default LandingPage;

const AlreadyHaveAccount = styled.div``;

const Content = styled.div`
    display: flex;
    width: 100%;
    height: 100%;
    padding: 20px;
    justify-content: space-evenly;
`;

const Title = styled.h1``;

const Recommend = styled.span`
    font-size: 10px;
    background-color: burlywood;
    font-weight: 700;
    text-transform: uppercase;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 5px;
`;

const Explain = styled.p`
    font-size: 24px;
    text-align: center;
    font-weight: 700;
    width: 220px;
    margin: auto 0;
`;

const Component = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 100%;

    background-color: #fff;

    border-radius: 20px;
    padding: 20px;
    border: 2px solid ${({ theme }) => theme.colors.DarkBlue};

    > h1 {
        margin-bottom: 35px;
    }
`;

const XeroLogin = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    cursor: pointer;
    > div {
        display: flex;
        align-items: center;
        justify-content: center;
        background-color: ${({ theme }) => theme.colors.Pink};
        margin: 10px;
        border-radius: 45px;
        height: 45px;
        padding: 10px;

        &:hover {
            background-color: ${({ theme }) => theme.colors.DarkPink};
        }

        img {
            height: 100%;
            object-fit: cover;
            margin-right: 10px;
        }
    }
`;
