import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import useReport from "hooks/reports/useReport";
import { Item } from "types/OrganisationType";

const RevenueActual = ({
    months,
    revenueItems,
}: {
    months: MonthKey[];
    revenueItems: Array<[string, Item]> | undefined;
}) => {
    const revenueActual = useReport()?.revenue.actual;

    return (
        <Container>
            {revenueItems &&
                revenueItems.map(([code, item], index1) => {
                    return (
                        <RevenueItemDiv key={`${index1}${code}`}>
                            {months.map((month, index) => (
                                <RevenueMonthDiv key={`${index1}${index}`}>
                                    {revenueActual?.[month]?.[code]
                                        ? revenueActual[month][code]
                                        : ""}
                                </RevenueMonthDiv>
                            ))}
                        </RevenueItemDiv>
                    );
                })}
        </Container>
    );
};

export default RevenueActual;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const RevenueItemDiv = styled.div`
    display: flex;
    flex-direction: row;
`;
const RevenueMonthDiv = styled.div`
    position: relative;
    height: 30px;
    width: 120px;
    border: 1px solid #686868;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
`;
