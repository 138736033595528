import styled from "@emotion/styled";
import Avatar from "components/Avatar";
import useModal from "components/general/CoverPage/Modal/useModal";
import {
    EmailAuthProvider,
    GoogleAuthProvider,
    linkWithCredential,
    linkWithPopup,
    reauthenticateWithCredential,
    reauthenticateWithPopup,
    unlink,
    updateProfile,
    verifyBeforeUpdateEmail,
} from "firebase/auth";
import useAuthState from "hooks/firebase/useAuthState";
import { auth, updateUserState } from "lib/firebase";
import { useEffect, useState } from "react";
import { addNotification } from "redux/slices/NotificationSlice";
import { useAppDispatch } from "redux/store";

const provider = new GoogleAuthProvider();

const add_goggle_account = () => {
    if (auth.currentUser) {
        linkWithPopup(auth.currentUser, provider)
            .then(result => {
                GoogleAuthProvider.credentialFromResult(result);
                updateUserState();
            })
            .catch(error => {
                console.log("Account linking error", error);
            });
    }
};

const add_password_account = async (
    email: string,
    password: string,
    result: (err: any) => void,
) => {
    if (auth.currentUser) {
        try {
            await reauthenticateWithPopup(auth.currentUser, provider);
            const credential = EmailAuthProvider.credential(email, password);
            await linkWithCredential(auth.currentUser, credential);
            result(undefined);
            updateUserState();
        } catch (error) {
            result(error);
            console.log("Account linking error", error);
        }
    }
};

const unlink_provider = (providerId: "password" | "google.com") => {
    if (auth.currentUser) {
        unlink(auth.currentUser, providerId)
            .then(() => {
                updateUserState();
            })
            .catch(error => {
                console.log("Account unlinking error", error);
            });
    }
};

const Account = () => {
    const { Modal, setOpen, setClose } = useModal();
    const dispatch = useAppDispatch();
    const [user] = useAuthState();
    const [name, setName] = useState("");
    const [email, setEmail] = useState("");
    const [password, setPassword] = useState("");
    const [newProvider, SetNewProvider] = useState({ email: "", password: "" });
    const [showPassword, setShowPassword] = useState(false);
    const [loadingUpdate, setLoadingUpdate] = useState(false);

    const [invalidPassword, setInvalidPassword] = useState(false);

    useEffect(() => {
        if (showPassword) {
            window.addEventListener("keydown", e => {
                if (e.key === "Escape") {
                    setShowPassword(false);
                }
            });
        } else {
            window.removeEventListener("keydown", e => {
                if (e.key === "Escape") {
                    setShowPassword(false);
                }
            });
        }
    }, [showPassword]);

    const hasPassword = !!user?.providerData.find(
        x => x.providerId === "password",
    );

    const submit = () => {
        if (user && email !== user.email && email) {
            setShowPassword(true);
        } else {
            successful();
        }
    };

    const handleNewProvider = () => {
        if (newProvider.email && newProvider.password) {
            add_password_account(newProvider.email, newProvider.password, e => {
                if (e) {
                    dispatch(
                        addNotification({
                            message: "error linking provider",
                            type: "error",
                            persist: false,
                        }),
                    );
                    console.error(e);
                } else {
                    setClose();
                }
            });
        } else {
            dispatch(
                addNotification({
                    message: "Please fill out email and password",
                    type: "error",
                    persist: false,
                }),
            );
        }
    };

    const testPass = () => {
        if (auth.currentUser && auth.currentUser.email) {
            const credential = EmailAuthProvider.credential(
                auth.currentUser.email,
                password,
            );
            reauthenticateWithCredential(auth.currentUser, credential)
                .then(() => {
                    successful();
                    setShowPassword(false);
                    setInvalidPassword(false);
                })
                .catch(error => {
                    if (error.code === "auth/wrong-password") {
                        setInvalidPassword(true);
                    }
                });
        }
    };

    const successful = async () => {
        try {
            if (user && auth.currentUser) {
                setLoadingUpdate(true);
                if (name !== user.displayName && name !== "") {
                    await updateProfile(auth.currentUser, {
                        displayName: name,
                    });
                    setName("");
                }
                if (email !== user.email && email !== "") {
                    await verifyBeforeUpdateEmail(auth.currentUser, email);
                    setEmail("");
                }
                updateUserState();
                setLoadingUpdate(false);
            }
        } catch (error) {
            console.log(error);
        }
    };

    return (
        <Container>
            <span>
                <h1>Account</h1>
            </span>
            <AvatarDiv>
                <b>Avatar</b> <br />
                <Avatar size={50} />
            </AvatarDiv>
            <br />
            <div style={{ display: "flex" }}>
                <div>
                    <div>
                        <Item>
                            <b>DisplayName:</b>
                            <br />
                            <input
                                type='text'
                                value={name}
                                onChange={e => setName(e.target.value)}
                                placeholder={
                                    user?.displayName
                                        ? user?.displayName
                                        : undefined
                                }
                            />
                        </Item>
                        <Item>
                            <b>Email address:</b>
                            <br />
                            {!hasPassword && (
                                <>
                                    <CantEdit>
                                        unable to edit. signed in with google
                                    </CantEdit>
                                    <br />
                                </>
                            )}
                            <input
                                onChange={e => setEmail(e.target.value)}
                                type='email'
                                value={email}
                                readOnly={!hasPassword}
                                placeholder={
                                    user?.email ? user?.email : undefined
                                }
                            />
                        </Item>
                    </div>
                    <Submit>
                        <SaveButton onClick={submit}>Save Changes</SaveButton>
                    </Submit>
                    {showPassword && (
                        <Password>
                            <span onClick={() => setShowPassword(false)}>
                                X
                            </span>
                            <div>
                                <b>Password</b>
                                <input
                                    type='password'
                                    placeholder='password'
                                    onChange={e => setPassword(e.target.value)}
                                />
                                {invalidPassword && (
                                    <BadPassword>Invalid password</BadPassword>
                                )}
                                <SaveButton onClick={testPass}>
                                    Save Changes
                                </SaveButton>
                            </div>
                        </Password>
                    )}
                    {loadingUpdate && <div>loading</div>}
                </div>
                <AccountProviders>
                    <h3>Update and change auth providers</h3>
                    <div style={{ marginBottom: 10 }}>
                        <span>
                            <b> Provider </b>
                        </span>
                        <span>
                            <b> State</b>
                        </span>
                        <span>
                            <b> Action</b>
                        </span>
                    </div>
                    <div>
                        <span>Password</span>{" "}
                        {auth.currentUser?.providerData.find(
                            x => x.providerId === "password",
                        ) ? (
                            <>
                                <span>connected</span>{" "}
                                {auth.currentUser.providerData.length > 1 ? (
                                    <button
                                        onClick={() =>
                                            unlink_provider("password")
                                        }>
                                        disconnect
                                    </button>
                                ) : (
                                    <span />
                                )}
                            </>
                        ) : (
                            <>
                                <span>disconnected</span>{" "}
                                <button onClick={setOpen}>connect</button>
                            </>
                        )}
                    </div>
                    <div>
                        <span>Google</span>{" "}
                        {auth.currentUser?.providerData.find(
                            x => x.providerId === "google.com",
                        ) ? (
                            <>
                                <span>connected</span>{" "}
                                {auth.currentUser.providerData.length > 1 ? (
                                    <button
                                        onClick={() =>
                                            unlink_provider("google.com")
                                        }>
                                        disconnect
                                    </button>
                                ) : (
                                    <span />
                                )}
                            </>
                        ) : (
                            <>
                                <span>disconnected</span>{" "}
                                <button onClick={add_goggle_account}>
                                    connect
                                </button>
                            </>
                        )}
                    </div>
                </AccountProviders>
            </div>
            <Modal>
                <NewAccountProvider>
                    <div>
                        <span>Email:</span>{" "}
                        <input
                            onChange={i =>
                                SetNewProvider(p => ({
                                    ...p,
                                    email: i.target.value,
                                }))
                            }
                        />
                    </div>
                    <div>
                        <span>Password:</span>{" "}
                        <input
                            type='password'
                            onChange={i =>
                                SetNewProvider(p => ({
                                    ...p,
                                    password: i.target.value,
                                }))
                            }
                        />
                    </div>

                    <SaveButton
                        onClick={handleNewProvider}
                        style={{ marginLeft: "auto", marginRight: "auto" }}>
                        Authenticate and Submit
                    </SaveButton>
                </NewAccountProvider>
            </Modal>
        </Container>
    );
};

export default Account;

const NewAccountProvider = styled.div`
    background-color: ${props => props.theme.colors.Pink};
    padding: 30px;
    border-radius: 15px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    > div {
        width: 250px;
        width: 250px;
        display: grid;
        grid-template-columns: 1fr 1fr;
        align-items: center;
        > span {
            margin: 10px;
        }
    }
`;

const AccountProviders = styled.div`
    margin-left: auto;
    margin-right: 20px;
    > div {
        margin: 5px;
        width: 300px;
        display: grid;
        grid-template-columns: 1fr 1fr 1fr;
        align-items: center;
        button {
            border-radius: 10px;
            background-color: #c5c5c5;
            color: ${props => props.theme.colors.DarkBlue};
            &:hover {
                background-color: #a8a8a8;
            }
        }
        > h3 {
            margin: 0;
        }
    }
`;

const SaveButton = styled.button`
    background-color: #4a75e0;
    color: white;
    border-radius: 5px;
    &:hover {
        background-color: #4a75e0;
    }
`;

const Submit = styled.div`
    margin-left: 30%;
`;

const CantEdit = styled.span`
    font-size: 12px;
`;

const BadPassword = styled.span`
    width: 147px;
    background-color: #ff000065;
    text-align: center;
`;

const Password = styled.div`
    position: fixed;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(0, 0, 0, 0.5);
    div {
        position: relative;
        width: 300px;
        height: 150px;
        border-radius: 15px;
        background-color: #fff;
        border: 1.8px solid #000;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
        button {
            margin-top: 10px;
        }
    }
    span {
        position: absolute;
        top: 0;
        right: 0;
        margin: 20px;
        color: #fff;
        font-size: 30px;
        cursor: pointer;
    }
`;

const Item = styled.div`
    margin: 10px;
    input {
        width: 230px;
        height: 30px;
        margin: 6px;
        border-radius: 3px;
        border: 1.8px solid ${props => props.theme.colors.DarkBlue};
        background-color: white;
        padding: 3px;
        &::placeholder {
            color: #8d8d8d;
        }
    }
`;

const Container = styled.div`
    border: 1.8px solid black;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
`;
const AvatarDiv = styled.div`
    display: flex;
    align-items: center;
    > div,
    img {
        margin-left: 10px;
    }
`;
