import styled from "@emotion/styled";
import monthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import AddItemTypeMarketing from "components/inputs/addItemType";
import Bubble from "components/reports/Bubble";
import useReport from "hooks/reports/useReport";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { toMmmYyyy } from "lib/date/reStringifyMonth";
import {
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { useAppSelector } from "redux/store";
import { theme } from "style/theme";
import { ItemCustomClasification } from "types/OrganisationType";

import { ChartHeader } from "./Styled";

const CostPerCustomer = () => {
    const { customerName, customers } = useAppSelector(
        state => state.customers.present,
    );
    const report = useReport();

    if (!report) {
        return null;
    }

    const {
        expenses,
        reportDates: { lastTwelveMonths, selectedMonth, sameMonthLastYear },
        items,
    } = report;

    //Calculate the total cost of marketing items
    const itemMonthlyCostMarketing = (month: monthKey) => {
        const object = expenses.actual[month];
        if (object) {
            const marketingItems = Object.entries(object).filter(
                ([item, number]) =>
                    items[item].itemClasification ===
                    ItemCustomClasification.Marketing,
            );
            const total = marketingItems.reduce(
                (a, [code, number]) => a + (isNaN(number) ? 0 : number),
                0,
            );
            return total;
        }
        return 0;
    };

    //Calculate the number of new customers in a month (not including cancellations)
    const newCustomers = (month: monthKey) => {
        const lastMonth = addMonthsTo(month, -1);
        const lastMonthActual =
            (customers[lastMonth] && customers[lastMonth].actual) || 0;
        const thisMonthActual =
            (customers[month] && customers[month].actual) || 0;
        //Calculate the change from last month to this month
        const totalChange = thisMonthActual - lastMonthActual;
        return totalChange + (customers[month]?.cancellations || 0);
    };

    //Create array to hold months that we can't calculate
    const monthsToIgnore: monthKey[] = [];

    //Calculate the cost per new customer
    const costPerCustomer = lastTwelveMonths.map(m => {
        const newCust = newCustomers(m);
        const marketingSpend = itemMonthlyCostMarketing(m);
        const cantCalculate = marketingSpend <= 0 || newCust <= 0;
        if (cantCalculate) {
            monthsToIgnore.push(m);
        }
        return {
            name: m,
            marketingSpend,
            newCust,
            cost: cantCalculate ? null : marketingSpend / newCust,
        };
    });

    const lastYearNewCustomers = lastTwelveMonths.reduce(
        (state, m) => {
            state.cost += itemMonthlyCostMarketing(m);
            state.new += newCustomers(m);
            return state;
        },
        { new: 0, cost: 0 },
    );

    const selectedMonthCustomers = customers[selectedMonth]?.actual ?? 0;
    const lastYearCustomers = customers[sameMonthLastYear]?.actual ?? 0;

    const annualChangeInTotalCustomers =
        selectedMonthCustomers - lastYearCustomers;
    return (
        <ItemSection padding='0' light column>
            <ChartHeader>
            <h1>
                Cost to acquire a{" "}
                {customerName.slice(0, customerName.length - 1).toLowerCase()}
            </h1>
            <h2>
                Total marketing cost divided by total new {customerName.toLowerCase()}
            </h2>
            </ChartHeader>
            <AddItemTypeMarketing />
            <ResponsiveContainer height={300} width='100%'>
                <LineChart data={costPerCustomer} margin={{ right: 10 }}>
                    <XAxis
                        interval={0}
                        type='category'
                        dataKey='name'
                        style={{ fill: theme.colors.DarkBlue }}
                        // stroke={"none"}
                        tick={tickMonthWrap}
                    />
                    <ReferenceLine
                        y={0}
                        label=''
                        stroke='red'
                        strokeDasharray='3 3'
                    />
                    <YAxis
                        type='number'
                        style={{ fill: theme.colors.DarkBlue }}
                        // stroke={"none"}
                        tickFormatter={(value: number) =>
                            Writer.FormatCurrency(value)
                        }
                    />
                    <Tooltip
                        content={({ payload }) => {
                            if (!payload || !payload.length) return null;

                            const { marketingSpend, newCust, cost, name } =
                                payload[0].payload;
                            return (
                                <div
                                    style={{
                                        background: "#ffffff",
                                        padding: "10px",
                                        margin: "0px",
                                    }}>
                                    <h3 style={{ margin: "0px" }}>
                                        {toMmmYyyy(name)}
                                    </h3>
                                    <p style={{ margin: "0px" }}>
                                        Marketing Spend:{" "}
                                        {Writer.FormatCurrency(marketingSpend)}
                                    </p>
                                    <p style={{ margin: "0px" }}>
                                        New Customers: {newCust}
                                    </p>
                                    <p style={{ margin: "0px" }}>
                                        <strong>
                                            Cost to aquire a customer:
                                        </strong>{" "}
                                        {Writer.FormatCurrency(cost)}
                                    </p>
                                </div>
                            );
                        }}
                    />
                    <Line
                        name='Cost per Customer'
                        strokeWidth={5}
                        type='monotone'
                        dataKey='cost'
                        stroke={theme.colors.Yellow}
                        dot={true}
                    />
                </LineChart>
            </ResponsiveContainer>
            {monthsToIgnore.length > 0 && (
                <div>
                    <i>
                        *Some months have been left off the graph because they
                        have no marketing spend or no new customers to calculate
                        the cost per customer: {monthsToIgnore.join(", ")}.
                    </i>
                </div>
            )}
            <Row>
                <Bubble>
                    <h3>Average cost to acquire a new customer</h3>
                    <mark>
                        $
                        {(
                            costPerCustomer.reduce((output, input) => {
                                return output + (input.cost || 0);
                            }, 0) / 12
                        ).toFixed(2)}
                    </mark>
                    <span></span>
                </Bubble>
                <Bubble>
                    <h3>Your spent</h3>
                    <mark>
                        {Writer.FormatCurrency(lastYearNewCustomers.cost)}
                    </mark>
                    <span> on marketing</span>
                </Bubble>
                <Bubble>
                    <h3>
                        Your total number of customers{" "}
                        {Writer.DescribeChange(
                            annualChangeInTotalCustomers,
                            "increased",
                            "decreased",
                            "did not change",
                        )}{" "}
                        by
                    </h3>
                    <mark>
                        {Writer.FormatNumber(
                            Math.abs(annualChangeInTotalCustomers),
                        )}
                    </mark>
                    <span></span>
                </Bubble>
                <Bubble>
                    <h3>
                        Your net cost to increase total customers by one was
                    </h3>
                    <mark>
                        {Writer.FormatCurrency(
                            lastYearNewCustomers.cost /
                                annualChangeInTotalCustomers,
                        )}
                    </mark>
                    <span></span>
                </Bubble>
            </Row>
        </ItemSection>
    );
};
export default CostPerCustomer;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
`;
