import MonthKey from "common/types/monthKey";
import { OrganisationIdentifier } from "common/types/objects/Organisation";

/**
 * Represents cash flow data and transfer items data for an organization.
 */
export interface Cashflow {
    /** An object containing cash flow data for the organization. */
    cashflowData: CashflowData;
    /** An object containing transfer items data for the organization. */
    transfersItemData: OrgData;
}

/**
 * Represents data for an organization indexed by MonthKey.
 */
export interface OrgData {
    /** A dictionary of MonthItems indexed by MonthKey (YYYY-MM date format). */
    [month: MonthKey]: MonthItems;
}

/**
 * Represents a collection of items for a given month.
 */
export interface MonthItems {
    /** A dictionary of item amounts indexed by item name. */
    [itemName: string]: number;
}

/**
 * Represents a collection of items indexed by name.
 */
interface Items {
    /** A dictionary of item names indexed by item name. */
    [itemName: string]: string;
}

/**
 * Represents a collection of items indexed by item code.
 */
export interface ItemObject {
    /** A dictionary of Item objects indexed by item code. */
    [itemCode: string]: Item;
}

/**
 * Represents an individual financial item.
 */
export interface Item {
    /** The name of the item. */
    name: string;
    /** The type of the item, from the ItemType enum. */
    itemType: ItemType;
    /** The subtype of the item, from the ItemSubType enum. */
    itemSubType: ItemSubType;
    /** The custom classification of the item, from the ItemCustomClasification enum. */
    itemClasification: ItemCustomClasification;
}

/**
 * Represents a combination of items and data for an organization.
 */
export interface RevenueAndExpenses {
    /** A dictionary of item names indexed by item name. */
    items: Items;
    /** An object containing data for the organization. */
    data: OrgData;
}

/**
 * Represents a balance sheet with various financial sections.
 */
export interface BalanceSheet {
    /** A BalanceItem object representing current liabilities. */
    currentLiabilities: BalanceItem;
    /** A BalanceItem object representing the bank balance. */
    bankBalance: BalanceItem;
    /** A BalanceItem object representing liabilities. */
    liabilities: BalanceItem;
    /** A BalanceItem object representing assets. */
    assets: BalanceItem;
    /** A BalanceItem object representing equity. */
    equity: BalanceItem;
    /** A BalanceItem object representing current assets. */
    currentAssets: BalanceItem;
}

/**
 * Represents a collection of bank account balances indexed by account code.
 */
export interface BankBalances {
    /** A dictionary of account balances indexed by account code and MonthKey (YYYY-MM date format). */
    [accountCode: string]: {
        [month: MonthKey]: number;
    };
}

export interface BankAccount {
    id: string;
    name: string;
    AccountNumber: string;
}

export interface BalanceItem {
    [months: MonthKey]: number;
}

export interface DemoOrg extends OrganisationIdentifier {
    isDemo: true;
    balanceSheet: BalanceSheet;
    revenueBySource: RevenueAndExpenses;
    expensesBySource: RevenueAndExpenses;
    expensesBudgetBySource: RevenueAndExpenses;
    revenueBudgetBySource: RevenueAndExpenses;
}

export interface CashflowData {
    [month: string]: {
        spend: number;
        recieve: number;
        spendExTax: number;
        recieveExTax: number;
        taxCollected: number;
        taxPaid: number;
        netCashMovement?: number;
        netTaxMovement?: number;
    };
}

export interface MonthlySalesTaxCashSummary {
    cashOut: number;
    cashOutExTax: number;
    taxComponentCashOut: number;
    cashIn: number;
    cashInExTax: number;
    taxComponentCashIn: number;
    netCash: number;
    netCashExTax: number;
    netTaxOnTransactions: number;
    recieveTransfer: number;
    sendTransfer: number;
}

export enum ItemType {
    "asset" = 1,
    "equity" = 2,
    "expense" = 3,
    "liability" = 4,
    "revenue" = 5,
}

export enum ItemSubType {
    "current" = 1,
    "currliab" = 2,
    "depreciatn" = 3,
    "directcosts" = 4,
    "equity" = 5,
    "expense" = 6,
    "fixed" = 7,
    "inventory" = 8,
    "liability" = 9,
    "noncurrent" = 10,
    "otherincome" = 11,
    "overheads" = 12,
    "prepayment" = 13,
    "revenue" = 14,
    "sales" = 15,
    "termliab" = 16,
    "paygliability" = 17,
    "payg" = 18,
    "superannuationexpense" = 19,
    "superannuationliability" = 20,
    "wagesexpense" = 21,

    "BankAccount" = 99,
}

export enum ItemCustomClasification {
    "SalesTax" = 1,
    "Marketing" = 2,
    "CustomerRevenue" = 3,
}
