import axios from "axios";
import { parseISO } from "date-fns";
import EventEmitter from "events";
import { getAuth } from "firebase/auth";

const client = axios.create();

export const httpClientEventEmitter = new EventEmitter();

/* 
  Axios interceptors can be used to globally catch responses and handle them. Interceptors allow you to intercept requests or responses before they are handled by the then or catch methods.
*/

//Handling dates returned from the API
client.interceptors.response.use(
    originalResponse => {
        // Any status code that lies within the range of 2xx will cause this function to trigger
        handleDates(originalResponse.data);
        return originalResponse;
    },
    error => {
        // Any status codes that fall outside the range of 2xx will cause this function to trigger
        if (
            error.response &&
            error.response.status === 502 &&
            error.response.data === "xero reconnection required"
        ) {
            httpClientEventEmitter.emit("xeroDisconnected");
        } else {
            // If the error does not meet the condition, pass it to the next error handler
            return Promise.reject(error);
        }
    },
);

/*
    If the connection to xero is lost, a specific 502 is returned. 
    This can happen for a varity of reasons, we may have stuffed up somehow, or the user may have revoked access (intentinally or otherwise), or mybe Xero is just being a pain.
    For a good user experence we will handle this situation and guide them to reconnect xero (assuming it's a one off)
*/

//Authentication object for getting id token
const auth = getAuth();

const sendRequest = async <T>(
    method: "get" | "post" | "put" | "patch" | "options" | "delete",
    url: string,
    data?: any,
    params?: any,
    body?: any,
): Promise<T> => {
    console.log("sendRequest", method, url, data, params, body);
    const token = await auth.currentUser?.getIdToken();
    const response = client.request({
        method: method,
        url,
        baseURL: process.env.REACT_APP_API_URL,
        // baseURL: "http://localhost:8081",
        headers: { Authorization: "Bearer " + token },
        params: params,
        data: data,
    });
    return response
        .then(response => {
            return response.data;
        })
        .catch(error => {
            if (error.response) {
                // The request was made and the server responded with a status code
                // that falls out of the range of 2xx
                const message = `Error: ${error.response.status} ${error.response.statusText}. ${error.response.data}`;
                return Promise.reject(new Error(message));
            } else if (error.request) {
                // The request was made but no response was received
                // `error.request` is an instance of XMLHttpRequest in the browser and an instance of
                // http.ClientRequest in node.js
                return Promise.reject(
                    new Error("No response was received from the server."),
                );
            } else {
                // Something happened in setting up the request that triggered an Error
                return Promise.reject(error);
            }
        });
};

export const HttpClient = {
    post: async <T = any>(url: string, data?: any, params?: any) =>
        await sendRequest<T>("post", url, data, params),
    put: async <T = any>(url: string, data?: any, params?: any) =>
        await sendRequest<T>("put", url, data, params),
    patch: async <T = any>(url: string, data?: any, params?: any) =>
        await sendRequest<T>("patch", url, data, params),
    options: async <T = any>(url: string, data?: any, params?: any) =>
        await sendRequest<T>("options", url, data, params),
    get: async <T = any>(url: string, data?: any, params?: any) =>
        await sendRequest<T>("get", url, data, params),
    delete: async <T = any>(url: string, data?: any, params?: any) =>
        await sendRequest<T>("delete", url, data, params),
};

const isoDateFormat = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(?:\.\d*)?$/;

function isIsoDateString(value: any): boolean {
    return value && typeof value === "string" && isoDateFormat.test(value);
}

const handleDates = (body: any) => {
    if (body === null || body === undefined || typeof body !== "object")
        return body;

    // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
    for (const key of Object.keys(body)) {
        const value = body[key];
        // eslint-disable-next-line @typescript-eslint/no-unsafe-argument
        if (isIsoDateString(value)) body[key] = parseISO(value);
        else if (typeof value === "object") handleDates(value); // run on object
    }
};
