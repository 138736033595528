import styled from "@emotion/styled";
import { isPreviewEnvironment } from "common/isPreview";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import Switch from "components/general/Switch";
import MonthQuarterSelect from "components/inputs/MonthQuarterSelect";
import MoreInfo from "components/ui/MoreInfo";
import useReport from "hooks/reports/useReport";
import { sumOfMonth, totalOfGivenMonths } from "lib/apiDataHelpers/sumOfMonths";
import Period from "lib/date/dateClasses/Period";
import { toMmmmYyyy } from "lib/date/reStringifyMonth";
import { useState } from "react";
import { theme } from "style/theme";

import Bubble, { bubbleDark, bubbleLight, bubblePink } from "../Bubble";

const BreakDownsSectionIntroPage = () => {
    const report = useReport();
    const [alternate, setAlternate] = useState(false);

    if (!report) return null;

    const {
        reportDates: {
            selectedMonth,
            showQuarterly,
            lastTwelveMonths,
            period,
            lastFinancialQuarter,
            lastFinancialQuarterMonths,
        },
    } = report;

    const displayPeriod = showQuarterly ? lastFinancialQuarter : selectedMonth;

    const latestRevenue = showQuarterly
        ? totalOfGivenMonths(report.revenue.actual, lastFinancialQuarterMonths)
        : sumOfMonth(report.revenue.actual[displayPeriod]);

    const latestExpenses = showQuarterly
        ? totalOfGivenMonths(report.expenses.actual, lastFinancialQuarterMonths)
        : sumOfMonth(report.expenses.actual[displayPeriod]);

    const latestProfit = latestRevenue - latestExpenses;

    const compareData = {
        revenue: sumOfMonth(
            report.revenue.actual[displayPeriod.addMonthsTo(-12)],
        ),

        expenses: sumOfMonth(
            report.expenses.actual[displayPeriod.addMonthsTo(-12)],
        ),
    };

    const showCompare = {
        revenue: +((latestRevenue / compareData.revenue) * 100 - 100).toFixed(
            1,
        ),
        expenses: +(
            (latestExpenses / compareData.expenses) * 100 -
            100
        ).toFixed(1),
        profit: +(
            (latestProfit / (compareData.revenue - compareData.expenses)) *
                100 -
            100
        ).toFixed(1),
    };

    const averageRevenue =
        totalOfGivenMonths(report.revenue.actual, lastTwelveMonths) / 12;
    const averageExpenses =
        totalOfGivenMonths(report.expenses.actual, lastTwelveMonths) / 12;

    const compareMonthsBack = new Period(
        selectedMonth.addMonthsTo(-23),
        selectedMonth.addMonthsTo(-12),
    );

    const averageCompareData = {
        revenue:
            totalOfGivenMonths(
                report.revenue.actual,
                compareMonthsBack.getMonths(),
            ) / 12,
        expenses:
            totalOfGivenMonths(
                report.expenses.actual,
                compareMonthsBack.getMonths(),
            ) / 12,
    };
    const averageProfit = averageRevenue - averageExpenses;

    const showAverageCompare = {
        revenue: +(
            (averageRevenue / averageCompareData.revenue) * 100 -
            100
        ).toFixed(1),
        expenses: +(
            (averageExpenses / averageCompareData.expenses) * 100 -
            100
        ).toFixed(1),
        profit: +(
            (averageProfit /
                (averageCompareData.revenue - averageCompareData.expenses)) *
                100 -
            100
        ).toFixed(1),
    };

    const compareMonthsString = `${compareMonthsBack
        .getMonthKeys()
        .earliestMonth.toMmmYyyy()} - ${compareMonthsBack
        .getMonthKeys()
        .latestMonth.toMmmYyyy()}`;

    return (
        <ItemSection column light={true} minHeight={356}>
            <Title>
                Lets take a look at your{" "}
                {isPreviewEnvironment ? <MonthQuarterSelect /> : "monthly"}{" "}
                profit
            </Title>
            <Month>
                <h1>
                    {report && toMmmmYyyy(displayPeriod)}
                    {showQuarterly ? " quarter" : ""}
                </h1>
            </Month>
            {alternate ? (
                <Container>
                    <Bubble style={bubbleLight}>
                        <h3>Average Revenue</h3>
                        <mark>
                            {report && Writer.FormatCurrency(averageRevenue)}
                        </mark>
                        <span></span>
                        <MoreInfo
                            helpContent={`${Math.abs(
                                showAverageCompare.revenue,
                            )}% ${
                                showAverageCompare.revenue > 0 ? "more" : "less"
                            } than ${compareMonthsString}`}
                        />
                    </Bubble>
                    <Bubble big drop style={bubbleDark}>
                        <h3>Average Expenses</h3>
                        <mark>
                            {report && Writer.FormatCurrency(averageExpenses)}
                        </mark>
                        <span></span>
                        <MoreInfo
                            helpContent={`${Math.abs(
                                showAverageCompare.expenses,
                            )}% ${
                                showAverageCompare.expenses > 0
                                    ? "more"
                                    : "less"
                            } than ${compareMonthsString}`}
                        />
                    </Bubble>
                    <Bubble style={bubblePink}>
                        <h3>Average Profit</h3>
                        <mark>
                            {report && Writer.FormatCurrency(averageProfit)}
                        </mark>
                        <span></span>
                        <MoreInfo
                            helpContent={`${Math.abs(
                                showAverageCompare.profit,
                            )}% ${
                                showAverageCompare.profit > 0 ? "more" : "less"
                            } than ${compareMonthsString}`}
                        />
                    </Bubble>
                </Container>
            ) : (
                <Container>
                    <Bubble style={bubbleLight}>
                        <h3>Revenue</h3>
                        <mark>
                            {report && Writer.FormatCurrency(latestRevenue)}
                        </mark>
                        <span></span>
                        <MoreInfo
                            helpContent={`${Math.abs(showCompare.revenue)}% ${
                                showCompare.revenue > 0 ? "more" : "less"
                            } than ${displayPeriod
                                .addMonthsTo(-12)
                                .toMmmmYyyy()}`}
                        />
                    </Bubble>
                    <Bubble big drop style={bubbleDark}>
                        <h3>Expenses</h3>
                        <mark>
                            {report && Writer.FormatCurrency(latestExpenses)}
                        </mark>
                        <span></span>
                        <MoreInfo
                            helpContent={`${Math.abs(showCompare.expenses)}% ${
                                showCompare.expenses > 0 ? "more" : "less"
                            } than ${displayPeriod
                                .addMonthsTo(-12)
                                .toMmmmYyyy()}`}
                        />
                    </Bubble>
                    <Bubble style={bubblePink}>
                        <h3>Profit</h3>
                        <mark>
                            {report && Writer.FormatCurrency(latestProfit)}
                        </mark>
                        <span></span>
                        <MoreInfo
                            helpContent={`${Math.abs(showCompare.profit)}% ${
                                showCompare.profit > 0 ? "more" : "less"
                            } than ${displayPeriod
                                .addMonthsTo(-12)
                                .toMmmmYyyy()}`}
                        />
                    </Bubble>
                </Container>
            )}
            <Alternate>
                {!alternate ? (
                    <span>Latest Month</span>
                ) : (
                    <span>Last 12 Months Average</span>
                )}
                <Switch
                    toggle={alternate}
                    cbSwitch={setAlternate}
                    colorOption1={theme.colors.DarkAqua}
                    colorOption2={theme.colors.DarkPink}
                    width={50}
                />
            </Alternate>
        </ItemSection>
    );
};

export default BreakDownsSectionIntroPage;

const Alternate = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: absolute;
    top: 30px;
    right: 50px;
    width: 190px;
    > span {
        font-size: 15px;
        flex: 1;
        text-align: right;
        margin-right: 3px;
        /* text-transform: uppercase; */
    }
`;

const Month = styled.div`
    position: absolute;
    top: 15px;
    left: 20px;
    font-size: ${({ theme }) => theme.fonts.defaultFontSize};
`;

const Title = styled.h1`
    position: absolute;
    top: 0;
    left: 20px;
    font-size: 18px;
`;

const Container = styled.div`
    display: flex;
    flex-wrap: wrap;
    justify-content: space-around;
    width: 100%;
    height: 100%;
`;
