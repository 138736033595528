import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { HttpClient } from "adapters/HttpClient";
import {
    OrganisationIdentifier,
    OrgSubscriptionStatus,
} from "common/types/objects/Organisation";
import { getUserType } from "lib/firebase";
import { useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router";
import organsationSlice from "redux/rtkQuery/organisations";
import { useAppDispatch } from "redux/store";
import { theme } from "style/theme";

import { Container } from "./BaseStyles";
import Progress from "./Progress";

let isWaitingForServerResponseForProvider = false;

//These are the post urls for each provider, they create the token and store it in the database
//The server will then return the array of organisations connected to the user
const postUrls: Record<string, string> = {
    xero: "xero/handle-provider-connect",
    quickbooks: "quickbooks/handle-connect",
};

const HandleProviderResponse = () => {
    const { provider } = useParams();
    const navigate = useNavigate();
    const dispatch = useAppDispatch();
    const [isAppstore, setIsAppstore] = useState<boolean>();

    //We don't use the rtk query here because we are only interested in the response from the server
    const [orginisation, setOrginisation] = useState<OrganisationIdentifier>();

    const sendProviderResponseToServer = () => {
        if (!isWaitingForServerResponseForProvider && provider) {
            isWaitingForServerResponseForProvider = true;
            getUserType().then(userType => {
                setIsAppstore(!!userType.isAppstore);
                //do an axios post to the postUrls endpoint
                HttpClient.post<OrganisationIdentifier[]>(
                    postUrls[provider],
                    undefined,
                    { ...window.location },
                )
                    .then(organisations => {
                        const defaultOrginisation =
                            organisations.find(
                                org =>
                                    org.organisationId ===
                                        localStorage.getItem("org_id") ||
                                    org.subscriptionStatus !==
                                        OrgSubscriptionStatus.none,
                            ) || organisations[0];
                        //Update rtk query with the new organisations
                        dispatch(
                            organsationSlice.util.updateQueryData(
                                "getOrganisations",
                                undefined,
                                draft => {
                                    draft.organisations = organisations;
                                    draft.currentOrganisation =
                                        defaultOrginisation;
                                },
                            ),
                        );

                        if (userType.isAppstore) {
                            //If they are an app store customer send them straight to the last page for data sync
                            navigate("/onboarding/finished");
                        } else {
                            //If not an app store customer they will go to the payment page
                            navigate("/onboarding/payment");
                        }
                    })
                    .finally(() => {
                        isWaitingForServerResponseForProvider = false;
                    });
            });
        }
    };

    // Make sure we only send the provider response once
    // eslint-disable-next-line react-hooks/exhaustive-deps
    useEffect(sendProviderResponseToServer, []);

    return (
        <div
            style={{
                position: "relative",
            }}>
            <Div>
                {isAppstore === false && (
                    <CentreProgress>
                        <Progress progress={1} />
                    </CentreProgress>
                )}

                <Explain>
                    <p
                        style={{
                            textAlign: "center",
                            width: 440,
                            marginLeft: "auto",
                            marginRight: "auto",
                        }}>
                        Well, well, well! You've just linked up your Xero
                        account, and now it's time for a little organization
                        extravaganza! Hang tight, as we whisk away into the
                        digital realm and prepare to make your data easy to
                        understand!
                    </p>
                </Explain>
            </Div>
            <ELoadholder>
                <MLoader>
                    <EText>Loading</EText>
                </MLoader>
            </ELoadholder>
        </div>
    );
};

export default HandleProviderResponse;

const Explain = styled.div`
    margin: 20px;
    padding: 5px;
    border: 3px solid ${props => props.theme.colors.DarkBlue};
    background-color: #fff;
    border-radius: 10px;
`;

const Div = styled(Container)`
    position: relative;
`;

const CentreProgress = styled.div`
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
`;

const COLOR = theme.colors.PinkMed;
const centre = (axis = "both") => css`
    position: absolute;
    ${axis == "y" &&
    css`
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    `}
    ${axis == "x" &&
    css`
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
    `}
    ${axis == "both" &&
    css`
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-51%, -50%);
        -moz-transform: translate(-51%, -50%);
        -ms-transform: translate(-51%, -50%);
        -o-transform: translate(-51%, -50%);
        transform: translate(-51%, -50%);
    `}
`;

const textColour = keyframes`
0% {
    color: ${theme.colors.DarkBlue};
}
100% {
    color: ${theme.colors.DarkPink};
}`;

const outerRotate1 = keyframes` 
0% {
    transform: translate(-50%, -50%) rotate(0);
}
100% {
    transform: translate(-50%, -50%) rotate(360deg);
}`;

const outerRotate2 = keyframes` 
0% {
    transform: translate(-50%, -50%) rotate(0);
}
100% {
    transform: translate(-50%, -50%) rotate(-360deg);
}`;

const EText = styled.div`
    ${centre()}
    font-size: ${1.4 * 10}px;
    line-height: ${13 * 10}px;
    animation: ${textColour} 1s alternate linear infinite;
    display: block;
    width: 140px;
    height: 140px;
    text-align: center;
    border: 5px solid ${props => props.theme.colors.DarkBlue};
    border-radius: 70px;
    box-sizing: border-box;
    z-index: 20;

    &:before,
    &:after {
        ${centre()}
        content: " ";
        display: block;
        background: ${COLOR};
        transform-origin: center;
        z-index: -1;
    }

    &:before {
        width: 110%;
        height: 40px;
        animation: ${outerRotate2} 3.5s infinite linear;
    }
    &:after {
        width: 40px;
        height: 110%;
        animation: ${outerRotate1} 8s infinite linear;
    }
`;
const MLoader = styled.div`
    ${centre()}
    width: 200px;
    height: 200px;
    color: #888;
    text-align: center;
    border: 5px solid ${props => props.theme.colors.DarkBlue};
    border-radius: 50%;
    box-sizing: border-box;
    z-index: 20;
    text-transform: uppercase;

    &:after {
        ${centre()}
        content: " ";
        display: block;
        background: ${COLOR};
        transform-origin: center;
        z-index: -1;
        width: 100px;
        height: 106%;
        animation: ${outerRotate1} 15s infinite linear;
    }
`;

const ELoadholder = styled.div`
    ${centre()}
    width: 240px;
    height: 240px;
    border: 5px solid ${props => props.theme.colors.DarkBlue};
    border-radius: 50%;
    box-sizing: border-box;

    &:after {
        ${centre()}
        content: " ";
        display: block;
        background: ${COLOR};
        transform-origin: center;
        z-index: 0;
        width: 100px;

        height: 105%;
        animation: ${outerRotate2} 30s infinite linear;
    }
`;
