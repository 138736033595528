import styled from "@emotion/styled";
import { theme } from "style/theme";

export interface style {
    backgroundColor: string;
    color: string;
    markColor: string;
    drop?: boolean;
    borderColor?: string;
}

interface bubbleProps {
    children?: any;
    style?: style;
    drop?: boolean;
    big?: boolean;
}

export const bubbleDark = {
    backgroundColor: theme.colors.DarkBlue,
    color: theme.colors.Pink,
    markColor: theme.colors.Pink,
};
export const bubbleLight = {
    backgroundColor: theme.colors.Yellow,
    color: theme.colors.DarkBlue,
    markColor: theme.colors.DarkBlue,
};

export const bubblePink = {
    backgroundColor: theme.colors.DarkPink,
    color: theme.colors.DarkBlue,
    markColor: theme.colors.DarkBlue,
};
export const bubblePinkClear = {
    backgroundColor: theme.colors.Pink,
    color: theme.colors.DarkPink,
    markColor: theme.colors.DarkPink,
    borderColor: theme.colors.DarkPink,
};

export const bubbleBlueClear = {
    backgroundColor: theme.colors.Pink,
    color: theme.colors.DarkBlue,
    markColor: theme.colors.DarkBlue,
    borderColor: theme.colors.DarkBlue,
};

const Bubble = ({ children, style = bubbleDark, drop, big }: bubbleProps) => {
    return (
        <Container big={big} drop={drop} style={style}>
            {children}
        </Container>
    );
};

export default Bubble;

const Container = styled.div<{ style: style; drop?: boolean; big?: boolean }>`
    justify-content: center;
    align-items: center;
    display: inline-flex;
    flex-direction: column;
    width: ${props => (props.big ? "200px" : "175px")};
    height: ${props => (props.big ? "200px" : "175px")};
    background-color: ${props => props.style.backgroundColor};
    border: 1px solid
        ${props =>
            props.style.borderColor
                ? props.style.borderColor
                : props.style.backgroundColor};
    color: ${props => props.style.color};
    border-radius: 50%;
    text-align: center;
    margin: 10px;
    margin-top: ${props => (props.drop ? "50px" : "10px")};

    > * {
        margin: 0 auto;
        padding: 0;
        display: block;
        max-width: 90%;
        text-align: center;
        font-size: ${props => props.theme.fonts.defaultFontSize};
    }

    h1,
    h2,
    h3,
    h4 {
        max-width: 75%;
        font-weight: normal;
    }

    span {
        display: block;
        max-width: 100px;
        margin: 0 auto;
    }

    mark {
        background-color: transparent;
        font-size: 27px;
        margin: 5px 0;
        color: ${props => props.style.markColor};
    }

    &.inline {
        display: inline-block;
    }

    @media (max-width: ${props => props.theme.sizes.contentWidthPhone}) {
        margin: 7px;
    }
`;
