import SetSelectedMonth from "components/helper/SetSelectedMonth";
import ReportsNavbar from "components/navigation/ReportsNavbar";
import SmoothScrollLink from "components/navigation/SmoothScrollLink";
import useAuthState from "hooks/firebase/useAuthState";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { useAppSelector } from "redux/store";
import { theme } from "style/theme";

import ReportsCoverPage from "../ReportsCoverPage";

const RevenueSectionCoverPage = () => {
    const selectedMonth = useAppSelector(state => state.dates?.selectedMonth);
    const currentOrganisation = useCurrentOrg();
    const [user] = useAuthState();

    return (
        <>
            <ReportsCoverPage name='Revenue'>
                <header>
                    <h1>Hello {user?.displayName}</h1>
                    Welcome to your <SetSelectedMonth /> revenue reports for{" "}
                    {currentOrganisation?.name}.
                </header>
            </ReportsCoverPage>
            <ReportsNavbar navBackgroundColor={theme.colors.Yellow}>
                <SmoothScrollLink to='#month-breakdown'>
                    {selectedMonth?.toTextMonth()} breakdown
                </SmoothScrollLink>
                <SmoothScrollLink to='#month-budget'>
                    {selectedMonth?.toTextMonth()} budget
                </SmoothScrollLink>
                <SmoothScrollLink to='#progress-ytd'>
                    Progress to date
                </SmoothScrollLink>
            </ReportsNavbar>
        </>
    );
};

export default RevenueSectionCoverPage;
