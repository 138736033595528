import styled from "@emotion/styled";
import useModal from "components/general/CoverPage/Modal/useModal";
import useAuthState from "hooks/firebase/useAuthState";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useSyncDataMutation } from "redux/rtkQuery/organisations";

const XeroDisconnectedHandler: React.FC = () => {
    const [, { error }] = useSyncDataMutation({
        fixedCacheKey: "syncData",
    });
    const modal = useModal(false);
    const [user] = useAuthState();
    const navigate = useNavigate();

    useEffect(() => {
        const modalShouldBeOpen = 
        !/^\/xero-signup/.test(location.pathname) && // don't show if on xero signup page
            !/^\/account\/connect/.test(location.pathname) // don't show if on xero signup page
            && error &&
            "status" in error &&
            error?.status === 502 &&
            error?.data === "xero reconnection required" &&
            user?.email;
        if (modalShouldBeOpen) {
            modal.setOpen();
        }
        //Modal is changing for some reason but we don't care
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [error, user?.email]);

    return (
                <modal.Modal>
                    <Container>
                        <Heading>Xero Connection Lost</Heading>
                        <Paragraph>
                            We apologize for the inconvenience, but it seems
                            your Hello Cashflow account has been disconnected
                            from Xero. If this issue persists, please contact
                            our support team.
                        </Paragraph>
                        <Paragraph>
                            To continue using Hello Cashflow, you will need to
                            reconnect to Xero.
                        </Paragraph>
                        <LoginButton
                            onClick={e =>
                                navigate("/xero-signup/start?reconnect=true")
                            }>
                            Reconnect to Xero
                        </LoginButton>
                        <Paragraph>
                            Simply click the button above, log into you xero
                            account (if required) and click allow.
                        </Paragraph>
                        <ExplanationText>
                            <i>
                                <Strong>Why did this happen?</Strong>
                                <br />
                                Generally, connecting Xero is a one-time
                                process. However, in certain situations, the
                                connection may need to be re-established. This
                                can occur if access to Hello Cashflow has been
                                revoked in Xero, or if there are changes on
                                either our end or Xero's end that require
                                reauthorization.
                                <br />
                                We apologize for any inconvenience caused.
                            </i>
                        </ExplanationText>
                    </Container>
                </modal.Modal>
    );
};

export default XeroDisconnectedHandler;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #f5f5f5;
    border-radius: 10px;
    padding: 20px;
    max-width: 600px;
    margin: 20px auto;
`;

const Heading = styled.h3`
    color: #2c3e50;
    margin-bottom: 10px;
`;

const Paragraph = styled.p`
    color: #34495e;
    text-align: center;
    margin-bottom: 15px;
`;

const LoginButton = styled.button`
    background-color: #3498db;
    color: white;
    border: none;
    border-radius: 5px;
    padding: 10px 20px;
    font-size: 16px;
    cursor: pointer;
    transition: background-color 0.2s;

    &:hover {
        background-color: #2980b9;
    }
`;

const ExplanationText = styled.p`
    font-size: 14px;
    color: #7f8c8d;
    text-align: center;
    margin-top: 30px;
`;

const Strong = styled.strong`
    color: #2c3e50;
`;
