import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import MonthKey from "common/types/monthKey";
import { Item, OrgData } from "types/OrganisationType";

export interface BudgetState {
    expenses: OrgData;
    revenue: OrgData;
}

const initialState: BudgetState = {
    expenses: {},
    revenue: {},
};

export const BudgetSlice = createSlice({
    name: "budget",
    initialState,
    reducers: {
        setBudget: (
            state,
            action: PayloadAction<{
                stateItem: keyof BudgetState;
                month: MonthKey;
                item: string;
                input: number;
            }>,
        ) => {
            const stateItem = (state[action.payload.stateItem] ??= {});

            stateItem[action.payload.month] ??= {};
            stateItem[action.payload.month][action.payload.item] =
                action.payload.input;
        },
        setAllBudget: (
            state,
            action: PayloadAction<{
                stateItem: keyof BudgetState;
                data: OrgData;
            }>,
        ) => {
            state[action.payload.stateItem] = action.payload.data;
        },
        clearBudget: (state, action: PayloadAction<MonthKey[]>) => {
            const months = action.payload;
            months.forEach(month => {
                if (state.revenue) {
                    state.revenue[month] = {};
                }
                if (state.expenses) {
                    state.expenses[month] = {};
                }
            });
        },
    },
});

export const { setBudget, clearBudget, setAllBudget } =
    BudgetSlice.actions;

export default BudgetSlice.reducer;
