import styled from "@emotion/styled";
import { Writer } from "common/Writer";
import { monthItemByPriceDce } from "lib/apiDataHelpers/monthItemByPrice";
import { getOrgItem } from "lib/apiDataHelpers/sumOfMonths";
import { useMemo, useState } from "react";
import {
    Bar,
    BarChart,
    CartesianAxis,
    Legend,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { useAppSelector } from "redux/store";
import { theme } from "style/theme";
import { ItemObject, OrgData } from "types/OrganisationType";
import { ReportDateInfo } from "types/ReportDataInfo";

interface Props {
    actual: OrgData;
    budget: OrgData;
    items: ItemObject;
}
interface XProps {
    x?: number;
    value?: number;
    fill?: string;
}

// eslint-disable-next-line @typescript-eslint/ban-types
interface XObj {
    [key: string]: XProps;
}

const defualtX = {
    ["0"]: {
        x: undefined,
        value: undefined,
        fill: undefined,
    },
    ["1"]: {
        x: undefined,
        value: undefined,
        fill: undefined,
    },
    ["2"]: {
        x: undefined,
        value: undefined,
        fill: undefined,
    },
    ["3"]: {
        x: undefined,
        value: undefined,
        fill: undefined,
    },
    ["4"]: {
        x: undefined,
        value: undefined,
        fill: undefined,
    },
    ["5"]: {
        x: undefined,
        value: undefined,
        fill: undefined,
    },
};

const check = (value: any) => {
    const test = value ?? false;
    if (test !== false) {
        return true;
    } else {
        return false;
    }
};

const BudgetBox = ({ actual, budget, items }: Props) => {
    const reportDates = useAppSelector(state => state.dates);
    const [xdata, setXdata] = useState<XObj>(defualtX);
    const data = useMemo(() => {
        return Object.values(xdata).map((i, index) => {
            const props = i;
            const checkx = check(props.x);
            const checkvalue = check(props.value);
            if (checkx && checkvalue && props.fill) {
                return (
                    <g key={index}>
                        <text
                            x={props?.x}
                            y={0}
                            dy={15}
                            textAnchor='middle'
                            fill={props.fill}
                            fontSize={16}
                            // fontWeight='bold'
                        >
                            {Writer.FormatCurrency(props.value as number)}
                        </text>
                    </g>
                );
            }
        });
    }, [xdata]);
    const chart = useMemo(() => {
        return (
            reportDates && (
                <ResponsiveContainer
                    height={
                        50 +
                        chartData({ actual, budget, items, reportDates })
                            .length *
                            100
                    }
                    width='100%'>
                    <BarChart
                        layout='vertical'
                        data={chartData({ actual, budget, items, reportDates })}
                        barSize={25}
                        margin={{
                            top: 5,
                            right: 10,
                            bottom: 5,
                        }}>
                        <Legend verticalAlign='top' />
                        <Tooltip
                            labelStyle={{ color: theme.colors.DarkBlue }}
                            formatter={value =>
                                Writer.FormatCurrency(value as number)
                            }
                        />
                        <CartesianAxis axisLine={false} />
                        <XAxis
                            tick={props => customTick(props, setXdata)}
                            tickCount={6}
                            type='number'
                            style={{ fill: theme.colors.DarkBlue }}
                            stroke={"none"}
                            height={1}
                        />
                        <YAxis
                            width={170}
                            type='category'
                            dataKey='name'
                            style={{ fill: theme.colors.DarkBlue }}
                            stroke={"none"}
                        />
                        <Bar
                            isAnimationActive={false}
                            dataKey='Actual'
                            fill={theme.colors.Yellow}
                        />
                        <Bar
                            isAnimationActive={false}
                            dataKey='Budget'
                            fill={theme.colors.DarkPink}
                        />
                    </BarChart>
                </ResponsiveContainer>
            )
        );
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [actual, budget, items]);
    return (
        <Container>
            <Chart>{chart}</Chart>
            <XLabels>
                <svg width='100%' height={20}>
                    {data}
                </svg>
            </XLabels>
        </Container>
    );
};

export default BudgetBox;
const XLabels = styled.div`
    border-top: 1px solid ${theme.colors.DarkBlue};
    margin-top: 10px;
`;
const Chart = styled.div`
    max-height: 500px;
    min-height: 180px;
    overflow-y: auto;
    overflow-x: hidden;
`;

const Container = styled.div`
    padding: 10px;
    border: 2px solid ${theme.colors.DarkBlue};
    border-radius: 5px;
`;

const chartData = ({
    actual,
    budget,
    items,
    reportDates,
}: {
    actual: OrgData;
    budget: OrgData;
    items: ItemObject;
    reportDates: ReportDateInfo;
}) => {
    const orderedActual = monthItemByPriceDce(
        actual[reportDates.selectedMonth],
    );
    return orderedActual.map(item => {
        return {
            name: items[item[0]].name,
            Actual: item[1] ?? 0,
            Budget: getOrgItem(budget, reportDates.selectedMonth, item[0]),
        };
    });
};

const customTick = (
    props: any,
    cb: React.Dispatch<React.SetStateAction<XObj>>,
) => {
    const { x, y, payload, style } = props;
    cb(p => {
        p[props.index] = {
            x: props.index === 5 ? x - 10 : x,
            value: payload.value,
            fill: style.fill,
        } as XProps;
        if (props.index === 5) {
            return { ...p };
        }
        return p;
    });
    return <g></g>;
};
