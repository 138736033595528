import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import useReport from "hooks/reports/useReport";
import { Item } from "types/OrganisationType";

const ExpensesActual = ({
    months,
    expensesItems,
}: {
    months: MonthKey[];
    expensesItems: Array<[string, Item]> | undefined;
}) => {
    const expensesActual = useReport()?.expenses.actual;

    return (
        <Container>
            {expensesItems &&
                expensesItems.map(([code, item], index1) => {
                    return (
                        <RevenueItemDiv key={`${index1}${code}`}>
                            {months.map((month, index) => (
                                <RevenueMonthDiv key={`${index1}${index}`}>
                                    {expensesActual?.[month]?.[code]
                                        ? expensesActual[month]?.[code]
                                        : ""}
                                </RevenueMonthDiv>
                            ))}
                        </RevenueItemDiv>
                    );
                })}
        </Container>
    );
};

export default ExpensesActual;

const Container = styled.div`
    display: flex;
    flex-direction: column;
`;

const RevenueItemDiv = styled.div`
    display: flex;
    flex-direction: row;
`;
const RevenueMonthDiv = styled.div`
    position: relative;
    height: 30px;
    width: 120px;
    border: 1px solid #686868;
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: 13px;
`;
