import MonthKey from "common/types/monthKey";
import { format } from "date-fns";

import { addMonthsTo } from "./addMonthsTo";

// const d_ = new Date();
// d_.setDate(0); //Previous month

// export const reportDates.selectedMonth = format(d_, "yyyy-MM") as MonthKey;
// export const lastMonth = addMonthsTo(reportDates.selectedMonth, -1);
// export const sameMonthLastYear = addMonthsTo(reportDates.selectedMonth, -12);
// export const lastTwelveMonths = Array.from(Array(12).keys()).map(i =>
//     addMonthsTo(reportDates.selectedMonth, -11 + i),
// );
// export const generateMonthsKeysBack = (months: number) => {
//     const array = Array.from(Array(months).keys());
//     return array.map(i => addMonthsTo(reportDates.selectedMonth, -months + i + 1));
// };
// export const generateMonthsKeysForward = (months: number) => {
//     const array = Array.from(Array(months).keys());
//     return array.map(i => addMonthsTo(reportDates.selectedMonth, i + 1));
// };
export const generateMonthsKeysBackFromMonth = (
    startingMonth: MonthKey,
    months: number,
) => {
    const array = Array.from(Array(months).keys());
    return array.map(i => addMonthsTo(startingMonth, -months + i + 1));
};

//** Will always return the requested number of months, either starting with the starting month or the next month */
export const generateMonthsKeysForwardFromMonth = (
    startingMonth: MonthKey,
    months: number,
    includeStartMonth?: boolean,
) => {
    const shift = includeStartMonth ? 0 : 1;
    const array = Array.from(Array(months).keys());
    return array.map(i => addMonthsTo(startingMonth, i + shift));
};

//REgardless of which month is selected, we sometimes need to know what the last complete month is (ee, editing data)
const d_ = new Date();
export const currentMonth = format(d_, "yyyy-MM") as MonthKey;

export const yearEndedThisMonth = Array.from(Array(12).keys()).map(i =>
    addMonthsTo(currentMonth, -11 + i),
);

d_.setDate(0); //Previous month
export const latestCompleteMonth = format(d_, "yyyy-MM") as MonthKey;

export const yearEndedLastCompleteMonth = Array.from(Array(12).keys()).map(i =>
    addMonthsTo(latestCompleteMonth, -11 + i),
);
