import { skipToken } from "@reduxjs/toolkit/dist/query";
import MonthKey from "common/types/monthKey";
import {
    useGetCashflowQuery,
    useGetCashTaxSummaryQuery,
} from "redux/rtkQuery/reports";
import { MonthlySalesTaxCashSummary } from "types/OrganisationType";

import useCurrentOrg from "./useCurrentOrg";

const useCashflow = () => {
    const org = useCurrentOrg();

    const organisationId = org?.organisationId;

    const { data: cashflowData } = useGetCashflowQuery(
        organisationId ?? skipToken,
    );

    const { data: cashTaxSummary } = useGetCashTaxSummaryQuery(
        organisationId ?? skipToken,
    );

    console.log({ cashflowData, cashTaxSummary });    

    //If cashTaxSummary is not undefined, map it to an object with monthKey as the key and the value as the cashTaxSummary object
    const cashTaxSummaryMap: Record<MonthKey, MonthlySalesTaxCashSummary> | undefined = cashTaxSummary
        ? cashTaxSummary.reduce(
                (acc, curr) => ({
                    ...acc,
                    [curr.monthKey]: curr,
                }),
                {},
            )
        : undefined;

    return cashTaxSummaryMap && cashflowData
        ? {
              cashflowData,
              cashTaxSummary: cashTaxSummaryMap,
          }
        : undefined;
};

export default useCashflow;
