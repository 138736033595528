import styled from "@emotion/styled";
import { isPreviewEnvironment } from "common/isPreview";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import Switch from "components/general/Switch";
import MonthQuarterSelect from "components/inputs/MonthQuarterSelect";
import MoreInfo from "components/ui/MoreInfo";
import useReport from "hooks/reports/useReport";
import { sumOfMonth, totalOfGivenMonths } from "lib/apiDataHelpers/sumOfMonths";
import { toMmmmYyyy } from "lib/date/reStringifyMonth";
import { useState } from "react";
import { theme } from "style/theme";

import Bubble, { bubbleBlueClear, bubbleDark } from "../Bubble";

const ExpensesSectionIntroPage = () => {
    const [compareLastMonth, setCompareLastMonth] = useState(false);
    const report = useReport();

    if (!report) return null;

    const {
        reportDates: {
            selectedMonth,
            showQuarterly,
            period,
            lastFinancialQuarter,
            lastFinancialQuarterMonths,
            previousFinancialQuarter,
            lastMonth,
            sameMonthLastYear,
            yearAgoFinancialQuarter,
            yearAgoFinancialQuarterMonths,
        },
    } = report;

    const displayPeriod = showQuarterly ? lastFinancialQuarter : selectedMonth;

    const comparePeriod = compareLastMonth
        ? showQuarterly
            ? previousFinancialQuarter
            : lastMonth
        : showQuarterly
        ? yearAgoFinancialQuarter
        : sameMonthLastYear;

    const bublecolor = compareLastMonth ? bubbleBlueClear : bubbleDark;

    const latest = showQuarterly
        ? totalOfGivenMonths(report.expenses.actual, lastFinancialQuarterMonths)
        : sumOfMonth(report.expenses.actual[displayPeriod]);
    const compareData = showQuarterly
        ? totalOfGivenMonths(
              report.expenses.actual,
              yearAgoFinancialQuarterMonths,
          )
        : sumOfMonth(report.expenses.actual[comparePeriod]);

    const percentLastYearMonth = +((latest / compareData) * 100 - 100).toFixed(
        1,
    );

    return (
        <ItemSection column light minHeight={256}>
            <Title>
                Lets take a look at your{" "}
                {isPreviewEnvironment ? <MonthQuarterSelect /> : "monthly"}{" "}
                expenses
            </Title>
            <Container>
                <Bubble style={bubbleDark}>
                    <h3>Here's your expenses for this {period}:</h3>
                    <mark>{report && Writer.FormatCurrency(latest)}</mark>
                    <span>{`${toMmmmYyyy(displayPeriod)}${
                        showQuarterly ? " quarter" : ""
                    }`}</span>
                </Bubble>
                <Bubble big={true} drop={true} style={bublecolor}>
                    <h3>
                        {compareLastMonth
                            ? `Here's your total expenses for this ${period}:`
                            : `Here's your total expenses for the same ${period} last year: `}
                    </h3>
                    <mark>{report && Writer.FormatCurrency(compareData)}</mark>
                    <span>{`${toMmmmYyyy(comparePeriod)}${
                        showQuarterly ? " quarter" : ""
                    }`}</span>
                </Bubble>
                <Bubble style={bublecolor}>
                    <h3>You spent</h3>
                    <mark>
                        {report &&
                            Writer.FormatCurrency(
                                Math.abs(latest - compareData),
                            )}
                    </mark>
                    <span>
                        {report &&
                            `${Writer.DescribeChange(
                                sumOfMonth(
                                    report.expenses.actual[displayPeriod],
                                ) -
                                    sumOfMonth(
                                        report.expenses.actual[comparePeriod],
                                    ),
                                "MORE",
                                "LESS",
                            )} in ${toMmmmYyyy(
                                displayPeriod,
                            )} than in ${comparePeriod.toMmmmYyyy()}`}
                        <MoreInfo
                            helpContent={`${Math.abs(percentLastYearMonth)}% ${
                                percentLastYearMonth > 0 ? "more" : "less"
                            } than ${comparePeriod.toMmmmYyyy()}`}
                        />
                    </span>
                </Bubble>
                <SwitchMonths>
                    <SwitchWords>
                        <b>{compareLastMonth ? "last month" : "last year"}</b>
                    </SwitchWords>
                    <Switch
                        cbSwitch={setCompareLastMonth}
                        toggle={compareLastMonth}
                        width={39}
                        border={theme.colors.DarkBlue}
                        colorOption1={theme.colors.Pink}
                        colorOption2={theme.colors.DarkBlue}
                        borderWideth={1}
                    />
                </SwitchMonths>
            </Container>
        </ItemSection>
    );
};

export default ExpensesSectionIntroPage;
const Title = styled.h1`
    position: absolute;
    top: 0;
    left: 20px;
    font-size: 18px;
`;
const Container = styled.div`
    display: flex;
    width: 100%;
    justify-content: space-evenly;
    flex-wrap: wrap;
`;

const SwitchWords = styled.span`
    margin-right: 10px;
    letter-spacing: 3.2px;
`;

const SwitchMonths = styled.div`
    position: absolute;
    right: 35px;
    top: 10px;
    display: flex;
    align-items: center;
    height: 40px;
    letter-spacing: 3.2px;

    color: ${({ theme }) => theme.colors.DarkBlue};
    text-transform: uppercase;
`;
