import styled from "@emotion/styled";
import useReport from "hooks/reports/useReport";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { CSVLink } from "react-csv";
import { ItemType } from "types/OrganisationType";

const numbers = [1, 2, 3, 4, 5, 6, 7, 8, 9, 10, 11, 12];

const ExtraOptions = () => {
    const data = useReport();

    const months =
        data &&
        numbers.map(number =>
            addMonthsTo(data.reportDates.selectedMonth, number),
        );

    const reveneueItems =
        data &&
        Object.entries(data.items).filter(
            ([code, item]) => item.itemType === ItemType.revenue,
        );
    const expensesItems =
        data &&
        Object.entries(data.items).filter(
            ([code, item]) => item.itemType === ItemType.expense,
        );

    const csvData = months &&
        expensesItems &&
        reveneueItems &&
        data && [
            ["*Account", ...months],
            ...reveneueItems.map(([number, name]) => {
                return [
                    `${name.name} (${number})`,
                    ...months.map(month => {
                        const backmonth = addMonthsTo(month, -12);
                        return data.revenue.actual[backmonth]?.[number] || 0;
                    }),
                ];
            }),
            ...expensesItems.map(([number, name]) => {
                return [
                    `${name.name} (${number})`,
                    ...months.map(month => {
                        const backmonth = addMonthsTo(month, -12);
                        return data.expenses.actual[backmonth]?.[number] || 0;
                    }),
                ];
            }),
        ];
    return (
        <Container>
            <CSVDownload>
                Click Download button to download your budget for the next 12
                mounths. This takes your last twelve months of data for the next
                12 months budget. to use download csv. then go to xero budget
                editor and click import then click import file and select the
                csv file.
                {csvData && (
                    <CSVLink
                        filename={`${
                            data.reportDates.selectedMonth
                        }_to_${addMonthsTo(
                            data.reportDates.selectedMonth,
                            12,
                        )}_budget.csv`}
                        data={csvData}>
                        Download
                    </CSVLink>
                )}
            </CSVDownload>
        </Container>
    );
};
export default ExtraOptions;

const CSVDownload = styled.div`
    font-size: 13px;
    width: 300px;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    margin-top: 1rem;
    margin-bottom: 1rem;
    a {
        background-color: #f5f5f5;
        padding: 8px;
        border-radius: 4px;
        text-decoration: none;
        color: ${props => props.theme.colors.DarkBlue};
        font-size: 14px;
        font-weight: bold;
        &:hover {
            text-decoration: underline;
            background-color: ${props => props.theme.colors.LightBlue};
            color: ${props => props.theme.colors.Pink};
        }
        &:active {
            text-decoration: underline;
        }
        &:visited {
            text-decoration: underline;
        }
    }
`;

const Container = styled.div`
    border: 1.8px solid black;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
`;
