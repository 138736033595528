import styled from "@emotion/styled";
import { HttpClient } from "adapters/HttpClient";
import { format, parseISO } from "date-fns";
import { signInWithCustomToken } from "firebase/auth";
import { auth } from "lib/firebase";
import { useEffect, useState } from "react";

const SignInCommnad = async (uuid: string) => {
    try {
        const token = await HttpClient.get("/user/getCustomToken", undefined, {
            userUid: uuid,
        });
        await signInWithCustomToken(auth, token);
        window.open("/", "_blank");
    } catch (e) {
        console.log(e);
    }
};

const AdminContent = () => {
    const [uuid, setUuid] = useState("");
    const [recentUsers, setRecentUsers] = useState<
        Array<{
            uid: string;
            displayName: string;
            createdAt: string;
            email: string;
        }>
    >();

    useEffect(() => {
        HttpClient.get("/user/all").then(setRecentUsers);
    }, []);

    return (
        <Container>
            <h1>Admin Settings</h1>
            <p>Only admins can see this page.</p>
            <Items>
                <UUIDInput
                    placeholder='UUID'
                    onChange={i => setUuid(i.target.value)}
                />
                <Maskerade onClick={() => SignInCommnad(uuid)}>
                    Sign in
                </Maskerade>
            </Items>
            <h2>Recently added users:</h2>
            <table>
                <thead>
                    <tr>
                        <th>Display name</th>
                        <th>Email address</th>
                        <th>Created at</th>
                        <th></th>
                    </tr>
                </thead>
                <tbody>
                    {(recentUsers || []).map(u => (
                        <tr>
                            <td>{u.displayName}</td>
                            <td>{u.email}</td>
                            <td>
                                {format(
                                    parseISO(u.createdAt),
                                    "d MMM yyyy h:mm a",
                                )}
                            </td>
                            <td>
                                <Maskerade onClick={() => SignInCommnad(u.uid)}>
                                    Sign in
                                </Maskerade>
                            </td>
                        </tr>
                    ))}
                </tbody>
            </table>
        </Container>
    );
};

export default AdminContent;

const UUIDInput = styled.input`
    height: 25px;
`;
const Maskerade = styled.div`
    width: 100px;
    height: 25px;
    background-color: #68ff9b;
    color: black;
    font-size: 20px;
    font-weight: bold;
    text-align: center;
    border-radius: 10px;
    margin-left: 10px;
    cursor: pointer;

    &:hover {
        background-color: #2dfc72;
        color: black;
    }
`;

const Items = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
`;

const Container = styled.div`
    border: 1.8px solid black;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
`;
