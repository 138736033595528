import styled from "@emotion/styled";
import { Portal } from "components/general/portal";
import SocialIcons from "components/general/SocialIcons";
import { useEffect, useState } from "react";
import { theme } from "style/theme";

import { ReactComponent as PhoneMenuSVG } from "../../assets/general/pink-phone-menu.svg";
import { ReactComponent as Xsvg } from "../../assets/general/X.svg";

const PhoneNav = () => {
    const [transform, setTransform] = useState(100);
    useEffect(() => {
        if (transform < 100) {
            document.body.style.overflow = "hidden";
            (
                document.getElementById("portal") as unknown as HTMLElement
            ).style.zIndex = "10";
        } else {
            document.body.style.overflow = "auto";
            setTimeout(() => {
                (
                    document.getElementById("portal") as unknown as HTMLElement
                ).style.zIndex = "0";
            }, 500);
        }
    }, [transform]);

    const open = () => {
        setTransform(0);
    };

    const close = () => {
        setTransform(100);
    };

    return (
        <>
            <PhoneMenuOpen onClick={open} />
            <Portal>
                <Container>
                    <PageCover
                        transform={transform}
                        onClick={close}></PageCover>
                    <SmallNavMenu transform={transform}>
                        <ExitIcon
                            onClick={close}
                            height={20}
                            fill={theme.colors.DarkBlue}
                        />
                        <SocialIcons
                            style={{ marginTop: "65vh" }}
                            height={35}
                        />
                    </SmallNavMenu>
                </Container>
            </Portal>
        </>
    );
};

export default PhoneNav;
const Container = styled.div`
    display: none;
    overflow: hidden;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -100;
    @media (max-width: ${props => props.theme.sizes.contentWidthPhone}) {
        display: flex;
    }
`;

const ExitIcon = styled(Xsvg)`
    margin: 10px;
    margin-left: auto;
`;

const PhoneMenuOpen = styled(PhoneMenuSVG)`
    display: none;
    width: 35px;
    margin-right: 15px;
    z-index: 2;

    @media (max-width: ${props => props.theme.sizes.contentWidthPhone}) {
        display: flex;
    }
`;
const defaulfView = styled.div`
    position: absolute;
    top: 0;
    z-index: 10;
    height: 100vh;
`;

const PageCover = styled(defaulfView)<{ transform: number }>`
    left: 0;
    width: 30%;
    z-index: 10;
    background-color: black;
    opacity: 0.7;
    transform: translateX(-${props => props.transform}%);
    transition: transform 0.25s ease-in-out;
`;

const SmallNavMenu = styled(defaulfView)<{ transform: number }>`
    right: 0;
    width: 70%;
    transition: transform 0.25s ease-in-out;
    transform: translateX(${props => props.transform}%);
    z-index: 10;

    display: flex;
    flex-direction: column;
    background-color: ${props => props.theme.colors.Pink};
`;
