import MonthKey from "common/types/monthKey";
import { toMmmYy } from "lib/date/reStringifyMonth";
import { theme } from "style/theme";

/**
 * returns month in svg format for recharts
 * @param props
 * @returns
 */
export const tickMonthWrap = (props: any) => {
    const getMonthPart = /([^\s]+) '(.*)/;
    const x = (): number => {
        if (props.index === 0) {
            return props.x + 9;
        }
        if (props.index === props.visibleTicksCount) {
            return props.x - 9;
        }
        return props.x;
    };
    const monthPart = getMonthPart.exec(
        toMmmYy(props.payload.value as MonthKey),
    );

    const color = props.style.fill ?? theme.colors.DarkBlue;

    return (
        <g>
            <text fill={color} textAnchor='middle' x={x()} y={props.y + 6}>
                {monthPart && monthPart[1]}
            </text>
            <text fill={color} textAnchor='middle' x={x()} y={props.y + 20}>
                {monthPart && monthPart[2]}
            </text>
        </g>
    );
};
