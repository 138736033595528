import styled from "@emotion/styled";
import {
    generateMonthsKeysBackFromMonth,
    generateMonthsKeysForwardFromMonth,
    latestCompleteMonth,
} from "lib/date/dateConst";
import { useState } from "react";

import ExportXeroBudget from "./ExportXeroBudget";
import FutureFillRevExp from "./FutureFillRevExp";
import RevenueAndExpensesEditor from "./RevenueAndExpensesEditor";
import SelectMonths from "./SelectMonths";

// Generate actual and forecast month arrays
const actualMonthsArray = generateMonthsKeysBackFromMonth(
    latestCompleteMonth,
    localStorage.getItem(`budget-editor months ${true}`)
        ? +(localStorage.getItem(`budget-editor months ${true}`) as string)
        : 3,
);
const forecastMonthsArray = generateMonthsKeysForwardFromMonth(
    latestCompleteMonth,
    localStorage.getItem(`budget-editor months ${undefined}`)
        ? +(localStorage.getItem(`budget-editor months ${undefined}`) as string)
        : 12,
);

const RevenueAndExpensesEditorContainer = () => {
    // State for actual and forecast months
    const [actualMonths, setActualMonths] = useState(actualMonthsArray);
    const [forecastMonths, setForecastMonths] = useState(forecastMonthsArray);

    return (
        <EditorBody>
            <EditorMenu>
                <FutureFillRevExp />
                <ExportXeroBudget />
                {/* Select how many actual and future months to show in the editor */}
                <div style={{ height: 50 }}>
                    <span
                        style={{
                            width: "100%",
                            textAlign: "center",
                            display: "inline-block",
                        }}>
                        Select Months to show
                    </span>
                    <div style={{ display: "flex" }}>
                        <SelectMonths
                            months={actualMonths.length}
                            actual
                            callback={setActualMonths}
                        />
                        <SelectMonths
                            months={forecastMonths.length}
                            callback={setForecastMonths}
                        />
                    </div>
                </div>
                {/* Tell them to budget ext Sales Tax */}
                <div
                    style={{
                        margin: "auto 40px auto auto",
                    }}>
                    Budgets exclude sales tax
                </div>
            </EditorMenu>
            {/* Revenue and Expenses Editor */}
            <div style={{ height: "76vh" }}>
                <RevenueAndExpensesEditor
                    actualMonths={actualMonths}
                    forecastMonths={forecastMonths}
                />
            </div>
        </EditorBody>
    );
};

export default RevenueAndExpensesEditorContainer;

// Styled components
const EditorBody = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 87vh;
`;

const EditorMenu = styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
    padding: 16px;
    z-index: 2;
`;
