import styled from "@emotion/styled";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import Bubble from "components/reports/Bubble";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { toMmmYy } from "lib/date/reStringifyMonth";
import {
    Bar,
    BarChart,
    CartesianAxis,
    Legend,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { useAppSelector } from "redux/store";
import { theme } from "style/theme";
import { OrgData } from "types/OrganisationType";

import { ChartHeader } from "./Styled";

interface CustomersGainednLostProps {
    customerName: string;
    customerInputs: OrgData;
}

const CustomersGainednLost = ({
    customerInputs,
    customerName,
}: CustomersGainednLostProps) => {
    const lastTwelveMonths = useAppSelector(
        state => state.dates?.lastTwelveMonths,
    );

    if (!lastTwelveMonths) return null;

    const turnoverData = lastTwelveMonths.map(m => {
        const lostCustomers =
            (customerInputs[m] && customerInputs[m].cancellations) || 0;
        const totalThisMonth =
            (customerInputs[m] && customerInputs[m].actual) || 0;
        const lastMonth = addMonthsTo(m, -1);
        const totalLastMonth =
            (customerInputs[lastMonth] && customerInputs[lastMonth].actual) ||
            0;
        return {
            name: m,
            gained: totalThisMonth - totalLastMonth + lostCustomers, //implied new customers from change plus making up cancellations
            lost: lostCustomers,
        };
    });

    return (
        <ItemSection padding='0' light column>
            <ChartHeader>
            <h1>Here's how many {customerName.toLowerCase()} you gained and lost</h1>
            <h2>
                Total number of {customerName.toLowerCase()} gained and lost over time
            </h2>
</ChartHeader>
            <ResponsiveContainer width='100%' height={300}>
                <BarChart
                    barCategoryGap={20}
                    data={turnoverData}
                    layout='horizontal'
                    margin={{
                        top: 5,
                        right: 100,
                        left: 0,
                        bottom: 5,
                    }}>
                    <CartesianAxis axisLine={false} />
                    <ReferenceLine
                        y={0}
                        label=''
                        stroke='red'
                        strokeDasharray='3 3'
                    />
                    <YAxis
                        type='number'
                        style={{ fill: theme.colors.DarkBlue }}
                        // stroke={"none"}
                        tickFormatter={(value: number) =>
                            Writer.FormatNumber(value)
                        }
                    />
                    <XAxis
                        type='category'
                        dataKey='name'
                        style={{ fill: theme.colors.DarkBlue }}
                        // stroke={"none"}
                        tick={tickMonthWrap}
                    />
                    <Tooltip
                        labelStyle={{
                            color: theme.colors.DarkBlue,
                        }}
                        labelFormatter={props => toMmmYy(props)}
                        formatter={value =>
                            Writer.FormatNumber(value as number)
                        }
                    />
                    <Bar
                        name='Gained'
                        dataKey='gained'
                        fill={theme.colors.DarkBlue}
                    />
                    <Bar
                        name='Lost'
                        dataKey='lost'
                        fill={theme.colors.DarkPurple}
                    />
                    <Legend />
                </BarChart>
            </ResponsiveContainer>
            <Row>
                <Bubble>
                    <h3>{customerName} Gained</h3>
                    <mark>
                        {turnoverData
                            .reduce((output, input) => {
                                return output + input.gained || 0;
                            }, 0)
                            .toFixed(0)}
                    </mark>
                    <span></span>
                </Bubble>
                <Bubble>
                    <h3>{customerName} Lost</h3>
                    <mark>
                        {turnoverData
                            .reduce((output, input) => {
                                return output + input.lost || 0;
                            }, 0)
                            .toFixed(0)}
                    </mark>
                    <span></span>
                </Bubble>
                <Bubble>
                    <h3>Net {customerName} Gained</h3>
                    <mark>
                        {turnoverData
                            .reduce((output, input) => {
                                return (
                                    output +
                                    (input.gained || 0) -
                                    (input.lost || 0)
                                );
                            }, 0)
                            .toFixed(0)}
                    </mark>
                    <span></span>
                </Bubble>
            </Row>
            <Row>
                <Bubble>
                    <h3>Monthly average of new {customerName.toLowerCase()} gained:</h3>
                    <mark>
                        {(turnoverData
                            .reduce((output, input) => {
                                return output + input.gained || 0;
                            }, 0) /12 )
                            .toFixed(0)}
                    </mark>
                    <span></span>
                </Bubble>
                <Bubble>
                    <h3>Monthly average of new {customerName.toLowerCase()} lost: </h3>
                    <mark>
                        {(turnoverData
                            .reduce((output, input) => {
                                return output + input.lost || 0;
                            }, 0) / 12)
                            .toFixed(0)}
                    </mark>
                    <span></span>
                </Bubble>
                <Bubble>
                    <h3>our average monthly {customerName.toLowerCase()} growth is:</h3>
                    <mark>
                        {(turnoverData
                            .reduce((output, input) => {
                                return (
                                    output +
                                    (input.gained || 0) -
                                    (input.lost || 0)
                                );
                            }, 0) / 12)
                            .toFixed(0)}
                    </mark>
                    <span></span>
                </Bubble>
            </Row>
        </ItemSection>
    );
};

export default CustomersGainednLost;
const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
`;
