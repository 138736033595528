import styled from "@emotion/styled";
import image from "assets/img/backgrounds/home_stretch.png";
import { CSSProperties, ReactNode } from "react";

const CoverPage = ({
    style,
    children,
}: {
    style?: CSSProperties;
    children: ReactNode;
}) => {
    return (
        <Div style={style}>
            {children}
            <BetaForm>
                <b>Beta Feedback Forms</b>
                <a href='https://forms.gle/S9ra3Dw3Qr1nVNnd8'>Issues</a>
                <a href='https://forms.gle/xwiuufjtYQQD7oas8'>Suggestions</a>
            </BetaForm>
        </Div>
    );
};

export default CoverPage;

const BetaForm = styled.div`
    position: absolute;
    right: 0;
    bottom: 0;
    margin: 50px;
    display: flex;
    flex-direction: column;
    a {
        color: ${props => props.theme.colors.DarkBlue};
        padding: 5px;
        text-decoration: none;
        &:hover {
            /* color: ${props => props.theme.colors.Pink}; */
            background-color: #0000005a;
            text-decoration: underline;
        }
        &:visited {
            color: ${props => props.theme.colors.DarkBlue};
        }
        &:active {
            opacity: 0.7;
            color: ${props => props.theme.colors.DarkBlue};
        }
    }
`;

const Div = styled.div`
    display: flex;
    flex-direction: column;
    position: relative;
    height: 75vh;
    background-color: transparent;
    background-image: url(${image});
    background-position: center;
    height: 686px;
    color: ${props => props.theme.colors.DarkBlue};

    .logo {
        position: absolute;
        top: 10px;
        left: 30px;
    }

    .frontpage-tagline {
        position: absolute;
        top: 100px;
        right: 30px;
        font-size: ${props => props.theme.fonts.defaultFontSize};
    }
`;
