import "lib/date/dateClasses/AddMonthHelpers";
import "./lib/firebase";
import { ThemeProvider } from "@emotion/react";
import APIQueryClient from "adapters/query/appQueryClient";
import ShowNotifications from "components/notification/ShowNotifications";
import { createRoot } from "react-dom/client";
import { QueryClientProvider } from "react-query";
import { ReactQueryDevtools } from "react-query/devtools";
import { Provider } from "react-redux";
import { BrowserRouter } from "react-router-dom";
import { store } from "redux/store";
import { theme } from "style/theme";

// setTimeout(() => {
//     HttpClient.post("/xero/connectTest");
// }, 5000);
import App from "./App";
import reportWebVitals from "./reportWebVitals";

const container = document.getElementById("root");
const root = createRoot(container!);

root.render(
    <ThemeProvider theme={theme}>
        <Provider store={store}>
            <ShowNotifications />
            <BrowserRouter>
                <QueryClientProvider client={APIQueryClient}>
                    <App />
                    <ReactQueryDevtools initialIsOpen={false} />
                </QueryClientProvider>
            </BrowserRouter>
        </Provider>
    </ThemeProvider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
