import styled from "@emotion/styled";
import OutsideAlerter from "components/inputs/OutsideAlerter";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import useReport from "hooks/reports/useReport";
import { useState } from "react";
import { useSetItemTypeMutation } from "redux/rtkQuery/reports";
import { useAppDispatch } from "redux/store";
import { ItemCustomClasification, ItemType } from "types/OrganisationType";

const SelectRevStream = () => {
    const [setItem] = useSetItemTypeMutation();
    const [alternate, setAlternate] = useState(false);
    const organisationId = useCurrentOrg()?.organisationId;
    const report = useReport();

    const items = report?.items;
    const dispatch = useAppDispatch();

    return (
        <Container>
            {/* {!alternate && ( */}
            <Button onClick={() => setAlternate(!alternate)}>
                Set customer revenue
            </Button>
            {/* )} */}
            <div style={{ position: "relative" }}>
                {alternate && items && organisationId && (
                    <OutsideAlerter callBack={() => setAlternate(false)}>
                        <Selectable>
                            {Object.entries(items)
                                .filter(
                                    ([id, item]) =>
                                        item.itemType === ItemType.revenue,
                                )
                                .map(([id, item]) => (
                                    <div key={id}>
                                        <span>
                                            {id}: {item.name}
                                        </span>
                                        <input
                                            type='checkbox'
                                            checked={
                                                item.itemClasification ===
                                                ItemCustomClasification.CustomerRevenue
                                            }
                                            onChange={() => {
                                               
                                                    setItem({
                                                        organisationId,
                                                        code: id,
                                                        Item: {
                                                            ...item,
                                                            itemClasification:
                                                                item.itemClasification ===
                                                                ItemCustomClasification.CustomerRevenue
                                                                    ? (null as unknown as ItemCustomClasification)
                                                                    : ItemCustomClasification.CustomerRevenue,
                                                        },
                                                    });
                                               
                                            }}
                                        />
                                    </div>
                                ))}
                        </Selectable>
                    </OutsideAlerter>
                )}
            </div>
        </Container>
    );
};
export default SelectRevStream;

const Selectable = styled.div`
    position: absolute;
    background-color: ${props => props.theme.colors.Pink};
    border: 2px solid ${props => props.theme.colors.DarkPink};
    padding: 4px;
    color: ${props => props.theme.colors.DarkBlue};
    border-radius: 4px;
    min-width: 220px;
    overflow-x: auto;
    max-height: 350px;
`;

const Button = styled.div`
    color: white;
    background-color: ${props => props.theme.colors.LightBlue};
    padding: 10px;
    border-radius: 5px;
    width: fit-content;
    cursor: pointer;
`;
const Container = styled.div`
    color: white;
    background-color: ${props => props.theme.colors.LightBlue};
    border-radius: 5px;
    z-index: 2;
    width: fit-content;
    > span {
        cursor: pointer;
    }
`;
