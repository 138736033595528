import styled from "@emotion/styled";
import { theme } from "style/theme";

import { ReactComponent as FaceBookIcon } from "../../assets/social-icons/FaceBookIcon.svg";
import { ReactComponent as InstagramIcon } from "../../assets/social-icons/InstagramIcon.svg";
import { ReactComponent as TwitterIcon } from "../../assets/social-icons/TwitterIcon.svg";
import { ReactComponent as YouTubeIcon } from "../../assets/social-icons/YouTubeIcon.svg";

const SocialIcons = ({
    height,
    light,
    style,
}: {
    height: number;
    light?: boolean;
    style?: React.CSSProperties;
}) => {
    const color = () => {
        if (light) {
            return theme.colors.Pink;
        } else {
            return theme.colors.DarkBlue;
        }
    };

    return (
        <Container style={style}>
            <a href=''>
                <FaceBookIcon height={height} fill={color()} />
            </a>
            <a href=''>
                <InstagramIcon height={height} fill={color()} />
            </a>
            <a href=''>
                <TwitterIcon height={height} fill={color()} />
            </a>
            <a href=''>
                <YouTubeIcon height={height} fill={color()} />
            </a>
        </Container>
    );
};

export default SocialIcons;

const Container = styled.div`
    width: 250px;
    padding: 10px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
`;
