import { css } from "@emotion/react";
import styled from "@emotion/styled";
import {
    BudgetSources,
    OrganisationTypes,
} from "common/types/objects/Organisation";
import Tooltip from "components/general/Tooltip";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import {
    useSetOrganisationBudgetMutation,
    useSetOrganisationTypeMutation,
} from "redux/rtkQuery/organisations";
import { useAppDispatch } from "redux/store";

const OrganisationSettings = () => {
    const dispatch = useAppDispatch();
    const organisation = useCurrentOrg();
    const [setOrgType] = useSetOrganisationTypeMutation();
    const [updateBudgetSource] = useSetOrganisationBudgetMutation();
    const organisationId = organisation?.organisationId;
    const orgType = organisation?.organisationType;

    const setType = (input: OrganisationTypes) => {
        setOrgType({
            organisationId,
            changeTo: input,
        });
    };

    return (
        <Container>
            <h1>Organisation Settings</h1>

            <span>
                Set the organisation above to the one you wish to change.
            </span>
            <Setting>
                <span>Budget Source:</span>
                <div>
                    <Option>
                        <span>hellocashflow</span>
                        <NotificationsButton
                            backgroundColor={
                                organisation?.budgetSource ===
                                BudgetSources.hellocashflow
                                    ? "black"
                                    : "white"
                            }
                            onClick={() => {
                                if (
                                    organisation?.budgetSource !==
                                    BudgetSources.hellocashflow
                                ) {
                                    updateBudgetSource({
                                        organisationId:
                                            organisation?.organisationId,
                                        type: BudgetSources.hellocashflow,
                                    });
                                }
                            }}>
                            <NotificationSelect
                                backgroundColor={
                                    organisation?.budgetSource ===
                                    BudgetSources.hellocashflow
                                        ? "black"
                                        : "white"
                                }
                            />
                        </NotificationsButton>
                    </Option>
                    <Option>
                        <span>Xero</span>
                        <NotificationsButton
                            backgroundColor={
                                organisation?.budgetSource ===
                                BudgetSources.xero
                                    ? "black"
                                    : "white"
                            }
                            onClick={() => {
                                if (
                                    organisation?.budgetSource !==
                                    BudgetSources.xero
                                ) {
                                    updateBudgetSource({
                                        organisationId:
                                            organisation?.organisationId,
                                        type: BudgetSources.xero,
                                    });
                                }
                            }}>
                            <NotificationSelect
                                backgroundColor={
                                    organisation?.budgetSource ===
                                    BudgetSources.xero
                                        ? "black"
                                        : "white"
                                }
                            />
                        </NotificationsButton>
                    </Option>
                </div>
            </Setting>
            <Setting>
                <p>Choose organisation type:</p>
                <div>
                    <Option>
                        <Tooltip
                            position='bottom'
                            content='A subscription business model is based on selling a product or
                        service in return for a recurring fee. You can offer content,
                        software, service, or even physical products as long as the
                        customers are willing to pay for them.'>
                            <span>subscription</span>
                        </Tooltip>
                        <NotificationsButton
                            backgroundColor={
                                orgType === OrganisationTypes.subscription
                                    ? "black"
                                    : "white"
                            }
                            onClick={() => {
                                if (
                                    orgType !== OrganisationTypes.subscription
                                ) {
                                    setType(OrganisationTypes.subscription);
                                }
                            }}>
                            <NotificationSelect
                                backgroundColor={
                                    orgType === OrganisationTypes.subscription
                                        ? "black"
                                        : "white"
                                }
                            />
                        </NotificationsButton>
                    </Option>
                    <Option>
                        <Tooltip
                            position='bottom'
                            content='Transactional marketing is a business strategy that focuses on
                single, "point of sale" transactions. The emphasis is on
                maximizing the efficiency and volume of individual sales rather
                than developing a relationship with the buyer.'>
                            <span>transactional</span>
                        </Tooltip>
                        <NotificationsButton
                            backgroundColor={
                                orgType === OrganisationTypes.transactional
                                    ? "black"
                                    : "white"
                            }
                            onClick={() => {
                                if (
                                    orgType !== OrganisationTypes.transactional
                                ) {
                                    setType(OrganisationTypes.transactional);
                                }
                            }}>
                            <NotificationSelect
                                backgroundColor={
                                    orgType === OrganisationTypes.transactional
                                        ? "black"
                                        : "white"
                                }
                            />
                        </NotificationsButton>
                    </Option>
                </div>
            </Setting>
            {/* <Setting>
                <span>Notification Options:</span>
                <div>
                    <Option>
                        <span>None</span>
                        <NotificationsButton
                            backgroundColor={
                                organisation?.notifications ===
                                OrgNotificationOptions.none
                                    ? "black"
                                    : "white"
                            }
                            onClick={() => {
                                if (
                                    organisation?.notifications !==
                                    OrgNotificationOptions.none
                                ) {
                                    dispatch(
                                        updateOrgNotifications({
                                            organisationId:
                                                organisation?.organisationId,
                                            type: OrgNotificationOptions.none,
                                        }),
                                    );
                                }
                            }}>
                            <NotificationSelect
                                backgroundColor={
                                    organisation?.notifications ===
                                    OrgNotificationOptions.none
                                        ? "black"
                                        : "white"
                                }
                            />
                        </NotificationsButton>
                    </Option>
                    <Option>
                        <span>Monthly Update</span>
                        <NotificationsButton
                            backgroundColor={
                                organisation?.notifications ===
                                OrgNotificationOptions.emailMonthlyUpdate
                                    ? "black"
                                    : "white"
                            }
                            onClick={() => {
                                if (
                                    organisation?.notifications !==
                                    OrgNotificationOptions.emailMonthlyUpdate
                                ) {
                                    dispatch(
                                        updateOrgNotifications({
                                            organisationId:
                                                organisation?.organisationId,
                                            type: OrgNotificationOptions.emailMonthlyUpdate,
                                        }),
                                    );
                                }
                            }}>
                            <NotificationSelect
                                backgroundColor={
                                    organisation?.notifications ===
                                    OrgNotificationOptions.emailMonthlyUpdate
                                        ? "black"
                                        : "white"
                                }
                            />
                        </NotificationsButton>
                    </Option>
                </div>
            </Setting> */}
        </Container>
    );
};

export default OrganisationSettings;

const Setting = styled.div`
    display: flex;
    align-items: center;
    > div {
        display: flex;
    }
`;

const Container = styled.div`
    border: 1.8px solid black;
    border-radius: 10px;
    padding: 15px;
    width: 100%;
    display: flex;
    flex-direction: column;
`;

const NotificationSelect = styled.div<{ backgroundColor: string }>`
    background-color: ${props => props.backgroundColor};
    height: 100%;
    width: 100%;
`;

const NotificationsButton = styled.button<{ backgroundColor: string }>`
    width: 10px;
    height: 10px;
    padding: 2px;
    transform: rotate(45deg);
    background-color: #f5f5f5;
    border: 1px solid ${props => props.theme.colors.DarkBlue};
    &:hover {
        background-color: #f5f5f5;
        div {
            ${props =>
                props.backgroundColor === "white" &&
                css`
                    background-color: #00000055;
                `}
        }
    }
`;

const Option = styled.div`
    margin: 10px;
    display: flex;
    flex-direction: row-reverse;
    height: 40px;
    align-items: center;
    justify-content: space-evenly;
    > * {
        margin-left: 6px;
    }
`;
