import MonthKey from "common/types/monthKey";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import {
    currentMonth,
    latestCompleteMonth,
    yearEndedThisMonth,
} from "lib/date/dateConst";
import { toMmmmYyyy } from "lib/date/reStringifyMonth";
import { setSelectedMonth } from "redux/slices/DateSlice";
import { useAppDispatch, useAppSelector } from "redux/store";

const SetSelectedMonth = () => {
    const dispatch = useAppDispatch();
    const currentOrg = useCurrentOrg();
    const selectedMonth = useAppSelector(state => state.dates?.selectedMonth);

    const handleChange: React.ChangeEventHandler<HTMLSelectElement> = e => {
        if (!currentOrg) return;
        dispatch(setSelectedMonth([e.target.value as MonthKey, currentOrg]));
    };

    return selectedMonth ? (
        <select value={selectedMonth} style={{}} onChange={handleChange}>
            {yearEndedThisMonth.map(month => (
                <option value={month} key={month}>
                    {toMmmmYyyy(month)}
                    {afterMonth(month)}
                </option>
            ))}
        </select>
    ) : null;
};

export default SetSelectedMonth;

const afterMonth = (month: MonthKey) => {
    switch (month) {
        case latestCompleteMonth:
            return " (latest complete month)";

        case currentMonth:
            return " (to date)";
        default:
            return "";
    }
};
