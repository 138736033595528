import styled from "@emotion/styled";
import { NavLink } from "react-router-dom";

import { ReactComponent as HorizontalLogoLight } from "../../assets/img/logo/horizental version light.svg";
import { ReactComponent as HorizontalLogoDark } from "../../assets/img/logo/horizental version.svg";

const LogoHorizontal = ({
    text,
    showText,
    asLink,
    absolute,
    light,
}: {
    text?: string;
    showText?: boolean;
    asLink?: boolean;
    absolute?: boolean;
    light?: boolean;
}) => {
    const useText = text ?? "Seamless data visualisation";

    const Logo = () => {
        if (light) {
            return <HorizontalLogoLight />;
        }

        return <HorizontalLogoDark />;
    };

    const content = (
        <>
            <Logo />

            {showText ? (
                <>
                    <br />
                    <Tagline>{useText}</Tagline>
                </>
            ) : null}
        </>
    );

    return asLink ? (
        <LogoLink to='/'>{content}</LogoLink>
    ) : (
        <LogoSpan>{content}</LogoSpan>
    );
};

export default LogoHorizontal;

const LogoLink = styled(NavLink)`
    top: 10px;
    left: 30px;
`;

const LogoSpan = styled.span`
    top: 10px;
    left: 30px;
`;
const Tagline = styled.span`
    font-size: ${props => props.theme.fonts.defaultFontSize};
`;
