import styled from "@emotion/styled";
import LogoHorizontal from "components/brand/LogoHorizontal";
import FullScreenNav from "components/navigation/fullScreenNav";
import PhoneNav from "components/navigation/PhoneNav";
import Footer from "components/reports/ReportsFooter/ReportsFooter";
import { useEffect, useRef } from "react";

const speed = 1.4;

function getRandomNumber(min: number, max: number): number {
    return Math.random() * (max - min) + min;
}

interface Info {
    x: number | undefined;
    y: number | undefined;
    xSpeed: number;
    ySpeed: number;
}

function PageNotFound() {
    const parentRef = useRef<HTMLDivElement>(null);
    const childrenRef = useRef<HTMLDivElement>(null);
    const info = useRef<Info>({
        x: undefined,
        y: undefined,
        xSpeed: speed,
        ySpeed: speed,
    });

    const contiune = useRef(true);
    const AnimateFunction = (timestamp: number) => {
        if (parentRef.current && childrenRef.current) {
            const parentHeight = parentRef.current.offsetHeight / 2;
            const parentWidth = parentRef.current.offsetWidth / 2;
            const childrenHeight = childrenRef.current.offsetHeight / 2;
            const childrenWidth = childrenRef.current.offsetWidth / 2;

            info.current.x ??= getRandomNumber(
                -(parentWidth - childrenWidth),
                parentWidth - childrenWidth,
            );
            info.current.y ??= getRandomNumber(
                -(parentHeight - childrenHeight),
                parentHeight - childrenHeight,
            );

            info.current.x = info.current.x + info.current.xSpeed;
            info.current.y = info.current.y + info.current.ySpeed;

            if (info.current.x + childrenWidth >= parentWidth) {
                info.current.xSpeed = -info.current.xSpeed;
                info.current.x = parentWidth - childrenWidth;
            } else if (info.current.x - childrenWidth <= -parentWidth) {
                info.current.xSpeed = -info.current.xSpeed;
                info.current.x = -parentWidth + childrenWidth;
            }
            if (info.current.y + childrenHeight >= parentHeight) {
                info.current.ySpeed = -info.current.ySpeed;
                info.current.y = parentHeight - childrenHeight;
            } else if (
                info.current.y - childrenHeight + 0.2 * childrenHeight <=
                -parentHeight
            ) {
                info.current.ySpeed = -info.current.ySpeed;
                info.current.y =
                    -parentHeight + childrenHeight - 0.2 * childrenHeight;
            }

            childrenRef.current.style.transform = `translateY(${info.current.y}px) translateX(${info.current.x}px)`;
        }
        if (contiune.current) {
            window.requestAnimationFrame(AnimateFunction);
        }
    };

    useEffect(() => {
        window.requestAnimationFrame(AnimateFunction);
        return () => {
            contiune.current = false;
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <Container>
            <TopBar>
                <LogoHorizontal
                    absolute={false}
                    showText={false}
                    asLink={true}
                />
                <FullScreenNav />
                <PhoneNav />
            </TopBar>
            <Body>
                <NF404 ref={parentRef}>
                    <FloatyThing ref={childrenRef} id='floaty-thing'>
                        <div>404</div>
                        <Page404>Page not found</Page404>
                    </FloatyThing>
                </NF404>
            </Body>
            <Footer />
        </Container>
    );
}

export default PageNotFound;

const NF404 = styled.div`
    //   height: 100%;
    display: flex;
    flex: 1;
    align-items: center;
    justify-content: center;
    // min-height: 90vh;
    font-size: 214px;
`;
const FloatyThing = styled.div`
    position: absolute;
    display: flex;
    flex-direction: column;
    //   animation: Floaty 25s linear infinite;
`;
const Page404 = styled.div`
    font-size: 32px;
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: bold;
`;

const Body = styled.div`
    display: flex;
    flex: 1;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
    background-color: ${props => props.theme.colors.Pink};
    min-height: 100vh;
`;

const TopBar = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;
    height: 64px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.DarkBlue};
`;
