import { QueryStatus } from "@reduxjs/toolkit/dist/query";
import { OrgSubscriptionStatus } from "common/types/objects/Organisation";
import useAuthState from "hooks/firebase/useAuthState";
import { getUserType } from "lib/firebase";
import { useEffect } from "react";
import { useNavigate } from "react-router";
import { useGetOrganisationsQuery } from "redux/rtkQuery/organisations";

const useCurrentOrg = (onEmptyOrgs?: () => void) => {
    const [user] = useAuthState();
    const navigate = useNavigate();
    const {
        data: orgs,
        status,
        isLoading,
    } = useGetOrganisationsQuery(undefined, { skip: !user });

    useEffect(() => {
        //Check the subscription status of the current organisation
        if (
            orgs?.currentOrganisation &&
            orgs?.currentOrganisation.subscriptionStatus ===
                OrgSubscriptionStatus.none &&
            !/\/onboarding/.test(window.location.pathname)
        ) {
            //There are two types of customers, direct and app store.
            //If we got this far we don't have a subscription, so we deal with each type differently
            getUserType().then(userType => {
                const isAppstore =
                    userType.isAppstore &&
                    (userType.isXero || userType.isQuickbooks);
                //was organisation created in last 7 days?
                const createdInLast7Days =
                    new Date(orgs.currentOrganisation.createdAt).getTime() >
                    Date.now() - 7 * 24 * 60 * 60 * 1000;
                //If they are an app store customer they get 7 days free
                if (
                    orgs.currentOrganisation.createdAt &&
                    isAppstore &&
                    !createdInLast7Days
                ) {
                    navigate("/account/appstore-subscribe");
                } else if (!isAppstore) {
                    //If not an app store customer they should have a subscription
                    navigate("/onboarding/payment", {
                        state: { orgs: orgs.organisations, rejoin: true },
                    });
                }
            });
        }
    }, [navigate, orgs]);

    if (
        !isLoading &&
        status === QueryStatus.fulfilled &&
        orgs?.organisations.length === 0
    ) {
        onEmptyOrgs?.();
    }

    return orgs?.currentOrganisation;
};

export default useCurrentOrg;
