import { MonthItems } from "types/OrganisationType";
export type SortedItems = Array<[string, number]>;

/**
 * returns month itmes in array sorted by value in descending order
 * @param {MonthItems} month object to filter by months
 * @returns {SortedItems} sorted array
 */
export const monthItemByPriceDce = (month: MonthItems): SortedItems => {
    if (!month) return [];
    //Return only objects that have data
    return Object.entries(month)
        .filter(e => !!e[1])
        .sort((a, b) => b[1] - a[1]);
};
/**
 * returns month itmes in array sorted by value in accending order
 * @param {MonthItems} month object to filter by months
 * @returns {SortedItems} sorted array
 */
export const monthItemByPriceAce = (month: MonthItems): SortedItems => {
    return Object.entries(month)
        .filter(e => !!e[1])
        .sort((a, b) => a[1] - b[1]);
};
