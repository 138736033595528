import { NFormats } from "./types/NFormats";

export class Writer {
    static writeAsWord = (num: number, upper?: boolean) => {
        if (num === 0) return upper ? "Zero" : "zero";
        else {
            const s = convert_millions(num);
            return upper ? s.charAt(0).toUpperCase() + s.slice(1) : s;
        }
    };

    static FormatNumber: (
        n?: number,
        format?: NFormats,
        decimals?: number,
    ) => string = (n, format, decimals) => {
        if (!n && n !== 0) return "";
        format = format ?? NFormats.numberic;
        const toDp = decimals ?? 0;
        switch (format) {
            case NFormats.numberic:
                return n.toLocaleString(undefined, {
                    maximumFractionDigits: toDp,
                    minimumFractionDigits: toDp,
                });
            case NFormats.currency:
                return (
                    (n < 0 ? "-" : "") +
                    "$" +
                    Math.abs(n).toLocaleString(undefined, {
                        maximumFractionDigits: toDp,
                        minimumFractionDigits: toDp,
                    })
                );
            case NFormats.percentage:
                return (n * 100).toFixed(decimals) + "%";
            default:
                return Number.toString();
        }
    };

    static FormatCurrency = (n: number) =>
        this.FormatNumber(n, NFormats.currency);

    static FormattedPercent = (value: number, base: number) =>
        this.FormatNumber(value / base - 1, NFormats.percentage);

    static WordChangePercent = (
        num: number,
        compare: number,
        decimals?: number,
    ) => {
        //debugger
        const ratio = num / compare - 1;
        const p = this.FormatNumber(Math.abs(ratio), NFormats.percentage);
        return (ratio * 100).toFixed(decimals ?? 0) === "0"
            ? "similar to"
            : ratio > 0
            ? `up ${p} from`
            : `down ${p} from`;
    };

    static WordCompareBudget = (actual: number, budget: number) => {
        const dif = actual - budget;
        return `${this.FormatNumber(Math.abs(dif), NFormats.currency)} ${
            dif > 0 ? "above" : "below"
        } your budget`;
    };

    static CurrencyAheadBehind = (a: number, b: number) => {
        const dif = a - b;
        return `${this.FormatNumber(Math.abs(dif), NFormats.currency)} ${
            dif > 0 ? "ahead of" : "behind"
        }`;
    };

    static CurrencyHigherLower = (a: number, b: number) => {
        const dif = a - b;
        return `${this.FormatNumber(Math.abs(dif), NFormats.currency)} ${
            dif > 0 ? "higher" : "lower"
        }`;
    };

    /**
     *
     * @param change the number being described
     * @param textUp If the number is posative this will be returned
     * @param textDown If the number is negative this will be returned
     * @param textEqual If the number is equal this will be returned (default "unchanged")
     * @param percision How percise (e.g. 0.1) - default within 1
     */
    static DescribeChange = (
        change: number,
        textUp: string,
        textDown: string,
        textEqual?: string,
        percision?: number,
    ) => {
        if (Math.abs(change) < (percision ?? 1))
            return textEqual ?? "unchanged";
        return change > 0 ? textUp : textDown;
    };
}

/// utilities

const ones = [
    "",
    "one",
    "two",
    "three",
    "four",
    "five",
    "six",
    "seven",
    "eight",
    "nine",
];
const tens = [
    "",
    "",
    "twenty",
    "thirty",
    "forty",
    "fifty",
    "sixty",
    "seventy",
    "eighty",
    "ninety",
];
const teens = [
    "ten",
    "eleven",
    "twelve",
    "thirteen",
    "fourteen",
    "fifteen",
    "sixteen",
    "seventeen",
    "eighteen",
    "nineteen",
];

function convert_millions(num: number): string {
    if (num >= 1000000) {
        return (
            convert_millions(Math.floor(num / 1000000)) +
            " million " +
            convert_thousands(num % 1000000)
        );
    } else {
        return convert_thousands(num);
    }
}

function convert_thousands(num: number): string {
    if (num >= 1000) {
        return (
            convert_hundreds(Math.floor(num / 1000)) +
            " thousand " +
            convert_hundreds(num % 1000)
        );
    } else {
        return convert_hundreds(num);
    }
}

function convert_hundreds(num: number): string {
    if (num > 99) {
        return (
            ones[Math.floor(num / 100)] + " hundred " + convert_tens(num % 100)
        );
    } else {
        return convert_tens(num);
    }
}

function convert_tens(num: number): string {
    if (num < 10) return ones[num];
    else if (num >= 10 && num < 20) return teens[num - 10];
    else {
        return tens[Math.floor(num / 10)] + " " + ones[num % 10];
    }
}
