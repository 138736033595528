import styled from "@emotion/styled";
import LogoHorizontal from "components/brand/LogoHorizontal";
import { NavLink } from "react-router-dom";

interface Footer {
    light?: boolean;
}

const Footer = ({ light }: Footer) => {
    return (
        <Container light={light}>
            <div style={{ flex: 1 }}>
                <Subscribe>
                    <LogoHorizontal asLink={true} light={true} />
                    {/* <div style={{ marginLeft: "10px" }}>
                        <Tagline>
                            Experience seamless finance visualisation
                        </Tagline>
                        <Form method='get' action='#'>
                            <input type='email' placeholder='Your email...' />
                            <input type='submit' value='Subscribe' />
                        </Form>
                    </div> */}
                </Subscribe>
                <FooterNav>
                    {/* <ul>
                        <li>
                            <b>Sign up</b>
                        </li>
                        <li>Lorem ipsum al</li>
                        <li>Dolar at</li>
                        <li>Veraotio</li>
                        <li>Chauloe</li>
                    </ul>
                    <ul>
                        <li>
                            <b>About</b>
                        </li>
                        <li>About Us</li>
                        <li>Sooth Stories</li>
                        <li>Contact Us</li>
                        <li>Learn More</li>
                    </ul>
                    <ul>
                        <li>
                            <b>More</b>
                        </li>
                        <li>Dolar at</li>
                        <li>Lorem at al</li>
                        <li>Veraotio</li>
                    </ul> */}
                </FooterNav>
            </div>
            <Line />
            <RightBox>
                <h3 style={{ textTransform: "uppercase" }}>
                    Hello cashflow limited all rights reserved
                    <br />© 2019 HelloCashflow
                </h3>
                {/* <BodyLinks>
                    <a href='#1'>Privacy Policy</a>
                    <a href='#2'>Terms of Use</a>
                    <a href='#3'>Contact Us</a>
                </BodyLinks> */}
                {/* too be added later */}
                {/* <SocialIcons light={true} height={35} /> */}
                <BodyLinks>
                    <NavLink to='/Privacy'>Privacy Policy</NavLink>·
                    <NavLink to='/TermsAndConditions'>
                        Terms and Conditions
                    </NavLink>
                </BodyLinks>
            </RightBox>
        </Container>
    );
};

export default Footer;

const Line = styled.span`
    margin: 0 20px;
    border: 1px solid ${({ theme }) => theme.colors.Pink};
    @media (max-width: ${props => props.theme.sizes.contentWidthPhone}) {
        margin: 10px -10px;
    }
`;

const Container = styled.div<{ light?: boolean }>`
    display: flex;
    flex-direction: row;
    padding: 48px;
    color: ${props => props.theme.colors.Pink};
    font-size: 12px;
    background-color: ${props =>
        props.light ? props.theme.colors.Pink : props.theme.colors.DarkBlue};
    @media (max-width: ${props => props.theme.sizes.contentWidthPhone}) {
        flex-direction: column;
        padding: 30px;
    }
`;

const Subscribe = styled.div`
    flex-basis: 50%;
    display: flex;
    @media (max-width: ${props => props.theme.sizes.contentWidthPhone}) {
        flex-direction: column;
    }
`;

const Tagline = styled.span`
    font-size: ${props => props.theme.fonts.defaultFontSize};
`;

const Form = styled.form`
    border-radius: 3px;
    padding-top: 10px;
`;

const FooterNav = styled.div`
    width: 50%;
    display: grid;
    grid-template-columns: 1fr 1fr 1fr;
    ul {
        display: grid;
        list-style: none;
        padding: 0;
        line-height: 1.7;
        width: 100px;
    }

    a {
        text-decoration: none;
        color: white;
        &:hover {
            text-decoration: underline;
        }
    }
    @media (max-width: ${props => props.theme.sizes.contentWidthPhone}) {
        width: 100%;
        ul {
            justify-content: center;
        }
    }
`;

const RightBox = styled.div`
    display: flex;
    flex-direction: column;
    @media (max-width: ${props => props.theme.sizes.contentWidthPhone}) {
        align-items: center;
    }
`;

const BodyLinks = styled.nav`
    a {
        color: ${props => props.theme.colors.Pink};
        padding: 10px;
        text-decoration: none;
        &:hover {
            color: ${props => props.theme.colors.Pink};
            text-decoration: underline;
        }
        &:visited {
            color: ${props => props.theme.colors.Pink};
        }
        &:active {
            opacity: 0.7;
            color: ${props => props.theme.colors.Pink};
        }
    }
`;
