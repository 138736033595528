export interface OrganisationIdentifier {
    organisationId: string;
    name: string;
    provider?: number;
    dataLastUpdated?: string;
    reportingYearBeginsMonth: number;
    isError?: boolean;
    salesTaxPeriodMonths?: number;
    budgetSource: BudgetSources;
    firstTime: boolean;
    organisationType?: OrganisationTypes;
    notifications: OrgNotificationOptions;
    quarters: boolean;
    subscriptionStatus: OrgSubscriptionStatus;
    createdAt: string;
    dataSyncedFrom: string;
    shortCode?: string;
}

export enum OrgSubscriptionStatus {
    none = 0,
    xeroAppStore = 1,
    manual = 2,
    stripe = 3,
}

export enum OrgNotificationOptions {
    none = 0,
    emailMonthlyUpdate = 1,
}

export enum OrganisationTypes {
    transactional = 0,
    subscription = 1,
}

export enum BudgetSources {
    xero = 0,
    hellocashflow = 1,
}
