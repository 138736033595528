import styled from "@emotion/styled";
import { ReactComponent as BackArrow } from "assets/general/down-arrow.svg";
import { ReactComponent as EmailSvg } from "assets/general/email.svg";
import { ReactComponent as ErrorSvg } from "assets/general/error.svg";
import { sendPasswordResetEmail } from "firebase/auth";
import { auth } from "lib/firebase";
import { useEffect, useState } from "react";
import { useLocation } from "react-router";

import { EmailRegex } from "./Login";

const ForgotPassword = () => {
    const { state } = useLocation();
    const [emailError, setEmailError] = useState<string | undefined>(undefined);
    const [emailSent, setEmailSent] = useState<string | undefined>();

    useEffect(() => {
        document.title = "ForgotPassword";
    }, []);

    return (
        <Container>
            <BackButton onClick={() => window.history.back()}>
                <BackArrow />
            </BackButton>
            <Header>ForgotPassword</Header>
            <LoginContainer
                onSubmit={(e: any) => {
                    e.preventDefault();
                    const email = e.target[0].value as string;

                    if (email.toLowerCase().match(EmailRegex)) {
                        sendPasswordResetEmail(auth, email)
                            .then(() => {
                                setEmailError(undefined);
                                setEmailSent(
                                    "Password reset email sent! Check your inbox",
                                );
                            })
                            .catch(error => {
                                const errorCode = error.code;
                                switch (errorCode) {
                                    case "auth/user-not-found":
                                        setEmailError("User not found");
                                        break;
                                    default:
                                        setEmailError(errorCode);
                                }
                            });
                    } else {
                        setEmailError("Invalid email address");
                    }
                }}>
                <InputContainer>
                    <span>Email</span>
                    <InputDiv>
                        <EmailSVG />
                        <input
                            defaultValue={state?.emailAddress}
                            type='text'
                            name='email'
                            placeholder='Type your email'
                        />
                        {emailError && (
                            <InputError>
                                <ErrorSvg />
                                <div>{emailError}</div>
                            </InputError>
                        )}
                    </InputDiv>
                </InputContainer>
                {emailSent && <EmailSent>{emailSent}</EmailSent>}

                <LoginButton type='submit'>
                    <div />
                    <p>Send</p>
                </LoginButton>
            </LoginContainer>
        </Container>
    );
};

export default ForgotPassword;

const EmailSent = styled.div`
    height: 50px;
    width: 100%;
    border-radius: 5px;
    padding: 10px;
    background: rgb(200, 248, 208);
    display: flex;
    align-items: center;
    margin-top: 15px;
    justify-content: center;
    color: #000;
    line-height: 1.5px;
    font-size: 14px;
`;

const InputError = styled.div`
    position: absolute;
    right: 0;

    svg {
        height: 20px;
        width: 20px;
        margin-right: 5px;
        fill: #f02929;
        cursor: pointer;
    }

    div {
        display: none;
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        left: calc(5px + 100%);
        background: rgb(248, 208, 200);
        padding: 8px;
        border-radius: 5px;
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -5px;
            border-width: 7px;
            border-style: solid;
            border-color: transparent rgb(248, 208, 200) transparent transparent;
        }
    }

    &:hover {
        div {
            display: block;
        }
    }
`;

const BackButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        transform: translateX(-3px) rotate(90deg);
        height: 20px;
        width: 20px;
        fill: ${props => props.theme.colors.DarkBlue};
    }
    &:hover {
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 50%;
    }
`;

const EmailSVG = styled(EmailSvg)`
    position: absolute;
    height: calc(100% - 15px);
    width: 30px;
    bottom: 10px;
    left: 0;
    margin-top: calc(100%);
    z-index: 1;
`;

const ExternalSignIn = styled.div`
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const GoogleButton = styled(ExternalSignIn)`
    background-color: ${props => props.theme.colors.Pink};

    background-image: url("https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30px 30px;
    border: 2px solid ${props => props.theme.colors.Pink};
    color: #fff;
    font-size: 35px;
    font-weight: 700;

    &:hover {
        border: 2px solid ${props => props.theme.colors.DarkBlue};
        /* background: #e91e1e; */
    }
`;

const SignUP = styled.div`
    font-size: 14px;
`;

const CustomError = styled.div`
    border-radius: 5px;
    padding: 10px;
    background: rgb(248, 208, 200);
    display: flex;
    align-items: center;
    margin-top: 15px;

    svg {
        height: 70px;
        width: 70px;
        margin-right: 10px;
    }
`;

const LoginButton = styled.button`
    all: unset;
    position: relative;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    width: 50%;
    margin: 10px auto;
    padding: 10px 0;
    border-radius: 25px;
    overflow: hidden;
    cursor: pointer;

    div {
        transition: 0.25s;
        position: absolute;
        width: 300%;
        height: 100%;
        background: linear-gradient(
            90deg,
            ${props => props.theme.colors.PinkMed},
            ${props => props.theme.colors.DarkPink},
            ${props => props.theme.colors.PinkMed},
            ${props => props.theme.colors.DarkPink}
        );
    }

    p {
        z-index: 1;
        margin: 0;
    }
    &:hover {
        div {
            transform: translateX(-30%);
        }
    }
`;

const InputDiv = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 0;
    border-bottom: 2px solid #ccc;
    position: relative;

    input {
        padding-left: 10px;
        font-size: 16px;
        color: #333;
        line-height: 1.2;
        display: block;
        width: 100%;
        height: 30px;
        background: 0 0;
        outline: none;
        border: none;
        padding: 10px 30px;
        position: relative;
        background-color: transparent;
    }
`;
const InputContainer = styled.div`
    span {
        font-size: 16px;
        color: #333;
        line-height: 1.2;
        display: block;
        width: 100%;
        background: 0 0;
    }
`;

const ExternalLogin = styled.div`
    font-size: 16px;
    color: #333;
    line-height: 1.2;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
        margin-top: 10px;
    }
`;

const LoginContainer = styled.form`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    width: 100%;
`;

const LoginHeader = styled.h1`
    margin: 0;
`;
const Header = styled.h1`
    margin: 0;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px 40px;
    width: 100%;
    max-width: 400px;
`;
