import MonthKey from "common/types/monthKey";
import assert from "lib/assert";

import { addMonthsTo } from "../addMonthsTo";

/**
 * @class
 * @classdesc Represents a period with a range between two months.
 */
class Period {
    private static parseYearAndMonth(monthKey: MonthKey): [number, number] {
        const [year, month] = monthKey.split("-").map(part => parseInt(part));
        return [year, month];
    }

    private latestMonth: MonthKey;
    private earliestMonth: MonthKey;

    /**
     * Creates a new Period instance.
     * @param {MonthKey} earliestMonth - The starting month of the period.
     * @param {MonthKey} latestMonth - The ending month of the period.
     * @throws {Error} If the latest month is not greater than the earliest month.
     */
    constructor(earliestMonth: MonthKey, latestMonth: MonthKey) {
        assert(
            latestMonth >= earliestMonth,
            "Latest month must be greater than earliest month",
        );

        this.earliestMonth = earliestMonth;
        this.latestMonth = latestMonth;
    }

    /**
     * Gets the month keys for the period.
     * @returns {Object} The latest and earliest month keys.
     */
    getMonthKeys() {
        return {
            latestMonth: this.latestMonth,
            earliestMonth: this.earliestMonth,
        };
    }

    /**
     * Gets an array of all months between the earliest and latest month keys.
     * @returns {MonthKey[]} An array of month keys.
     */
    getMonths(): MonthKey[] {
        const outputMonths: MonthKey[] = [];
        let currentMonth = this.latestMonth;
        const earliestMonth = this.earliestMonth;

        do {
            outputMonths.push(currentMonth);
            currentMonth = addMonthsTo(currentMonth, -1);
        } while (currentMonth !== earliestMonth);

        outputMonths.push(earliestMonth);
        return outputMonths;
    }

    /**
     * Calculates the number of months between the earliest and latest month keys.
     * @returns {number} The number of months between the earliest and latest month keys.
     */
    numberOfMonthsBetween(): number {
        const [startYear, startMonth] = Period.parseYearAndMonth(
            this.earliestMonth,
        );
        const [endYear, endMonth] = Period.parseYearAndMonth(this.latestMonth);

        return (endYear - startYear) * 12 + (endMonth - startMonth);
    }
}

export default Period;
