import styled from "@emotion/styled";
import { HttpClient } from "adapters/HttpClient";
import MonthKey from "common/types/monthKey";
import MonthDataEditor, {
    MonthDataEditorProps,
} from "components/ui/MonthDataEditor";
import { format } from "date-fns";
import useCashflow from "hooks/reports/useCashflow";
import useReport from "hooks/reports/useReport";
import useUpdator from "hooks/useUpdator";
import { addItemMonths } from "lib/apiDataHelpers/sumOfMonths";
import { addMonthsTo } from "lib/date/addMonthsTo";
import {
    decrementTransfers,
    incrementTransfers,
    setAllTransfers,
    setTransfers,
} from "redux/slices/TransfersSlice";
import { useAppDispatch } from "redux/store";
import {
    ItemCustomClasification,
    ItemSubType,
    ItemType,
    OrgData,
} from "types/OrganisationType";

//LEt's anchor the editor on the current month rather than the selected reporting month for now
const d_ = new Date();
d_.setDate(0); //Previous month
const editorAnchorMonth = format(d_, "yyyy-MM") as MonthKey;
const lastTwelveMonths = Array.from(Array(12).keys()).map(i =>
    addMonthsTo(editorAnchorMonth, -11 + i),
);

const EditTransfers = () => {
    const dispatch = useAppDispatch();

    const report = useReport();
    useUpdator(
        state => state.transfers.present.transfers,
        (data, id) => {
            HttpClient.put("/transfer-inputs", data, {
                organisationId: id,
            });
        },
    );
    const cashflow = useCashflow();

    if (!report || !cashflow) return null;

    const editorProps: MonthDataEditorProps = {
        back: 0,
        forward: 12,
        id: `transfers_${report.organisation.organisationId}`,
        startMonth: editorAnchorMonth,
        items: {
            assets: "Asset purchases",
            loanPayments: "Loan repayments",
            drawings: "Pay yourself (drawings)",
        },
        enableFillHelper: ["loanPayments", "drawings", "assets"],
        setValue: (month: MonthKey, item: string, input?: number) => {
            dispatch(setTransfers({ month, item, input }));
        },
        modifyValue: (month: MonthKey, item: string, change: "+" | "-") => {
            if (change === "+") {
                dispatch(incrementTransfers({ month, item }));
            } else {
                dispatch(decrementTransfers({ month, item }));
            }
        },
        reduxPath: state => state.transfers.present.transfers,
    };

    const fillHistory = (tranfers: OrgData) => {
        const lastYearByItemCode = addItemMonths(tranfers, lastTwelveMonths);
        const byType = Object.keys(lastYearByItemCode).reduce((agg, code) => {
            const itemDetail = report.items[code];
            if (
                itemDetail.itemType !== ItemType.revenue &&
                itemDetail.itemType !== ItemType.expense &&
                itemDetail.itemClasification !==
                    ItemCustomClasification.SalesTax
            ) {
                const key =
                    itemDetail.name.toLowerCase().search("drawings") !== -1
                        ? "drawings"
                        : itemDetail.itemType === ItemType.asset
                        ? "assets"
                        : itemDetail.itemType === ItemType.liability &&
                          itemDetail.itemSubType === ItemSubType.noncurrent
                        ? "loanPayments"
                        : "other";

                agg[key] = (agg[key] || 0) + lastYearByItemCode[code];
            }
            return agg;
        }, {} as { [key: string]: number });

        const projection: OrgData = {};
        for (let i = 0; i <= 12; i++) {
            projection[addMonthsTo(editorAnchorMonth, i)] = {
                assets: -((byType["assets"] ?? 0) / 12).toFixed(0),
                loanPayments: -((byType["loanPayments"] ?? 0) / 12).toFixed(0),
                drawings: -((byType["drawings"] ?? 0) / 12).toFixed(0),
            };
        }

        dispatch(setAllTransfers(projection));
    };

    return (
        <Container>
            <h1>Let’s take a look at your balance sheet budget</h1>
            <LastYearsValue
                // onClick={() =>
                //     //cashflow && fillHistory(cashflow.transfersItemData) TODO
                // }
                title='Click to fill budget from last 12 months'>
                Use last year's values
            </LastYearsValue>
            <p>
                <strong>*Please note:</strong> We have already forecasted your
                tax payments for you (see tax section below)
            </p>
            <MonthDataEditor {...editorProps} />
            <p>
                A balance sheet budget is where you budget what you’re going to
                spend on asset purchases, debt payments and any drawings you’re
                going to put in or take out of your business
            </p>
        </Container>
    );
};

export default EditTransfers;

const LastYearsValue = styled.button`
    background: #f5f5f5;
    border: 1px solid #ccc;
    border-radius: 3px;
    color: #333;
    cursor: pointer;
    font-size: 14px;
    font-weight: bold;
    padding: 5px 5px;
    text-align: center;
    width: 170px;
    box-shadow: 0px 1px 1px rgba(0, 0, 0, 0.288);
    &:hover {
        background: #e6e6e6;
    }
`;

const Container = styled.div`
    p {
        margin: 0;
    }
    h1 {
        margin-top: 10px;
    }
`;
