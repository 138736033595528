import styled from "@emotion/styled";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import Bubble from "components/reports/Bubble";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { toMmmYy } from "lib/date/reStringifyMonth";
import {
    CartesianAxis,
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { useAppSelector } from "redux/store";
import { theme } from "style/theme";
import { OrgData } from "types/OrganisationType";

import { ChartHeader } from "./Styled";

interface TotalCustomersProps {
    customerName: string;
    customerInputs: OrgData;
}

const TotalCustomers = ({
    customerInputs,
    customerName,
}: TotalCustomersProps) => {
    const lastTwelveMonths = useAppSelector(
        state => state.dates?.lastTwelveMonths,
    );

    if (!lastTwelveMonths) return null;

    const totalCustomerData = lastTwelveMonths.map(m => ({
        name: m,
        target: (customerInputs[m] && customerInputs[m].target) || 0,
        actual: (customerInputs[m] && customerInputs[m].actual) || 0,
    }));
    return (
        <ItemSection padding='0' light column>
            <ChartHeader>
            <h1>Here's your total number of {customerName.toLowerCase()}</h1>
            <h2>
                Breakdown of{" "}
                {customerName.slice(0, customerName.length - 1).toLowerCase()} count over time
            </h2>
</ChartHeader>
            <ResponsiveContainer width='100%' height={300}>
                <LineChart
                    barCategoryGap={20}
                    data={totalCustomerData}
                    layout='horizontal'
                    margin={{
                        top: 5,
                        right: 100,
                        left: 0,
                        bottom: 5,
                    }}>
                    <CartesianAxis axisLine={false} />
                    <YAxis
                        type='number'
                        style={{ fill: theme.colors.DarkBlue }}
                        // stroke={"none"}
                        tickFormatter={(value: number) =>
                            Writer.FormatNumber(value)
                        }
                    />
                    <ReferenceLine
                        y={0}
                        label=''
                        stroke='red'
                        strokeDasharray='3 3'
                    />
                    <XAxis
                        type='category'
                        dataKey='name'
                        style={{ fill: theme.colors.DarkBlue }}
                        tick={tickMonthWrap}
                        // stroke={"none"}
                    />
                    <Tooltip
                        labelStyle={{
                            color: theme.colors.DarkBlue,
                        }}
                        labelFormatter={props => toMmmYy(props)}
                        formatter={value =>
                            Writer.FormatNumber(value as number)
                        }
                    />
                    <Line
                        strokeWidth={3}
                        type='monotone'
                        dataKey='actual'
                        name='Actual'
                        stroke={theme.colors.Yellow}
                    />
                    <Line
                        strokeWidth={3}
                        type='monotone'
                        dataKey='target'
                        name='Target'
                        stroke={theme.colors.DarkPink}
                    />
                    <Legend />
                </LineChart>
            </ResponsiveContainer>

            <Row>
                <Bubble>
                    <h3>Average Number of customers over last 12 months</h3>
                    <mark>
                        {(
                            totalCustomerData.reduce((output, innput) => {
                                return output + innput.actual || 0;
                            }, 0) / 12
                        ).toFixed(0)}
                    </mark>
                    <span></span>
                </Bubble>
            </Row>
        </ItemSection>
    );
};

export default TotalCustomers;

const Row = styled.div`
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    width: 100%;
`;
