import { CUSTOMER_TAG, ORGANIZATION_TAG } from "common/constants/TAGS";
import { setAllCustomers } from "redux/slices/CustomersSlice";
import { OrgData } from "types/OrganisationType";

import { api } from "./api";

const customerSlice = api.injectEndpoints({
    endpoints: builder => ({
        getCustomers: builder.query<OrgData, string>({
            query: organisationId => ({
                url: `customer-inputs`,
                params: {
                    organisationId,
                },
            }),
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                try {
                    const { data } = await queryFulfilled;
                    dispatch(setAllCustomers(data));
                } catch (error) {
                    dispatch(setAllCustomers({}));
                }
            },
            providesTags: (result, error, organisationId) => [
                { type: CUSTOMER_TAG, id: organisationId },
                ORGANIZATION_TAG,
            ],
        }),
        updateCustomers: builder.mutation<void, {
            organisationId: string;
            data: OrgData;
        }>({
            query: ({organisationId, data}) => ({
                url: "customer-inputs",
                method: "PUT",
                params: {
                    organisationId,
                },
                data,
            }),
        }),
    }),
});

export default customerSlice;

export const { useGetCustomersQuery, useUpdateCustomersMutation } =
    customerSlice;
