import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { NavLink } from "react-router-dom";
import { useAppSelector } from "redux/store";

const WelcomeScreen = () => {
    const isDemo =
        useCurrentOrg()?.organisationId ==
        "333ae1a4-90e2-4314-8242-e16051302f6f";
    const dates = useAppSelector(state => state.dates);

    return (
        <>
            <p>
                Your {dates?.selectedMonth.toMmmmYyyy()} reports
                {!isDemo ? "" : "*"} are ready:
            </p>
            {
                <NavLink to='/reports/my-revenue' className='button'>
                    Take me there.
                </NavLink>
            }
            {/* 
        Currently giving them the option to link xero if there is no error state and they don't have any linked orginasations 
        TODO: better error handling, ability to add an org after initial linking....
      */}
        </>
    );
};

export default WelcomeScreen;
