import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import OutsideAlerter from "components/inputs/OutsideAlerter";
import useReport from "hooks/reports/useReport";
import { filterByMonths } from "lib/apiDataHelpers/filterByMonths";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { toMmmYy } from "lib/date/reStringifyMonth";
import { useState } from "react";
import {
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { theme } from "style/theme";
import { BalanceSheet } from "types/OrganisationType";

const ChartData = (
    BalanceSheet: BalanceSheet | undefined,
    selectedMonth: MonthKey | undefined,
) => {
    if (!BalanceSheet || !selectedMonth) {
        return undefined;
    }
    const data = filterByMonths({
        obj: BalanceSheet.bankBalance,
        latestMonth: selectedMonth,
        totalMonths: 13,
    });
    return (Object.keys(data) as MonthKey[]).reduce(
        (output, key) => {
            // console.log({
            //     Name: key,
            //     Assets: BalanceSheet.assets[key],
            //     Liabilities: BalanceSheet.liabilities[key],
            //     Equity: BalanceSheet.equity[key],
            //     CurrentLiabilities: BalanceSheet.currentLiabilities[key],
            //     BankBalance: BalanceSheet.bankBalance[key],
            // });
            output.unshift({
                Name: key,
                Assets: BalanceSheet.assets[key],
                Liabilities: BalanceSheet.liabilities[key],
                Equity: BalanceSheet.equity[key],
                CurrentLiabilities: BalanceSheet?.currentLiabilities[key] ?? 0,
                BankBalance: BalanceSheet.bankBalance[key],
            });
            return output;
        },
        [] as Array<{
            Name: MonthKey;
            Assets: number;
            Liabilities: number;
            Equity: number;
            CurrentLiabilities: number;
            BankBalance: number;
        }>,
    );
};

type names = "Assets" | "Liabilities" | "Equity" | "CurrentLiabilities";

const BalanceSheetGraphs = () => {
    const report = useReport();
    const [showOptions, setShowOptions] = useState(false);
    const [selected, setSelected] = useState({
        Assets: true,
        Liabilities: false,
        Equity: true,
        CurrentLiabilities: false,
        BankBalance: false,
    });

    const handleChange = (props: any) => {
        setSelected(pre => {
            return {
                ...pre,
                [props.target.name]: !pre[props.target.name as names],
            };
        });
    };

    return (
        <ItemSection column>
            <div style={{ position: "relative" }}>
                <Label>Here's a look at your Assets and Liabilities</Label>
                <ResponsiveContainer height={250} width='100%'>
                    <LineChart
                        data={ChartData(
                            report?.balanceSheet,
                            report?.reportDates.selectedMonth,
                        )}
                        margin={{ right: 10 }}>
                        <XAxis
                            interval={0}
                            type='category'
                            dataKey='Name'
                            style={{ fill: theme.colors.Pink }}
                            stroke={"none"}
                            tick={props => {
                                if (
                                    props.payload.value === "auto" ||
                                    props.payload.value === 0
                                )
                                    return <></>;
                                return tickMonthWrap(props);
                            }}
                        />
                        <YAxis
                            type='number'
                            style={{ fill: theme.colors.Pink }}
                            stroke={"none"}
                            tickFormatter={(value: number) =>
                                Writer.FormatCurrency(value)
                            }
                        />
                        <ReferenceLine
                            y={0}
                            label=''
                            stroke='red'
                            strokeDasharray='3 3'
                        />

                        <Tooltip
                            labelStyle={{
                                color: theme.colors.DarkBlue,
                            }}
                            labelFormatter={props => {
                                if (props === 0 || props === "auto")
                                    return null;
                                return toMmmYy(props);
                            }}
                            formatter={(value: number | string) => {
                                if (value === 0 || typeof value === "string")
                                    return "";
                                return Writer.FormatCurrency(value);
                            }}
                        />
                        <Legend verticalAlign='top' />
                        {selected.Assets && (
                            <Line
                                dot={false}
                                dataKey='Assets'
                                strokeWidth={2}
                                type='monotone'
                                stroke={theme.colors.Yellow}
                            />
                        )}
                        {selected.Liabilities && (
                            <Line
                                dot={false}
                                dataKey='Liabilities'
                                strokeWidth={2}
                                type='monotone'
                                stroke={theme.colors.Pink}
                            />
                        )}
                        {selected.Equity && (
                            <Line
                                dot={false}
                                dataKey='Equity'
                                strokeWidth={2}
                                type='monotone'
                                stroke={theme.colors.DarkAqua}
                            />
                        )}
                        {selected.CurrentLiabilities && (
                            <Line
                                dot={false}
                                dataKey='CurrentLiabilities'
                                strokeWidth={2}
                                type='monotone'
                                stroke={theme.colors.DarkPink}
                            />
                        )}
                        {selected.BankBalance && (
                            <Line
                                dot={false}
                                dataKey='BankBalance'
                                strokeWidth={2}
                                type='monotone'
                                stroke={theme.colors.WhiteAqua}
                            />
                        )}
                    </LineChart>
                </ResponsiveContainer>
                <Postion>
                    <OutsideAlerter callBack={() => setShowOptions(false)}>
                        <Container>
                            <Title onClick={() => setShowOptions(pre => !pre)}>
                                <SelectInput>Options</SelectInput>
                                <SelectDown>
                                    <span></span>
                                    <div aria-hidden='true'>
                                        <svg
                                            height='20'
                                            width='20'
                                            viewBox='0 0 20 20'
                                            aria-hidden='true'
                                            focusable='false'>
                                            <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
                                        </svg>
                                    </div>
                                </SelectDown>
                            </Title>
                            {showOptions && (
                                <SelectOptions>
                                    <li>
                                        <Checkbox
                                            label='Assets'
                                            value={selected.Assets}
                                            onChange={handleChange}
                                        />
                                    </li>
                                    <li>
                                        <Checkbox
                                            label='Liabilities'
                                            value={selected.Liabilities}
                                            onChange={handleChange}
                                        />
                                    </li>
                                    <li>
                                        <Checkbox
                                            label='Equity'
                                            value={selected.Equity}
                                            onChange={handleChange}
                                        />
                                    </li>
                                    <li>
                                        <Checkbox
                                            label='CurrentLiabilities'
                                            value={selected.CurrentLiabilities}
                                            onChange={handleChange}
                                        />
                                    </li>
                                    <li>
                                        <Checkbox
                                            label='BankBalance'
                                            value={selected.BankBalance}
                                            onChange={handleChange}
                                        />
                                    </li>
                                </SelectOptions>
                            )}
                        </Container>
                    </OutsideAlerter>
                </Postion>
            </div>
        </ItemSection>
    );
};

export default BalanceSheetGraphs;

const Checkbox = ({ label, value, onChange }: any) => {
    return (
        <label>
            <input
                name={label}
                type='checkbox'
                checked={value}
                onChange={onChange}
            />
            {label}
        </label>
    );
};

const Postion = styled.div`
    position: absolute;
    top: 0;
    right: 0;
`;

const Container = styled.div`
    position: relative;
    width: 140px;
`;

const SelectInput = styled.div`
    -moz-box-align: center;
    align-items: center;
    display: grid;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
`;

const Title = styled.div`
    padding-left: 10px;
    font-size: 14px;
    -moz-box-align: center;
    align-items: center;
    background-color: ${theme.colors.DarkBlue};
    border: 2px solid ${theme.colors.Pink};
    display: flex;
    flex-wrap: wrap;
    -moz-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: currentcolor none 0px !important;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    &:hover {
        color: ${theme.colors.DarkPink};
        cursor: pointer;
        svg {
            fill: ${theme.colors.DarkPink};
        }
        span {
            background: ${theme.colors.DarkPink};
        }
    }
`;
const SelectDown = styled.div`
    -moz-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;
    span {
        align-self: stretch;
        background-color: ${theme.colors.Pink};
        margin-bottom: 8px;
        margin-top: 8px;
        width: 1px;
        box-sizing: border-box;
    }
    div {
        color: rgb(204, 204, 204);
        display: flex;
        padding: 8px;
        transition: color 150ms ease 0s;
        box-sizing: border-box;
    }
`;

const SelectOptions = styled.ul`
    margin: 0;
    z-index: 1;
    position: absolute;
    top: 38px;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    background-color: white;
    border: 2px solid ${theme.colors.Pink};
    color: ${theme.colors.DarkBlue};
    list-style: none;
    padding: 10px;

    li {
        padding: 2px 0;
        label {
            cursor: pointer;
        }
    }

    animation: fadeInAnimation ease-in-out 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
`;

const Label = styled.h1`
    margin-right: auto;
    margin-top: -15px;
    margin-bottom: 30px;
    font-size: 24px;
`;
