import { skipToken } from "@reduxjs/toolkit/dist/query";
import { OrganisationIdentifier } from "common/types/objects/Organisation";
import { useMemo } from "react";
import {
    useGetBalanceSheetQuery,
    useGetExpensesQuery,
    useGetItemsQuery,
    useGetRevenueQuery,
} from "redux/rtkQuery/reports";
import { useAppSelector } from "redux/store";
import { BalanceSheet, ItemObject, OrgData } from "types/OrganisationType";
import { ReportDateInfo } from "types/ReportDataInfo";

import useCurrentOrg from "./useCurrentOrg";

const useReport = (): ReportHook | null => {
    const currentOrganisation = useCurrentOrg();
    const date = useAppSelector(state => state.dates);
    const { data: items } = useGetItemsQuery(
        currentOrganisation?.organisationId ?? skipToken,
    );
    const { data: revenueActual } = useGetRevenueQuery(
        currentOrganisation?.organisationId ?? skipToken,
    );
    const { data: expensesActual } = useGetExpensesQuery(
        currentOrganisation?.organisationId ?? skipToken,
    );
    const { revenue: revenueBudget, expenses: expensesBudget } = useAppSelector(
        state => state.budget.present,
    );
    const { data: balanceSheet } = useGetBalanceSheetQuery(
        currentOrganisation?.organisationId ?? skipToken,
    );

    return useMemo(() => {
        if (
            items &&
            revenueActual &&
            revenueBudget &&
            expensesActual &&
            expensesBudget &&
            balanceSheet &&
            currentOrganisation &&
            date
        ) {
            return {
                items,
                organisation: currentOrganisation,
                reportDates: {
                    ...date,
                    period: date.showQuarterly ? "quarter" : "month",
                },
                revenue: {
                    budget: revenueBudget,
                    actual: revenueActual,
                },
                expenses: {
                    budget: expensesBudget,
                    actual: expensesActual,
                },
                balanceSheet,
            };
        } else {
            return null;
        }
    }, [
        items,
        revenueActual,
        revenueBudget,
        expensesActual,
        expensesBudget,
        balanceSheet,
        date,
        currentOrganisation,
    ]);
};

export default useReport;

export interface ReportDateInfoExpanded extends ReportDateInfo {
    period: string;
}

/**
 * Represents a report hook containing financial data for an organization.
 */
export interface ReportHook {
    /** A dictionary of items indexed by item code, each representing a financial item within the organization. */
    items: ItemObject;
    /** An object containing information about the organization. */
    organisation: OrganisationIdentifier;
    /** An object containing expanded report date information. */
    reportDates: ReportDateInfoExpanded;
    /** An object containing revenue data (budget and actual) for the organization. */
    revenue: {
        /** Budget revenue data for the organization. */
        budget: OrgData;
        /** Actual revenue data for the organization. */
        actual: OrgData;
    };
    /** An object containing expenses data (budget and actual) for the organization. */
    expenses: {
        /** Budget expenses data for the organization. */
        budget: OrgData;
        /** Actual expenses data for the organization. */
        actual: OrgData;
    };
    /** An object representing the balance sheet of the organization. */
    balanceSheet: BalanceSheet;
}
