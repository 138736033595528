import UIErrorBoundary from "components/error-boundaries/SectionErrorBoundary";
import ExpensesSectionIncomeBreakdown from "components/reports/expenses/Breakdown/SectionIncomeBreakdown";
import ExpensesSectionBudget from "components/reports/expenses/Budget/SectionBudget";
import ExpensesSectionCoverPage from "components/reports/expenses/SectionCoverPage";
import ExpensesSectionIntroPage from "components/reports/expenses/SectionIntroPage";
import ExpensesSectionLastYear from "components/reports/expenses/SectionLastYear";
import ExpensesSectionYtdProgress from "components/reports/expenses/YTDProgress/SectionYtdProgress";
import Footer from "components/reports/ReportsFooter/ReportsFooter";
import { useEffect } from "react";

const ExpensesPage = () => {
    useEffect(() => {
        document.title = "My Expenses";
    }, []);
    return (
        <>
            <UIErrorBoundary>
                <ExpensesSectionCoverPage />

                <ExpensesSectionIntroPage />

                <ExpensesSectionIncomeBreakdown />

                <ExpensesSectionBudget />

                <ExpensesSectionYtdProgress />

                <ExpensesSectionLastYear />
            </UIErrorBoundary>
            <Footer />
        </>
    );
};

export default ExpensesPage;
