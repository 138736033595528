import ErrorService from "common/ErrorService";
import React from "react";

// eslint-disable-next-line @typescript-eslint/no-empty-interface
interface UIErrorBoundaryProps {
    children: any;
}

interface UIErrorBoundaryState {
    hasError: boolean;
}

class UIErrorBoundaryTool extends React.Component<
    UIErrorBoundaryProps,
    UIErrorBoundaryState
> {
    static getDerivedStateFromError(error: Error) {
        // Update state so the next render will show the fallback UI.
        return { hasError: true };
    }
    constructor(props: UIErrorBoundaryProps) {
        super(props);
        this.state = { hasError: false };
    }

    componentDidCatch(error: Error, errorInfo: React.ErrorInfo) {
        ErrorService.logError(error, errorInfo);
    }

    render() {
        if (this.state.hasError) {
            // You can render any custom fallback UI
            return <h1>Something went wrong</h1>;
        }

        return this.props.children;
    }
}

const UIErrorBoundary = ({ children }: { children?: any[] | any }) => {
    const type = () => {
        if (Array.isArray(children)) {
            const items = children.map((item, index) => {
                return (
                    <UIErrorBoundaryTool key={index}>
                        {item}
                    </UIErrorBoundaryTool>
                );
            });
            return <>{items}</>;
        } else {
            return <UIErrorBoundaryTool>{children}</UIErrorBoundaryTool>;
        }
    };

    return type();
};

export default UIErrorBoundary;
