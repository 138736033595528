import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactNode, useState } from "react";

type item = ReactNode;

export interface dropMenu {
    title: ReactNode;
    items: item[];
}

interface Props {
    menu: dropMenu;
    isPhoneNav?: boolean;
    line?: boolean;
}

function DropDownMenu({ menu, isPhoneNav, line }: Props) {
    const [MenuState, setMenuState] = useState(false);
    const [isClicked, setIsClicked] = useState(false);

    const listItems = menu.items.map((item, index) => {
        return <li key={index}>{item}</li>;
    });

    const clicked = () => {
        if (!isClicked) {
            setIsClicked(true);
            if (!MenuState) {
                setMenuState(true);
            }
            setTimeout(() => {
                window.addEventListener("click", close);
            }, 0);
        }
    };

    const mouseLeave = () => {
        if (!isClicked) {
            close();
        }
    };

    const close = () => {
        setIsClicked(false);
        setMenuState(false);
        setTimeout(() => {
            window.removeEventListener("click", close);
        }, 0);
    };
    const mouseHover = () => {
        setMenuState(true);
    };

    return (
        <>
            <DropMenu onMouseEnter={mouseHover} onMouseLeave={mouseLeave}>
                <MenuName onClick={clicked} show={line && MenuState}>
                    {menu.title}
                </MenuName>
                {MenuState ? <MenuItems>{listItems}</MenuItems> : null}
            </DropMenu>
        </>
    );
}

export default DropDownMenu;

const DropMenu = styled.div`
    position: relative;
    display: flex;
    height: 100%;
`;

const MenuName = styled.button<{ show?: boolean }>`
    height: 100%;
    background: none;
    border: none;
    padding: 0 16px;
    font: inherit;
    outline: inherit;
    display: flex;
    align-items: center;
    color: black;
    text-decoration: none;
    /* z-index: 1; */
    ${props =>
        props.show &&
        css`
            &::after {
                left: 16px;
                width: calc(100% - 32px);
                background: #000;
                top: 45px;
                bottom: 13px;
                content: "";
                height: 3px;
                position: absolute;
                color: #000;
                font-family: Inter, X-LocaleSpecific, sans-serif;
                font-weight: 700;
                line-height: 1.5;
            }
        `}
    &:hover {
        background-color: transparent;
    }
`;

const MenuItems = styled.ul`
    padding: 6px 0;
    cursor: pointer;
    list-style: none;
    top: 35px;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 100%;
    border-radius: 8px;
    overflow: visible;
    box-shadow: 0 16px 24px 2px rgba(29, 17, 51, 0.04),
        0 6px 32px 4px rgba(9, 32, 77, 0.12),
        0 8px 12px -5px rgba(29, 17, 51, 0.12);
    z-index: 10;
    right: 0;
    left: auto;

    li {
        height: 100%;
        padding: 0 10px;
        overflow: visible;
        width: unset;
        animation: menu-fade 80ms ease-in 0ms 1 normal both;
        p {
            margin: 0;
        }
        &:hover {
            background-color: #4a75e0;
            color: #fff;
            text-decoration: none;
            outline: none;
            a {
                color: white;
            }
        }
    }
    @keyframes menu-fade {
        0% {
            opacity: 0;
            visibility: hidden;
        }
        100% {
            opacity: 1;
            visibility: visible;
        }
    }
`;
