import { api } from "./api";

export enum AccountingProvider {
    "xero" = 1,
    "quickbooks" = 2,
}

export interface ProviderConnection {
    connectionId: string;
    organisationName: string;
    providerOrginisationId: string;
    provider: AccountingProvider;
}

//TODO - replace anything xero specific
const accountingProviderSlice = api.injectEndpoints({
    endpoints: builder => ({
        xeroDownloadOrgs: builder.query<void, string[]>({
            query: orgs => ({
                url: "xero/download-data",
                method: "POST",
                data: orgs,
            }),
        }),
        xeroLoginUrl: builder.query<string, void>({
            query: () => ({
                url: "xero/loginurl",
                method: "GET",
            }),
        }),
        providerDisconnect: builder.mutation<void, [string, string]>({
            query: ([providerConnectionId, organisationId]) => ({
                url: "providerconnections",
                method: "DELETE",
                params: {
                    providerConnectionId,
                    organisationId,
                },
            }),
        }),
        xeroConnections: builder.query<ProviderConnection[], void>({
            query: () => ({
                url: "providerconnections",
                method: "GET",
            }),
        }),
    }),
});

export const {
    useXeroDownloadOrgsQuery: useXeroDownloadOrgsQuery,
    useXeroLoginUrlQuery,
    useXeroConnectionsQuery,
    useProviderDisconnectMutation,
} = accountingProviderSlice;
