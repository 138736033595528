import styled from "@emotion/styled";
import useAuthState from "hooks/firebase/useAuthState";
import { useState } from "react";

const Avatar = ({ size }: { size: number }) => {
    const [userState, loading] = useAuthState();
    const [error, setError] = useState<boolean>(false);

    if (!userState) return null;

    const initials = userState.displayName
        ?.split(" ")
        .map(n => n.charAt(0))
        .join("");

    return (
        <>
            {userState.photoURL ? (
                <>
                    <ImagePic size={size} src={userState.photoURL} />
                </>
            ) : (
                <LetterPic size={size}>
                    <div>{initials}</div>
                </LetterPic>
            )}
        </>
    );
};

export default Avatar;

const ImagePic = styled.img<{ size: number }>`
    width: ${({ size }) => (size ? size : 0)}px;
    height: ${({ size }) => (size ? size : 0)}px;
    border-radius: 50%;
`;
const LetterPic = styled.div<{ size: number }>`
    width: ${({ size }) => (size ? size : 0)}px;
    height: ${({ size }) => (size ? size : 0)}px;
    border-radius: 50%;
    background-color: #a0b8f5;
    display: flex;
    justify-content: center;
    align-items: center;
    text-transform: uppercase;
`;
