import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import MonthSelect from "components/inputs/MonthSelect";
import DoughnutChart from "components/recharts/DoughnutChart";
import useReport from "hooks/reports/useReport";
import { filterByMonths } from "lib/apiDataHelpers/filterByMonths";
import { sumOfItemsforMonths } from "lib/apiDataHelpers/sumOfMonths";
import { useEffect, useState } from "react";
import { theme } from "style/theme";
import { MonthItems, OrgData } from "types/OrganisationType";

import ItemsList from "../../shared/ItemsList";

const sumOf = (data: OrgData, startPeriod: MonthKey, endPeriod: MonthKey) => {
    const months = filterByMonths({
        obj: data,
        latestMonth: startPeriod,
        earliestMonth: endPeriod,
    });
    return sumOfItemsforMonths(months);
};

const RevenueDetailYtdProgress = () => {
    const report = useReport();

    const [budgetItems, setBudgetItems] = useState<MonthItems>({});
    const [actualItems, setActualItems] = useState<MonthItems>({});
    const [period, setPeriod] = useState<{
        startDate: MonthKey;
        endDate: MonthKey;
    }>();
    useEffect(() => {
        if (period && report) {
            setBudgetItems(
                sumOf(report.revenue.budget, period.startDate, period.endDate),
            );
            setActualItems(
                sumOf(report.revenue.actual, period.startDate, period.endDate),
            );
        }
    }, [period, report]);

    if (!report) {
        return null;
    }

    const {
        revenue: { actual, budget },
    } = report;

    const breakdownData =
        period &&
        filterByMonths({
            obj: actual,
            latestMonth: period.startDate,
            earliestMonth: period.endDate,
        });

    const breakdownBudget =
        period &&
        filterByMonths({
            obj: budget,
            latestMonth: period.startDate,
            earliestMonth: period.endDate,
        });

    return (
        <Container>
            <Coloumn>
                <h1>Revenue - customize your dates</h1>
                <h2>
                    Here you can customise dates and see how you revenue has
                    gone within the time frame that you choose.
                </h2>
                <MonthSelect
                    currentPeriod={{
                        startDate: period?.startDate,
                        endDate: period?.endDate,
                    }}
                    inputMonths={[actual]}
                    onChange={setPeriod}
                    defaultPeriod={{
                        startDate: report.reportDates.selectedMonth,
                        endDate: report.reportDates.sameMonthLastYear,
                    }}
                />
                <Chart>
                    {period && (
                        <DoughnutChart
                            actual={actual}
                            months={{
                                start: period?.startDate,
                                end: period?.endDate,
                            }}
                            color={theme.colors.DarkBlue}
                        />
                    )}
                </Chart>
                <ItemListStyle>
                    <ItemsList
                        actual={actual}
                        budget={budget}
                        period={period}
                    />
                </ItemListStyle>
            </Coloumn>
        </Container>
    );
};

export default RevenueDetailYtdProgress;

const ItemListStyle = styled.div`
    width: 100%;
    margin: 20px 0;
    padding: 20px;
    border: 2px solid ${theme.colors.DarkBlue};
    border-radius: 5px;
`;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
`;
const Coloumn = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: ${({ theme }) => theme.sizes.modelWidth};
    h1 {
        text-align: center;
        font-size: ${props => props.theme.fonts.defaultHeaderFontSize};
        line-height: 1px;
    }
    h2 {
        text-align: center;

        font-size: ${props => props.theme.fonts.defaultSubHeaderFontSize};
    }
`;

const Chart = styled.div`
    width: 350px;
    height: 350px;
    margin: 40px auto;
`;
