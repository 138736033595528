import UIErrorBoundary from "components/error-boundaries/SectionErrorBoundary";
import Footer from "components/reports/ReportsFooter/ReportsFooter";
import RevenueSectionIncomeBreakdown from "components/reports/revenue/Breakdown/SectionIncomeBreakdown";
import RevenueSectionBudget from "components/reports/revenue/Budget/SectionBudget";
import RevenueSectionCoverPage from "components/reports/revenue/SectionCoverPage";
import RevenueSectionIntroPage from "components/reports/revenue/SectionIntroPage";
import RevenueSectionLastYear from "components/reports/revenue/SectionLastYear";
import RevenueSectionYtdProgress from "components/reports/revenue/YTDProgress/SectionYtdProgress";
import { useEffect } from "react";

const RevenuePage = () => {
    useEffect(() => {
        document.title = "My Revenue";
    }, []);
    return (
        <>
            <UIErrorBoundary>
                <RevenueSectionCoverPage />

                <RevenueSectionIntroPage />

                <RevenueSectionIncomeBreakdown />

                <RevenueSectionBudget />

                <RevenueSectionYtdProgress />

                <RevenueSectionLastYear />
                <Footer />
            </UIErrorBoundary>
        </>
    );
};

export default RevenuePage;
