import { theme } from "style/theme";

const COLORS = {
    PIE_CHART: [
        theme.colors.Pie,
        theme.colors.Pie2,
        theme.colors.Pie3,
        theme.colors.Pie4,
        theme.colors.Pie5,
    ],
    COMPARE_CHART: ["#E49927", "#F05B8D"],

    PALLET: {
        DarkBlue: "#0c0341",
    },
};

export default COLORS;
