import MonthKey from "common/types/monthKey";
import useReport from "hooks/reports/useReport";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { clearBudget, setBudget } from "redux/slices/BudgetSlice";
import { useAppDispatch } from "redux/store";

const useBudgetFill = () => {
    const data = useReport();
    const dispatch = useAppDispatch();

    const futureFill = (months: MonthKey[], force?: boolean) => {
        if (data) {
            months.forEach(month => {
                const lastYearRev = data.revenue.actual[month] ?? {};
                Object.entries(lastYearRev).forEach(([code, value]) => {
                    if (
                        (data.revenue.budget[month] &&
                            data.revenue.budget[month][code] === undefined) ||
                        force
                    ) {
                        dispatch(
                            setBudget({
                                stateItem: "revenue",
                                month: addMonthsTo(month, 12),
                                item: code,
                                input: value,
                            }),
                        );
                    }
                });

                const lastYearExp = data.expenses.actual[month] ?? {};
                Object.entries(lastYearExp).forEach(([code, value]) => {
                    if (
                        (data.expenses.budget[month] &&
                            data.expenses.budget[month][code] === undefined) ||
                        force
                    ) {
                        dispatch(
                            setBudget({
                                stateItem: "expenses",
                                month: addMonthsTo(month, 12),
                                item: code,
                                input: value,
                            }),
                        );
                    }
                });
            });
        }
    };

    const clearBudgetMonths = (months: MonthKey[]) => {
        dispatch(clearBudget(months));
    };
    return { clearBudget: clearBudgetMonths, futureFill };
};

export default useBudgetFill;
