import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import ReportsModal from "components/navigation/expandableSection/ReportsModal";
import useReport from "hooks/reports/useReport";
import { filterByMonths } from "lib/apiDataHelpers/filterByMonths";
import { sumOfMonths } from "lib/apiDataHelpers/sumOfMonths";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { toMmmYy } from "lib/date/reStringifyMonth";
import {
    Legend,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { theme } from "style/theme";
import { OrgData } from "types/OrganisationType";

import ExpensesDetailYtdProgress from "./ModelYtdProgress";

interface ChartItem {
    name: string;
    Actual: number;
    Budget: number;
}
const getChartData = (
    startMonth: MonthKey,
    monthCount: number,
    actual: OrgData,
    budget: OrgData,
) => {
    const rows: ChartItem[] = [];
    const filteredActual = sumOfMonths(
        filterByMonths({
            obj: actual,
            latestMonth: startMonth,
            totalMonths: monthCount,
        }),
    );
    const filteredBudget = sumOfMonths(
        filterByMonths({
            obj: budget,
            latestMonth: startMonth,
            totalMonths: monthCount,
        }),
    );
    (Object.keys(filteredActual) as MonthKey[]).forEach(month => {
        rows.unshift({
            Actual: filteredActual[month],
            Budget: filteredBudget[month],
            name: month,
        });
    });
    return rows;
};

const ExpensesSectionYtdProgress = () => {
    const report = useReport();
    if (!report) {
        return null;
    }

    const {
        expenses: { budget, actual },
        reportDates: { monthsInToYear, selectedMonth },
    } = report;

    return (
        <>
            <ItemSection id='progress-ytd' minHeight={393}>
                <div className='col'>
                    {/* <h1>Here's how your expenses is tracking for the {useFullYear ? "last twelve months" : "year so far"}</h1> */}
                    <h1>Here's how your expenses are tracking</h1>
                    <figure>
                        <div>
                            <ResponsiveContainer height={200} width='100%'>
                                <LineChart
                                    data={getChartData(
                                        selectedMonth,
                                        12,
                                        actual,
                                        budget,
                                    )}
                                    margin={{
                                        right: 10,
                                    }}>
                                    <XAxis
                                        interval={0}
                                        type='category'
                                        dataKey='name'
                                        style={{ fill: theme.colors.Pink }}
                                        stroke={"none"}
                                        tick={tickMonthWrap}
                                    />
                                    <YAxis
                                        type='number'
                                        style={{ fill: theme.colors.Pink }}
                                        stroke={"none"}
                                        tickFormatter={(value: number) =>
                                            Writer.FormatCurrency(value)
                                        }
                                    />
                                    <Tooltip
                                        labelFormatter={props => {
                                            if (props === 0 || props === "auto")
                                                return null;
                                            return toMmmYy(props);
                                        }}
                                        labelStyle={{
                                            color: theme.colors.DarkBlue,
                                        }}
                                        formatter={value =>
                                            Writer.FormatCurrency(
                                                value as number,
                                            )
                                        }
                                    />
                                    <Legend verticalAlign='top' />
                                    <Line
                                        strokeWidth={2}
                                        type='monotone'
                                        dataKey='Actual'
                                        stroke={theme.colors.Yellow}
                                    />
                                    <Line
                                        strokeWidth={2}
                                        type='monotone'
                                        dataKey='Budget'
                                        stroke={theme.colors.DarkPink}
                                    />
                                </LineChart>
                            </ResponsiveContainer>
                        </div>
                    </figure>
                    <p>
                        The chart shows actual and budgeted expenses for the
                        last 12 months. Your current financial year began{" "}
                        {Writer.writeAsWord(monthsInToYear)} month
                        {monthsInToYear === 1 ? "" : "s"} ago.
                    </p>
                </div>

                <ReportsModal path='YTD-progress-model'>
                    <ExpensesDetailYtdProgress />
                </ReportsModal>
            </ItemSection>
        </>
    );
};

export default ExpensesSectionYtdProgress;
