import styled from "@emotion/styled";
import backgroundImage from "assets/img/backgrounds/home_stretch.png";
import LogoHorizontal from "components/brand/LogoHorizontal";
import { AnimatedSymbol } from "components/general/AnimatedSymbol";
import { StylisedGraph } from "components/styledComponects/StyalisedGraph";
import { signInWithCustomToken } from "firebase/auth";
import useAuthState from "hooks/firebase/useAuthState";
import { auth } from "lib/firebase";
import { useEffect } from "react";
import { NavLink, useNavigate } from "react-router-dom";
import { theme } from "style/theme";

import LoginComponent from "./LoginComponent";

const AccountPage = () => {
    const [user, loading] = useAuthState();

    const navigate = useNavigate();
    useEffect(() => {
        document.title = "Login";
        const token = new URLSearchParams(window.location.search).get("token");
        if (token) {
            (async () => {
                await signInWithCustomToken(auth, token);
                navigate("/");
            })();
        }
        //if the user is already logged in, navigate to home
        else if (user) {
            navigate("/"); //this should only run on first page load to not interfere with the login flow, hence the empty dependency array
        }
    // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
        <div>
            <Container
                style={{
                    height: "100vh",
                    display: "flex",
                    flexDirection: "column",
                }}>
                <Logo>
                    <LogoHorizontal />
                </Logo>
                <AnimatedSymbol />
                <StylisedGraph />
                <p
                    style={{
                        position: "absolute",
                        top: "100px",
                        right: "30px",
                        fontSize: theme.fonts.defaultFontSize,
                    }}>
                    Seamless data visualisation
                </p>
                {user ? <p>Logging in...</p> : (
                    <>
                        <LoginComponent />

                        <p>
                            We have streamlined our login process. Please sign in with your accounting provider above.
                        </p>
                        <p>
                            First time here? Log in with your accounting provider to get started. It is free to create an account.
                        </p>
                        <p style={{ fontSize: 10 }}>
                            By creating and using a Hello Cashflow account you
                            are agreeing to these{" "}
                            <NavLink to='/TermsAndConditions'>
                                Terms and Conditions
                            </NavLink>
                        </p>
                    </>
                )}
            </Container>
        </div>
    );
};

export default AccountPage;

const Logo = styled.div`
    top: 10px;
    position: absolute;
    left: 30px;
`;

const Container = styled.div`
    padding: 10px;
    position: relative;
    height: 75vh;
    background-color: transparent;
    background-image: url(${backgroundImage});
    background-position: center;
    min-height: 400px;
    max-height: 686px;
    color: ${props => props.theme.colors.DarkBlue};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
        position: absolute;
        top: 10px;
        left: 30px;
    }

    .frontpage-tagline {
        position: absolute;
        top: 100px;
        right: 30px;
        font-size: $default-font-size;
    }
`;
