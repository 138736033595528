import styled from "@emotion/styled";
import COLORS from "common/constants/COLORS";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import useReport from "hooks/reports/useReport";
import { filterByMonths } from "lib/apiDataHelpers/filterByMonths";
import { monthItemByPriceDce } from "lib/apiDataHelpers/monthItemByPrice";
import { sumOfItemsforMonths } from "lib/apiDataHelpers/sumOfMonths";
import { SetStateAction, useCallback, useState } from "react";
import { Cell, Pie, PieChart, ResponsiveContainer, Sector } from "recharts";
import { theme } from "style/theme";
import { ItemObject, MonthItems, OrgData } from "types/OrganisationType";

const renderActiveShape = (props: any, items: ItemObject, color: string) => {
    const RADIAN = Math.PI / 180;
    const {
        cx,
        cy,
        midAngle,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
        payload,
        percent,
        value,
    } = props;
    const sin = Math.sin(-RADIAN * midAngle);
    const cos = Math.cos(-RADIAN * midAngle);
    const sx = cx + (outerRadius + 10) * cos;
    const sy = cy + (outerRadius + 10) * sin;
    const mx = cx + (outerRadius + 30) * cos;
    const my = cy + (outerRadius + 30) * sin;
    const ex = mx + (cos >= 0 ? 1 : -1) * 22;
    const ey = my;
    const textAnchor = cos >= 0 ? "start" : "end";
    return (
        <g style={{ overflow: "visible" }}>
            <text x={cx} y={cy} dy={8}>
                {" "}
            </text>
            <foreignObject
                max={100}
                fill={fill}
                fontSize={20}
                width={200}
                height={200}
                x={(cx / 2) * 0.85}
                y={(cy / 2) * 0.95}>
                <p
                    style={{
                        display: "flex",
                        justifyContent: "center",
                        alignItems: "center",
                        width: "100%",
                        height: "100%",
                        margin: 0,
                        padding: "10px",
                        textAlign: "center",
                    }}>
                    {payload.name === "Other"
                        ? payload.name
                        : items[payload?.name]?.name}
                </p>
            </foreignObject>
            <Sector
                cx={cx}
                cy={cy}
                innerRadius={innerRadius}
                outerRadius={outerRadius}
                startAngle={startAngle}
                endAngle={endAngle}
                fill={fill}
            />
            <Sector
                cx={cx}
                cy={cy}
                startAngle={startAngle}
                endAngle={endAngle}
                innerRadius={outerRadius + 6}
                outerRadius={outerRadius + 10}
                fill={fill}
            />
            <path
                d={`M${sx},${sy}L${mx},${my}L${ex},${ey}`}
                stroke={fill}
                fill='none'
            />
            <circle cx={ex} cy={ey} r={2} fill={fill} stroke='none' />
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                textAnchor={textAnchor}
                fill={color}>{`${Writer.FormatCurrency(value)}`}</text>
            <text
                x={ex + (cos >= 0 ? 1 : -1) * 12}
                y={ey}
                dy={18}
                textAnchor={textAnchor}
                fill={theme.colors.Pink}>
                {`${(percent * 100).toFixed(1)}%`}
            </text>
        </g>
    );
};

const DoughnutChart = ({
    actual,
    month,
    months,
    color,
}: {
    actual: OrgData;
    month?: MonthKey;
    months?: { start: MonthKey; end: MonthKey };
    color?: string;
}) => {
    const report = useReport();

    const items = report?.items;
    const [activeIndex, setActiveIndex] = useState(0);

    const onPieEnter = useCallback(
        (_: any, index: SetStateAction<number>) => {
            setActiveIndex(index);
        },
        [setActiveIndex],
    );
    if (!items) return null;
    const monthItems = month ? actual[month] : {};

    return (
        <ResponsiveContainer width='100%' height={350}>
            <StyledPieChart
                margin={{ right: 110, left: 110, bottom: 20, top: 30 }}>
                <Pie
                    isAnimationActive={false}
                    activeIndex={activeIndex}
                    stroke='none'
                    data={
                        months
                            ? chartDataMonths(months, actual)
                            : chartDataMonth(monthItems)
                    }
                    activeShape={props =>
                        renderActiveShape(props, items, color ? color : "white")
                    }
                    onMouseEnter={onPieEnter}
                    dataKey='value'
                    nameKey='name'
                    cx='50%'
                    cy='50%'
                    innerRadius={105}
                    outerRadius={140}>
                    {(months
                        ? chartDataMonths(months, actual)
                        : chartDataMonth(monthItems)
                    ).map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={
                                COLORS.PIE_CHART[
                                    index % COLORS.PIE_CHART.length
                                ]
                            }
                        />
                    ))}
                </Pie>
            </StyledPieChart>
        </ResponsiveContainer>
    );
};

export default DoughnutChart;

const StyledPieChart = styled(PieChart)`
    svg {
        overflow: visible;
    }
`;

const chartDataMonths = (
    months: { start: MonthKey; end: MonthKey },
    data: OrgData,
) => {
    const items = sumOfItemsforMonths(
        filterByMonths({
            obj: data,
            latestMonth: months.start,
            earliestMonth: months.end,
        }),
    );
    return chartDataMonth(items);
};
const chartDataMonth = (data: MonthItems) => {
    const filteredData = monthItemByPriceDce(data);
    let upto = 0;
    let other = 0;
    return filteredData.flatMap((item, index) => {
        if (!item[1]) return [];
        if (upto < 9) {
            upto += 1;
            return {
                name: item[0],
                value: item[1],
            };
        } else {
            if (index === filteredData.length - 1) {
                return {
                    name: "Other",
                    value: (other += item[1]),
                };
            }
            other += item[1];
            return [];
        }
    });
};
