import { PolarAngleAxis, RadialBar, RadialBarChart } from "recharts";

const TestChart2 = ({
    name,
    value,
    fillColor = "#82ca9d",
    baseColor = "#f5f5f5",
    opacity,
    circleSize = 250,
}: {
    name?: string;
    value: number;
    fillColor?: string;
    baseColor?: string;
    opacity?: number;
    circleSize?: number;
}) => {
    const data = [{ name, value }];

    return (
        <RadialBarChart
            width={circleSize}
            height={circleSize}
            cx={circleSize / 2}
            cy={circleSize / 2}
            innerRadius={circleSize / 2 - circleSize / 8}
            outerRadius={circleSize / 2}
            barSize={25}
            data={data}
            startAngle={90}
            endAngle={-270}>
            <PolarAngleAxis
                type='number'
                domain={[0, 100]}
                angleAxisId={0}
                tick={false}
            />
            <RadialBar
                background={{ fill: baseColor, opacity: opacity }}
                dataKey='value'
                cornerRadius={circleSize / 2}
                fill={fillColor}
            />
            <text
                x={circleSize / 2}
                y={circleSize / 2}
                textAnchor='middle'
                fontSize={30}
                dominantBaseline='middle'
                className='progress-label'>
                {value}%
            </text>
        </RadialBarChart>
    );
};

export default TestChart2;
