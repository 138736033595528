import "firebase/compat/auth";
import styled from "@emotion/styled";
import { ReactComponent as BackArrow } from "assets/general/down-arrow.svg";
import { ReactComponent as EmailSvg } from "assets/general/email.svg";
import { ReactComponent as ErrorSvg } from "assets/general/error.svg";
import { ReactComponent as Password } from "assets/general/password.svg";
import { ReactComponent as Person } from "assets/general/person.svg";
import {
    createUserWithEmailAndPassword,
    updateProfile,
    UserCredential,
} from "firebase/auth";
import { auth } from "lib/firebase";
import { useEffect, useRef, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router";

import { handleSignIn as defaultSignIn } from "./helpers/handleSignIn";

export const EmailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

function Login({
    handleSignIn = defaultSignIn,
}: {
    handleSignIn?: (result: UserCredential, navigate: NavigateFunction) => void;
}) {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Create Account";
    }, []);

    const formRef = useRef<HTMLFormElement>(null);
    const [emailError, setEmailError] = useState<string | undefined>(undefined);
    const [passwordError, setPasswordError] = useState<string | undefined>(
        undefined,
    );
    const [error, setError] = useState<string | undefined>(undefined);

    return (
        <Container>
            <BackButton onClick={() => window.history.back()}>
                <BackArrow />
            </BackButton>
            <LoginHeader>Sign Up</LoginHeader>
            {error && (
                <CustomError>
                    <ErrorSvg />
                    {error}
                </CustomError>
            )}

            <LoginContainer
                ref={formRef}
                onSubmit={(e: any) => {
                    e.preventDefault();
                    const firstName = e.target[0].value as string;
                    const lastName = e.target[1].value as string;
                    const email = e.target[2].value as string;
                    const password = e.target[3].value as string;

                    if (email.toLowerCase().match(EmailRegex)) {
                        createUserWithEmailAndPassword(
                            auth,
                            email,
                            password,
                        ).then(res => {
                            if (auth.currentUser) {
                                updateProfile(auth.currentUser, {
                                    displayName: `${firstName} ${lastName}`,
                                })
                                    .then(() => {
                                        handleSignIn(res, navigate);
                                    })
                                    .catch(error => {
                                        setError(error.code);
                                    });
                            }
                        });
                    }
                }}>
                <InputContainer>
                    <span>First Name</span>
                    <InputDiv>
                        <PersonSVG />
                        <input
                            type='text'
                            name='first_name'
                            placeholder='First Name'
                        />
                    </InputDiv>
                </InputContainer>
                <InputContainer>
                    <span>Last Name</span>
                    <InputDiv>
                        <PersonSVG />
                        <input
                            type='text'
                            name='last_name'
                            placeholder='Last name'
                        />
                    </InputDiv>
                </InputContainer>
                <InputContainer>
                    <span>Email</span>
                    <InputDiv>
                        <EmailSVG />
                        <input type='text' name='email' placeholder='E-email' />
                        {emailError && (
                            <InputError>
                                <ErrorSvg />
                                <div>{emailError}</div>
                            </InputError>
                        )}
                    </InputDiv>
                </InputContainer>

                <InputContainer>
                    <span>Password</span>
                    <InputDiv>
                        <PasswordSvg />
                        <input
                            type='password'
                            name='pass'
                            placeholder='Password'
                        />
                        {passwordError && (
                            <InputError>
                                <ErrorSvg />
                                <div>{passwordError}</div>
                            </InputError>
                        )}
                    </InputDiv>
                </InputContainer>

                <LoginButton type='submit'>
                    <div />
                    <p>Create</p>
                </LoginButton>
            </LoginContainer>
        </Container>
    );
}

export default Login;

const InputError = styled.div`
    position: absolute;
    right: 0;

    svg {
        height: 20px;
        width: 20px;
        margin-right: 5px;
        fill: #f02929;
        cursor: pointer;
    }

    div {
        display: none;
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        left: calc(5px + 100%);
        background: rgb(248, 208, 200);
        padding: 8px;
        border-radius: 5px;
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -5px;
            border-width: 7px;
            border-style: solid;
            border-color: transparent rgb(248, 208, 200) transparent transparent;
        }
    }

    &:hover {
        div {
            display: block;
        }
    }
`;

const EmailSVG = styled(EmailSvg)`
    position: absolute;
    height: calc(100% - 15px);
    width: 30px;
    bottom: 10px;
    left: 0;
    margin-top: calc(100%);
    z-index: 1;
`;

const PersonSVG = styled(Person)`
    position: absolute;
    height: calc(100% - 15px);
    width: 30px;
    bottom: 10px;
    left: 0;
    margin-top: calc(100%);
    z-index: 1;
`;
const PasswordSvg = styled(Password)`
    position: absolute;
    height: calc(100% - 15px);
    width: 30px;
    bottom: 10px;
    left: 0;
    margin-top: calc(100%);
    z-index: 1;
`;

const ExternalSignIn = styled.div`
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
`;

const GoogleButton = styled(ExternalSignIn)`
    background-color: ${props => props.theme.colors.Pink};

    background-image: url("https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30px 30px;
    border: 2px solid ${props => props.theme.colors.Pink};
    color: #fff;
    font-size: 35px;
    font-weight: 700;

    &:hover {
        border: 2px solid ${props => props.theme.colors.DarkBlue};
        /* background: #e91e1e; */
    }
`;

const SignUP = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    flex-direction: column;

    a {
        color: ${props => props.theme.colors.DarkPink};
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        margin-top: 10px;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const CustomError = styled.div`
    border-radius: 5px;
    padding: 10px;
    background: rgb(248, 208, 200);
    display: flex;
    align-items: center;
    margin-top: 15px;

    svg {
        height: 70px;
        width: 70px;
        margin-right: 10px;
    }
`;

const ForgotPassword = styled.div`
    height: 30px;
    margin: auto 0;

    color: #333;
    line-height: 1.2;
    display: block;
    width: 100%;
    background: 0 0;
    text-align: right;
    font-size: 16px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const LoginButton = styled.button`
    all: unset;
    position: relative;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    width: 50%;
    margin: 10px auto;
    padding: 10px 0;
    border-radius: 25px;
    overflow: hidden;
    cursor: pointer;

    div {
        transition: 0.25s;
        position: absolute;
        width: 300%;
        height: 100%;
        background: linear-gradient(
            90deg,
            ${props => props.theme.colors.PinkMed},
            ${props => props.theme.colors.DarkPink},
            ${props => props.theme.colors.PinkMed},
            ${props => props.theme.colors.DarkPink}
        );
    }

    p {
        z-index: 1;
        margin: 0;
    }

    &:hover {
        div {
            transform: translateX(-30%);
        }
    }
`;

const InputDiv = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 0;
    border-bottom: 2px solid #ccc;
    position: relative;

    input {
        padding-left: 10px;
        font-size: 16px;
        color: #333;
        line-height: 1.2;
        display: block;
        width: 100%;
        height: 30px;
        background: 0 0;
        outline: none;
        border: none;
        padding: 10px 30px;
        position: relative;
        background-color: transparent;
    }
`;
const InputContainer = styled.div`
    margin-top: 8px;
    span {
        font-size: 16px;
        color: #333;
        line-height: 1.2;
        display: block;
        width: 100%;
        background: 0 0;
    }
`;

const ExternalLogin = styled.div`
    font-size: 16px;
    color: #333;
    line-height: 1.2;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    div {
        margin-top: 10px;
    }
`;

const LoginContainer = styled.form`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    width: 100%;
    font-size: 14px;
`;

const LoginHeader = styled.h1`
    margin: 0;
`;

const Container = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px 40px;
    width: 100%;
    max-width: 400px;
`;

const BackButton = styled.div`
    position: absolute;
    top: 0;
    left: 0;
    margin: 10px;
    height: 40px;
    width: 40px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;

    svg {
        transform: translateX(-3px) rotate(90deg);
        height: 20px;
        width: 20px;
        fill: ${props => props.theme.colors.DarkBlue};
    }
    &:hover {
        background-color: rgba(0, 0, 0, 0.4);
        border-radius: 50%;
    }
`;
