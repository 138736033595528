import styled from "@emotion/styled";

interface ExpandButtonProps {
    onClick: React.MouseEventHandler<HTMLDivElement>;
    title: string;
}

const ExpandButton = ({ onClick, title }: ExpandButtonProps) => {
    return (
        <Button onClick={onClick}>
            <SelectInput>{title}</SelectInput>
            <SelectDown>
                <span />
                <div aria-hidden='true'>
                    <svg
                        height='20'
                        width='20'
                        viewBox='0 0 20 20'
                        aria-hidden='true'
                        focusable='false'>
                        <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
                    </svg>
                </div>
            </SelectDown>
        </Button>
    );
};

export default ExpandButton;

const SelectDown = styled.div`
    -moz-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;

    span {
        align-self: stretch;
        background-color: ${props => props.theme.colors.DarkBlue};
        margin-bottom: 8px;
        margin-top: 8px;
        width: 1px;
        box-sizing: border-box;
    }
    div {
        color: ${props => props.theme.colors.DarkBlue};
        display: flex;
        padding: 8px;
        transition: color 150ms ease 0s;
        box-sizing: border-box;
    }
`;
const SelectInput = styled.div`
    -moz-box-align: center;
    align-items: center;
    display: grid;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    text-align: center;
`;
const Button = styled.div`
    width: 220px;
    font-size: 14px;
    -moz-box-align: center;
    align-items: center;
    background-color: ${props => props.theme.colors.Pink};
    border: 2px solid ${props => props.theme.colors.DarkBlue};
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -moz-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: currentcolor none 0px !important;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.colors.DarkPink};
        svg {
            fill: ${props => props.theme.colors.DarkPink};
        }
        span {
            background: ${props => props.theme.colors.DarkPink};
        }
    }
`;
