import { skipToken } from "@reduxjs/toolkit/dist/query";
import RequireAuth from "components/auth/RequireAuth";
import PageNotFound from "components/general/404Stuff/PageNotFound";
import FirstTimeLogin from "components/general/firstTime/FirstTimeLogin";
import { createScript1, createScript2 } from "components/gtag";
import BudgetEditor from "components/inputs/BudgetEditor/BudgetEditor";
import XeroDisconnectedHandler from "components/popups/XeroDisconnectedHandler";
import AccountRoutes from "components/routing/AccountRoutes";
import ReportsRoutes from "components/routing/ReportsRoutes";
import SplashPage from "components/ui/SplashPage";
import useAuthState from "hooks/firebase/useAuthState";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import SignUpWithXero from "pages/Account/AppStoreSignUpXero";
import SubscribePage from "pages/Account/SubscribePage";
import Privacy from "pages/HC/Privacy";
import TermsAndConditions from "pages/HC/TermsAndConditions";
import HomePage from "pages/HomePage";
import AccountPage from "pages/LoginPage/LoginPage";
import OnBoardingRoutes from "pages/onboarding/OnBoardingRoutes";
import StripeConnected from "pages/StripeConnected";
import SupportPage from "pages/SupportPage";
import Profile from "pages/user/profile";
import { useEffect } from "react";
import { Route, Routes, useNavigate, useSearchParams } from "react-router-dom";
import "./style/main.scss";
import { useGetCustomersQuery } from "redux/rtkQuery/customers";
import {
    useGetExpensesBudgetQuery,
    useGetRevenueBudgetQuery,
} from "redux/rtkQuery/reports";
import { useGetTransfersQuery } from "redux/rtkQuery/transfers";
//
function App() {
    const navigate = useNavigate();
    const [user, loading] = useAuthState();
    const [searchParams] = useSearchParams();
    const currentOrg = useCurrentOrg(() => {
        // if there are no organisations connected, send them to the providers page to connect
        if (
            user &&
            !/^\/xero-signup/.test(window.location.pathname) &&
            !/^\/account/.test(window.location.pathname) &&
            !/^\/onboarding/.test(window.location.pathname)
        ) {
            navigate("/account/providers");
        }
    });

    useGetCustomersQuery(currentOrg?.organisationId ?? skipToken);
    useGetTransfersQuery(currentOrg?.organisationId ?? skipToken);
    useGetRevenueBudgetQuery(currentOrg?.organisationId ?? skipToken);
    useGetExpensesBudgetQuery(currentOrg?.organisationId ?? skipToken);

    useEffect(() => {
        createScript1();
        createScript2();
        const orgId = searchParams.get("orgId");
        if (orgId) {
            localStorage.setItem("org_id", orgId);
            searchParams.delete("orgId");
        }
        if (user) {
            const params = new URLSearchParams(window.location.search).get(
                "to",
            );
            if (params) {
                navigate(params);
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    if (loading) {
        return <SplashPage title='Initialising' />;
    }

    return (
        <div>
            <FirstTimeLogin />
            <Routes>
                <Route
                    path='/budget-editor/*'
                    element={
                        <RequireAuth>
                            <BudgetEditor />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/TermsAndConditions'
                    element={
                        <RequireAuth>
                            <TermsAndConditions />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/Privacy'
                    element={
                        <RequireAuth>
                            <Privacy />
                        </RequireAuth>
                    }
                />
                <Route path='/onboarding/*' element={<OnBoardingRoutes />} />
                <Route
                    path='/stripe-connected'
                    element={
                        <RequireAuth>
                            <StripeConnected />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/subscribe'
                    element={
                        <RequireAuth>
                            <SubscribePage />
                        </RequireAuth>
                    }
                />
                <Route path='/login/*' element={<AccountPage />} />
                <Route
                    path='/subscribe'
                    element={
                        <RequireAuth>
                            <SubscribePage />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/'
                    element={
                        <RequireAuth>
                            <HomePage />
                        </RequireAuth>
                    }
                />
                <Route
                    path='/reports/*'
                    element={
                        <RequireAuth>
                            <ReportsRoutes />
                        </RequireAuth>
                    }
                />
                <Route path='/account/*' element={<AccountRoutes />} />
                <Route
                    path='/profile/*'
                    element={
                        <RequireAuth>
                            <Profile />
                        </RequireAuth>
                    }
                />
                <Route path='/xero-signup/*' element={<SignUpWithXero />} />
                <Route path='support' element={<SupportPage />} />
                <Route path='*' element={<PageNotFound />} />
            </Routes>
            <XeroDisconnectedHandler />
        </div>
    );
}

export default App;
