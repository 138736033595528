import { css } from "@emotion/react";
import styled from "@emotion/styled";
import OutsideAlerter from "components/inputs/OutsideAlerter";
import { useEffect, useState } from "react";
import { useAppSelector } from "redux/store";

import PersistantNotification from "./PerstaantNotification";
import { ReactComponent as BellSVG } from "../../assets/general/bell.svg";

const NotificationsPersistant = () => {
    const [exit, setExit] = useState(false);
    const [show, setShow] = useState(false);
    const [alternate, setAlternate] = useState(false);

    const persistantNotificationsIds = useAppSelector(
        state => state.notifications.persistantNotificationsIds,
    );

    useEffect(() => {
        if (persistantNotificationsIds.length > 0) {
            setExit(false);
            setShow(true);
        } else {
            setExit(true);
            setShow(false);
        }
    }, [persistantNotificationsIds]);

    return (
        <>
            <OutsideAlerter callBack={() => setAlternate(false)}>
                <Container>
                    {show && !alternate && (
                        <BellContainer
                            exit={exit}
                            onClick={() => setAlternate(true)}>
                            <NotificationNumber>
                                {persistantNotificationsIds.length}
                            </NotificationNumber>
                            <Bell />
                        </BellContainer>
                    )}
                    {show && alternate && (
                        <NotificationsContainer>
                            <TopBar>
                                Notifications
                                <span>{persistantNotificationsIds.length}</span>
                            </TopBar>
                            {persistantNotificationsIds.map(notification => (
                                <PersistantNotification
                                    key={notification}
                                    id={notification}
                                />
                            ))}
                        </NotificationsContainer>
                    )}
                </Container>{" "}
            </OutsideAlerter>
        </>
    );
};

export default NotificationsPersistant;

const TopBar = styled.div`
    width: 100%;
    padding: 0.5rem 1rem;
    display: flex;
    justify-content: center;
    align-items: center;
    span {
        display: flex;
        justify-content: center;
        align-items: center;
        margin-left: 5px;
        background-color: #7bff00;
        padding: 0px 4px;
        border-radius: 50%;
    }
`;

const NotificationsContainer = styled.div`
    background-color: #ffffff;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    align-items: flex-end;
    justify-content: flex-end;
    border-radius: 10px 10px 0 0;
    overflow: hidden;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
`;

const Bell = styled(BellSVG)``;

const NotificationNumber = styled.div`
    position: absolute;
    top: -3px;
    right: 1px;
    font-size: 16px;
    color: #ff4747;
`;

const BellContainer = styled.div<{ exit: boolean }>`
    background-color: #fff;
    display: flex;
    position: absolute;
    top: -80px;
    right: 80px;
    width: 40px;
    height: 40px;
    padding: 7px;
    border-radius: 50%;
    cursor: pointer;

    animation: SlideLeft 0.4s;
    animation-fill-mode: forwards;

    @keyframes SlideLeft {
        0% {
            opacity: 0%;
        }

        100% {
            opacity: 100%;
        }
    }

    ${({ exit }) =>
        exit &&
        css`
            animation: SlideRight 0.4s;
            animation-fill-mode: forwards;
        `}

    @keyframes SlideRight {
        0% {
            opacity: 100%;
        }

        100% {
            opacity: 0%;
        }
    }
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    width: 100%;
`;
