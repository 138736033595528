import styled from "@emotion/styled";
import { OrganisationIdentifier } from "common/types/objects/Organisation";

const ChangeOrg = ({
    org,
    updateSelectedOrganisation,
}: {
    org: OrganisationIdentifier;
    updateSelectedOrganisation: (org: OrganisationIdentifier) => void;
}) => {
    return (
        <>
            <Item
                onClick={() => {
                    localStorage.setItem("org_id", org.organisationId);
                    updateSelectedOrganisation(org);
                    gtag("event", "changed_org");
                }}>
                {org.name}
            </Item>
        </>
    );
};

export default ChangeOrg;

const Item = styled.div`
    height: 100%;
    width: 100%;
    font-size: 20px;
    align-items: center;
    justify-content: center;
    display: flex;
`;
