import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import Switch from "components/general/Switch";
import useReport from "hooks/reports/useReport";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { generateMonthsKeysBackFromMonth } from "lib/date/dateConst";
import { toMmmmYyyy } from "lib/date/reStringifyMonth";
import { useState } from "react";
import {
    Legend,
    Line,
    LineChart,
    ReferenceLine,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { theme } from "style/theme";

const WorkingCapital = () => {
    const report = useReport();
    const [year, setYear] = useState(true);

    if (!report) return null;

    const {
        reportDates: { lastTwelveMonths, selectedMonth, monthsInToYear },
    } = report;

    const YTDMonths =
        report &&
        generateMonthsKeysBackFromMonth(selectedMonth, monthsInToYear );

    const months = year ? lastTwelveMonths : YTDMonths;

    const graphData =
        report && months
            ? months.map((month: MonthKey) => {
                  return {
                      month: month,
                      workingCapital:
                          (report.balanceSheet?.currentAssets?.[month] ?? 0) -
                          (report.balanceSheet?.currentLiabilities?.[month] ??
                              0),
                  };
              })
            : undefined;

    return (
        <ItemSection column>
            <SwitchContainer>
                {year ? "Last 12 Months" : "Year to Date"}
                <Switch
                    colorOption1={theme.colors.Yellow}
                    colorOption2={theme.colors.DarkPink}
                    width={50}
                    toggle={year}
                    cbSwitch={setYear}
                />
            </SwitchContainer>
            <h1>Here's how your working capital is tracking</h1>
            <Container>
                <Chart>
                    <ResponsiveContainer width='100%' height={350}>
                        <LineChart data={graphData} margin={{ right: 10 }}>
                            <XAxis
                                interval={0}
                                type='category'
                                dataKey='month'
                                style={{ fill: theme.colors.Pink }}
                                stroke={"none"}
                                tick={tickMonthWrap}
                            />
                            <YAxis
                                type='number'
                                style={{ fill: theme.colors.Pink }}
                                stroke={"none"}
                                tickFormatter={(value: number) =>
                                    Writer.FormatCurrency(value)
                                }
                            />

                            <ReferenceLine
                                y={0}
                                label=''
                                stroke='red'
                                strokeDasharray='3 3'
                            />
                            <Tooltip
                                labelFormatter={props => {
                                    if (props === 0 || props === "auto")
                                        return null;
                                    return toMmmmYyyy(props);
                                }}
                                labelStyle={{
                                    color: theme.colors.DarkBlue,
                                }}
                                formatter={value =>
                                    Writer.FormatCurrency(value as number)
                                }
                            />
                            <Line
                                type='monotone'
                                strokeWidth={2}
                                stroke={theme.colors.Yellow}
                                dataKey='workingCapital'
                                name='Working Capital'
                                dot={false}
                            />
                            <Legend />
                        </LineChart>
                    </ResponsiveContainer>
                </Chart>

                {/* <Bubble style={bubblePink}>
                    <h3> Your current working capital is:</h3>
                    <mark>
                        {graphData &&
                            Writer.FormatCurrency(
                                graphData[graphData.length - 1].workingCapital,
                            )}
                    </mark>
                </Bubble> */}
            </Container>
        </ItemSection>
    );
};
export default WorkingCapital;

const SwitchContainer = styled.div`
    display: flex;
    width: 170px;
    justify-content: space-between;
    align-items: center;
    font-size: 15px;
    position: absolute;
    top: 48px;
    right: 48px;
`;

const Chart = styled.div`
    flex: 1;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
`;
