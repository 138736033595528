import "firebase/compat/auth";
import styled from "@emotion/styled";
import { ReactComponent as EmailSvg } from "assets/general/email.svg";
import { ReactComponent as ErrorSvg } from "assets/general/error.svg";
import { ReactComponent as Password } from "assets/general/password.svg";
import qbLogin from "assets/img/qb/Sign_in_blue_btn_tall_default2x.png";
import qbLoginHover from "assets/img/qb/Sign_in_blue_btn_tall_hover2x.png";
import xeroLogin from "assets/img/xero/sign-in-dark.png";
import { signInWithEmailAndPassword, UserCredential } from "firebase/auth";
import { auth } from "lib/firebase";
import { useEffect, useRef, useState } from "react";
import { NavigateFunction, useNavigate } from "react-router";

import {
    handleRedirect,
    signInWithGoogle,
    signInWithXero,
} from "./helpers/functions";
import { handleSignIn as defaultSignIn } from "./helpers/handleSignIn";

export const EmailRegex = new RegExp(
    /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|.(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/,
);

function Login({
    handleSignIn = defaultSignIn,
}: {
    handleSignIn?: (result: UserCredential, navigate: NavigateFunction) => void;
}) {
    const navigate = useNavigate();

    useEffect(() => {
        document.title = "Login";
        handleRedirect(navigate);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const formRef = useRef<HTMLFormElement>(null);
    const [emailError, setEmailError] = useState<string | undefined>(undefined);
    const [passwordError, setPasswordError] = useState<string | undefined>(
        undefined,
    );
    const [error, setError] = useState<string | undefined>(undefined);

    return (
        <Container>
            {error && (
                <CustomError>
                    <ErrorSvg />
                    {error}
                </CustomError>
            )}
            <ExternalLogin>
                <h2>Please log in:</h2>
                {/* <h3>Quickbooks users:</h3>
                    <div>
                        <QuickbooksButton onClick={() => signInWithIntuit(navigate)} />
                    </div> */}
                <h3>Xero users:</h3>
                <div>
                    <XeroButton
                        src={xeroLogin}
                        onClick={() => signInWithXero(navigate)}
                    />
                </div>
            </ExternalLogin>

            {/* <SignUP>
                    <span>Need an account? </span>

                    <NavLink to='/login/create'>SIGN UP</NavLink>
                </SignUP> */}

            <p>
                <i>
                    You can sign in or sign up with your accounting provider. If
                    you have already signed up with your accounting provider,
                    please login above. If you prefer to use a local account you
                    can sign in below.
                </i>
            </p>
            <p>
                Log in with Google:{" "}
                <GoogleButton onClick={() => signInWithGoogle(navigate)} />
            </p>
            <p>
                <strong>Log in with username/password:</strong>
            </p>
            <LoginContainer
                ref={formRef}
                onSubmit={(e: any) => {
                    e.preventDefault();
                    const email = e.target[0].value as string;
                    const password = e.target[1].value as string;

                    if (email.toLowerCase().match(EmailRegex)) {
                        signInWithEmailAndPassword(auth, email, password)
                            .then(res => {
                                handleSignIn(res, navigate);
                            })
                            .catch(err => {
                                switch (err.code) {
                                    case "auth/invalid-email":
                                        setEmailError("Invalid email address");
                                        break;
                                    case "auth/user-disabled":
                                        setError(
                                            "The user account has been disabled by an administrator.",
                                        );
                                        break;
                                    case "auth/wrong-password":
                                        setPasswordError(
                                            "The password is invalid",
                                        );
                                        break;

                                    case "auth/user-not-found":
                                        setError(
                                            "There is no user record corresponding to this identifier. The user may not have been created yet.",
                                        );
                                        break;
                                    default:
                                        console.log("err", err.code);
                                }
                            });
                    } else {
                        setEmailError("Invalid email address");
                    }
                }}>
                <InputContainer>
                    <span>Email</span>
                    <InputDiv>
                        <EmailSVG />
                        <input
                            type='text'
                            name='email'
                            placeholder='Type your email'
                        />
                        {emailError && (
                            <InputError>
                                <ErrorSvg />
                                <div>{emailError}</div>
                            </InputError>
                        )}
                    </InputDiv>
                </InputContainer>

                <InputContainer>
                    <span>Password</span>
                    <InputDiv>
                        <PasswordSvg />
                        <input
                            type='password'
                            name='pass'
                            placeholder='Type your password'
                        />
                        {passwordError && (
                            <InputError>
                                <ErrorSvg />
                                <div>{passwordError}</div>
                            </InputError>
                        )}
                    </InputDiv>
                </InputContainer>

                <ForgotPassword
                    onClick={() =>
                        navigate("forgot-password", {
                            state: {
                                emailAddress: (
                                    formRef.current?.elements[0] as any
                                ).value,
                            },
                        })
                    }>
                    Forgot password?
                </ForgotPassword>

                <LoginButton type='submit'>
                    <div />
                    <p>Login</p>
                </LoginButton>
            </LoginContainer>
        </Container>
    );
}

export default Login;

const InputError = styled.div`
    position: absolute;
    right: 0;

    svg {
        height: 20px;
        width: 20px;
        margin-right: 5px;
        fill: #f02929;
        cursor: pointer;
    }

    div {
        display: none;
        font-size: 12px;
        font-weight: 700;
        position: absolute;
        z-index: 1;
        top: 50%;
        transform: translateY(-50%);
        left: calc(5px + 100%);
        background: rgb(248, 208, 200);
        padding: 8px;
        border-radius: 5px;
        &:after {
            content: "";
            position: absolute;
            top: 50%;
            right: 100%;
            margin-top: -5px;
            border-width: 7px;
            border-style: solid;
            border-color: transparent rgb(248, 208, 200) transparent transparent;
        }
    }

    &:hover {
        div {
            display: block;
        }
    }
`;

const EmailSVG = styled(EmailSvg)`
    position: absolute;
    height: calc(100% - 15px);
    width: 30px;
    bottom: 10px;
    left: 0;
    margin-top: calc(100%);
    z-index: 1;
`;
const PasswordSvg = styled(Password)`
    position: absolute;
    height: calc(100% - 15px);
    width: 30px;
    bottom: 10px;
    left: 0;
    margin-top: calc(100%);
    z-index: 1;
`;

const XeroButton = styled.img`
    width: 250px;
    cursor: pointer;
`;

const QuickbooksButton = styled.div`
    width: 201px;
    height: 48px;
    cursor: pointer;
    margin: 0 0 10px 0;

    background-image: url(${qbLogin});
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 100%;

    &:hover {
        background-image: url(${qbLoginHover});
    }
`;

const SignUP = styled.div`
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 15px;
    flex-direction: row;

    a {
        color: ${props => props.theme.colors.DarkPink};
        font-size: 16px;
        font-weight: 700;
        text-decoration: none;
        margin-left: 5px;
        &:hover {
            text-decoration: underline;
        }
    }
`;

const CustomError = styled.div`
    border-radius: 5px;
    padding: 10px;
    background: rgb(248, 208, 200);
    display: flex;
    align-items: center;
    margin-top: 15px;

    svg {
        height: 70px;
        width: 70px;
        margin-right: 10px;
    }
`;

const ForgotPassword = styled.div`
    height: 30px;
    margin: auto 0;

    color: #333;
    line-height: 1.2;
    display: block;
    width: 100%;
    background: 0 0;
    text-align: right;
    font-size: 16px;
    cursor: pointer;

    &:hover {
        text-decoration: underline;
    }
`;

const LoginButton = styled.button`
    all: unset;
    position: relative;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    width: 50%;
    margin: 10px auto;
    padding: 10px 0;
    border-radius: 25px;
    overflow: hidden;
    cursor: pointer;

    div {
        transition: 0.25s;
        position: absolute;
        width: 300%;
        height: 100%;
        background: linear-gradient(
            90deg,
            ${props => props.theme.colors.PinkMed},
            ${props => props.theme.colors.DarkPink},
            ${props => props.theme.colors.PinkMed},
            ${props => props.theme.colors.DarkPink}
        );
    }

    p {
        z-index: 1;
        margin: 0;
    }

    &:hover {
        div {
            transform: translateX(-30%);
        }
    }
`;

const InputDiv = styled.div`
    display: flex;
    align-items: center;
    padding: 5px 0;
    border-bottom: 2px solid #ccc;
    position: relative;

    input {
        padding-left: 10px;
        font-size: 16px;
        color: #333;
        line-height: 1.2;
        display: block;
        width: 100%;
        height: 30px;
        background: 0 0;
        outline: none;
        border: none;
        padding: 10px 30px;
        position: relative;
        background-color: transparent;
    }
`;
const InputContainer = styled.div`
    span {
        font-size: 16px;
        color: #333;
        line-height: 1.2;
        display: block;
        width: 100%;
        background: 0 0;
    }
`;

const ExternalLogin = styled.div`
    font-size: 16px;
    color: #333;
    line-height: 1.2;
    margin: 10px auto;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
        display: flex;
        margin-top: 10px;

        > div {
            margin: 5px;
        }
    }

    h1,
    h2 {
        margin: 0;
    }

    h3 {
        margin: 10px 0 0 0;
    }
`;

const LoginContainer = styled.form`
    display: flex;
    flex-direction: column;
    margin: 10px 0;
    width: 100%;
    font-size: 14px;
`;

const LoginHeader = styled.h1`
    margin: 0;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    border-radius: 10px;
    padding: 40px 40px;
    width: 100%;
    max-width: 400px;
    max-height: 100%;
    overflow-y: auto;
`;

const GoogleButton = styled.div`
    height: 50px;
    width: 50px;
    border-radius: 50%;
    display: inline-flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    vertical-align: middle;

    &:hover {
        border: 2px solid ${props => props.theme.colors.DarkBlue};
    }
    background-color: ${props => props.theme.colors.Pink};

    background-image: url("https://www.gstatic.com/firebasejs/ui/2.0.0/images/auth/google.svg");
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 30px 30px;
    border: 2px solid ${props => props.theme.colors.Pink};
    color: #fff;
    font-size: 35px;
    font-weight: 700;
`;
