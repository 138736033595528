import useAuthState from "hooks/firebase/useAuthState";
import { auth } from "lib/firebase";
import { useEffect, useState } from "react";

const useIsAdmin = () => {
    const [isAuthorised, setIsAuthorised] = useState(false);
    const [user] = useAuthState();

    useEffect(() => {
        auth.currentUser
            ?.getIdTokenResult()
            .then(t => setIsAuthorised(!!t.claims.admin));
    }, [user]);

    return isAuthorised;
};

export default useIsAdmin;
