import {
    DATE_BUDGET_END_MONTH,
    DATE_END_MONTH,
    DATE_START_MONTH,
} from "common/constants/MONTH";
import {
    BUDGET_TAG,
    ITEM_TAG,
    ORGANIZATION_TAG,
    REPORT_TAG,
} from "common/constants/TAGS";
import MonthKey from "common/types/monthKey";
import { setAllBudget } from "redux/slices/BudgetSlice";
import {
    BalanceSheet,
    BankAccount,
    BankBalances,
    Item,
    ItemObject,
    MonthlySalesTaxCashSummary,
    OrgData,
} from "types/OrganisationType";

import { api } from "./api";

const reportSlice = api.injectEndpoints({
    endpoints: builder => ({
        getExpenses: builder.query<OrgData, string>({
            query: organisationId => ({
                url: "organisations/monthlyByType",
                params: {
                    organisationId,
                    startMonth: DATE_START_MONTH,
                    endMonth: DATE_END_MONTH,
                    itemType: 3, //expenses
                    dataType: 1, //p&l
                },
            }),
            transformResponse: (response: any) => {
                return response.data as OrgData;
            },
            providesTags: (_result, _error, organisationId) => [
                { type: ORGANIZATION_TAG, id: organisationId },
                REPORT_TAG,
            ],
        }),
        getRevenue: builder.query<OrgData, string>({
            query: organisationId => ({
                url: "organisations/monthlyByType",
                params: {
                    organisationId,
                    startMonth: DATE_START_MONTH,
                    endMonth: DATE_END_MONTH,
                    itemType: 5, //revenue
                    dataType: 1, //p&l
                },
            }),
            transformResponse: (response: any) => {
                return response.data as OrgData;
            },
            providesTags: (_result, _error, organisationId) => [
                { type: ORGANIZATION_TAG, id: organisationId },
                REPORT_TAG,
            ],
        }),
        getCashflow: builder.query<OrgData, string>({
            query: organisationId => ({
                url: "organisations/monthlyByType",
                params: {
                    organisationId,
                    startMonth: DATE_START_MONTH,
                    endMonth: DATE_END_MONTH,
                    itemType: [1, 2, 3, 4, 5], //all types
                    dataType: 3, //Cashflow
                },
            }),
            transformResponse: (response: any) => {
                return response.data as OrgData;
            },
            providesTags: (_result, _error, organisationId) => [
                { type: ORGANIZATION_TAG, id: organisationId },
                REPORT_TAG,
            ],
        }),
        getCashTaxSummary: builder.query<Array<MonthlySalesTaxCashSummary & { monthKey: MonthKey }>, string>({
            query: organisationId => ({
                url: "organisations/cash-tax-summary",
                params: {
                    organisationId,
                    startMonth: DATE_START_MONTH,
                    endMonth: DATE_END_MONTH,
                },
            }),
            providesTags: (_result, _error, organisationId) => [
                { type: ORGANIZATION_TAG, id: organisationId },
                REPORT_TAG,
            ],
        }),
        getExpensesBudget: builder.query<OrgData, string>({
            query: organisationId => ({
                url: "organisations/monthlyByType",
                params: {
                    organisationId,
                    startMonth: DATE_START_MONTH,
                    endMonth: DATE_BUDGET_END_MONTH,
                    itemType: 3, //expenses
                    dataType: 2, //budget
                },
            }),
            transformResponse: (response: any) => {
                return response.data as OrgData;
            },
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                dispatch(
                    setAllBudget({
                        stateItem: "expenses",
                        data: (await queryFulfilled).data,
                    }),
                );
            },
            providesTags: (_result, _error, organisationId) => [
                { type: ORGANIZATION_TAG, id: organisationId },
                BUDGET_TAG,
            ],
        }),
        getRevenueBudget: builder.query<OrgData, string>({
            query: organisationId => ({
                url: "organisations/monthlyByType",
                params: {
                    organisationId,
                    startMonth: DATE_START_MONTH,
                    endMonth: DATE_BUDGET_END_MONTH,
                    itemType: 5, //revenue
                    dataType: 2, //budget
                },
            }),
            transformResponse: (response: any) => {
                return response.data as OrgData;
            },
            onQueryStarted: async (_, { dispatch, queryFulfilled }) => {
                dispatch(
                    setAllBudget({
                        stateItem: "revenue",
                        data: (await queryFulfilled).data,
                    }),
                );
            },
            providesTags: (_result, _error, organisationId) => [
                { type: ORGANIZATION_TAG, id: organisationId },
                BUDGET_TAG,
            ],
        }),
        getBalanceSheet: builder.query<BalanceSheet, string>({
            query: organisationId => ({
                url: "organisations/balanceSheetItems",
                params: {
                    organisationId,
                    startMonth: DATE_START_MONTH,
                    endMonth: DATE_END_MONTH,
                },
            }),
            providesTags: (_result, _error, organisationId) => [
                { type: ORGANIZATION_TAG, id: organisationId },
                REPORT_TAG,
            ],
        }),
        getItems: builder.query<ItemObject, string>({
            query: organisationId => ({
                url: "Items",
                params: {
                    organisationId,
                },
            }),
            providesTags: (_result, _error, organisationId) => [
                { type: ORGANIZATION_TAG, id: organisationId },
                ITEM_TAG,
            ],
        }),
        getBankAccounts: builder.query<BankAccount[], string>({
            query: organisationId => ({
                url: "organisation/getBankAccounts",
                params: {
                    organisationId,
                },
            }),
            providesTags: (_result, _error, organisationId) => [
                { type: ORGANIZATION_TAG, id: organisationId },
                ITEM_TAG,
            ],
        }),
        getTax: builder.query<{ salesTax: number }, string>({
            query: organisationId => ({
                url: "tax",
                params: {
                    organisationId,
                },
            }),
            providesTags: (_result, _error, organisationId) => [
                { type: ORGANIZATION_TAG, id: organisationId },
            ],
        }),
        getBankBalances: builder.query<BankBalances, string>({
            query: organisationId => ({
                url: "organisation/getBankBalances",
                params: {
                    organisationId,
                    startMonth: DATE_START_MONTH,
                    endMonth: DATE_END_MONTH,
                },
            }),
            providesTags: (_result, _error, organisationId) => [
                { type: ORGANIZATION_TAG, id: organisationId },
            ],
        }),
        updateBudget: builder.mutation<
            void,
            {
                revenue: OrgData;
                expenses: OrgData;
                organisationId: string;
            }
        >({
            query: ({ revenue, expenses, organisationId }) => ({
                url: "budget",
                method: "PUT",
                data: {
                    revenue,
                    expenses,
                },
                params: {
                    organisationId,
                },
            }),
        }),
        setItemType: builder.mutation<
            void,
            {
                code: string;
                Item: Item;
                organisationId: string;
            }
        >({
            query: ({ code, Item, organisationId }) => ({
                url: "Items/SetClasification",
                method: "PUT",
                params: {
                    organisationId,
                },
                data: {
                    code,
                    itemClasification: Item.itemClasification,
                    organisationId,
                },
            }),
            onQueryStarted: async (
                { code, Item, organisationId },
                { dispatch, queryFulfilled },
            ) => {
                const patchResult = dispatch(
                    reportSlice.util.updateQueryData(
                        "getItems",
                        organisationId,
                        draft => {
                            draft[code] = Item;
                        },
                    ),
                );
                try {
                    await queryFulfilled;
                } catch (error) {
                    patchResult.undo();
                }
            },
        }),
    }),
});

export default reportSlice;

export const {
    useGetExpensesQuery,
    useGetRevenueQuery,
    useGetCashflowQuery,
    useGetCashTaxSummaryQuery,
    useGetBalanceSheetQuery,
    useGetItemsQuery,
    useGetTaxQuery,
    useGetBankBalancesQuery,
    useGetExpensesBudgetQuery,
    useGetRevenueBudgetQuery,
    useUpdateBudgetMutation,
    useSetItemTypeMutation,
    useGetBankAccountsQuery,
} = reportSlice;
