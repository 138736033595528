import { css } from "@emotion/react";
import { EmotionJSX } from "@emotion/react/types/jsx-namespace";
import styled from "@emotion/styled";
import ItemSection from "components/general/itemSection/ItemSection";
import ExpandButton from "components/ui/ExpandButton";
import useReport from "hooks/reports/useReport";
import { toMmmYy, toMmmYyyy } from "lib/date/reStringifyMonth";
import { useEffect, useState } from "react";
import { graphOption, setGraph } from "redux/slices/CustomersSlice";
import { useAppDispatch, useAppSelector } from "redux/store";

import EditCustomerInput from "./EditCustomerInput";
import AverageRev from "./Graphs/AverageRev";
import ChurnRate from "./Graphs/ChurnRate";
import CostPerCustomer from "./Graphs/CostPerCustomer";
import CustomerGrowth from "./Graphs/CustomerGrowth";
import CustomersGainednLost from "./Graphs/CustomersGainednLost";
import RetentionRate from "./Graphs/RetentionRate";
import TotalCustomers from "./Graphs/TotalCustomers";
import Bubble, { bubbleDark } from "../Bubble";

const dateOption = ["Current Month", "Last 12 Months", "YTD", "Calendar Year"];

interface ShowData {
    target: number;
    actual: number;
    cancellations: number;
    month: string;
}
const CustomersOverview = () => {
    const [date, setDate] = useState(dateOption[0]);
    const dispatch = useAppDispatch();
    const report = useReport();
    // const customerInputs = useCustomerInputs(
    //     revenueData?.report.organisation.organisationId,
    // );
    const {
        customers: customerInputs,
        graph,
        customerName,
    } = useAppSelector(state => state.customers.present);
    const [editorOpen, setEditorOpen] = useState(false);

    useEffect(() => {
        const element = document.querySelector(
            `[data-type="customers"][data-index="0"]`,
        );
        if (!element) return;
        dispatch(
            setGraph({
                index: 0,
                parent_size: element.clientHeight,
                transform: 0,
            }),
        );
    }, [
        dispatch,
        // eslint-disable-next-line react-hooks/exhaustive-deps
        document.querySelector(`[data-type="customers"][data-index="0"]`),
    ]);

    if (!report || !customerInputs) {
        return null;
    }

    const {
        reportDates: { selectedMonth },
    } = report;

    const showData = {
        target: customerInputs[selectedMonth]?.target,
        actual: customerInputs[selectedMonth]?.actual,
        cancellations: customerInputs[selectedMonth]?.cancellations,
        month: toMmmYy(selectedMonth),
    };

    const graphs: EmotionJSX.Element[] = [
        <TotalCustomers
            customerInputs={customerInputs}
            customerName={customerName}
        />,
        <CustomersGainednLost
            customerInputs={customerInputs}
            customerName={customerName}
        />,
        <AverageRev
            customerInputs={customerInputs}
            customerName={customerName}
        />,
        <CostPerCustomer />,
        <ChurnRate
            customerInputs={customerInputs}
            customerName={customerName}
        />,
        <RetentionRate
            customerInputs={customerInputs}
            customerName={customerName}
        />,
        // <CostToAcuirenR
        //     customerInputs={customerInputs}
        //     customerName={customerName}
        // />,
        <CustomerGrowth
            customerInputs={customerInputs}
            customerName={customerName}
        />,
    ];

    const handleChange = (index: number) => {
        const element = document.querySelector(
            `[data-type="customers"][data-index="${index}"]`,
        ) as Element;
        const parent_size = element.clientHeight;

        const transform = graphs.reduce((output, graph, index2) => {
            if (index2 < index) {
                const element = document.querySelector(
                    `[data-type="customers"][data-index="${index2}"]`,
                ) as Element;
                output += element.clientHeight;
            }
            return output;
        }, 0);
        dispatch(setGraph({ index, transform, parent_size }));
    };

    return (
        <>
            <ItemSection padding='0px 48px' column id='customer-overview' light>
                <Title>Lets take a look at your Customers</Title>
                <h1>{toMmmYyyy(selectedMonth)} customers</h1>

                <ExpandButton
                    title='Enter customer info'
                    onClick={() => setEditorOpen(!editorOpen)}
                />

                {editorOpen ? (
                    <EditCustomerInput
                        organisationId={report.organisation.organisationId}
                    />
                ) : (
                    <OverView>
                        <Bubble style={bubbleDark}>
                            <mark>{showData?.target}</mark>
                            <h3>
                                {date !== dateOption[0]
                                    ? "Average target"
                                    : "Target"}{" "}
                                number of {customerName} in {showData?.month}
                            </h3>
                        </Bubble>
                        <Bubble style={bubbleDark}>
                            <mark>{showData?.actual}</mark>
                            <h3>
                                {date !== dateOption[0]
                                    ? "Average actual"
                                    : "Actual"}{" "}
                                number of {customerName} in {showData?.month}
                            </h3>
                        </Bubble>
                        <Bubble style={bubbleDark}>
                            <mark>{showData?.cancellations}</mark>
                            <h3>
                                {date !== dateOption[0]
                                    ? "Average customer"
                                    : "Customer"}{" "}
                                cancellations in {showData?.month}
                            </h3>
                        </Bubble>
                        {/* temparory removed till new design */}
                        {/* <DateSelect>
                            <DropSelector
                                options={dateOption}
                                selected={date}
                                onChange={setDate}
                            />
                        </DateSelect> */}
                    </OverView>
                )}
            </ItemSection>

            <ItemSection id='month-budget' light minHeight={355}>
                <GraphSelector>
                    <>
                        <span>Select a graph to view</span>
                        {/* <DropSelector
                        options={graphOption}
                        selected={graph}
                        onChange={value => dispatch(setGraph(value))}
                    /> */}
                        {graphOption.map((value, index) => (
                            <SelecteGraph
                                onClick={() =>
                                    graph.index !== index && handleChange(index)
                                }
                                key={index}
                                isSlected={
                                    graph.index === index ? "true" : undefined
                                }>
                                {value}
                            </SelecteGraph>
                        ))}
                    </>
                </GraphSelector>
                <Graphs height={graph.parent_size}>
                    {graphs.map((item, index) => {
                        return (
                            <Graph
                                data-type='customers'
                                data-index={index}
                                key={index}
                                transform={graph.transform}>
                                {item}
                            </Graph>
                        );
                    })}
                </Graphs>
            </ItemSection>
        </>
    );
};

export default CustomersOverview;

const Graph = styled.div<{ transform: number }>`
    transition: all 0.4s linear;
    transform: translateY(-${props => props.transform}px);
`;

const Graphs = styled.div<{ height: number }>`
    margin-left: 15px;
    position: relative;
    overflow: hidden;
    height: ${props => props.height}px;
    transition: all 0.4s linear;
    width: 100%;
    padding: 5px;
    h1 {
        margin-top: 0;
        margin-left: 15px;
    }
`;

const SelecteGraph = styled.div<{ isSlected?: string }>`
    margin-top: -2px;
    cursor: pointer;
    padding: 5px;
    font-size: 14px;
    border: 2px solid ${props => props.theme.colors.DarkBlue};
    &:hover {
        color: ${props => props.theme.colors.DarkPink};
        transform: translateX(5px);
    }

    ${props =>
        props.isSlected &&
        css`
            color: #929292 !important;
            transform: translateX(10px) !important;
        `}
`;

const GraphSelector = styled.div`
    display: flex;
    flex-direction: column;
    width: 250px;
    > span {
        font-size: 18px;
        font-weight: bold;
        padding: 6px;
        margin-bottom: 5px;
    }
    z-index: 3;
`;

const Title = styled.h1`
    margin-top: 10px;
    margin-bottom: -15px;
    font-size: 18px;
`;
const OverView = styled.div`
    position: relative;
    margin: 25px;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    align-items: center;
`;
const OverViewItem = styled.div`
    text-align: center;
    display: flex;
    flex-direction: column;
    background-color: white;
    padding: 20px;
    width: 200px;
    height: 150px;
    span {
        font-size: 30px;
        font-weight: bold;
    }
`;
