import { Cell, Pie, PieChart, Sector } from "recharts";
import { theme } from "style/theme";

const SpeedoGraph = ({
    chartMax,
    chartValue,
    reverse,
}: {
    chartValue: number;
    chartMax: number;
    reverse?: boolean;
}) => {
    const width = 250;
    const data = [
        {
            value: 50,
            color: theme.colors.DarkerPink,
        },
        {
            value: 50,
            color: theme.colors.Yellow,
        },
        {
            value: 50,
            color: theme.colors.DarkAqua,
        },
        {
            value: 50,
            color: "#4caf50",
        },
    ];
    const colorData = reverse ? data.reverse() : data;

    const activeSectorIndex = colorData
        .map((cur, index, arr) => {
            const curMax = [...arr].splice(0, index + 1).reduce((a, b) => ({
                value: a.value + b.value,
                color: "",
            })).value;
            return chartValue > curMax - cur.value && chartValue <= curMax;
        })
        .findIndex(cur => cur);

    const sumValues = colorData.map(cur => cur.value).reduce((a, b) => a + b);

    const arrowData = [
        { value: chartValue < chartMax ? chartValue : chartMax },
        { value: 0 },
        { value: sumValues - (chartValue < chartMax ? chartValue : chartMax) },
    ];

    const pieProps = {
        startAngle: 180,
        endAngle: 0,
        cx: width / 2,
        cy: width / 2,
    };

    const pieRadius = {
        innerRadius: width * 0.35,
        outerRadius: width * 0.4,
    };

    const Arrow = ({ cx, cy, midAngle, outerRadius }: any) => {
        //eslint-disable-line react/no-multi-comp
        const RADIAN = Math.PI / 180;
        const sin = Math.sin(-RADIAN * midAngle);
        const cos = Math.cos(-RADIAN * midAngle);
        const mx = cx + (outerRadius + width * 0.03) * cos;
        const my = cy + (outerRadius + width * 0.03) * sin;
        return (
            <g>
                <circle
                    cx={cx}
                    cy={cy}
                    r={width * 0.07}
                    fill={theme.colors.DarkBlue}
                    stroke='none'
                />
                <path
                    d={`M${cx},${cy}L${mx},${my}`}
                    strokeWidth='6'
                    stroke={theme.colors.DarkBlue}
                    fill='none'
                    strokeLinecap='round'
                />
            </g>
        );
    };

    const ActiveSectorMark = ({
        cx,
        cy,
        innerRadius,
        outerRadius,
        startAngle,
        endAngle,
        fill,
    }: any) => {
        //eslint-disable-line react/no-multi-comp
        return (
            <g>
                <Sector
                    cx={cx}
                    cy={cy}
                    innerRadius={innerRadius}
                    outerRadius={outerRadius * 1.2}
                    startAngle={startAngle}
                    endAngle={endAngle}
                    fill={fill}
                />
            </g>
        );
    };

    return (
        <PieChart width={width} height={width / 2 + 30}>
            <Pie
                dataKey='value'
                activeIndex={activeSectorIndex}
                activeShape={ActiveSectorMark}
                data={colorData}
                fill={theme.colors.Pink}
                {...pieRadius}
                {...pieProps}>
                {colorData.map((entry, index) => (
                    <Cell key={`cell-${index}`} fill={colorData[index].color} />
                ))}
            </Pie>
            <Pie
                dataKey='value'
                stroke='none'
                activeIndex={1}
                activeShape={Arrow}
                data={arrowData}
                outerRadius={pieRadius.innerRadius}
                fill='none'
                {...pieProps}
            />
            <text
                x={width / 2 + 5}
                y={width / 2 + 5}
                textAnchor='middle'
                fontSize={13}
                fill={theme.colors.Pink}
                dominantBaseline='middle'
                className='progress-label'>
                {chartValue}%
            </text>
        </PieChart>
    );
};

export default SpeedoGraph;
