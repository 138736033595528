import MonthKey from "common/types/monthKey";
import assert from "lib/assert";

import {
    addMonthsTo,
    getLastCompleteQuarterFromReportingMonth,
} from "./addMonthsTo";
import { generateMonthsKeysForwardFromMonth } from "./dateConst";
import { getValues, monthsElapsed } from "./reStringifyMonth";

//Get the current financial year start
export const getFinancialYear = (
    selectedMonth: MonthKey,
    financialYearStartsMonth: number,
) => {
    const monthParts = getValues(selectedMonth);
    const financialYearStart = (
        monthParts.monthValue === financialYearStartsMonth
            ? selectedMonth
            : `${
                  monthParts.monthValue > financialYearStartsMonth
                      ? monthParts.yearValue
                      : monthParts.yearValue - 1
              }-${financialYearStartsMonth.toLocaleString("en-US", {
                  minimumIntegerDigits: 2,
                  useGrouping: false,
              })}`
    ) as MonthKey;
    const monthsInToYear = monthsElapsed(selectedMonth, financialYearStart) + 1; //inclusive of the current month
    const financialYearToDate = generateMonthsKeysForwardFromMonth(
        financialYearStart,
        monthsInToYear,
        true,
    );

    assert(
        monthChecker.test(financialYearStart),
        "financialYearStart not a valid month",
    );

    return {
        financialYearStart,
        monthsInToYear,
        financialYearToDate,
    };
};

//Get the last complete quarter based on the start month
export const getLastCompleteQuarter = (
    financialYearStarts: number,
    reportingMonth: MonthKey,
) => {
    //If the year starts in April, then the quarter ends are June, September, December, and March
    //USe financialYearStarts to get the last complete quarter
    const lastFinancialQuarter = getLastCompleteQuarterFromReportingMonth(
        reportingMonth,
        financialYearStarts,
    );
    const lastEightQuarters: MonthKey[] = [
        addMonthsTo(lastFinancialQuarter, -21),
        addMonthsTo(lastFinancialQuarter, -18),
        addMonthsTo(lastFinancialQuarter, -15),
        addMonthsTo(lastFinancialQuarter, -12),
        addMonthsTo(lastFinancialQuarter, -9),
        addMonthsTo(lastFinancialQuarter, -6),
        addMonthsTo(lastFinancialQuarter, -3),
        lastFinancialQuarter,
    ];
    const previousFinancialQuarter = lastEightQuarters[6];
    const yearAgoFinancialQuarter = lastEightQuarters[3];
    return {
        lastEightQuarters,
        lastFinancialQuarter,
        previousFinancialQuarter,
        yearAgoFinancialQuarter,

        lastFinancialQuarterMonths: [
            addMonthsTo(lastFinancialQuarter, -2),
            addMonthsTo(lastFinancialQuarter, -1),
            lastFinancialQuarter,
        ],
        previousFinancialQuarterMonths: [
            addMonthsTo(previousFinancialQuarter, -2),
            addMonthsTo(previousFinancialQuarter, -1),
            previousFinancialQuarter,
        ],
        yearAgoFinancialQuarterMonths: [
            addMonthsTo(yearAgoFinancialQuarter, -2),
            addMonthsTo(yearAgoFinancialQuarter, -1),
            yearAgoFinancialQuarter,
        ],
    };
};

export const getMonthInfo = (m: MonthKey) => ({
    selectedMonth: m,
    lastMonth: addMonthsTo(m, -1),
    sameMonthLastYear: addMonthsTo(m, -12),
    lastTwelveMonths: Array.from(Array(12).keys()).map(i =>
        addMonthsTo(m, -11 + i),
    ),
    nextTwelveMonths: Array.from(Array(12).keys()).map(i =>
        addMonthsTo(m, 1 + i),
    ),
});

// const demoState: ReportOptionsState = {
//     status: "demo",

//     organisations: [demoCompany],
//     reportModel: {
//         currentOrganisation: demoCompany,
//         reportDates: {
//             showQuarterly: false,
//             ...getLastCompleteQuarter(
//                 demoCompany.reportingYearBeginsMonth,
//                 defaultSelectedMonth,
//             ),
//             ...getMonthInfo(defaultSelectedMonth),
//             ...getFinancialYear(defaultSelectedMonth, 4),
//         },
//     },
// };

// function getStartOfCurrentFinancialYear(numericYearStart: number) {
//     const currentYearFinancialStart = `${getYear(
//         reportDates.selectedMonth,
//     )}-${toTwoDigitMonth(numericYearStart)}` as MonthKey;

//     const financialYearStart =
//         currentYearFinancialStart > reportDates.selectedMonth //Weve set a month in the future (e.g. it's Jan and we start in April)
//             ? addMonthsTo(currentYearFinancialStart, -12) //Previous year
//             : currentYearFinancialStart; //We are already into the year

//     const monthsIntoYear = monthsElapsed(reportDates.selectedMonth, financialYearStart) + 1;
//     return {
//         financialYearStart,
//         monthsIntoYear, //inclusive
//         financialYearToDate: Array.from(Array(monthsIntoYear).keys()).map(i =>
//             addMonthsTo(reportDates.selectedMonth, -(monthsIntoYear - 1) + i),
//         ),
//     };
// }

export const monthChecker = new RegExp(/20\d\d-[0,1]\d/);
