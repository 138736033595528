import styled from "@emotion/styled";
import OutsideAlerter from "components/inputs/OutsideAlerter";
import { ReactNode, useRef } from "react";
import { theme } from "style/theme";

type Input = string | number;

interface DropSelectorProps<T> {
    options: T[];
    selected: T;
    onChange: (value: T) => void;
}

const DropSelector = <T extends ReactNode>({
    options,
    selected,
    onChange,
}: DropSelectorProps<T>) => {
    const menu = useRef<HTMLDivElement>(null);

    return (
        <>
            <OutsideAlerter
                callBack={() =>
                    menu.current && (menu.current.style.display = "none")
                }>
                <Select>
                    <SelectTitle
                        onClick={() =>
                            menu.current &&
                            (menu.current.style.display =
                                menu.current.style.display === "none" ||
                                menu.current.style.display === ""
                                    ? "flex"
                                    : "none")
                        }>
                        <SelectInput>{selected}</SelectInput>
                        <SelectDown>
                            <span></span>
                            <div aria-hidden='true'>
                                <svg
                                    height='20'
                                    width='20'
                                    viewBox='0 0 20 20'
                                    aria-hidden='true'
                                    focusable='false'>
                                    <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
                                </svg>
                            </div>
                        </SelectDown>
                    </SelectTitle>
                    <SelectOptions ref={menu}>
                        {options.map(l => (
                            <button
                                key={l?.toString()}
                                style={{
                                    color:
                                        selected === l
                                            ? "grey"
                                            : theme.colors.DarkPink,
                                }}
                                onClick={() => onChange(l)}
                                disabled={selected === l}>
                                {l}
                            </button>
                        ))}
                    </SelectOptions>
                </Select>
            </OutsideAlerter>
        </>
    );
};
export default DropSelector;

const Select = styled.div`
    position: relative;
    box-sizing: border-box;
`;
const SelectOptions = styled.div`
    z-index: 1;
    position: absolute;
    top: 38px;
    width: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    border: 2px solid ${props => props.theme.colors.DarkBlue};
    background-color: white;

    animation: fadeInAnimation ease-in-out 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    button {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 100%;
        height: 40px;
        border: none;
        border-bottom: 1.3px solid grey;
        background: white;
        color: ${props => props.theme.colors.DarkPink};
        &:hover {
            background: rgba(0, 0, 0, 0.185);
            color: white;
        }
    }
`;

const SelectDown = styled.div`
    -moz-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;

    span {
        align-self: stretch;
        background-color: ${props => props.theme.colors.DarkBlue};
        margin-bottom: 8px;
        margin-top: 8px;
        width: 1px;
        box-sizing: border-box;
    }
    div {
        color: ${props => props.theme.colors.DarkBlue};
        display: flex;
        padding: 8px;
        transition: color 150ms ease 0s;
        box-sizing: border-box;
    }
`;
const SelectInput = styled.div`
    -moz-box-align: center;
    align-items: center;
    display: grid;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    text-align: center;
`;
const SelectTitle = styled.div`
    font-size: 14px;
    -moz-box-align: center;
    align-items: center;
    background-color: ${props => props.theme.colors.Pink};
    border: 2px solid ${props => props.theme.colors.DarkBlue};
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -moz-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: currentcolor none 0px !important;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.colors.DarkPink};
        svg {
            fill: ${props => props.theme.colors.DarkPink};
        }
        span {
            background: ${props => props.theme.colors.DarkPink};
        }
    }
`;
