import styled from "@emotion/styled";
import Logo from "components/brand/LogoHorizontal";
import HorizontalFolderNav from "components/navigation/FolderNavHor";
import DesktopNav from "components/navigation/fullScreenNav";
import MobileNav from "components/navigation/PhoneNav";
import { useEffect } from "react";
import { useNavigate } from "react-router";

import TransfersContainer from "./cashflowEdit/TransfersContainer";
import CustomerEditorContainer from "./CustomerEdit/CustomerEditor";
import RevenueAndExpensesContainer from "./revenuenexpenses/ContainerRevenueAndExpensesEditor";

const BudgetEditor = () => {
    const navigate = useNavigate();

    // Set the document title
    useEffect(() => {
        document.title = "Budget Editor";
    }, []);

    return (
        <EditorContainer>
            {/* Top bar with logo and navigation */}
            <Header>
                <Logo absolute={false} showText={false} asLink={true} />
                <DesktopNav />
                <MobileNav />
            </Header>

            {/* Main content with folder navigation */}
            <HorizontalFolderNav
                baseUrl='budget-editor'
                items={[
                    {
                        name: "Revenue & Expenses",
                        children: <RevenueAndExpensesContainer />,
                    },
                    {
                        name: "Cashflow",
                        children: <TransfersContainer />,
                    },
                    {
                        name: "Customer",
                        children: <CustomerEditorContainer />,
                    },
                ]}
            />
        </EditorContainer>
    );
};

export default BudgetEditor;

const EditorContainer = styled.div`
    width: 100%;
    min-height: 100vh;
    background-color: ${props => props.theme.colors.Pink};
`;

const Header = styled.div`
    z-index: 1;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin: 0 24px;
    height: 64px;
    border-bottom: 2px solid ${({ theme }) => theme.colors.DarkBlue};
`;

export const InputEdit = styled.input`
    width: 100%;
    height: 100%;
    text-align: center;
    background-color: transparent;
    -moz-appearance: textfield;
    border: none;
    &::-webkit-outer-spin-button,
    &::-webkit-inner-spin-button {
        -webkit-appearance: none;
        margin: 0;
    }
`;
