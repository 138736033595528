import useCurrentOrg from "hooks/reports/useCurrentOrg";

const AppStoreSubscribe = () => {
    const org = useCurrentOrg();
    return org ? (
        <div>
            <h1>Thank you for trialing Hello Cashflow!</h1>
            <p>
                Your free seven day trial for <strong> {org.name} </strong>
                has expired, please subscribe to contiue being part of the Hello
                Cashflow community.
            </p>
            <p>
                <a
                    href={
                        org.shortCode
                            ? `https://apps.xero.com/${org.shortCode}/subscribe/2a135089-3955-43d3-a17c-39a6fe12f642`
                            : "https://apps.xero.com/subscribe/2a135089-3955-43d3-a17c-39a6fe12f642"
                    }>
                    Subscribe in xero app store
                </a>
            </p>
        </div>
    ) : null;
};

export default AppStoreSubscribe;
