import { useEffect, useRef } from "react";
import { RootState, useAppSelector } from "redux/store";

import useCurrentOrg from "./reports/useCurrentOrg";

const UPDATE_INTERVAL = 5000;

const useUpdator = <T>(
    path: (state: RootState) => T,
    serverUpdator: (
        data: Exclude<T, undefined | null>,
        organisationId: string,
    ) => void,
) => {
    const organisationId = useCurrentOrg()?.organisationId;
    const isDemo =
        useCurrentOrg()?.organisationId ==
        "333ae1a4-90e2-4314-8242-e16051302f6f";
    const appData = useAppSelector(path);

    const changed = useRef(false);
    const data = useRef<
        { orgdata: Exclude<T, undefined | null>; orgId: string } | undefined
    >(undefined);

    //Use callback is important here
    const saveEditorDataToServerIfModified = () => {
        //If state hasen't changed, don't do anything
        if (data.current && changed.current) {
            serverUpdator(data.current.orgdata, data.current.orgId);
            changed.current = false;
        }
    };

    useEffect(() => {
        if (organisationId && appData && !isDemo) {
            changed.current = true;
            data.current = {
                orgdata: appData as Exclude<T, undefined | null>,
                orgId: organisationId,
            };
        }
    }, [appData, isDemo, organisationId]);

    //Run the update on a schedule, update and clean up if organisation id changes or compoennt unmounts
    useEffect(() => {
        if (!isDemo) {
            const interval = setInterval(
                saveEditorDataToServerIfModified,
                UPDATE_INTERVAL,
            );
            return () => {
                clearInterval(interval);
                saveEditorDataToServerIfModified();
            };
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isDemo]);
};

export default useUpdator;
