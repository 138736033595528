import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import OutsideAlerter from "components/inputs/OutsideAlerter";
import SpeedoGraph from "components/recharts/test/SpeedoGraph";
import TestChart2 from "components/recharts/test/testChart2";
import useReport from "hooks/reports/useReport";
import { sumOfMonth, totalForMonths } from "lib/apiDataHelpers/sumOfMonths";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { toTextMonth } from "lib/date/reStringifyMonth";
import { useRef, useState } from "react";
import { theme } from "style/theme";
import { OrgData } from "types/OrganisationType";

const BugetAccuracy = () => {
    const report = useReport();
    const menu = useRef<HTMLDivElement>(null);
    const [month, setMonth] = useState<0 | -1 | -12>(0);
    if (!report) {
        return null;
    }
    const {
        revenue,
        expenses,
        reportDates: { selectedMonth },
    } = report;

    const RevenueActualTotal = getData(revenue.actual, selectedMonth, month);
    const RevenuesBudgetTotal = getData(revenue.budget, selectedMonth, month);
    const ExpensesActualTotal = getData(expenses.actual, selectedMonth, month);
    const ExpensesBudgetTotal = getData(expenses.budget, selectedMonth, month);

    const RevenueAccuracy = Math.round(
        (RevenueActualTotal / RevenuesBudgetTotal) * 100,
    );

    const ExpensesAccuracy = Math.round(
        (ExpensesActualTotal / ExpensesBudgetTotal) * 100,
    );

    const OverallTotal =
        100 -
        Math.round(
            (Math.abs(
                RevenueActualTotal +
                    ExpensesActualTotal -
                    (RevenuesBudgetTotal + ExpensesBudgetTotal),
            ) *
                100) /
                (RevenuesBudgetTotal + ExpensesBudgetTotal),
        );

    return (
        <ItemSection light column minHeight={416}>
            <Title>
                Here's how accurate your budget was this{" "}
                {toTextMonth(addMonthsTo(selectedMonth, month))}
            </Title>
            <Menu>
                <OutsideAlerter
                    callBack={() =>
                        menu.current && (menu.current.style.display = "none")
                    }>
                    <Select>
                        <SelectTitle
                            onClick={() =>
                                menu.current &&
                                (menu.current.style.display =
                                    menu.current.style.display === "none"
                                        ? "flex"
                                        : "none")
                            }>
                            <SelectInput>{monthToWords(month)}</SelectInput>
                            <SelectDown>
                                <span></span>
                                <div aria-hidden='true'>
                                    <svg
                                        height='20'
                                        width='20'
                                        viewBox='0 0 20 20'
                                        aria-hidden='true'
                                        focusable='false'>
                                        <path d='M4.516 7.548c0.436-0.446 1.043-0.481 1.576 0l3.908 3.747 3.908-3.747c0.533-0.481 1.141-0.446 1.574 0 0.436 0.445 0.408 1.197 0 1.615-0.406 0.418-4.695 4.502-4.695 4.502-0.217 0.223-0.502 0.335-0.787 0.335s-0.57-0.112-0.789-0.335c0 0-4.287-4.084-4.695-4.502s-0.436-1.17 0-1.615z'></path>
                                    </svg>
                                </div>
                            </SelectDown>
                        </SelectTitle>
                        {
                            <SelectOptions ref={menu}>
                                <button
                                    style={{
                                        color:
                                            month === 0
                                                ? "grey"
                                                : theme.colors.DarkPink,
                                    }}
                                    onClick={() => setMonth(0)}
                                    disabled={month === 0}>
                                    {monthToWords(0)}
                                </button>
                                <button
                                    style={{
                                        color:
                                            month === -1
                                                ? "grey"
                                                : theme.colors.DarkPink,
                                    }}
                                    onClick={() => setMonth(-1)}
                                    disabled={month === -1}>
                                    {monthToWords(-1)}
                                </button>
                                <button
                                    style={{
                                        color:
                                            month === -12
                                                ? "grey"
                                                : theme.colors.DarkPink,
                                    }}
                                    onClick={() => setMonth(-12)}
                                    disabled={month === -12}>
                                    {monthToWords(-12)}
                                </button>
                            </SelectOptions>
                        }
                    </Select>
                </OutsideAlerter>
            </Menu>
            <Contianer>
                <Graphy>
                    <span>REVENUE</span>
                    <SpeedoGraph
                        chartMax={200}
                        chartValue={
                            RevenueAccuracy === -Infinity ||
                            RevenueAccuracy === Infinity
                                ? 0
                                : RevenueAccuracy
                        }
                    />
                    <p>
                        {RevenueAccuracy === -Infinity ||
                        RevenueAccuracy === Infinity ? (
                            "You have no Budget please do your budget to use this graph"
                        ) : (
                            <p>
                                You generated:{" "}
                                {Writer.FormatCurrency(
                                    Math.abs(
                                        RevenueActualTotal -
                                            RevenuesBudgetTotal,
                                    ),
                                )}{" "}
                                {RevenueActualTotal - RevenuesBudgetTotal > 0
                                    ? "more"
                                    : "less"}{" "}
                                then you budgeted
                            </p>
                        )}
                    </p>
                </Graphy>
                <Graphy>
                    <span>EXPENSES</span>
                    <SpeedoGraph
                        reverse
                        chartMax={200}
                        chartValue={
                            ExpensesAccuracy === -Infinity ||
                            ExpensesAccuracy === Infinity
                                ? 0
                                : ExpensesAccuracy
                        }
                    />
                    <p>
                        {ExpensesAccuracy === -Infinity ||
                        ExpensesAccuracy === Infinity ? (
                            "You have no Budget please do your budget to use this graph"
                        ) : (
                            <p>
                                {" "}
                                You spent:{" "}
                                {Writer.FormatCurrency(
                                    Math.abs(
                                        ExpensesActualTotal -
                                            ExpensesBudgetTotal,
                                    ),
                                )}{" "}
                                {ExpensesActualTotal - ExpensesBudgetTotal > 0
                                    ? "more"
                                    : "less"}{" "}
                                then you budgeted
                            </p>
                        )}
                    </p>
                </Graphy>
                <Graphy>
                    <span>OVERALL</span>
                    <TestChart2
                        circleSize={170}
                        fillColor={theme.colors.DarkPink}
                        baseColor={theme.colors.PinkMed}
                        value={
                            OverallTotal === -Infinity ||
                            OverallTotal === Infinity
                                ? 0
                                : OverallTotal
                        }
                    />
                    {/* <p>
                        Over all your budget accuracy was good
                        {Writer.FormatCurrency(
                            Math.abs(RevenueActualTotal - RevenuesBudgetTotal),
                        )}{" "}
                        {RevenueActualTotal - RevenuesBudgetTotal > 0
                            ? "more"
                            : "less"}{" "}
                        then you budgeted.
                    </p> */}
                </Graphy>
            </Contianer>
        </ItemSection>
    );
};

export default BugetAccuracy;

const Select = styled.div`
    position: relative;
    box-sizing: border-box;
`;
const SelectOptions = styled.div`
    z-index: 1;
    position: absolute;
    top: 38px;
    width: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    border: 2px solid ${theme.colors.DarkBlue};
    background-color: white;

    animation: fadeInAnimation ease-in-out 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    button {
        width: 100%;
        height: 40px;
        border: none;
        border-bottom: 1.3px solid grey;
        background: white;
        color: ${theme.colors.DarkPink};
        &:hover {
            background: rgba(0, 0, 0, 0.185);
            color: white;
        }
    }
`;

const Menu = styled.div`
    position: absolute;
    top: 38px;
    right: 48px;
    width: 140px;
    margin-left: auto;
    box-sizing: border-box;
`;
const SelectDown = styled.div`
    -moz-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;

    span {
        align-self: stretch;
        background-color: ${theme.colors.DarkBlue};
        margin-bottom: 8px;
        margin-top: 8px;
        width: 1px;
        box-sizing: border-box;
    }
    div {
        color: ${theme.colors.DarkBlue};
        display: flex;
        padding: 8px;
        transition: color 150ms ease 0s;
        box-sizing: border-box;
    }
`;
const SelectInput = styled.div`
    -moz-box-align: center;
    align-items: center;
    display: grid;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    text-align: center;
`;
const SelectTitle = styled.div`
    font-size: 14px;
    -moz-box-align: center;
    align-items: center;
    background-color: ${theme.colors.Pink};
    border: 2px solid ${theme.colors.DarkBlue};
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -moz-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: currentcolor none 0px !important;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    &:hover {
        cursor: pointer;
        color: ${theme.colors.DarkPink};
        svg {
            fill: ${theme.colors.DarkPink};
        }
        span {
            background: ${theme.colors.DarkPink};
        }
    }
`;

const Title = styled.h1`
    margin-right: auto;
    margin-top: -15px;
    margin-bottom: 30px;
    font-size: 24px;
`;
const Contianer = styled.div`
    display: flex;
    width: 100%;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
`;

const Graphy = styled.div`
    display: flex;
    flex-basis: 33%;
    flex-direction: column;
    align-items: center;
    p {
        font-size: 14px;
    }
    span {
        font-size: 16px;
    }
`;

const monthToWords = (month: 0 | -1 | -12) => {
    switch (month) {
        case 0:
            return "This Month";
        case -1:
            return "Last Month";
        case -12:
            return "Last 12 Months";
        default:
            return "";
    }
};

const getData = (
    data: OrgData,
    currentmonth: MonthKey,
    tomonth: 0 | -1 | -12,
) => {
    if (tomonth === -12) {
        return totalForMonths({
            org: data,
            earliestMonth: addMonthsTo(currentmonth, tomonth),
            latestMonth: currentmonth,
        });
    } else {
        return sumOfMonth(data[addMonthsTo(currentmonth, tomonth)]);
    }
};
