export default class ErrorService {
    static logError: (error: Error, errorInfo: React.ErrorInfo) => void = (
        error,
        errorInfo,
    ) => {
        if (process.env.NODE_ENV === "development") {
            console.error(
                {
                    error,
                    errorInfo,
                },
            );
        } else {
            //TODO
        }
    };
}
