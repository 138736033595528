// You can use any HTTP client
import { HttpClient } from "adapters/HttpClient";
import { linkWithCredential, OAuthProvider } from "firebase/auth";
import useAuthState from "hooks/firebase/useAuthState";
import { signInCustomToken } from "lib/firebase";
import { useEffect } from "react";
import { useNavigate, useRoutes } from "react-router";

let isUpdating = false;

function SignUpWithXero() {
    // Define the routes (currently under )
    const routes = useRoutes([
        {
            path: "/start",
            element: <SignUpXeroAppStore />,
        },
        {
            path: "/connect",
            element: <HandleAppstoreSignupRedirect />,
        },
        {
            path: "/sign-up-complete",
            element: <SignedUp />,
        },
    ]);

    function SignedUp() {
        return (
            <>
                <h1>You'll be hearing from us soon</h1>
                <p>
                    That's all you need to do for now. We are busy downloading
                    your Xero data and getting things ready. You will recieve an
                    email shortly when you are all set up.
                </p>
                <p>You may now close this window.</p>
            </>
        );
    }

    // The SignUp component initiates the Xero connection
    //This page is for app store users only, other users can sign up directly from the login page by clicking the xero button
    //The users who go through this Sign up with Xero flow to create their account should be led to the App Store subscription flow (which includes revenue share)
    function SignUpXeroAppStore() {
        const navigate = useNavigate();
        const [user, loading] = useAuthState();

        useEffect(() => {
            //If the user is logged in, we shouldn't be here, navigate to home
            if (user) {
                navigate("/");
            }
            else {   
                HttpClient.get<string>("xero/appstore-loginurl-signup").then(response => {
                    location.href = response;
                });
            }
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        , []);

        return null;
    }

    // The Connect component posts the completed token back to the server
    function HandleAppstoreSignupRedirect() {
        const navigate = useNavigate();
        useEffect(() => {
            if (!isUpdating) {
                const provider = new OAuthProvider("oidc.xero"); //Allows us to link the created account to the xero provider, allowing seamless login next time
                document.body.style.cursor = "wait";
                console.log("posting");
                isUpdating = true;
                //Post to the handler on server
                //Server will reply with the xero token and the token for the created firebase account
                HttpClient.post<{ firebaseToken: string; oidcIdToken: string }>(
                    "xero/handle-appstore-signup-redirect",
                    undefined,
                    {
                        ...window.location,
                        receiveEmail: true,
                    },
                ).then(result =>
                    //Sign the user in with the firebase account
                    signInCustomToken(result.firebaseToken).then(authResult => {
                        //Link the xero provider using the id token
                        const credential = provider.credential({
                            idToken: result.oidcIdToken,
                        });
                        linkWithCredential(authResult.user, credential)
                            .then(() => {
                                navigate("/account/providers"); //Hand off to the account providers page which will handle the rest of the setup (the two step process is in line with xero requirements for app store users)
                                isUpdating = false;
                            })
                            .catch(error => {
                                if (
                                    error.code ===
                                    "auth/provider-already-linked"
                                ) {
                                    navigate("/account/providers"); //No problem, continue as above                                
                                }
                            }).finally(() => {
                                document.body.style.cursor = "default";
                                isUpdating = false;
                            });
                    }),
                );
            }
        // eslint-disable-next-line react-hooks/exhaustive-deps
        }, []);
        return <p>Creating your Hello Cashflow account...</p>;
    }

    return routes;
}

export default SignUpWithXero;

// async function signInXero() {
//     const provider = new OAuthProvider("oidc.xero");
//     provider.addScope("email");
//     provider.addScope("profile");

//     await signInWithPopup(auth, provider)
//         .then(result => {
//             console.log(result);
//         })
//         .catch(error => {
//             alert(`failed to sign in with xero: ${error.message}`);
//         });
// }
