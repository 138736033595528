import { isRejectedWithValue } from "@reduxjs/toolkit";
import type { Middleware, MiddlewareAPI } from "@reduxjs/toolkit";

const lastWarning: number | null = null;

export const rtkQuerySubscriptionHandler: Middleware =
  (api: MiddlewareAPI) => (next) => (action) => {
    // RTK Query uses `createAsyncThunk` from redux-toolkit under the hood, so we're able to utilize these matchers!
    if (isRejectedWithValue(action)) {
      //If the error is a HTTP 403 error, and the http content is "Subscription required" we want to warn the user with a alert
        if (action.payload?.status === 403 && action.payload?.data === "Subscription required") {
            // //only run a maxamum of once per minute
            // if (lastWarning && Date.now() - lastWarning < 60000) {
            //     return next(action);
            // } else {
            //     lastWarning = Date.now();
            //     alert("You need to subscribe to use this app.");
            // }     

            //TODO: I have disabled this logic for now because subscription status and flow is currently being handled in the useorg hook
            //This middleware is probabily a better place for it  
            return next(action);    
        }
    }

    return next(action);
  };