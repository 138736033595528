import styled from "@emotion/styled";
import backgroundImage from "assets/img/backgrounds/home_stretch.png";
import LogoHorizontal from "components/brand/LogoHorizontal";
import { AnimatedSymbol } from "components/general/AnimatedSymbol";
import WelcomeScreen from "components/home/WelcomeScreen";
import { StylisedGraph } from "components/styledComponects/StyalisedGraph";
import useAuthState from "hooks/firebase/useAuthState";
import { useEffect } from "react";
import { NavLink } from "react-router-dom";

const HomePage = () => {
    const [user, loading] = useAuthState();
    useEffect(() => {
        document.title = "Home";
    }, []);
    return (
        <Container>
            <Logo>
                <LogoHorizontal />
            </Logo>
            <AnimatedSymbol />
            <StylisedGraph />
            <Feature>
                {user ? (
                    <>
                        {`Hello ${user?.displayName}`}
                        <p className='intense-quote'></p>
                        <WelcomeScreen />
                    </>
                ) : (
                    <>
                        <Opening>
                            <b>You are signed out.</b>
                            <br />
                            Please sign in if you wish to see you data or
                            continue if you want to see the demo
                        </Opening>
                        <NavLink to={"/login"}>
                            <button>Continue</button>
                        </NavLink>
                    </>
                )}
            </Feature>
        </Container>
    );
};

export default HomePage;

const Opening = styled.p`
    max-width: 350px;
    font-size: 13px;
    b {
        font-size: 20px;
        margin-bottom: 10px;
    }
`;
const Logo = styled.div`
    top: 10px;
    position: absolute;
    left: 30px;
`;

const Feature = styled.div`
    max-width: ${props => props.theme.sizes.contentWidth} / 2;
    text-align: center;
    font-size: 28px;
    font-weight: 500;

    h1 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 28px;
        border-bottom: 1px solid ${props => props.theme.colors.DarkBlue};
        padding: 5px 0;
        margin: 0 0 40px 0;
    }
`;

const Container = styled.div`
    padding: 10px;
    position: relative;
    height: 75vh;
    background-color: transparent;
    background-image: url(${backgroundImage});
    background-position: center;
    min-height: 400px;
    max-height: 686px;
    color: ${props => props.theme.colors.DarkBlue};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
        position: absolute;
        top: 10px;
        left: 30px;
    }
`;
