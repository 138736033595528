import styled from "@emotion/styled";

import image1 from "../../assets/img/backgrounds/symbol/1.png";
import image2 from "../../assets/img/backgrounds/symbol/2.png";
import image3 from "../../assets/img/backgrounds/symbol/3.png";
import image4 from "../../assets/img/backgrounds/symbol/4.png";
import image5 from "../../assets/img/backgrounds/symbol/5.png";
import image6 from "../../assets/img/backgrounds/symbol/6.png";

export const AnimatedSymbol = styled.span`
    background-repeat: no-repeat;
    background-position: 0 0;

    background-size: 100%;
    position: absolute;
    width: 150px;
    height: 150px;
    top: 250px;
    right: 50px;
    background-image: url(${image1});

    animation-name: transition-bg;
    animation-duration: 20s;
    animation-iteration-count: infinite;
    animation-direction: alternate;

    @media (max-width: ${props => props.theme.sizes.contentWidthPhone}) {
        display: none;
    }

    @keyframes transition-bg {
        from {
            background-image: url(${image1});
        }
        20% {
            background-image: url(${image2});
        }
        40% {
            background-image: url(${image3});
        }
        60% {
            background-image: url(${image4});
        }
        80% {
            background-image: url(${image5});
        }
        to {
            background-image: url(${image6});
        }
    }
`;
