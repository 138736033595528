import styled from "@emotion/styled";
import Avatar from "components/Avatar";
import { getAuth, signOut } from "firebase/auth";
import useAuthState from "hooks/firebase/useAuthState";
import { auth } from "lib/firebase";
import { useState } from "react";
import { NavLink } from "react-router-dom";
import { useAppDispatch } from "redux/store";
const nameRegex = /^[a-zA-Z0-9]*/;

const User = () => {
    const [MenuState, setMenuState] = useState(false);
    const [isClicked, setIsClicked] = useState(false);
    const [userState, loading] = useAuthState();
    const dispatch = useAppDispatch();

    const clicked = () => {
        if (!isClicked) {
            setIsClicked(true);
            if (!MenuState) {
                setMenuState(true);
            }
            setTimeout(() => {
                window.addEventListener("click", close);
            }, 0);
        }
    };

    const mouseLeave = () => {
        if (!isClicked) {
            close();
        }
    };

    const close = () => {
        setIsClicked(false);
        setMenuState(false);
        setTimeout(() => {
            window.removeEventListener("click", close);
        }, 0);
    };
    const mouseHover = () => {
        setMenuState(true);
    };
    const name =
        userState?.displayName && nameRegex.exec(userState?.displayName);

    const runSignOut = () => {
        const auth = getAuth();
        signOut(auth)
            .then(() => {
                console.log("signed out");
                dispatch({ type: "user/logout" });
            })
            .catch(error => {
                console.error(error);
            });
    };
    const image = userState?.photoURL;

    return (
        <div
            style={{
                position: "relative",
                height: "100%",
                display: "flex",
                alignItems: "center",
            }}
            onMouseEnter={mouseHover}
            onMouseLeave={mouseLeave}>
            <Container onClick={clicked}>
                <span>{name?.length && name[0]}</span>
                <span style={{ marginLeft: "10px" }}>
                    <Avatar size={30} />
                </span>
            </Container>
            {MenuState && (
                <Menu>
                    <Items>
                        <li>
                            <Avatar size={50} />
                            <p>
                                <strong>{userState?.displayName}</strong>
                                <br />
                                {auth.currentUser?.email}
                            </p>
                        </li>
                        <UserPage>
                            <NavLink to='/profile/account'>
                                Profile & Settings
                            </NavLink>
                        </UserPage>
                        <SignOut>
                            <Line />
                            <p onClick={runSignOut}>Sign Out</p>
                        </SignOut>
                        <li></li>
                    </Items>
                </Menu>
            )}
        </div>
    );
};

export default User;

const UserPage = styled.li`
    width: 100%;
    min-height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    padding: 10px !important;
    a {
        color: ${props => props.theme.colors.DarkBlue};
        text-decoration: none;
    }
    &:hover {
        cursor: pointer;
        background-color: #0c0c0c2d;
    }
`;

const SignOut = styled.li`
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    p {
        padding: 10px !important;
    }
    &:hover {
        p {
            width: 100%;
            display: flex;
            cursor: pointer;
            align-items: center;
            justify-content: center;
            flex-direction: column;
            height: 100%;
            background-color: #0c0c0c2d;
        }
    }
`;

const Items = styled.ul`
    text-decoration: none;
    list-style: none;
    padding: 0;
    li {
        padding: 0 10px;
        display: flex;
        p {
            margin: 0;
        }
        hr {
            transform: rotate(90deg);
        }
    }
`;

const Line = styled.span`
    margin: 10px;
    border: 1px solid ${({ theme }) => theme.colors.DarkBlue};
    width: 100%;
`;

const Menu = styled.div`
    p {
        padding: 10px;
        strong {
            text-transform: capitalize;
        }
    }
    background-color: ${props => props.theme.colors.Pink};
    list-style: none;
    top: 50px;
    position: absolute;
    background-color: #f9f9f9;
    min-width: 230%;
    border-radius: 8px;
    overflow: visible;
    box-shadow: 0 16px 24px 2px rgba(29, 17, 51, 0.04),
        0 6px 32px 4px rgba(9, 32, 77, 0.12),
        0 8px 12px -5px rgba(29, 17, 51, 0.12);
    z-index: 10;
    right: 0;
    left: auto;
`;

const Container = styled.div`
    display: flex;
    align-items: center;
    cursor: pointer;
`;
