import UIErrorBoundary from "components/error-boundaries/SectionErrorBoundary";
import BankAccounts from "components/reports/cashflow/BankAccounts";
import BudgetTransfers from "components/reports/cashflow/BudgetTransfers";
import CashflowGraphSection from "components/reports/cashflow/CachflowGraphSection";
import CashflowSectionCoverPage from "components/reports/cashflow/CashflowCoverPage";
import MovementsSection from "components/reports/cashflow/MovementsSection";
import WorkingCapital from "components/reports/cashflow/WorkingCapital";
import Footer from "components/reports/ReportsFooter/ReportsFooter";
import { useEffect } from "react";

const CashflowPage = () => {
    useEffect(() => {
        document.title = "My Cashflow";
    }, []);

    return (
        <>
            <UIErrorBoundary>
                <CashflowSectionCoverPage />
                <MovementsSection />
                <BudgetTransfers />
                <CashflowGraphSection />
                {/* <TaxInfo /> */}
                <WorkingCapital />
                <BankAccounts />
                {/*
                <NetCash />
                */}
            </UIErrorBoundary>
            <Footer />
        </>
    );
};

export default CashflowPage;
