import styled from "@emotion/styled";

export const ChartHeader = styled.div`
    margin-bottom: 1rem;
    padding: 0;

    h2 {
        font-size: 1rem;
        font-weight: 500;
        margin: 0 0 0 15px;
    }

    h1 {
        font-size: 1.5rem;
        font-weight: bold;
        margin: 0 0 0 15px;
    }
    `;