import styled from "@emotion/styled";
import { useAppSelector } from "redux/store";

import NotificationBar from "./NotificationBar";
import NotificationsPersistant from "./NotificationsPersistant";

const ShowNotifications = () => {
    const notificationsIds = useAppSelector(
        state => state.notifications.notificationsIds,
    );

    const show = window.location.pathname === "/subscribe";
    return (
        <>
            <NotificationBars>
                {notificationsIds.map(notification => (
                    <NotificationBar key={notification} id={notification} />
                ))}
            </NotificationBars>
            {show && (
                <NotificationBell>
                    <NotificationsPersistant />
                </NotificationBell>
            )}
        </>
    );
};

export default ShowNotifications;

const NotificationBars = styled.div`
    z-index: 999999;
    position: fixed;
    top: 10px;
    right: 10px;
    width: 300px;
`;
const NotificationBell = styled.div`
    z-index: 999999;
    position: fixed;
    bottom: 0px;
    right: 10px;
    width: 300px;
`;
