//Currently only Xero is setup

import styled from "@emotion/styled";
import backgroundImage from "assets/img/backgrounds/home_stretch.png";
import qbConnect from "assets/img/qb/C2QB_green_btn_med_default_2x.png";
import qbConnectHover from "assets/img/qb/C2QB_transparent_btn_med_hover_2x.png";
import { ReactComponent as ConnectXero } from "assets/img/xero/connect-blue.svg";
import { connectWithIntuit } from "components/auth/helpers/functions";
import LogoHorizontal from "components/brand/LogoHorizontal";
import { AnimatedSymbol } from "components/general/AnimatedSymbol";
import initiateXeroConnect from "components/helper/initiateXeroConnect";
import { StylisedGraph } from "components/styledComponects/StyalisedGraph";
import { getCustomClaims } from "lib/firebase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";


/*

Xero issues a temporary code which needs to be exchanged for a token.
The temporary code is sent to the server where it is exchanged and the token securly stored
The client app never sees the token, and all data exchange with xero is handled by the server.
The purpose of this page is to initiate that process and hand off to the server.

*/

const LinkProvider = () => {
    const [claims, setClaims] = useState<Record<string, any>>();

    useEffect(() => {
        const fetchClaims = async () => {
          const result = await getCustomClaims();
          setClaims(result || {});
        };
    
        fetchClaims();
      }, []);

    //get custom claim from firebase user
    const isQuckbooks = claims?.quickbooks;
    const isXero = claims?.xero;

    const navigate = useNavigate();

    //Eventually may have multiple handelrs for providers
    const handleXero: React.MouseEventHandler<SVGElement> = e =>
        initiateXeroConnect(e); //This page is designed to be used for a logged in user, it will fail if the user is not authenticated

    const handeQuickbooksConnect: React.MouseEventHandler<HTMLDivElement> = (e) => {
        //Disable the div to prevent double clicks
        e.currentTarget.style.pointerEvents = "none";
        connectWithIntuit(navigate);
    };


    return claims ? (
        <Container>
            <Logo>
                <LogoHorizontal asLink showText={false} />
            </Logo>
            <AnimatedSymbol />
            <StylisedGraph />
            <Tagline>Seamless data visualisation</Tagline>
            <Content>
                <h3>Connect to your accounting provider</h3>
                <p>
                    To use hellocashflow you need to connect to your organisation in your accounting provider. This will allow us to pull in your data and provide you with customised insights and reports.
                </p>
                {/* if the user has signed up with quickbooks or xero, we will only show their accounting provider. local accounts get both options. */}

                {!isQuckbooks ? <ConnectXero style={{cursor:"pointer", width:446}} onClick={handleXero} /> : null}

                {!isXero ? <QuickBooksButton onClick={handeQuickbooksConnect} /> : null}

                <p>
                    <i>
                        By completing this step you agree to share your data with hellocashflow. We will never share your data with any third party. For more information please see our privacy policy.
                    </i>
                </p>
                <p>
                    <i>
                        You can disconnect your accounting provider at any time. This will remove your data from hellocashflow and you will no longer be able to use our services.
                    </i>
                </p>
            </Content>
        </Container>
    ) : null;
};

export default LinkProvider;

const QuickBooksButton = styled.div`
    background-image: url(${qbConnect});
    background-size: contain;
    background-repeat: no-repeat;
    width: 446px;
    height: 72px;
    margin: 10px 0;
    cursor: pointer;
    transition: background-image 0.3s ease-in-out;

    &:hover {
        background-image: url(${qbConnectHover});
    }
`;

const Content = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
        max-width: 500px;
    }
`;

const Tagline = styled.p`
    position: absolute;
    top: 100px;
    right: 30px;
    font-size: $default-font-size;
`;

const Logo = styled.div`
    top: 10px;
    position: absolute;
    left: 30px;
`;

const Feature = styled.div`
    max-width: ${props => props.theme.sizes.contentWidth} / 2;
    text-align: center;
    font-size: 28px;
    font-weight: 500;

    h1 {
        text-transform: uppercase;
        font-weight: 700;
        font-size: 28px;
        border-bottom: 1px solid ${props => props.theme.colors.DarkBlue};
        padding: 5px 0;
        margin: 0 0 40px 0;
    }
`;

const Container = styled.div`
    padding: 10px;
    position: relative;
    height: 75vh;
    background-color: transparent;
    background-image: url(${backgroundImage});
    background-position: center;
    min-height: 400px;
    max-height: 686px;
    color: ${props => props.theme.colors.DarkBlue};
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .logo {
        position: absolute;
        top: 10px;
        left: 30px;
    }
`;
