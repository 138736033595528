import styled from "@emotion/styled";
import {
    BudgetSources,
    OrganisationTypes,
} from "common/types/objects/Organisation";
import TransfersEditor from "components/inputs/BudgetEditor/cashflowEdit/TransfersContainer";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { useSetOrganisationFirstTimeMutation } from "redux/rtkQuery/organisations";
import { useAppDispatch } from "redux/store";

interface Props {
    changePage: React.Dispatch<React.SetStateAction<number>>;
    index: number;
}
const EditCashflow = ({ changePage, index }: Props) => {
    const organisation = useCurrentOrg();
    const [setFirstTime] = useSetOrganisationFirstTimeMutation();

    const dispatch = useAppDispatch();

    const hellocashflowbudget =
        organisation?.budgetSource === BudgetSources.hellocashflow ? 0 : 1;
    const subchange =
        organisation?.organisationType === OrganisationTypes.subscription
            ? 0
            : 1;
    const change = 1 + (!subchange ? 0 : hellocashflowbudget + subchange);

    const finished = () => {
        setFirstTime({
            organisationId: organisation?.organisationId,
            changeTo: false,
        });
    };

    return (
        <Container>
            <Menu>
                <Back onClick={() => changePage(index - change)}>Back</Back>
                <h1>Congratulations - you are at the final step!</h1>
                <Continue onClick={finished}>Finish</Continue>
            </Menu>

            <p style={{ width: "90%", marginLeft: 25, height: "200" }}>
                This page only takes a few moments to fill out. <br />
                Write down what you think you’ll spend on the following things
                this year.
                <br />
                <br />
                <b>Drawings</b>: Do you to take regular drawings from your
                business? Or are you planning to take a lump sum in the near
                future? Record this in the relevant months below.
                <br />
                <b>Assets</b>: Add in where you are planning to buy that
                tractor, or that new supercomputer. If you have no plans to
                spend any money here, feel free to leave this blank.
                <br />
                <b>Loan payments</b>: do you have a regular loan payment to add
                in here? Or a one off loan payment coming up? If you do, add it
                in!
                <br />
            </p>
            <div>
                <TransfersEditor />
            </div>
            <img
                src='/assets/horizental version.png'
                alt='HelloCashflow'
                height={50}
                width={160}
                style={{
                    position: "absolute",
                    bottom: 0,
                    marginRight: "auto",
                    marginBottom: 20,
                    marginLeft: 20,
                    marginTop: 20,
                }}
            />
        </Container>
    );
};

export default EditCashflow;

const Container = styled.div`
    position: relative;
    overflow: hidden;
    height: 100%;
    width: 100%;
    padding: 10px;
`;
const Menu = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 0 10px;
    h1 {
        margin: 0;
    }
`;

const Continue = styled.button`
    padding: 10px;
    border-radius: 15px;
    width: 150px;
    height: 35px;
`;

const Back = styled.button`
    padding: 10px;
    height: 35px;
    border-radius: 15px;
    width: 150px;
    background-color: #636363;
    &:hover {
        background-color: #444444;
        color: white;
    }
`;
