import PageNotFound from "components/general/404Stuff/PageNotFound";
import { Route, Routes } from "react-router";

import HandleProviderResponse from "./ConnectedXero";
import Finished from "./Finished";
import LandingPage from "./LandingPage";
import Payment from "./Payment";

const OnBoardingRoutes = () => {
    return (
        <Routes>
            <Route path='/landing' element={<LandingPage />} />
            <Route
                path='/connected/:provider'
                element={<HandleProviderResponse />}
            />
            <Route path='/payment' element={<Payment />} />
            <Route path='/finished' element={<Finished />} />
            <Route path='*' element={<PageNotFound />} />
        </Routes>
    );
};

export default OnBoardingRoutes;
