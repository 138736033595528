import { css, keyframes } from "@emotion/react";
import styled from "@emotion/styled";
import { OrganisationIdentifier } from "common/types/objects/Organisation";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { getUserType } from "lib/firebase";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router";
import { useSyncDataMutation } from "redux/rtkQuery/organisations";
import { theme } from "style/theme";

import { Container } from "./BaseStyles";
import Progress from "./Progress";

const Finished = () => {
    const navigate = useNavigate();
    const orginisation = useCurrentOrg();
    const [syncData] = useSyncDataMutation({
        fixedCacheKey: "syncData",
    });

    const [error, setError] = useState<string>();
    const [isAppstore, setIsAppstore] = useState<boolean>();

    async function updateOrganisationData(
        org: OrganisationIdentifier,
        appstore: boolean,
    ) {
        const result = await syncData({
            organisation: org,
            years: 3,
            email: true,
        });
        if ("error" in result) {
            if ("status" in result.error && result.error.status === 403) {
                //If the server has returned a 403, they don't have a subscription and shouldn't have ended up here
                navigate(
                    appstore
                        ? "/account/appstore-subscribe"
                        : "/onboarding/payment",
                );
            } else {
                setError(JSON.stringify(result.error));
            }
        } else {
            localStorage.setItem(`synced-years-${org.organisationId}`, "3");
            navigate("/");
        }
    }

    useEffect(() => {
        getUserType().then(userType => {
            setIsAppstore(!!userType.isAppstore);
            if (orginisation) {
                console.log("first time update");
                updateOrganisationData(orginisation, !!userType.isAppstore);
            }
        });
        //TODO - is infinate loop because of the updating of org last synced?
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [orginisation]);

    return (
        <Container>
            {isAppstore === false && <Progress progress={4} />}
            {error ? (
                <>
                    <h1>
                        Error updating data for{" "}
                        {orginisation?.name || "organisation"}
                    </h1>
                    <span>
                        Please get in touch with support. We are sorry for the
                        inconvenience. The error is: {error}
                    </span>
                </>
            ) : (
                <>
                    <h1>Finished</h1>
                    <span>
                        {" "}
                        We are downloading your data. You can close this window
                        now - you will get an email when Hello Cashflow is
                        ready.
                    </span>
                    <ELoadholder>
                        <MLoader>
                            <EText>Loading</EText>
                        </MLoader>
                    </ELoadholder>
                </>
            )}
        </Container>
    );
};

export default Finished;

const COLOR = theme.colors.PinkMed;

const centre = (axis = "both") => css`
    position: absolute;
    ${axis == "y" &&
    css`
        top: 50%;
        -webkit-transform: translateY(-50%);
        -moz-transform: translateY(-50%);
        -ms-transform: translateY(-50%);
        -o-transform: translateY(-50%);
        transform: translateY(-50%);
    `}
    ${axis == "x" &&
    css`
        left: 50%;
        -webkit-transform: translateX(-50%);
        -moz-transform: translateX(-50%);
        -ms-transform: translateX(-50%);
        -o-transform: translateX(-50%);
        transform: translateX(-50%);
    `}
    ${axis == "both" &&
    css`
        top: 50%;
        left: 50%;
        -webkit-transform: translate(-51%, -50%);
        -moz-transform: translate(-51%, -50%);
        -ms-transform: translate(-51%, -50%);
        -o-transform: translate(-51%, -50%);
        transform: translate(-51%, -50%);
    `}
`;

const textColour = keyframes`
0% {
    color: ${theme.colors.DarkBlue};
}
100% {
    color: ${theme.colors.DarkPink};
}`;

const outerRotate1 = keyframes` 
0% {
    transform: translate(-50%, -50%) rotate(0);
}
100% {
    transform: translate(-50%, -50%) rotate(360deg);
}`;

const outerRotate2 = keyframes` 
0% {
    transform: translate(-50%, -50%) rotate(0);
}
100% {
    transform: translate(-50%, -50%) rotate(-360deg);
}`;

const EText = styled.div`
    ${centre()}
    font-size: ${1.4 * 10}px;
    line-height: ${13 * 10}px;
    animation: ${textColour} 1s alternate linear infinite;
    display: block;
    width: 140px;
    height: 140px;
    text-align: center;
    border: 5px solid ${props => props.theme.colors.DarkBlue};
    border-radius: 70px;
    box-sizing: border-box;
    z-index: 20;

    &:before,
    &:after {
        ${centre()}
        content: " ";
        display: block;
        background: ${COLOR};
        transform-origin: center;
        z-index: -1;
    }

    &:before {
        width: 110%;
        height: 40px;
        animation: ${outerRotate2} 3.5s infinite linear;
    }
    &:after {
        width: 40px;
        height: 110%;
        animation: ${outerRotate1} 8s infinite linear;
    }
`;
const MLoader = styled.div`
    ${centre()}
    width: 200px;
    height: 200px;
    color: #888;
    text-align: center;
    border: 5px solid ${props => props.theme.colors.DarkBlue};
    border-radius: 50%;
    box-sizing: border-box;
    z-index: 20;
    text-transform: uppercase;

    &:after {
        ${centre()}
        content: " ";
        display: block;
        background: ${COLOR};
        transform-origin: center;
        z-index: -1;
        width: 100px;
        height: 106%;
        animation: ${outerRotate1} 15s infinite linear;
    }
`;

const ELoadholder = styled.div`
    ${centre()}
    width: 240px;
    height: 240px;
    border: 5px solid ${props => props.theme.colors.DarkBlue};
    border-radius: 50%;
    box-sizing: border-box;

    &:after {
        ${centre()}
        content: " ";
        display: block;
        background: ${COLOR};
        transform-origin: center;
        z-index: 0;
        width: 100px;

        height: 105%;
        animation: ${outerRotate2} 30s infinite linear;
    }
`;
