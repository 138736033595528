import styled from "@emotion/styled";
import { ReactNode } from "react";
import { Route, Routes, useNavigate } from "react-router";

interface FolderNavHorProps {
    baseUrl: string;
    items: Array<{ name: string; children: ReactNode }>;
}

const url = (name: string, baseUrl: string) => {
    return `/${baseUrl}/${name.replaceAll(" ", "-").toLocaleLowerCase()}`;
};

const FolderNavHor = ({ items, baseUrl }: FolderNavHorProps) => {
    const navagate = useNavigate();
    const handleClick = (name: string) => {
        navagate(url(name, baseUrl));
    };

    return (
        <Container>
            <Navmenu>
                {items.map(({ name }, index) => {
                    return (
                        <NavItem
                            selected={
                                url(name, baseUrl) === window.location.pathname
                            }
                            key={index}
                            onClick={() => handleClick(name)}>
                            {name}
                        </NavItem>
                    );
                })}
            </Navmenu>
            <Children>
                <Routes>
                    {items.map(({ children, name }, index) => (
                        <Route
                            key={index}
                            path={name.replaceAll(" ", "-").toLocaleLowerCase()}
                            element={children}
                        />
                    ))}
                </Routes>
            </Children>
        </Container>
    );
};

export default FolderNavHor;

const NavItem = styled.div<{ selected: boolean }>`
    min-width: 150px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    margin: 0 3px;
    padding: 5px;
    box-shadow: -1px -1px 1px #5e5e5e9e, 0px -1px 1px #5e5e5e9e;
    background-color: ${props =>
        props.selected ? props.theme.colors.Pink : "#ffebf1"};
    border-radius: 5px 5px 0 0;
    cursor: ${props => (props.selected ? "default" : "pointer")};
    z-index: ${props => (props.selected ? 4 : "")};

    &:hover {
        background-color: ${props =>
            props.selected ? props.theme.colors.Pink : "#ffe7ee"};
        &:before {
            border: 2px solid
                ${props => (props.selected ? "" : props.theme.colors.DarkPink)};
        }

        &:after {
            border-bottom: 30px solid
                ${props =>
                    props.selected ? props.theme.colors.Pink : "#ffe3ec"};
        }
    }

    &:before {
        z-index: 3;
        top: 1px;
        position: absolute;
        content: "";
        width: 90%;
        height: 0;
        border-radius: 5px;
        border: 2px solid
            ${props => (props.selected ? props.theme.colors.DarkBlue : "#fff")};
    }

    &:after {
        z-index: 3;
        right: -17px;
        top: 0;
        position: absolute;
        content: "";
        width: 0;
        height: 0;

        border-right: 20px solid transparent;
        border-bottom: 30px solid
            ${props => (props.selected ? props.theme.colors.Pink : "#ffebf1")};
        filter: drop-shadow(2px 0px 1px #5e5e5e9e);
    }
`;
const Navmenu = styled.div`
    display: flex;
    margin-left: 20px;
`;
const Children = styled.div`
    border: 2px solid ${props => props.theme.colors.DarkBlue};
    border-radius: 5px;
`;
const Container = styled.div`
    width: 100%;
    padding: 20px;
`;
