import { configureStore } from "@reduxjs/toolkit";
import { TypedUseSelectorHook, useDispatch, useSelector } from "react-redux";
import undoable from "redux-undo";

import { rtkQuerySubscriptionHandler } from "./middleware/subscriptionMiddleware";
import { api } from "./rtkQuery/api";
import { stripeSlice } from "./rtkQuery/stripe";
import BudgetSlice from "./slices/BudgetSlice";
import CustomersSlice from "./slices/CustomersSlice";
import DateSlice from "./slices/DateSlice";
import NotificationSlice from "./slices/NotificationSlice";
import TransfersSlice from "./slices/TransfersSlice";

const reducer = {
    customers: undoable(CustomersSlice, {
        undoType: "customerUndo",
        redoType: "customerRedo",
    }),
    transfers: undoable(TransfersSlice, {
        undoType: "transferUndo",
        redoType: "transferRedo",
    }),
    budget: undoable(BudgetSlice, {
        undoType: "budgetUndo",
        redoType: "budgetRedo",
    }),
    dates: DateSlice,
    notifications: NotificationSlice,
    [api.reducerPath]: api.reducer,
};

export const store = configureStore({
    reducer,
    middleware: getDefaultMiddleware =>
        getDefaultMiddleware()
            .concat(stripeSlice.middleware)
            .concat(api.middleware)
            .concat(rtkQuerySubscriptionHandler),
});

// Infer the `RootState` and `AppDispatch` types from the store itself
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;

// Use throughout your app instead of plain `useDispatch` and `useSelector`
export const useAppDispatch: () => AppDispatch = useDispatch;
export const useAppSelector: TypedUseSelectorHook<RootState> = useSelector;
