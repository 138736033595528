import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import MonthKey from "common/types/monthKey";
import { OrgData } from "types/OrganisationType";

export const graphOption = [
    "Total number of customers",
    "Customers gained & lost",
    "Average revenue per customer",
    "Cost to acquire a new Customer",
    "Churn Rate",
    "Retention Rate",
    // "Cost to acquire a Customer(net)",
    "Customer Growth Rate",
];

export const customerOptions = [
    "Customers",
    "Clients",
    "Subscribers",
    "Students",
    "Members",
];

export interface CustomersState {
    customers: OrgData;
    graph: {
        index: number;
        parent_size: number;
        transform: number;
    };
    customerName: string;
}

const initialState: CustomersState = {
    customers: {},
    graph: {
        index: 0,
        parent_size: 0,
        transform: 0,
    },
    customerName: localStorage.getItem("customerName") ?? customerOptions[0],
};

export const CustomersSlice = createSlice({
    name: "customers",
    initialState,
    reducers: {
        incrementCustomer: (
            state,
            action: PayloadAction<{
                month: MonthKey;
                item: string;
            }>,
        ) => {
            state.customers[action.payload.month] ??= {};
            state.customers[action.payload.month][action.payload.item] ??= 0;

            state.customers[action.payload.month][action.payload.item] += 1;
        },
        decrementCustomer: (
            state,
            action: PayloadAction<{
                month: MonthKey;
                item: string;
            }>,
        ) => {
            state.customers[action.payload.month] ??= {};
            state.customers[action.payload.month][action.payload.item] ??= 0;

            state.customers[action.payload.month][action.payload.item] -= 1;
        },
        setCustomers: (
            state,
            action: PayloadAction<{
                month: MonthKey;
                item: string;
                input: number;
            }>,
        ) => {
            state.customers[action.payload.month] ??= {};
            state.customers[action.payload.month][action.payload.item] ??= 0;
            state.customers[action.payload.month][action.payload.item] =
                action.payload.input;
        },
        setAllCustomers: (state, action: PayloadAction<OrgData>) => {
            state.customers = action.payload;
        },
        setGraph: (
            state,
            action: PayloadAction<{
                index: number;
                parent_size: number;
                transform: number;
            }>,
        ) => {
            state.graph = action.payload;
        },
        setCustomerName: (state, action: PayloadAction<string>) => {
            localStorage.setItem("customerName", action.payload);
            state.customerName = action.payload;
        },
    },
});

export const {
    setCustomers,
    setAllCustomers,
    incrementCustomer,
    decrementCustomer,
    setGraph,
    setCustomerName,
} = CustomersSlice.actions;

export default CustomersSlice.reducer;
