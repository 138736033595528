import styled from "@emotion/styled";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { useLocation, useNavigate } from "react-router";
import { useCreateCheckoutSessionQuery } from "redux/rtkQuery/stripe";

import { Container } from "./BaseStyles";
import Progress from "./Progress";

// const testOrgs: OrganisationIdentifier[] = [
//     {
//         name: "Jonny Wilson (no MMW access)",
//         organisationId: "39c3a689-6600-4fd3-b318-382a17f02691",
//         reportingYearBeginsMonth: 4,
//         dataLastUpdated: "2023-07-06T23:50:35.512Z",
//         provider: 1,
//         salesTaxPeriodMonths: 12,
//         budgetSource: 1,
//         firstTime: false,
//         organisationType: 1,
//         notifications: 0,
//         quarters: false,
//         subscriptionStatus: OrgSubscriptionStatus.none,
//     },
//     {
//         name: "Goodtime Foundation",
//         organisationId: "eeaaecf8-7d8b-41fc-80eb-03ea4fdb5d77",
//         reportingYearBeginsMonth: 4,
//         dataLastUpdated: "2023-07-06T23:50:35.512Z",
//         provider: 1,
//         salesTaxPeriodMonths: 2,
//         budgetSource: 1,
//         firstTime: false,
//         organisationType: 1,
//         notifications: 0,
//         quarters: false,
//         subscriptionStatus: OrgSubscriptionStatus.none,
//     },
//     {
//         name: "Wilson & Co Creative Limited",
//         organisationId: "95e11fcf-4ea7-46f8-91cc-977e21dd4958",
//         reportingYearBeginsMonth: 4,
//         dataLastUpdated: "2023-07-06T23:50:35.512Z",
//         provider: 1,
//         salesTaxPeriodMonths: 2,
//         budgetSource: 1,
//         firstTime: false,
//         organisationType: 1,
//         notifications: 1,
//         quarters: false,
//         subscriptionStatus: OrgSubscriptionStatus.stripe,
//     },
// ];

const Payment = () => {
    const { state } = useLocation();
    const navagate = useNavigate();
    const rejoined = state?.rejoin as boolean | undefined;
    // const orgs = state?.orgs as OrganisationIdentifier[] | undefined;
    // // const orgs = testOrgs as OrganisationIdentifier[] | undefined;
    // const [selOrgs, setFormOrgs] = useState<
    //     OrganisationIdentifier | undefined
    // >();
    // const [selectedOrg, setSelectedOrgs] = useState(
    //     orgs?.length === 1 ? orgs?.[0] : undefined,
    // );
    // const paramOrgs = selectedOrg && [
    //     { name: selectedOrg?.name, id: selectedOrg?.organisationId },
    // ];

    const org = useCurrentOrg();

    const { data: url, isFetching } = useCreateCheckoutSessionQuery(
        org ? [{ name: org?.name, id: org?.organisationId }] : skipToken,
    );

    // useEffect(() => {
    //     if (orgs?.length === 1) {
    //         localStorage.setItem(
    //             "onboardingOrgs",
    //             JSON.stringify([selectedOrg]),
    //         );
    //     }
    //     // eslint-disable-next-line react-hooks/exhaustive-deps
    // }, [orgs?.length]);

    const handleSubmit = () => {
        //localStorage.setItem("onboardingOrgs", JSON.stringify([selOrgs]));
        // if (org?.subscriptionStatus === OrgSubscriptionStatus.none) {
        //     setSelectedOrgs(selOrgs);
        // } else {
        navagate("/onboarding/finished");
        //}
    };

    return (
        <Container>
            <Progress progress={3} />
            <Explanation>
                <h1>You're nearly there! </h1>
                {rejoined && (
                    <div>
                        Welcome back lets finish setting up your account
                        <br />
                    </div>
                )}
                <p>
                    Please proceed to checkout so we can finish setting up your
                    HelloCashflow account.
                </p>
            </Explanation>
            {isFetching ? (
                <p>
                    please wait while we create a checkout <br />
                    loading...
                </p>
            ) : url ? (
                <button onClick={() => url && (window.location.href = url)}>
                    Go to stripe checkout
                </button>
            ) : (
                <p>something went wrong</p>
            )}
            <br />
            if you have any questions please contact us at <br />
            <a href='mailto:support@hellcashflow.com'>
                support@hellcashflow.com
            </a>
            <br />
        </Container>
    );
};

export default Payment;

const Explanation = styled.div``;

const ContinueButton = styled.button`
    all: unset;
    position: relative;
    font-size: 16px;
    display: flex;
    align-items: center;
    justify-content: center;
    color: #fff;

    width: 200px;
    margin: 10px auto;
    padding: 10px 0;
    border-radius: 25px;
    overflow: hidden;
    cursor: pointer;

    div {
        transition: 0.25s;
        position: absolute;
        width: 300%;
        height: 100%;
        background: linear-gradient(
            90deg,
            ${props => props.theme.colors.PinkMed},
            ${props => props.theme.colors.DarkPink},
            ${props => props.theme.colors.PinkMed},
            ${props => props.theme.colors.DarkPink}
        );
    }

    p {
        z-index: 1;
        margin: 0;
    }

    &:hover {
        div {
            transform: translateX(-30%);
        }
    }
`;

const OrgsContainer = styled.div``;

const ChooseOrg = styled.div`
    display: flex;

    > input {
        cursor: pointer;
    }
`;
