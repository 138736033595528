import styled from "@emotion/styled";
import { SeriesFunctions } from "common/SeriesFunctions";
import { OrganisationIdentifier } from "common/types/objects/Organisation";
import { Writer } from "common/Writer";
import useReport from "hooks/reports/useReport";
import { monthItemByPriceDce } from "lib/apiDataHelpers/monthItemByPrice";
import { toMmmmYyyy } from "lib/date/reStringifyMonth";
import { OrgData } from "types/OrganisationType";

interface monthItemsProps {
    dataName: string;
    data: OrgData;
    organisation: OrganisationIdentifier;
}

const DescribeMonthItems = ({
    organisation,
    dataName,
    data,
}: monthItemsProps) => {
    const report = useReport();

    if (!report) return null;

    const { reportDates, items } = report;

    const filteredData = monthItemByPriceDce(data[reportDates.selectedMonth]);
    const filteredDataCompare = monthItemByPriceDce(
        data[reportDates.lastMonth],
    );
    const filteredDataSeccondaryCompare = monthItemByPriceDce(
        data[reportDates.sameMonthLastYear],
    );

    const changeLastMonth = SeriesFunctions.ComputeChangeAndSort(
        filteredData,
        filteredDataCompare,
        "desc",
    );

    const changeLastYear = SeriesFunctions.ComputeChangeAndSort(
        filteredData,
        filteredDataSeccondaryCompare,
        "desc",
    );

    //Count of sources
    const itemCountselectedMonth = filteredData ? filteredData.length : 0;

    return (
        <Container>
            <h1>
                {dataName === "revenue"
                    ? "Here's where your revenue came from so far in"
                    : "Here's what your expenses are from in"}{" "}
                {reportDates.selectedMonth.selectByMonths(
                    reportDates.selectedMonth.toTextMonth(),
                    reportDates.selectedMonth.toTextMonth(),
                    reportDates.selectedMonth.toMmmmYyyy(),
                )}
            </h1>
            {/* <InputDatePeriods /> */}
            <ul>
                <li>
                    <p className='indent'>
                        The chart shows how your {dataName} for{" "}
                        {organisation?.name} breaks down for{" "}
                        {toMmmmYyyy(reportDates.selectedMonth)}.
                    </p>
                </li>
                <li>
                    <p className='featured'>
                        You had {Writer.writeAsWord(itemCountselectedMonth)}{" "}
                        source{itemCountselectedMonth > 1 && "s"} of {dataName}{" "}
                        for the month.{" "}
                        {itemCountselectedMonth === 0
                            ? //Don't sho this para if no items
                              ""
                            : //There is at least one source
                              `Your largest ${dataName} category was "${
                                  items[filteredData[0][0]]?.name
                              }" with ${
                                  filteredData &&
                                  Writer.FormatCurrency(filteredData[0][1])
                              }
                        ${
                            itemCountselectedMonth === 1
                                ? ""
                                : `
                            , followed by "${
                                items[filteredData[1][0]]?.name
                            }" with ${
                                      filteredData &&
                                      Writer.FormatCurrency(filteredData[1][1])
                                  } for the month.
                          `
                        }
                          `}
                    </p>
                </li>

                <li>
                    {changeLastMonth.increases.length === 0 ? (
                        `There were no ${dataName} categories that increased over the last month. ${
                            changeLastMonth.sortedChanges.length === 0
                                ? ""
                                : `The smallest decrease was "${
                                      items[changeLastMonth.sortedChanges[0][0]]
                                          ?.name
                                  }", dropping ${Writer.FormatCurrency(
                                      Math.abs(
                                          changeLastMonth.sortedChanges[0][1],
                                      ),
                                  )}.`
                        }`
                    ) : (
                        <p className='featured'>
                            {`The largest increase in ${dataName} by category for ${toMmmmYyyy(
                                reportDates.selectedMonth,
                            )} was "${
                                items[changeLastMonth.sortedChanges[0][0]]?.name
                            }", up ${Writer.FormatCurrency(
                                changeLastMonth.sortedChanges[0][1],
                            )}. compared to ${toMmmmYyyy(
                                reportDates.lastMonth,
                            )}`}
                        </p>
                    )}
                </li>

                <li>
                    {changeLastYear.increases.length === 0 ? (
                        `On an annual basis, there were no ${dataName} categories that increased compared to ${toMmmmYyyy(
                            reportDates.sameMonthLastYear,
                        )}. ${
                            changeLastYear.sortedChanges.length === 0
                                ? ""
                                : `The smallest decrease was "${
                                      items[changeLastYear.sortedChanges[0][0]]
                                          ?.name
                                  }", dropping ${Writer.FormatCurrency(
                                      Math.abs(
                                          changeLastYear.sortedChanges[0][1],
                                      ),
                                  )} beteen ${toMmmmYyyy(
                                      reportDates.sameMonthLastYear,
                                  )} and ${toMmmmYyyy(
                                      reportDates.selectedMonth,
                                  )}.`
                        }`
                    ) : (
                        <p className='featured'>
                            {`On an annual basis, the largest increase in ${dataName} by category was "${
                                items[changeLastYear.sortedChanges[0][0]]?.name
                            }", up ${Writer.FormatCurrency(
                                changeLastYear.sortedChanges[0][1],
                            )} in ${toMmmmYyyy(
                                reportDates.selectedMonth,
                            )} compared to ${toMmmmYyyy(
                                reportDates.sameMonthLastYear,
                            )}`}
                        </p>
                    )}
                </li>
            </ul>
        </Container>
    );
};

export default DescribeMonthItems;

const Container = styled.div`
    font-size: 15px;
    line-height: 1.7;
    ul {
        margin-right: 75px;
    }
`;
