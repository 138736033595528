import MonthKey from "common/types/monthKey";

type Base = Record<MonthKey, any>;

/**
 * returns obj with only the months wanted using either the endPeriod or timeSpan
 * @param {obj} obj object to filter by months
 * @param {string} startPeriod starting month
 * @param {string} endPeriod ending month
 * @param {number} timeSpan number of months
 * @returns {obj} filtered object
 */
export const filterByMonths = <Type extends Base>({
    obj,
    latestMonth,
    earliestMonth,
    totalMonths,
}: {
    obj: Type;
    latestMonth: MonthKey;
    earliestMonth?: MonthKey;
    totalMonths?: number;
}): Type => {
    const useEarliestMonth =
        earliestMonth ||
        (totalMonths && latestMonth.addMonthsTo(-totalMonths + 1));
    if (useEarliestMonth && obj) {
        const data = Object.entries(obj).reduce((output, [month, value]) => {
            if (month <= latestMonth && month >= useEarliestMonth) {
                output[month as MonthKey] = value;
            }
            return output;
        }, {} as Base);
        return data as Type;
    }
    return obj || ({} as Type);
};
