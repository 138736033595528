import styled from "@emotion/styled";
import COLORS from "common/constants/COLORS";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import Switch from "components/general/Switch";
import BreakdownItemsList from "components/reports/shared/breakdownItemsList";
import useReport from "hooks/reports/useReport";
import { filterByMonths } from "lib/apiDataHelpers/filterByMonths";
import { monthItemByPriceDce } from "lib/apiDataHelpers/monthItemByPrice";
import { sumOfMonth } from "lib/apiDataHelpers/sumOfMonths";
import { tickMonthWrap } from "lib/charthelpers/tickMonthWrap";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { toMmmmYyyy, toMmmYy } from "lib/date/reStringifyMonth";
import { useState } from "react";
import {
    Bar,
    BarChart,
    CartesianAxis,
    Line,
    LineChart,
    ResponsiveContainer,
    Tooltip,
    XAxis,
    YAxis,
} from "recharts";
import { theme } from "style/theme";
import { OrgData } from "types/OrganisationType";

const ChartData = (
    selectedMonth: MonthKey,
    actual: OrgData,
    budget: OrgData,
    key: { color: string; name?: string },
) => {
    const data: Array<{ name: string; actual: number; budget: number }> = [];
    const { name } = key;
    const filtered = filterByMonths({
        obj: actual,
        latestMonth: selectedMonth,
        totalMonths: 12,
    });
    if (!name) {
        Object.entries(filtered).forEach(month =>
            data.unshift({
                name: month[0],
                actual: sumOfMonth(month[1] ?? {}),
                budget: sumOfMonth(budget[month[0] as MonthKey] ?? {}),
            }),
        );
    } else {
        Object.entries(filtered).forEach(month => {
            data.unshift({
                name: month[0],
                actual: month[1]?.[name] ?? 0,
                budget: budget[month[0] as MonthKey]?.[name] ?? 0,
            });
        });
    }
    return data;
};

const RevenueDetailIncomeBreakdown = () => {
    const [selectedCategory, setSelectedCategory] = useState<{
        index: number;
        category: string;
    }>();
    const [compareLastMonth, setCompareLastMonth] = useState(false);
    const report = useReport();

    const [key, setKey] = useState<{ color: string; name?: string }>({
        color: theme.colors.Yellow,
    });

    if (!report) {
        return null;
    }

    const {
        revenue: { actual, budget },
        items,
        reportDates: { selectedMonth },
    } = report;

    const compareDate = compareLastMonth
        ? addMonthsTo(selectedMonth, -1)
        : addMonthsTo(selectedMonth, -12);

    const displayCategory = selectedCategory?.category; // || latestBrekdown[0][0]

    const data = () => {
        const selectedMonthData = monthItemByPriceDce(
            actual[selectedMonth],
        ).reduce((output, [name, value]) => {
            output[items[name].name] = value;
            return output;
        }, {} as { [name: string]: number });
        const compareDateData = monthItemByPriceDce(actual[compareDate]).reduce(
            (output, [name, value]) => {
                output[items[name].name] = value;
                return output;
            },
            {} as { [name: string]: number },
        );

        return [
            {
                name: toMmmYy(selectedMonth),
                ...selectedMonthData,
            },
            {
                name: toMmmYy(compareDate),
                ...compareDateData,
            },
        ];
    };

    return (
        <Container>
            <h1>
                Here's how your revenue are broken down between{" "}
                {toMmmmYyyy(selectedMonth)} and {toMmmmYyyy(compareDate)}
            </h1>
            <div>
                <Column>
                    <div>
                        <SwitchMonths>
                            <SwitchWords>
                                <b>
                                    {compareLastMonth
                                        ? "last month"
                                        : "last 12 months"}
                                </b>
                            </SwitchWords>
                            <Switch
                                cbSwitch={setCompareLastMonth}
                                toggle={compareLastMonth}
                                width={39}
                                border={theme.colors.DarkBlue}
                                colorOption1={theme.colors.Pink}
                                colorOption2={theme.colors.DarkBlue}
                                borderWideth={1}
                            />
                        </SwitchMonths>
                        <ResponsiveContainer width='100%' height={200}>
                            <BarChart
                                barCategoryGap={20}
                                data={data()}
                                layout='vertical'
                                margin={{
                                    top: 5,
                                    right: 10,
                                    left: 0,
                                    bottom: 5,
                                }}>
                                <CartesianAxis axisLine={false} />
                                <Tooltip
                                    labelStyle={{
                                        color: theme.colors.DarkBlue,
                                    }}
                                    formatter={value =>
                                        Writer.FormatCurrency(value as number)
                                    }
                                />
                                <XAxis
                                    tickCount={10}
                                    type='number'
                                    style={{ fill: theme.colors.DarkBlue }}
                                    stroke={"none"}
                                    tickFormatter={(value: number) =>
                                        Writer.FormatCurrency(value)
                                    }
                                />
                                <YAxis
                                    width={70}
                                    type='category'
                                    dataKey='name'
                                    style={{ fill: theme.colors.DarkBlue }}
                                    stroke={"none"}
                                />
                                {Object.keys(data()[0]).map((key, index) => {
                                    if (key === "name") return null;
                                    return (
                                        <Bar
                                            key={index}
                                            dataKey={key}
                                            stackId='a'
                                            fill={
                                                COLORS.PIE_CHART[
                                                    (index - 1) %
                                                        COLORS.PIE_CHART.length
                                                ]
                                            }
                                        />
                                    );
                                })}
                            </BarChart>
                        </ResponsiveContainer>
                    </div>
                    <div>
                        <h1>
                            Here's a historical look at your {displayCategory}{" "}
                            revenue
                        </h1>
                        <ResponsiveContainer width='100%' height={200}>
                            <LineChart
                                margin={{
                                    right: 15,
                                }}
                                data={ChartData(
                                    selectedMonth,
                                    actual,
                                    budget,
                                    key,
                                )}>
                                <Tooltip
                                    labelStyle={{
                                        color: theme.colors.DarkBlue,
                                    }}
                                    labelFormatter={props => {
                                        return toMmmYy(props);
                                    }}
                                    formatter={value =>
                                        Writer.FormatCurrency(value as number)
                                    }
                                />
                                <XAxis
                                    interval={0}
                                    tick={tickMonthWrap}
                                    type='category'
                                    dataKey='name'
                                    style={{ fill: theme.colors.DarkBlue }}
                                    stroke={"none"}
                                />
                                <YAxis
                                    width={70}
                                    type='number'
                                    style={{ fill: theme.colors.DarkBlue }}
                                    stroke={"none"}
                                    tickFormatter={(value: number) =>
                                        Writer.FormatCurrency(value)
                                    }
                                />
                                <Line
                                    type='monotone'
                                    dataKey='actual'
                                    name='Actual'
                                    stroke={key.color}
                                    strokeWidth={2}
                                />
                                <Line
                                    type='monotone'
                                    dataKey='budget'
                                    name='Budget'
                                    stroke={theme.colors.DarkPink}
                                    strokeWidth={2}
                                />
                            </LineChart>
                        </ResponsiveContainer>
                    </div>
                    <ItemList>
                        <BreakdownItemsList
                            setKey={setKey}
                            actual={actual}
                            items={items}
                            compareMonth={compareDate}
                        />
                    </ItemList>
                </Column>
            </div>
        </Container>
    );
};

export default RevenueDetailIncomeBreakdown;
const Column = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    width: 100%;
    max-width: ${({ theme }) => theme.sizes.modelWidth};
    > div {
        margin-top: 60px;
        width: 100%;
        position: relative;
        margin: 20px 0;
        h1 {
            text-align: center;
            font-size: ${theme.fonts.defaultSubHeaderFontSize};
        }
    }
`;
const ItemList = styled.div`
    width: 100%;
    margin: 20px 0;
    padding: 20px;
    border: 2px solid ${theme.colors.DarkBlue};
    border-radius: 5px;
`;

const Container = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;

    > div {
        width: 100%;
        display: flex;
        justify-content: center;
    }
    h1 {
        font-size: ${theme.fonts.defaultHeaderFontSize};
    }
`;

const SwitchWords = styled.span`
    margin-right: 10px;
    font-size: 12px;
    letter-spacing: 3.2px;
`;

const SwitchMonths = styled.div`
    position: absolute;
    right: 35px;
    top: -30px;
    display: flex;
    align-items: center;
    height: 40px;

    color: ${({ theme }) => theme.colors.DarkBlue};
    text-transform: uppercase;
`;
