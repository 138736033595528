/**
 *  tag for rtk query that depends on a user
 */
export const USER_TAG = "user";

/**
 * tag for rtk query that depends on a organizations
 */
export const ORGANIZATION_TAG = "organization";

/**
 * tag for rtk query that is a report
 */
export const REPORT_TAG = "report";

/**
 * tag for rtk query that is a budget
 */
export const BUDGET_TAG = "budget";

/**
 * tag for rtk query that is a items
 */
export const ITEM_TAG = "item";

/**
 * tag for rtk query that is a transactions
 */
export const TRANSACTION_TAG = "transaction";

/**
 * tag for rtk query that is a customers
 */
export const CUSTOMER_TAG = "customer";

/**
 * tag for rtk query that is a transfers
 */
export const TRANSFER_TAG = "transfer";
