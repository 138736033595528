import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { ReactNode, useEffect, useState } from "react";
import { NavLink, useLocation } from "react-router-dom";

interface ReportsNavbarProps {
    children?: ReactNode;
    navBackgroundColor: string;
}

interface PageProps {
    live?: string;
    borderradius?: string;
    zindex?: number;
    border?: string;
    dontshown?: string;
}

interface Pages {
    revenue?: PageProps;
    expenses?: PageProps;
    cashflow?: PageProps;
    reports?: PageProps;
    customers?: PageProps;
}

const navChangeStyles = (path: string, cb: (Pages: Pages) => void) => {
    switch (path) {
        case "/reports/my-revenue":
            return cb({
                revenue: { live: "true" },
                expenses: {
                    zindex: 7,
                    borderradius: "0 15px 0 15px",
                    border: "3px solid #552583",
                    dontshown: "true",
                },
            });
        case "/reports/my-expenses":
            return cb({
                revenue: { zindex: 5, borderradius: "0 0 15px 0" },
                expenses: { live: "true" },
                cashflow: {
                    zindex: 5,
                    borderradius: "0 15px 0 15px",
                    border: "3px solid #552583",
                    dontshown: "true",
                },
            });
        case "/reports/my-profit":
            return cb({
                expenses: { zindex: 4, borderradius: "0 0 15px 0" },
                cashflow: { live: "true" },
                reports: {
                    zindex: 5,
                    borderradius: "0 15px 0 15px",
                    border: "3px solid #552583",
                    dontshown: "true",
                },
            });
        case "/reports/my-cashflow":
            return cb({
                expenses: { zindex: 4 },
                cashflow: { zindex: 3, borderradius: "0 0 15px 0" },
                reports: { live: "true" },
                customers: {
                    zindex: 5,
                    borderradius: "0 15px 0 15px",
                    border: "3px solid #552583",
                    dontshown: "true",
                },
            });
        case "/reports/my-customers":
            cb({
                reports: { zindex: 2, borderradius: "0 0 15px 0" },
                customers: { live: "true" },
            });
    }
};

const ReportsNavbar = ({
    children,
    navBackgroundColor,
}: ReportsNavbarProps) => {
    const [page, setPage] = useState<Pages>({});
    const location = useLocation();
    useEffect(() => {
        const path = location.pathname;
        navChangeStyles(path, setPage);
    }, [location]);
    return (
        <Nav backgroundColor={navBackgroundColor}>
            <Pages>
                <NaveLink1 {...page.revenue} to='/reports/my-revenue'>
                    My Revenue
                </NaveLink1>
                <NaveLink2 {...page.expenses} to='/reports/my-expenses'>
                    My Expenses
                </NaveLink2>
                <NaveLink3 {...page.cashflow} to='/reports/my-profit'>
                    My Profit
                </NaveLink3>
                <NaveLink4 {...page.reports} to='/reports/my-cashflow'>
                    My Cashflow
                </NaveLink4>
                <NaveLink5 {...page.customers} to='/reports/my-customers'>
                    My Customers
                </NaveLink5>
            </Pages>
            {/* <PageNav>{children}</PageNav> */}
        </Nav>
    );
};

export default ReportsNavbar;

const BaseNavLink = styled(NavLink)<PageProps>`
    margin-right: -3px;
    font-size: 24px;
    flex: 1;
    border: 3px solid #552583;
    border-left: 0;
    border-radius: 0 15px 0 0;
    background-color: ${props => props.theme.colors.Yellow};
    color: white;
    display: flex;
    justify-content: center;
    align-items: center;
    transition: padding 0.2s ease, margin 0.2s ease;
    height: 100%;
    &:hover {
        height: 110%;
    }
    &.active {
        /* background-color: transparent !important; */
        cursor: default;
    }
    &:before {
        background-color: ${props => props.theme.colors.Yellow};
        transform: translateX(-100%);
        margin-right: auto;
        width: 10px;
        height: 65px;
        content: "";
        border-top: 3px solid #552583;
    }
    &:after {
        margin-left: auto;
        content: "";
    }
    ${props =>
        props.live &&
        css`
            /* box-shadow: 8px 12px 25px 2px rgba(0, 0, 0, 0.4); */
            z-index: 5;
            height: 115% !important;
            border-radius: 15px 15px 0 0 !important;
            border: 0;
            border-top: 3px solid #552583;
            color: #552583;
            &:hover {
                padding: 0;
            }
            &:after {
                transform: translateY(-3px) !important;
                border-right: 3px solid #552583;
                border-radius: 0 15px 0 0;
                width: 15px;
                height: 80%;
                content: "";
                margin-left: auto;
                margin-bottom: auto;
            }
            &:before {
                transform: translateX(0) translateY(-3px) !important;
                border-left: 3px solid #552583;
                border-radius: 15px 0 0 0;
                height: 80% !important;
                width: 15px;
                content: "";
                margin-right: auto;
                margin-bottom: auto;
            }
        `}
    z-index: ${props => props.zindex} !important;
    border-radius: ${props => props.borderradius};
    border: ${props => props.border};
    border-left: ${props => props.border};
    &:before {
        opacity: ${props => (props.dontshown ? 0 : 1)};
    }
`;

const NaveLink1 = styled(BaseNavLink)<PageProps>`
    /* background-color: ${props => props.theme.colors.Yellow}; */
    z-index: 5;
    border-left: 3px solid #552583;
    &:before {
        opacity: 0;
    }
`;
const NaveLink2 = styled(BaseNavLink)<PageProps>`
    /* background-color: ${props => props.theme.colors.DarkBlue}; */
    z-index: 4;
    &:hover {
        &:before {
            height: 71.5px;
        }
    }
`;
const NaveLink3 = styled(BaseNavLink)<PageProps>`
    /* background-color: ${props => props.theme.colors.DarkPink}; */
    z-index: 3;
    &:before {
        z-index: 1 !important;
    }
    &:hover {
        &:before {
            height: 71.5px;
        }
    }
`;
const NaveLink4 = styled(BaseNavLink)<PageProps>`
    /* background-color: ${props => props.theme.colors.DarkAqua}; */
    z-index: 2;
    &:before {
        z-index: 1 !important;
    }
    &:hover {
        &:before {
            height: 71.5px;
        }
    }
`;
const NaveLink5 = styled(BaseNavLink)<PageProps>`
    /* background-color: ${props => props.theme.colors.DarkAqua}; */
    z-index: 1;
    margin-right: 0;
    border-right: 3px solid #552583;
    &:after {
        opacity: 0;
    }
    &:hover {
        &:before {
            height: 71.5px;
        }
    }
`;

const Nav = styled.nav<{ backgroundColor: string }>`
    a,
    a:hover {
        text-decoration: none;
    }

    display: flex;
    flex-direction: column;
    padding: 0;
    margin: 0;
`;

const Pages = styled.div`
    position: relative;
    background-color: ${props => props.theme.colors.Yellow};
    /* background-color: ${props => props.theme.colors.Pink}; */
    height: 65px;
    display: flex;
    align-items: flex-end;
    /* @media (max-width: ${props => props.theme.sizes.contentWidthPhone}) {
        flex-direction: column;
    } */
`;

const PageNav = styled.nav`
    grid-column: 1/5;
    display: flex;
    flex-direction: row;
    justify-content: space-evenly;
    padding: 8px 0;
    text-transform: uppercase;
    min-height: 32px;

    @media (max-width: ${props => props.theme.sizes.contentWidthPhone}) {
        display: none;
    }
`;
