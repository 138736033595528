import styled from "@emotion/styled";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { useSyncDataMutation } from "redux/rtkQuery/organisations";

const SyncAccount = () => {
    const currentOrganisation = useCurrentOrg();
    const [syncData] = useSyncDataMutation({
        fixedCacheKey: "syncData",
    });

    if (!currentOrganisation) return null;

    return (
        <Container>
            Sync your account data with your provider data.
            <Sync
                onClick={() =>
                    syncData({
                        organisation: currentOrganisation,
                        years: 1,
                    })
                }>
                Sync Account Data
            </Sync>
        </Container>
    );
};

export default SyncAccount;
const Container = styled.div`
    display: flex;
    flex-direction: column;
    width: 200px;
`;

const Sync = styled.button`
    margin: 5px 0;
    background-color: #4ac2e0;
    color: white;
    border-radius: 5px;
    &:hover {
        background-color: #4ac2e0;
        color: black;
    }
`;
