import { createApi } from "@reduxjs/toolkit/dist/query/react";
import {
    BUDGET_TAG,
    CUSTOMER_TAG,
    ITEM_TAG,
    ORGANIZATION_TAG,
    REPORT_TAG,
    TRANSACTION_TAG,
    TRANSFER_TAG,
    USER_TAG,
} from "common/constants/TAGS";
import { axiosBaseQuery } from "redux/rtk_base_query";

export const api = createApi({
    baseQuery: axiosBaseQuery(),
    tagTypes: [
        ORGANIZATION_TAG,
        USER_TAG,
        BUDGET_TAG,
        ITEM_TAG,
        REPORT_TAG,
        TRANSACTION_TAG,
        CUSTOMER_TAG,
        TRANSFER_TAG,
    ],
    reducerPath: "api",
    endpoints: builder => ({}),
});
