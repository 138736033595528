import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import ItemSection from "components/general/itemSection/ItemSection";
import SpeedoGraph from "components/recharts/test/SpeedoGraph";
import useReport from "hooks/reports/useReport";
import { totalForMonths } from "lib/apiDataHelpers/sumOfMonths";
import { monthsElapsed } from "lib/date/reStringifyMonth";
import { useState } from "react";

import Bubble, { bubbleDark, bubbleLight } from "../../Bubble";

const AverageMonthlyRnEBudet = ({
    monthStart,
    monthEnd,
}: {
    monthStart?: MonthKey;
    monthEnd?: MonthKey;
}) => {
    const [alternate, setAlternate] = useState(false);
    const report = useReport();

    const numberOfMonths =
        monthEnd && monthStart && monthsElapsed(monthStart, monthEnd) + 1;

    const revenueAvg =
        report &&
        monthEnd &&
        monthStart &&
        numberOfMonths &&
        totalForMonths({
            latestMonth: monthStart,
            earliestMonth: monthEnd,
            org: report.revenue.budget,
        }) / numberOfMonths;

    const expensesAvg =
        report &&
        monthEnd &&
        monthStart &&
        numberOfMonths &&
        totalForMonths({
            latestMonth: monthStart,
            earliestMonth: monthEnd,
            org: report.expenses.budget,
        }) / numberOfMonths;

    return (
        <ItemSection column light>
            <Header>
                <Title>
                    Here's the average amounts you've budgeted per month for the
                    time period you've selected:
                </Title>
            </Header>
            <Container>
                <Bubble style={bubbleLight}>
                    <h3>Your Average Monthly Revenue budget is:</h3>
                    <mark>
                        {revenueAvg && Writer.FormatCurrency(revenueAvg)}
                    </mark>
                </Bubble>
                <Bubble style={bubbleDark}>
                    <h3>Your Average Monthly Expenses budget are:</h3>
                    <mark>
                        {expensesAvg && Writer.FormatCurrency(expensesAvg)}
                    </mark>
                </Bubble>
                <Graph>
                    <SpeedoGraph
                        chartValue={Math.round(
                            ((revenueAvg || 0) / (expensesAvg || 0)) * 100,
                        )}
                        chartMax={200}
                    />
                    <span>
                        On average you are budgeting to earn{" "}
                        {revenueAvg &&
                            expensesAvg &&
                            Writer.FormatCurrency(
                                Math.abs(revenueAvg - expensesAvg),
                            )}{" "}
                        {revenueAvg &&
                        expensesAvg &&
                        revenueAvg - expensesAvg > 0
                            ? "more"
                            : "less"}{" "}
                        than you are spending each month
                    </span>
                </Graph>
            </Container>
        </ItemSection>
    );
};

export default AverageMonthlyRnEBudet;

const Container = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-evenly;
`;
const Header = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding-right: 20px;
`;
const Title = styled.h1`
    margin: 0;
`;

const SwitchContainer = styled.div`
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-direction: row;
    width: 160px;
    font-size: 15px;
`;

const Graph = styled.div`
    text-align: center;
    width: 250px;
`;
