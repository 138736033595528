import { SortOrder } from "./types/data/SortOrder";

export class SeriesFunctions {
    static ComputeChangeAndSort = (
        currentSeries: Array<[string, number]>,
        baseSeries: Array<[string, number]>,
        sort?: SortOrder,
    ): {
        sortedChanges: Array<[string, number]>;
        increases: Array<[string, number]>;
        decreases: Array<[string, number]>;
    } => {
        const rtn: Array<[string, number]> = currentSeries.map(row => [
            row[0],
            row[1] - (baseSeries.find(r => r[0] === row[0])?.[1] ?? 0),
        ]);
        sort &&
            rtn.sort((a, b) => (sort === "asc" ? a[1] - b[1] : b[1] - a[1]));
        return {
            sortedChanges: rtn,
            increases: rtn.filter(r => r[1] > 0),
            decreases: rtn.filter(r => r[1] < 0),
        };
    };
}
