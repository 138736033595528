import { USER_TAG } from "common/constants/TAGS";

import { api } from "./api";

const notificationSlice = api.injectEndpoints({
    endpoints: builder => ({
        getNotificationSettings: builder.query<any, void>({
            query: () => ({
                url: "notification/subscribed-to",
            }),

            providesTags: [USER_TAG],
        }),
    }),
});

export const { useGetNotificationSettingsQuery } = notificationSlice;
