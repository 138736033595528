import styled from "@emotion/styled";
import {
    BudgetSources,
    OrganisationTypes,
} from "common/types/objects/Organisation";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { useState } from "react";
import { useSetOrganisationBudgetMutation } from "redux/rtkQuery/organisations";
import { useAppDispatch } from "redux/store";

interface Props {
    changePage: React.Dispatch<React.SetStateAction<number>>;
    index: number;
}

const ChooseBudget = ({ changePage, index }: Props) => {
    const dispatch = useAppDispatch();
    const organisation = useCurrentOrg();
    const [updateBudgetSource] = useSetOrganisationBudgetMutation();

    const budgetSource =
        organisation?.budgetSource === BudgetSources.hellocashflow;

    const budgetSourceChange = budgetSource ? 0 : 1;
    const subchange =
        organisation?.organisationType === OrganisationTypes.subscription
            ? 0
            : 1;
    const change =
        1 + (!budgetSourceChange ? 0 : budgetSourceChange + subchange);

    return (
        <Container>
            <Menu>
                <Back onClick={() => changePage(index - 1)}>Back</Back>
                <h1>Budgeting for your Business </h1>
                <Continue
                    onClick={() => {
                        changePage(index + change);
                    }}>
                    Continue
                </Continue>
            </Menu>

            <Pages>
                <p style={{ textAlign: "center" }}>
                    Would you like to use Hellocashflow’s budget editor or
                    Xero’s?
                </p>
                <Page>
                    <Option>
                        <div>
                            <Input
                                type='radio'
                                name='budgetSource'
                                checked={budgetSource}
                                onChange={e =>
                                    updateBudgetSource({
                                        organisationId:
                                            organisation?.organisationId,
                                        type: BudgetSources.hellocashflow,
                                    })
                                }
                            />
                            <span>
                                Hello Cashflow{" "}
                                <span
                                    style={{
                                        fontSize: 10,
                                        backgroundColor: "burlywood",
                                        textTransform: "uppercase",
                                        fontWeight: "bold",
                                    }}>
                                    --recommended--
                                </span>
                            </span>
                        </div>
                        <p>
                            Hello Cashflow supercharges your budgeting, letting
                            you project things other budget editors haven't
                            figured out! It's easy to use - you can even just
                            automate your budget based on your past numbers if
                            you like!
                        </p>
                    </Option>
                    <Option>
                        <div>
                            <Input
                                type='radio'
                                name='budgetSource'
                                checked={!budgetSource}
                                onChange={e =>
                                    updateBudgetSource({
                                        organisationId:
                                            organisation?.organisationId,
                                        type: BudgetSources.xero,
                                    })
                                }
                            />
                            <span>Xero</span>
                        </div>
                        <p>
                            This option means that the HelloCashflow app will
                            use your Xero budget when showing data.
                        </p>
                    </Option>
                </Page>
            </Pages>
            <img
                src='/assets/horizental version.png'
                alt='HelloCashflow'
                height={50}
                width={160}
                style={{
                    marginRight: "auto",
                    marginBottom: 20,
                    marginLeft: 20,
                }}
            />
        </Container>
    );
};

export default ChooseBudget;

const Pages = styled.div`
    display: flex;
    flex-direction: column;
    width: 400px;
    height: 100%;
    padding-top: 15%;
`;
const Page = styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
`;

const Input = styled.input`
    cursor: pointer;
    margin: 0;
`;
const Option = styled.div`
    margin: 10px;
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: space-evenly;
    div {
        display: flex;
        align-items: center;
    }
    span {
        margin: 0;
        margin-left: 5px;
    }

    p {
        margin: 0;
        margin-left: 20px;
        font-size: 12px;
    }
`;
const Menu = styled.div`
    width: 100%;
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-top: 10px;
    padding: 0 10px;
    h1 {
        margin: 0;
    }
`;

const Continue = styled.button`
    padding: 10px;
    border-radius: 15px;
    width: 150px;
    height: 35px;
`;

const Back = styled.button`
    padding: 10px;
    height: 35px;
    border-radius: 15px;
    width: 150px;
    background-color: #636363;
    &:hover {
        background-color: #444444;
        color: white;
    }
`;
const Container = styled.div`
    position: relative;
    height: 100%;
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    p {
    }
`;

interface MOREINFO {
    children: React.ReactNode;
    title: string;
}

const MoreInfo = ({ children, title }: MOREINFO) => {
    const [show, setShow] = useState(false);

    return (
        <MoreContainer>
            <br />
            <HLine />
            <Title onClick={() => setShow(p => !p)}>
                <p>{title}</p>
                <span>+</span>
            </Title>
            {show && (
                <MoreContent>
                    <VLine />
                    <p>{children}</p>
                </MoreContent>
            )}
            <HLine />
        </MoreContainer>
    );
};

const MoreContainer = styled.div`
    width: 400px;
`;

const MoreContent = styled.div`
    padding: 0 14px;
    display: flex;
    width: 100%;
    margin: 4px 0;
    p {
        margin: 6px 15px;
    }
`;

const Title = styled.div`
    font-size: 20px;
    padding: 0 14px;
    display: flex;
    justify-content: space-between;
    p {
        margin: 0;
    }
    span {
    }
`;

const HLine = styled.div`
    border: 1px solid #868686;
    height: 1px;
    width: 100%;
`;
const VLine = styled.div`
    border: 1px solid #868686;
    width: 1px;
`;
