import styled from "@emotion/styled";
import { HttpClient } from "adapters/HttpClient";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { latestCompleteMonth } from "lib/date/dateConst";
import { useEffect, useState } from "react";
import { OrgData } from "types/OrganisationType";

import ChooseBudget from "./Pages/ChooseBudget";
import ChooseOrgType from "./Pages/ChooseOrgType";
import EditBudget from "./Pages/EditBudget";
import EditCashflow from "./Pages/EditCashflow";
import EditCustomers from "./Pages/EditCustomers";
import Intro from "./Pages/Intro";

const dontShowFirstTimeSetupOnRoutes = [
    /^\/account/,
    /^\/onboarding/,
    /^\/xero-signup/,
];

const FirstTimeLogin = () => {
    const firstTime = useCurrentOrg()?.firstTime;

    //show if first time and dosent match the routes defined above
    const showFirstTimeSetup =
        firstTime &&
        !dontShowFirstTimeSetupOnRoutes.some(e =>
            e.test(window.location.pathname),
        );
    console.log({ showFirstTimeSetup, firstTime });

    const [page, setPage] = useState(0);
    const [budget, setBudget] = useState<{
        revenue: OrgData;
        expenses: OrgData;
    }>();
    const currentOrganisation = useCurrentOrg();

    useEffect(() => {
        if (showFirstTimeSetup && currentOrganisation && !budget) {
            //Get their providers budget for a starting point in the editor
            const { organisationId } = currentOrganisation;
            HttpClient.get("/organisations/monthlyByType", undefined, {
                organisationId,
                startMonth: latestCompleteMonth,
                endMonth: addMonthsTo(latestCompleteMonth, 12),
                dataType: 2, //Budget
                itemType: [3, 5], //revenue and expenses
            }).then(e => setBudget(e.data));
        }
    }, [budget, currentOrganisation, showFirstTimeSetup]);

    useEffect(() => {
        if (!showFirstTimeSetup) return;
        document.body.style.overflow = "hidden";
        return () => {
            document.body.style.overflow = "auto";
        };
    });

    const Pages = showFirstTimeSetup && [
        Intro,
        ChooseOrgType,
        ChooseBudget,
        EditBudget,
        EditCustomers,
        EditCashflow,
    ];

    return showFirstTimeSetup && Pages ? (
        <Container>
            <Content>
                {Pages.map((E, index) =>
                    page === index ? (
                        <E
                            key={index}
                            index={index}
                            changePage={setPage}
                            xeroBudget={budget}
                        />
                    ) : null,
                )}
            </Content>
        </Container>
    ) : null;
};

export default FirstTimeLogin;

const Content = styled.div`
    background-color: ${props => props.theme.colors.Pink};
    width: 1200px;
    max-height: 700px;
    height: 100vh;
`;

const Container = styled.div`
    display: flex;
    justify-content: center;
    align-items: center;
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: white;
    z-index: 9;
`;
