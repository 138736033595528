import styled from "@emotion/styled";
import { HttpClient } from "adapters/HttpClient";
import PageNotFound from "components/general/404Stuff/PageNotFound";
import useAuthState from "hooks/firebase/useAuthState";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { latestCompleteMonth } from "lib/date/dateConst";
import MyCustomersPage from "pages/reports/MyCustomers";
import { Route, Routes, useNavigate } from "react-router";
import { setSelectedMonth } from "redux/slices/DateSlice";
import { useAppDispatch, useAppSelector } from "redux/store";

import ExpensesPage from "../../pages/reports/ExpensesPage";
import CashflowPage from "../../pages/reports/MyCashflow";
import ProfitPage from "../../pages/reports/MyProfit";
import RevenuePage from "../../pages/reports/RevenuePage";

const ReportsRoutes = () => {
    const dispatch = useAppDispatch();
    const selectedMonth = useAppSelector(state => state.dates?.selectedMonth);
    const [user] = useAuthState();
    const currentOrg = useCurrentOrg();
    const navigate = useNavigate();
    const isDemo =
        useCurrentOrg()?.organisationId ==
        "333ae1a4-90e2-4314-8242-e16051302f6f";

    return (
        <Container>
            {isDemo && !window.location.pathname.includes("model") && (
                <DemoBanner>
                    <h1>This is a Demo Company</h1>

                    <span>
                        To connect your Xero account and get started on your
                        journey:
                    </span>

                    <AdvanceButton>
                        {user?.displayName === "demo" ? (
                            <button>Disabled for demo account</button>
                        ) : (
                            <button onClick={handleXero}>CLICK HERE</button>
                        )}
                    </AdvanceButton>
                </DemoBanner>
            )}

            {selectedMonth && selectedMonth < latestCompleteMonth && (
                <OutdatedMonth>
                    <p>
                        You are looking at historical data. To look at the most
                        recent data{" "}
                        <span
                            onClick={() =>
                                currentOrg &&
                                dispatch(
                                    setSelectedMonth([
                                        latestCompleteMonth,
                                        currentOrg,
                                    ]),
                                )
                            }>
                            CLICK HERE
                        </span>
                    </p>
                </OutdatedMonth>
            )}

            {selectedMonth && selectedMonth > latestCompleteMonth && (
                <OutdatedMonth>
                    <p>
                        You are looking at this months data - if the data looks
                        odd, remember that this month is not over yet - and
                        still needs to be reconciled in Xero!
                    </p>
                </OutdatedMonth>
            )}

            <Routes>
                <Route path='/my-revenue/*' element={<RevenuePage />} />
                <Route path='/my-expenses/*' element={<ExpensesPage />} />
                <Route path='/my-cashflow/*' element={<CashflowPage />} />
                <Route path='/my-profit/*' element={<ProfitPage />} />
                <Route path='/my-customers/*' element={<MyCustomersPage />} />
                <Route path='*' element={<PageNotFound />} />
            </Routes>
        </Container>
    );
};
export default ReportsRoutes;
const handleXero = (e: React.MouseEvent<HTMLButtonElement, MouseEvent>) => {
    e.currentTarget.disabled = true; //prevent multiple clicks
    HttpClient.get<string>("xero/loginurl")
        .then(url => {
            window.location.href = url;
        })
        .catch(err => alert(err.message));
};

const OutdatedMonth = styled.div`
    width: 100%;
    height: 45px;
    background: ${props => props.theme.colors.Pink};
    border-bottom: 2px solid ${props => props.theme.colors.DarkBlue};

    display: flex;
    justify-content: center;
    align-items: center;

    p {
        font-weight: 600;
        margin: 0;
    }

    span {
        color: ${props => props.theme.colors.LightBlue};
        cursor: pointer;

        &:hover {
            text-decoration: underline;
        }
    }
`;

const Container = styled.div`
    position: relative;
    width: 100%;
    height: 100%;
`;

const AdvanceButton = styled.div`
    button {
        margin: 0;
        position: relative;
        text-decoration: none;
        color: ${props => props.theme.colors.Pink};

        &:hover {
            background: none;

            &::after {
                left: 16px;
                width: calc(100%);
                background: ${props => props.theme.colors.Pink};

                top: 26px;
                left: 0;
                content: "";
                height: 3px;
                position: absolute;
                color: ${props => props.theme.colors.Pink};
            }
        }
    }

    a {
        position: relative;
        text-decoration: none;
        color: ${props => props.theme.colors.Pink};

        &:hover {
            &::after {
                left: 16px;
                width: calc(100%);
                background: ${props => props.theme.colors.Pink};

                top: 32px;
                left: 0;
                content: "";
                height: 3px;
                position: absolute;
                color: ${props => props.theme.colors.Pink};
            }
        }
    }
`;

const DemoBanner = styled.div`
    width: 100%;
    z-index: 9999;
    height: 140px;
    position: sticky;
    top: 0px;
    background-color: ${props => props.theme.colors.DarkerPink};
    border: 1px solid ${props => props.theme.colors.Pink};
    color: ${props => props.theme.colors.Pink};
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 32px;
    > h1 {
        font-size: 32px;
        margin: 0;
    }
`;
