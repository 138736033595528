import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import useModal from "components/general/CoverPage/Modal/useModal";
import useUpdator from "hooks/useUpdator";
import {
    generateMonthsKeysForwardFromMonth,
    latestCompleteMonth,
} from "lib/date/dateConst";
import { toMmmYy } from "lib/date/reStringifyMonth";
import { useEffect, useState } from "react";
import { useUpdateTransfersMutation } from "redux/rtkQuery/transfers";
import { setTransfers } from "redux/slices/TransfersSlice";
import { useAppDispatch, useAppSelector } from "redux/store";

import { InputEdit } from "../BudgetEditor";
import FillCells from "../FillCells";
const transfersItems = ["drawings", "assets", "loanPayments"];
const TransfersEditor = () => {
    const { Modal, show, setClose, setOpen } = useModal();
    const HandleOpen = (data: {
        month: MonthKey;
        item: string;
        baseValue?: number;
    }) => {
        setData(data);
        setOpen();
    };

    const [data, setData] = useState<{
        month: MonthKey;
        item: string;
        baseValue?: number;
    }>();

    const transfers = useAppSelector(
        state => state.transfers.present.transfers,
    );
    const dispatch = useAppDispatch();

    const [codeNow, setShow] = useState("");

    const [mutateTransfers] = useUpdateTransfersMutation();

    useUpdator(
        state => state.transfers.present.transfers,
        (data, organisationId) => {
            mutateTransfers([organisationId, data]);
        },
    );

    /**
     * Helper function to navigate through the items.
     * @param {object} currentFocused - The currently focused element's dataset.
     * @param {Array} items - An array of items for navigation (revenueItems or expensesItems).
     * @param {string} type - The type of item ("revenue" or "expenses").
     */
    const navigate = (currentFocused: any, items: any[], type: string) => {
        // Find the index of the current focused element in the items array
        const oldIndex = items.findIndex(x => x === currentFocused.code) + 1;

        // If there is a next element in
        if (oldIndex < items.length) {
            const code = items[oldIndex];
            // Find the next element using the type, month, and code
            const element: any = document.querySelector(
                `[data-type="${type}"][data-month="${currentFocused.month}"][data-code="${code}"]`,
            );

            // Focus the next element if it exists
            if (element) {
                element.focus();
            }
        }
    };

    useEffect(() => {
        const handleKeypress = (event: KeyboardEvent) => {
            if (event.key === "Enter") {
                const currentFocused = (document.activeElement as any).dataset;
                if (currentFocused && transfersItems) {
                    if (currentFocused.type === "transfers") {
                        // Navigate through revenue items
                        navigate(currentFocused, transfersItems, "transfers");
                    }
                }
            }
            if (event.ctrlKey) {
                if (event.key === "z") {
                    dispatch({
                        type: "transferUndo",
                    });
                }
                if (event.key === "y") {
                    dispatch({
                        type: "transferRedo",
                    });
                }
            }
        };
        window.addEventListener("keydown", handleKeypress);
        return () => {
            window.removeEventListener("keydown", handleKeypress);
        };
    }, [dispatch]);

    const months = generateMonthsKeysForwardFromMonth(latestCompleteMonth, 12);

    const transferDat =
        transfers &&
        transfersItems.map(item => {
            return (
                <BudgetingContainer key={item}>
                    {months.map((month, index) => (
                        <div
                            onMouseEnter={() => setShow(`${item} ${month}`)}
                            onMouseLeave={() => setShow("")}
                            key={index}
                            style={{
                                position: "relative",
                                backgroundColor: "white",
                            }}>
                            <InputEdit
                                data-type='transfers'
                                data-month={month}
                                data-code={item}
                                onChange={event => {
                                    if (
                                        parseInt(event.target.value) ||
                                        event.target.value === ""
                                    ) {
                                        dispatch(
                                            setTransfers({
                                                month,
                                                item,
                                                input: +event.target.value,
                                            }),
                                        );
                                    }
                                }}
                                type='number'
                                value={transfers?.[month]?.[item] ?? ""}
                            />
                            {codeNow === `${item} ${month}` && (
                                <Arrow
                                    onClick={() =>
                                        HandleOpen({
                                            month,
                                            item,
                                            baseValue:
                                                +transfers?.[month]?.[item] ??
                                                undefined,
                                        })
                                    }>
                                    <svg
                                        version='1.1'
                                        xmlns='http://www.w3.org/2000/svg'
                                        width='20'
                                        height='10'
                                        viewBox='-0.709 -0.235 213 71'>
                                        <polygon points='0,26.488 0,44.144 167.747,44.144 167.747,70.631 211.89,35.316 167.747,0 167.747,26.488 ' />
                                    </svg>
                                </Arrow>
                            )}
                        </div>
                    ))}
                </BudgetingContainer>
            );
        });

    /**
     * Create a JSX element for a month with a formatted date string.
     * @param {Array} months - An array of months.
     */
    const createMonthElements = (months: MonthKey[]) =>
        months.map((month, index) => (
            <div key={index}>
                <h3>{toMmmYy(month)}</h3>
            </div>
        ));

    const showMonths = createMonthElements(months);

    return (
        <Editor>
            <Modal>
                {data && (
                    <FillCells
                        close={setClose}
                        data={{ type: "cashflow", ...data }}
                    />
                )}
            </Modal>
            <EditorNames>
                <div style={{ height: 35 }}>
                    <h3>Transfers</h3>
                </div>
                {transfersItems &&
                    transfersItems.map((item, index) => {
                        return (
                            <div
                                style={{ textTransform: "capitalize" }}
                                key={index}>
                                {item === "loanPayments"
                                    ? "loan Payments"
                                    : item}
                            </div>
                        );
                    })}
            </EditorNames>
            <EditorInputs>
                <Months>{showMonths}</Months>

                {transferDat}
            </EditorInputs>
        </Editor>
    );
};

export default TransfersEditor;

const Editor = styled.div`
    display: flex;
    padding: 15px;
    width: 100%;
    height: 100%;
`;

const EditorNames = styled.div`
    margin-left: 24px;
    div {
        border: 1px solid #686868;
        padding: 4px;
        height: 30px;
        white-space: nowrap;
        h3 {
            margin: 5px 0;
        }
    }
`;
const EditorInputs = styled.div`
    display: flex;
    flex-direction: column;
    height: max-content;
    width: 100%;
    overflow-x: auto;
    overflow-y: hidden;
`;

const BudgetingContainer = styled.div`
    display: flex;
    text-align: center;
    width: 100%;
    > div {
        border: 1px solid #686868;
        /* padding: 4px; */
        height: 30px;
        h3 {
            margin: 5px 0;
        }
        span {
            display: flex;
            justify-content: center;
            align-items: center;
            height: 100%;
            width: 100%;
        }
    }
`;

const Arrow = styled.div`
    position: absolute;
    z-index: 10;
    right: -10px;
    top: 0px;
    height: 30px;
    display: flex;
    justify-content: center;
    align-items: center;
    cursor: pointer;
`;
const Months = styled.div`
    display: flex;
    width: 100%;

    div {
        background-color: ${props => props.theme.colors.Pink};
        height: 35px;
        width: 120px;
        display: flex;
        justify-content: center;
        align-items: center;
        border: 1px solid #686868;
    }
`;
