import styled from "@emotion/styled";
import image from "assets/img/backgrounds/home_stretched.png";
import LogoHorizontal from "components/brand/LogoHorizontal";

interface ContainerProps {
    children: React.ReactNode;
}

export const Container = ({ children }: ContainerProps) => {
    return (
        <Base>
            <Logo>
                <LogoHorizontal />
            </Logo>
            {children}
        </Base>
    );
};

const Logo = styled.div`
    position: absolute;
    top: 20px;
    left: 20px;
`;

export const Base = styled.div`
    background-color: ${({ theme }) => theme.colors.Pink};
    background-image: url(${image});
    background-size: 100%;
    background-repeat: round;
    height: 800px;
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: center;
    position: relative;
    padding: 20px;
`;
