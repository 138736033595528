import styled from "@emotion/styled";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import DropSelector from "components/general/dropselctor";
import MonthDataEditor, {
    MonthDataEditorProps,
} from "components/ui/MonthDataEditor";
import { format } from "date-fns";
import useUpdator from "hooks/useUpdator";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { useUpdateCustomersMutation } from "redux/rtkQuery/customers";
import {
    customerOptions,
    decrementCustomer,
    incrementCustomer,
    setCustomerName,
    setCustomers,
} from "redux/slices/CustomersSlice";
import { useAppDispatch, useAppSelector } from "redux/store";

//LEt's anchor the editor on the current month rather than the selected reporting month for now
const editorAnchorMonth = format(new Date(), "yyyy-MM") as MonthKey;

const EditCustomerInput = ({ organisationId }: { organisationId: string }) => {
    const dispatch = useAppDispatch();
    const customerName = useAppSelector(
        state => state.customers.present.customerName,
    );
    const [mutateCustomers] = useUpdateCustomersMutation();

    useUpdator(
        state => state.customers.present.customers,
        (data, organisationId) => {
            mutateCustomers({organisationId, data});
        },
    );

    const customerItems = {
        target: `Target number of ${customerName}`,
        actual: `Actual number of ${customerName}`,
        cancellations: `Cancellations this month`,
    };

    const editorSettings: MonthDataEditorProps = {
        items: customerItems, //[customerItems.target, customerItems.actual, customerItems.new],
        enableFillHelper: ["target"],
        forward: 12,
        back: 12,
        id: `customer_inputs_${organisationId}`, //Use the organistion Id to ensure this instance is unique to the organisation
        startMonth: editorAnchorMonth, //We will show this month plus 12 months in either direction (see forward and back above)
        setValue: (month: MonthKey, item: string, input: number) => {
            dispatch(setCustomers({ month, item, input }));
        },
        modifyValue: (month: MonthKey, item: string, change: "+" | "-") => {
            if (change === "+") {
                dispatch(incrementCustomer({ month, item }));
            } else {
                dispatch(decrementCustomer({ month, item }));
            }
        },
        reduxPath: state => state.customers.present.customers,
        /*
      This helper lets us set custom content for the last row(s) in the editor,
          We are going to show:
              Row 1: Implied total change for the month
              Row 2: Implied new customers gained, calculated from other inputs
          Note that Row 1 and 2 will be the same if there are no cancellations for the month.
      */
        summaryRowLables: [
            `Total monthy change, ${customerName}`,
            `New ${customerName} gained`,
        ],
        summaryRowHelper: (month, currentData) => {
            if (month > editorAnchorMonth) return ["", ""]; //Don't show for future months
            const lastMonth = addMonthsTo(month, -1);
            const lastMonthActual =
                (currentData[lastMonth] && currentData[lastMonth].actual) || 0;
            const thisMonthActual =
                (currentData[month] && currentData[month].actual) || 0;
            const thisMonthCancellations =
                (currentData[month] && currentData[month].cancellations) || 0;
            //Calculate the change from last month to this month
            const totalChange = thisMonthActual - lastMonthActual;
            const newCustomersThisMonth =
                totalChange +
                //Add cancellations if applicable (add because cancellations lower total customers so customer numbers so imply thre were more new customers, or fewer losses, than totals show)
                thisMonthCancellations;
            return [
                Writer.FormatNumber(totalChange),
                Writer.FormatNumber(newCustomersThisMonth),
            ];
        },
    };

    return (
        <>
            <ChooseType>
                <h2>I refer to my customers as:</h2>
                <Menu>
                    <DropSelector
                        options={customerOptions}
                        selected={customerName}
                        onChange={value => dispatch(setCustomerName(value))}
                    />
                </Menu>
            </ChooseType>
            <MonthDataEditor {...editorSettings} />
        </>
    );
};

export default EditCustomerInput;

const ChooseType = styled.div`
    display: flex;
    align-items: center;
`;

const Select = styled.div`
    position: relative;
    box-sizing: border-box;
`;
const SelectOptions = styled.div`
    z-index: 1;
    position: absolute;
    top: 38px;
    width: 100%;
    display: none;
    flex-direction: column;
    justify-content: center;
    overflow: hidden;
    border: 2px solid ${props => props.theme.colors.DarkBlue};
    background-color: white;

    animation: fadeInAnimation ease-in-out 0.3s;
    animation-iteration-count: 1;
    animation-fill-mode: forwards;

    @keyframes fadeInAnimation {
        0% {
            opacity: 0;
        }
        100% {
            opacity: 1;
        }
    }
    button {
        width: 100%;
        height: 40px;
        border: none;
        border-bottom: 1.3px solid grey;
        background: white;
        color: ${props => props.theme.colors.DarkPink};
        &:hover {
            background: rgba(0, 0, 0, 0.185);
            color: white;
        }
    }
`;
const Menu = styled.div`
    width: 140px;
    height: 40px;
    margin-left: 10px;
    box-sizing: border-box;
`;

const SelectDown = styled.div`
    -moz-box-align: center;
    align-items: center;
    align-self: stretch;
    display: flex;
    flex-shrink: 0;
    box-sizing: border-box;

    span {
        align-self: stretch;
        background-color: ${props => props.theme.colors.DarkBlue};
        margin-bottom: 8px;
        margin-top: 8px;
        width: 1px;
        box-sizing: border-box;
    }
    div {
        color: ${props => props.theme.colors.DarkBlue};
        display: flex;
        padding: 8px;
        transition: color 150ms ease 0s;
        box-sizing: border-box;
    }
`;
const SelectInput = styled.div`
    -moz-box-align: center;
    align-items: center;
    display: grid;
    flex: 1 1 0%;
    flex-wrap: wrap;
    padding: 2px 8px;
    position: relative;
    overflow: hidden;
    box-sizing: border-box;
    text-align: center;
`;
const SelectTitle = styled.div`
    font-size: 14px;
    -moz-box-align: center;
    align-items: center;
    background-color: ${props => props.theme.colors.Pink};
    border: 2px solid ${props => props.theme.colors.DarkBlue};
    cursor: default;
    display: flex;
    flex-wrap: wrap;
    -moz-box-pack: justify;
    justify-content: space-between;
    min-height: 38px;
    outline: currentcolor none 0px !important;
    position: relative;
    transition: all 100ms ease 0s;
    box-sizing: border-box;
    &:hover {
        cursor: pointer;
        color: ${props => props.theme.colors.DarkPink};
        svg {
            fill: ${props => props.theme.colors.DarkPink};
        }
        span {
            background: ${props => props.theme.colors.DarkPink};
        }
    }
`;
