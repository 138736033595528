import { css } from "@emotion/react";
import styled from "@emotion/styled";
import { useEffect, useRef, useState } from "react";
import {
    fullRemoveNotification,
    notifications,
    removeNotification,
} from "redux/slices/NotificationSlice";
import { useAppDispatch } from "redux/store";

import { ReactComponent as XSVG } from "../../assets/general/X.svg";

const NotificationBar = ({ id }: { id: string }) => {
    const { type, onClick, message } = notifications[id];
    const dispatch = useAppDispatch();
    const [exit, setExit] = useState(false);
    const [width, setWidth] = useState(0);
    const interval = useRef<NodeJS.Timer | null>(null);

    const handleStartTimer = () => {
        const id = setInterval(() => {
            setWidth(prev => {
                if (prev < 100) {
                    return prev + 0.5;
                }

                clearInterval(id);
                return prev;
            });
        }, 30);
        interval.current = id;
    };

    const handlePauseTimer = () => {
        if (interval.current) {
            clearInterval(interval.current);
        }
    };

    const handleCloseNotification = () => {
        handlePauseTimer();
        setExit(true);
        setTimeout(() => {
            dispatch(removeNotification(id));
        }, 400);
    };

    useEffect(() => {
        if (width === 100) {
            handleCloseNotification();
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [width]);

    useEffect(() => {
        handleStartTimer();
        return () => {
            handlePauseTimer();
        };
    }, []);

    const handleOnClick = () => {
        if (onClick) {
            onClick();
            handlePauseTimer();
            setExit(true);
            setTimeout(() => {
                dispatch(fullRemoveNotification(id));
            }, 400);
        }
    };

    return (
        <Container
            exit={exit}
            onMouseEnter={handlePauseTimer}
            onMouseLeave={handleStartTimer}
            onClick={handleOnClick}
            clickable={onClick !== undefined}
            type={type}>
            <ExitButton onClick={handleCloseNotification} />
            <p>{message}</p>
            <Bar width={width} type={type} />
        </Container>
    );
};

export default NotificationBar;

const ExitButton = styled(XSVG)`
    position: absolute;
    top: 10px;
    right: 10px;
    width: 10px;
    height: 10px;
    cursor: pointer;
`;

const Container = styled.div<{
    exit: boolean;
    type: "success" | "error" | "info";
    clickable: boolean;
}>`
    position: relative;
    box-shadow: 0 0 10px rgba(0, 0, 0, 0.3);
    border-radius: 10px;
    overflow: hidden;
    margin-bottom: 20px;
    animation: SlideLeft 0.4s;
    animation-fill-mode: forwards;
    width: 300px;
    background-color: ${props => {
        switch (props.type) {
            case "success":
                return "#87f387";
            case "error":
                return "#f07979";
            case "info":
                return "#fff";
        }
    }};

    @keyframes SlideLeft {
        0% {
            margin-left: 120%;
        }

        100% {
            margin-left: 0;
        }
    }

    &:hover {
        ${({ clickable, type }) =>
            clickable &&
            css`
                cursor: pointer;
                background-color: ${((): string => {
                    switch (type) {
                        case "success":
                            return "#549754";
                        case "error":
                            return "#a05252";
                        case "info":
                            return "#d6d6d6";
                    }
                })()};
            `}
    }
    ${({ exit }) =>
        exit &&
        css`
            animation: SlideRight 0.4s;
            animation-fill-mode: forwards;
        `}

    @keyframes SlideRight {
        0% {
            margin-left: 0;
        }

        100% {
            margin-left: 120%;
        }
    }
    p {
        margin: 0;
        padding: 10px;
    }
`;

const Bar = styled.div<{ width: number; type: "success" | "error" | "info" }>`
    height: 10px;
    width: ${props => props.width}%;
    background-color: ${props => {
        switch (props.type) {
            case "success":
                return "#00b300";
            case "error":
                return "#d40303";
            case "info":
                return "#00b300";
        }
    }};
`;
