import styled from "@emotion/styled";
import { ReactComponent as XSVG } from "assets/general/X.svg";
import MonthKey from "common/types/monthKey";
import { Writer } from "common/Writer";
import DropSelector from "components/general/dropselctor";
import useReport from "hooks/reports/useReport";
import { addMonthsTo } from "lib/date/addMonthsTo";
import { toMmmYy } from "lib/date/reStringifyMonth";
import { useState } from "react";
import { setBudget } from "redux/slices/BudgetSlice";
import { setCustomers } from "redux/slices/CustomersSlice";
import { addNotification } from "redux/slices/NotificationSlice";
import { setTransfers } from "redux/slices/TransfersSlice";
import { useAppDispatch } from "redux/store";

interface Props {
    close: () => void;
    data: {
        type: "expenses" | "revenue" | "customers" | "cashflow";
        month: MonthKey;
        item: string;
        baseValue?: number;
        numberOfMonths?: number;
    };
}

type AdjustTypes = "Fixed amount" | "Percentage";

const FillCells = ({ close, data }: Props) => {
    const [adjustType, setAdjustType] = useState<AdjustTypes>("Fixed amount");
    const [startValueType, setStartValueType] = useState<"Fixed" | "Average">(
        "Fixed",
    );

    const [numberOfMonths, setNumberOfMonths] = useState<number | undefined>(
        data.numberOfMonths,
    );
    const [baseValue, setBaseValue] = useState<number | undefined>(
        data.baseValue || undefined,
    );
    const [adjust, setAdjust] = useState<number>();
    const dispatch = useAppDispatch();
    const report = useReport();

    const handleStartValueType = (e: React.ChangeEvent<HTMLSelectElement>) => {
        setStartValueType(e.target.value as "Fixed" | "Average");
        if (e.target.value === "Average" && report) {
            const historicalData =
                data.type === "revenue"
                    ? report.revenue
                    : report.expenses;
            const avg =
                report.reportDates.lastTwelveMonths.reduce(
                    (acc, month: MonthKey) => {
                        return (
                            acc +
                            (historicalData.actual[month]?.[data.item] || 0)
                        );
                    },
                    0,
                ) / 12;
            setBaseValue(Math.round(avg));
        }
    };

    const submit = () => {
        let error = false;
        if (numberOfMonths === undefined) {
            dispatch(
                addNotification({
                    message: "Input number of months",
                    type: "error",
                }),
            );
            error = true;
        }
        if (baseValue === undefined) {
            dispatch(
                addNotification({
                    message: "Input base value",
                    type: "error",
                }),
            );
            error = true;
        }
        if (!data) {
            dispatch(
                addNotification({
                    message: "No month selected",
                    type: "error",
                }),
            );
            error = true;
        }
        if (error) return;

        const months = numberOfMonths as number;
        const value = baseValue as number;
        const month = data.month;

        if (adjust) {
            for (let index = 0; index < months; index++) {
                const monthselected = addMonthsTo(month, index);
                const input = Math.round(
                    adjustType === "Fixed amount"
                        ? value + adjust * index
                        : value * (1 + adjust / 100) ** (index + 1),
                );
                if (
                    data?.type === "expenses" ||
                    data?.type === "revenue"
                ) {
                    dispatch(
                        setBudget({
                            stateItem: data?.type,
                            month: monthselected,
                            item: data.item,
                            input,
                        }),
                    );
                } else if (data?.type === "cashflow") {
                    dispatch(
                        setTransfers({
                            month: monthselected,
                            item: data.item,
                            input,
                        }),
                    );
                } else if (data?.type === "customers") {
                    dispatch(
                        setCustomers({
                            month: monthselected,
                            item: data.item,
                            input,
                        }),
                    );
                }
            }
        } else {
            for (let index = 0; index < months; index++) {
                const monthselected = addMonthsTo(month, index);
                if (
                    data?.type === "expenses" ||
                    data?.type === "revenue"
                ) {
                    dispatch(
                        setBudget({
                            stateItem: data.type,
                            month: monthselected,
                            item: data.item,
                            input: value,
                        }),
                    );
                } else if (data?.type === "cashflow") {
                    dispatch(
                        setTransfers({
                            month: monthselected,
                            item: data.item,
                            input: value,
                        }),
                    );
                } else if (data?.type === "customers") {
                    dispatch(
                        setCustomers({
                            month: monthselected,
                            item: data.item,
                            input: value,
                        }),
                    );
                }
            }
        }
    };

    return (
        <>
            <ModalContent>
                <h4>
                    Fill data for {report && report.items[data.item].name} from{" "}
                    {toMmmYy(data.month)}
                </h4>
                <ExitSVg onClick={close} />
                {/* <div style={{ textAlign: "center" }}>
                Starting cell  {(data?.type == "expensesBudget" || data?.type == "revenueBudget") && items && data?.item ? items[data?.item].name : data?.item} {toMmmYy(data?.month)}{" "}
            </div> */}
                <div>
                    <span style={{ marginRight: 10 }}>Months forward</span>
                    <Input
                        defaultValue={numberOfMonths}
                        onChange={e => {
                            const number = e.target.value
                                ? +e.target.value
                                : undefined;
                            setNumberOfMonths(
                                number ? (number > 36 ? 36 : number) : number,
                            );
                        }}
                    />
                </div>
                <div>
                    <span style={{ marginRight: 10 }}>
                        <select
                            value={startValueType}
                            onChange={handleStartValueType}>
                            <option value='Fixed'>Fixed start value: </option>
                            <option value='Average'>
                                Avgerage last 12 months:
                            </option>
                        </select>
                    </span>
                    {startValueType === "Average" ? (
                        <strong>
                            {baseValue && Writer.FormatCurrency(baseValue)}
                        </strong>
                    ) : (
                        <Input
                            value={baseValue}
                            onChange={e =>
                                setBaseValue(
                                    e.target.value
                                        ? +e.target.value
                                        : undefined,
                                )
                            }
                        />
                    )}
                </div>
                <Inputs>
                    <div style={{ width: 130 }}>
                        <span
                            style={{
                                textAlign: "center",
                                width: "100%",
                                display: "block",
                            }}>
                            Adjust Type
                        </span>
                        <DropSelector
                            options={["Fixed amount", "Percentage"]}
                            selected={adjustType}
                            onChange={value => setAdjustType(value)}
                        />
                    </div>
                    <div style={{ width: 130 }}>
                        <span>Adjust By</span>
                        <input
                            onChange={e =>
                                setAdjust(
                                    e.target.value
                                        ? +e.target.value
                                        : undefined,
                                )
                            }
                        />
                    </div>
                </Inputs>
                <Go onClick={submit}>Go</Go>
                <p></p>
            </ModalContent>
        </>
    );
};

export default FillCells;

const Inputs = styled.div`
    display: flex;
    > * {
        margin: 4px;
    }
    input {
        width: 70px;
    }
`;

const Input = styled.input`
    width: 70px;
`;

const ExitSVg = styled(XSVG)`
    position: absolute;
    top: 11px;
    right: 11px;
    width: 20px;
    height: 20px;
    cursor: pointer;
    &:hover {
        fill: ${props => props.theme.colors.DarkPink};
    }
`;
const Go = styled.div`
    margin: 10px 0 0 10px;
    padding: 10px;
    width: 100px;
    display: flex;
    justify-content: center;
    align-items: center;
    color: white;
    background-color: ${props => props.theme.colors.LightBlue};
    border-radius: 5px;
    cursor: pointer;
    &:hover {
        background-color: #1d62b1;
    }
`;

const ModalContent = styled.div`
    position: relative;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    height: 300px;
    width: 450px;
    background-color: ${props => props.theme.colors.Pink};
    border-radius: 20px;
    padding: 20px;
    box-shadow: 0px 0px 10px rgba(0, 0, 0, 0.5);
    overflow: hidden;
    > div {
        margin: 5px;
    }
`;
