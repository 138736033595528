import { OrganisationIdentifier } from "common/types/objects/Organisation";
import useCurrentOrg from "hooks/reports/useCurrentOrg";
import { useState } from "react";
import { useSyncDataMutation } from "redux/rtkQuery/organisations";

const editBudgetUrl = "https://go.xero.com/Reports/EditBudget.aspx";

/**
    A (slighty hacky) temporary solution untill we make our own budget editor

    Should allow them to get directly to xero and we can reload their budget when they close the window/tab.
 */
const EditBudget = ({
    organisation,
}: {
    organisation: OrganisationIdentifier;
}) => {
    const [showHint, setShowHint] = useState(false);
    const organisationId = useCurrentOrg()?.organisationId;
    const [syncData] = useSyncDataMutation({
        fixedCacheKey: "syncData",
    });

    const showXero = () => {
        if (
            confirm(
                `A new window will open where you can edit your budget in xero.  
                
Ensure you are logged in for ${organisation.name}.
                
When you are done, close the window (click save first!!) and we will automatically reload your numbers from xero.`,
            )
        ) {
            const editorWindow = window.open(editBudgetUrl);
            if (!editorWindow) {
                alert(
                    "A popup blocker prevented us from opening the budget editor.  Enable popups for this site (check the message in your browser) or edit your budget at: " +
                        editBudgetUrl,
                );
            } else {
                setShowHint(true);
                const timer = setInterval(function () {
                    if (!editorWindow || editorWindow.closed) {
                        setShowHint(false);
                        clearInterval(timer);
                        organisationId && syncData({ organisation, years: 1 });
                    }
                }, 500);
            }
        }
    };

    return (
        <>
            <span
                onClick={() => showXero()}
                style={{ cursor: "pointer", position: "relative" }}>
                Edit budget
                {showHint ? (
                    <span
                        style={{
                            position: "absolute",
                            bottom: "-10px",
                            display: "block",
                            width: "100px",
                            whiteSpace: "nowrap",
                            fontSize: "9px",
                            color: "red",
                            fontStyle: "italic",
                        }}>
                        Please close the xero window to reload budget numbers
                    </span>
                ) : (
                    ""
                )}
            </span>
        </>
    );
};

export default EditBudget;
