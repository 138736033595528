import styled from "@emotion/styled";
import { Portal } from "components/general/portal";
import OutsideAlerter from "components/inputs/OutsideAlerter";
import { ReactNode, useRef, useState } from "react";
import { theme } from "style/theme";

interface MoreInfoProps {
    children?: string;
    helpTitle?: string;
    helpContent?: ReactNode;
}

const MoreInfo = ({ children, helpContent, helpTitle }: MoreInfoProps) => {
    const [show, setShow] = useState(false);
    const [clicked, setClicked] = useState(false);
    const [position, setPosition] = useState({ top: 0, left: 0 });
    const ref = useRef<HTMLHeadingElement>(null);

    const changePortalZIndex = (number: string) => {
        const element = document.getElementById("portal");
        if (element) {
            element.style.zIndex = number;
        }
    };

    return (
        <span
            onMouseLeave={() => {
                changePortalZIndex("0");
                !clicked && setShow(false);
            }}>
            <MoreInfoContainer
                ref={ref}
                onMouseEnter={e => {
                    setPosition({ left: e.clientX, top: e.clientY });
                    setShow(true);
                    changePortalZIndex("10");
                }}
                onClick={() => {
                    setClicked(true);
                    setShow(true);
                    changePortalZIndex("10");
                }}>
                {children}
            </MoreInfoContainer>
            <Portal>
                {show && (
                    <OutsideAlerter
                        callBack={() => {
                            setShow(false);
                            setClicked(false);
                            changePortalZIndex("0");
                        }}>
                        <MoreInfoDetail
                            style={{
                                ...position,
                            }}>
                            {helpTitle && <h2>{helpTitle}</h2>}
                            {helpContent}
                        </MoreInfoDetail>
                    </OutsideAlerter>
                )}
            </Portal>
        </span>
    );
};

export default MoreInfo;

const MoreInfoDetail = styled.div`
    position: fixed;
    z-index: 999999;
    width: 300px;
    max-height: 300px;
    overflow-y: auto;
    background-color: ${theme.colors.LightAqua};
    /* border-radius: 10px; */
    border: 2px solid ${theme.colors.DarkBlue};
    padding: 5px;
    overflow-x: hidden;
`;

const MoreInfoContainer = styled.span`
    cursor: help;
    text-decoration: underline;

    &:after {
        display: inline-block;
        content: "ⓘ";
        padding: 0 2px;
        text-decoration: none;
        position: relative;
        top: -5px;
    }
`;
